import { NavigationContainerRefWithCurrent } from "@react-navigation/native";
import URLMapper, {
  Screens,
  getPathFromScreen,
} from "../components/Navigation/URLMapper";
import { Platform } from "react-native";

export type Path = string;

// returns the path with values in it, cross platform
// i.e /racecards/ante-post/jun/2024
export const buildPath = (
  navRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>
): Path => {
  const currentRoute = navRef.getCurrentRoute()?.name;
  const routeParams = navRef.getCurrentRoute()?.params;
  if (!currentRoute) return;

  // we should ignore any routes that use params not defined in the root param list
  // i.e for racecards?date=2021-01-01&utm_source=google,
  // we should only return /racecards/2021-01-01
  // and ignore the utm_source param
  const corePath = getPathFromScreen(currentRoute as Screens);
  const validParams = getValidParams(routeParams, corePath);

  let basePath = URLMapper.config.screens[currentRoute];
  basePath = (basePath?.path || basePath).split(":")[0];

  // this messes up the navigation between the festival hub pages and other pages across the site on web
  if (Platform.OS !== "web" && currentRoute === "FestivalHub") {
    // handle festival hub as its a weird url
    if (basePath != "/") basePath = basePath + "/";
  }

  const stringParams = validParams && Object.values(validParams).join("/");
  let val = stringParams ? basePath + stringParams : (basePath as Path);
  if (val !== "/" && val.endsWith("/")) val = val.slice(0, -1);

  // replace any double slashes with a single slash
  val = val.replace(/\/{2,}/g, "/");

  return val;
};

// Valid params are those that are defined in the root param list, others are ignored.
//
// validKeys({ date: '2024-01-01', utm_source: 'google' }, '/racecards/:date?'));
//  -> { date: '2024-01-01' }
// validKeys({ date: '2024-01-01', utm_source: 'google', racecards: 'x' }, '/racecards/:date?');
//  -> { date: '2024-01-01' }
// validKeys({ utm_source: 'google' }, '/racecards/:date?'));
//  -> {}
function getValidParams(routeParams: object, corePath: string) {
  // Extract keys from corePath
  const pathKeys = corePath.match(/:\w+/g) || [];

  // Create a new object with keys from routeParams that are included in pathKeys
  const filteredParams = {};
  pathKeys.forEach((key: string) => {
    const cleanKey = key.substring(1); // Remove the colon
    if (routeParams?.hasOwnProperty(cleanKey)) {
      filteredParams[cleanKey] = routeParams[cleanKey];
    }
  });

  return filteredParams;
}
