import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import Gap from "../../../../../Gap";
import ScrollBar from "../../../../../ScrollBar";
import ContentPreviewLoader from "../ContentPreviewLoader";
import variables from "../../../../../../styles/variables";
import { useResponsive } from "../../../../../../hooks/useResponsive";

const { spacing } = variables;

export default function ContentScrollBarLoader() {
  const [contentListItemWidth, setContentListItemWidth] = useState(0);
  const { isXSmallOnly } = useResponsive();

  return (
    <View style={styles.contentScrollBar}>
      <ScrollBar childWidth={contentListItemWidth}>
        {[0, 1, 2, 3, 4].map((idx) => (
          <View style={{ display: "flex", flexDirection: "row" }} key={idx}>
            <ContentPreviewLoader
              displayAs="card"
              style={
                isXSmallOnly
                  ? styles.smallScreenScrollBarItem
                  : styles.scrollBarItem
              }
            />
            <Gap />
          </View>
        ))}
      </ScrollBar>
    </View>
  );
}

const styles = StyleSheet.create({
  contentScrollBar: {
    marginBottom: spacing.xsmall,
  },
  scrollBarItem: {
    minWidth: 300,
    maxWidth: 300,
  },
  smallScreenScrollBarItem: {
    minWidth: 200,
    maxWidth: 200,
  },
});
