import { fetchGet, fetchPost } from "..";
import { Search } from "../../components/Header/components/SearchClient";
import { Meta, Pagination } from "../../types/responses/APIResponse";
import {
  PreferencesNotificationMethods,
  PreferencesNotificationTypes,
  PreferencesMember,
} from "../../types/preferences";
import {
  CountryCode,
  HorseTrackerType,
  RunnersWithOdds,
  TrackedEntry,
  TrackedHorse,
} from "../../types/races/horse";
import { FinishStateChar } from "../../types/finishState";

// GET - Tracker Horses

type TrackedHorsesResponse = {
  meta: Meta;
  pagination: Pagination;
  horses: HorseTrackerType[];
};

export const getTrackedHorses = async (
  page: number
): Promise<TrackedHorsesResponse> => {
  const resp = await fetchGet("member/tracker/horses", {
    "pagination[page]": page,
  });
  return resp;
};

export const getTrackedHorsesByIds = async (ids: number[]): Promise<any> => {
  const formattedIds = ids.map((id) => `ids[]=${id}`);
  return await fetchGet(`member/tracker/horses/ids?${formattedIds.join("&")}`);
};

// GET - Tracker Entries

type TrackedEntriesResponse = {
  meta: Meta;
  pagination: Pagination;
  runners: TrackedEntry[];
};

export const getTrackedEntries = async (
  page: number
): Promise<TrackedEntriesResponse> => {
  const resp = await fetchGet(`member/tracker/upcoming`, {
    "pagination[page]": page,
  });
  return resp;
};

// UPDATE - Tracked Horse

type TrackedHorseResponse = {
  meta: Meta;
  horse: TrackedHorse;
};

export const updateTrackedHorse = async (
  id: number,
  comment?: string,
  notifications?: boolean
): Promise<TrackedHorseResponse> =>
  await fetchPost(
    `member/tracker/horses/${id}`,
    {},
    {
      horse: {
        comment,
        notifications,
      },
    },
    "PUT"
  );

// DELETE - Tracked Horse

export const deleteTrackedHorse = async (
  id: number
): Promise<TrackedHorseResponse> =>
  await fetchPost(`member/tracker/horses/${id}`, {}, {}, "DELETE");

// GET - Search Horses

export type HorseHit = {
  name: string;
  slug: string;
  country_code: CountryCode;
  last_run: {
    date?: string;
    finish_position: number;
    finish_text: FinishStateChar;
    runner_count: number;
  };
};

export type HorseSearchHit = {
  id: number;
  score: number;
  type: string;
  horse: HorseHit;
};

type HorseSearchResponse = {
  hits: HorseSearchHit[];
  search: Search;
  meta: Meta;
  pagination: Pagination;
};

export const getSearchHorseResults = async (
  query: string
): Promise<HorseSearchResponse> =>
  await fetchGet("member/tracker/horses/search", { query });

//  GET - Tracker Preferences

export type TrackerPreferencesResponse = {
  meta: Meta;
  member: PreferencesMember;
  notification_methods_available: PreferencesNotificationMethods;
  notification_types_available: PreferencesNotificationTypes;
};

export const getTrackerPreferences =
  async (): Promise<TrackerPreferencesResponse> =>
    await fetchGet("member/preferences");

export type UpdatedPreferences = {
  preference: {
    member: PreferencesMember;
  };
};

export const updateTrackerPreferences = async (
  preferences: UpdatedPreferences
) => await fetchPost("member/preferences", {}, preferences, "PUT");

type TrackedEntryOddsResponse = {
  meta: Meta;
  runners: RunnersWithOdds[];
};

export const getTrackerEntryOdds = async (
  ids: number[]
): Promise<TrackedEntryOddsResponse> => {
  const runnerIds = ids.map((id) => `runner_ids[]=${id}`).join("&");
  return await fetchGet(`racing/runner/odds?${runnerIds}`);
};
