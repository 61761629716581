import React, { useState, useEffect } from "react";
import { StyleSheet, Platform, TouchableOpacity } from "react-native";
import RText from "../RText";
import Gap from "../Gap";
import Icon from "../Icon";
import usePressableProps from "../../hooks/usePressableProps";
import { prepareParams } from "../Screens/Results/utils";
import { getReplay } from "../../client/watch/replays";

interface VideoLinkProps {
  race: Race | RaceResult;
  videoType: "replay" | "preview";
  openInNewTab?: boolean;
  trackSlug?: string;
  start_time_scheduled?: string;
  displayIconOnly?: boolean;
  textSize?: string;
  textWeight?: string;
  iconSize?: string;
}

const VideoLink: React.FC<VideoLinkProps> = ({
  race,
  videoType,
  openInNewTab = false,
  trackSlug,
  start_time_scheduled,
  displayIconOnly = false,
  textSize = "sm",
  textWeight = "bold",
  iconSize = "xlarge",
}) => {
  const [replayAvailable, setReplayAvailable] = useState(false);
  const params = prepareParams({
    time: start_time_scheduled || race?.start_time_scheduled,
    track: trackSlug || race?.meeting?.track?.slug,
  });

  useEffect(() => {
    const checkReplayAvailability = async () => {
      const track = trackSlug || race?.meeting?.track?.slug;

      try {
        await getReplay({
          date: params.date,
          time: params.time,
          track: params.track,
          prerollToken: null,
          withSource: false,
        });
        // If getReplay successfully returns, it means the replay is available
        setReplayAvailable(true);
      } catch (error) {
        console.error("Failed to fetch replay:", error);
        setReplayAvailable(false);
      }
    };

    checkReplayAvailability();
  }, [race, trackSlug, start_time_scheduled]);

  const options = {
    replayOption: {
      text: "Watch Replay",
      color: "secondary",
      iconName: "playCircled",
      pressableProps: usePressableProps({
        screen: "IndividualReplay",
        params: params,
        openInNewTab: openInNewTab,
      }),
      shouldHover: true,
    },
    previewOption: {
      text: "Watch Preview",
      color: "secondary",
      iconName: "playCircled",
      pressableProps: usePressableProps({
        screen: "IndividualRacecard",
        params: params,
        openInNewTab: openInNewTab,
      }),
      shouldHover: true,
    },
    awaitingOption: {
      text: "Awaiting Replay",
      color: "disabled",
      iconName: "playCircledUnavailable",
      pressableProps: null,
      shouldHover: false,
    },
  };

  let selectedOption;

  if (videoType == "preview") {
    selectedOption = "previewOption";
  } else {
    selectedOption = replayAvailable ? "replayOption" : "awaitingOption";
  }

  const hoverRef = React.useRef();

  const handleMouseEnter = () => {
    if (hoverRef.current && Platform.OS === "web") {
      hoverRef.current.style.transform = "scale(1.1)"; // Example hover style
    }
  };

  const handleMouseLeave = () => {
    if (hoverRef.current && Platform.OS === "web") {
      hoverRef.current.style.transform = "scale(1)"; // Revert style
    }
  };

  return (
    <TouchableOpacity
      ref={hoverRef}
      style={[styles.centeredRow, { marginRight: 10 }]}
      {...(options[selectedOption].pressableProps
        ? options[selectedOption].pressableProps
        : {})}
      onMouseEnter={
        options[selectedOption].shouldHover ? handleMouseEnter : () => {}
      }
      onMouseLeave={
        options[selectedOption].shouldHover ? handleMouseLeave : () => {}
      }
    >
      {!displayIconOnly && (
        <RText
          weight={textWeight}
          size={textSize}
          color={options[selectedOption].color}
        >
          {options[selectedOption].text}
        </RText>
      )}
      <Gap size="xsmall" />
      <Icon name={options[selectedOption].iconName} size={iconSize} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  centeredRow: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  // Include your hover style and any other styles you need
  hover: {
    transform: [{ scale: 1.1 }],
  },
});

export default VideoLink;
