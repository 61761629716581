import React, { MouseEvent, useEffect, useRef, useState } from "react";
import {
  View,
  StyleSheet,
  Animated,
  Pressable,
  Platform,
  GestureResponderEvent,
  LayoutChangeEvent,
} from "react-native";
import { useAnimatedTranslation } from "../../hooks/useAnimatedTranslation";
import variables from "../../styles/variables";
import { parseSecondsInMinutes } from "../../utils/TimeCalculator";
import Icon from "../Icon";
import RText from "../RText";
import { ControlBarProps } from "./ControlBar";

const isWeb = Platform.OS === "web";

type ProgressBarProps = {
  isLive?: boolean;
  timer: ControlBarProps["timer"];
  isAdPlaying?: boolean;
  onSeek?: (seconds: number) => void;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  isLive,
  timer,
  isAdPlaying,
  onSeek,
}) => {
  const { panResponder, pan, limits, startAnimation } =
    useAnimatedTranslation();
  const [widthBar, setWidthBar] = useState(1);
  const [isCursorHovered, setIsCursorHovered] = useState(false);
  const [isProgressBarHovered, setIsProgressBarHovered] = useState(false);
  const containerRef = useRef(null);
  const [currentSeek, setCurrentSeek] = useState(0);
  const [valueXHovered, setValueXHovered] = useState(0);
  const [timeToSeek, setTimeToSeek] = useState("00:00");
  const { duration, currentTime } = timer;
  const [progress, setProgress] = useState(0);

  const handlePress = (e: GestureResponderEvent & MouseEvent) => {
    if (isAdPlaying) return;
    const toValue =
      isWeb && containerRef.current
        ? e.nativeEvent.offsetX
        : e.nativeEvent.locationX;
    startAnimation(toValue);
  };

  const handleBarHover = (offsetX) => {
    const secondsToSeek = Math.floor((offsetX / widthBar) * duration);
    const time = parseSecondsInMinutes(secondsToSeek);
    setTimeToSeek(time);
  };

  const handleLayout = (e: LayoutChangeEvent) => {
    const { width } = e.nativeEvent.layout;
    setWidthBar(width);
  };

  useEffect(() => {
    if (isWeb) {
      containerRef.current.addEventListener("mousemove", ({ nativeEvent }) =>
        setValueXHovered(nativeEvent.offsetX)
      );
      return () => {
        containerRef.current?.removeEventListener(
          "mousemove",
          setValueXHovered
        );
      };
    }
  }, []);

  useEffect(() => {
    handleBarHover(valueXHovered);
  }, [valueXHovered]);

  useEffect(() => {
    limits.max = widthBar;
  }, [widthBar]);

  useEffect(() => {
    pan.addListener((value) => setCurrentSeek(value.x));
    return () => pan.removeAllListeners();
  }, []);

  useEffect(() => {
    if (currentSeek > 0) {
      const secondsToSeek = Math.floor((currentSeek / widthBar) * duration);
      if (secondsToSeek <= 0) return;
      if (isNaN(secondsToSeek)) return;
      if (isNaN(duration)) return;
      console.log("seeking to", secondsToSeek);
      onSeek?.(secondsToSeek);
    }
  }, [currentSeek]);

  useEffect(() => {
    if (isLive) setProgress(100);
    else if (duration && duration > 0) {
      setProgress((currentTime / duration) * 100);
      // setLoaded;
      // pan.x.setValue(progress * widthBar);
    } else {
      setProgress(100);
    }
  }, [timer]);

  return (
    <>
      {isProgressBarHovered && (
        <View
          style={[
            styles.timeToSeek,
            {
              transform: [{ translateX: valueXHovered }],
            },
          ]}
        >
          <RText color="white">{timeToSeek}</RText>
        </View>
      )}
      <View style={styles.container} onLayout={handleLayout}>
        <Pressable
          ref={containerRef}
          onHoverIn={() => setIsProgressBarHovered(true)}
          onHoverOut={() => setIsProgressBarHovered(false)}
          style={[styles.container]}
          onPress={handlePress}
        >
          <View style={styles.line}>
            <Animated.View
              {...panResponder.panHandlers}
              // style={[styles.progress, { width: pan.x }]}
              style={[styles.progress, { width: `${progress}%` }]}
            />
          </View>
        </Pressable>
        <Animated.View
          {...panResponder.panHandlers}
          // style={[
          //   styles.cursorContainer,
          //   {
          //     width: pan.x.interpolate({
          //       inputRange: [0, widthBar],
          //       outputRange: [0, widthBar],
          //       extrapolate: "clamp",
          //     }),
          //   },
          // ]}
          // animatedValueX={pan.x}
          // animatedValueY={pan.y}
          style={[styles.cursorContainer, { width: `${progress}%` }]}
        >
          <Pressable
            onHoverIn={() => setIsCursorHovered(true)}
            onHoverOut={() => setIsCursorHovered(false)}
          >
            <Icon
              style={{
                ...styles.cursor,
                transform: isCursorHovered ? [{ scale: 2 }] : [],
              }}
              name="playHead"
              fill="palette.rtv.white"
            />
          </Pressable>
        </Animated.View>
      </View>
    </>
  );
};

export default ProgressBar;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
  line: {
    width: "100%",
    height: 2,
    backgroundColor: "white",
  },
  progress: {
    height: 2,
    minWidth: "0%",
    maxWidth: "100%",
    backgroundColor: variables.colors.palette.rtv.secondary,
  },
  cursorContainer: {
    position: "absolute",
    height: 0,
    minWidth: "0%",
    maxWidth: "100%",
  },
  cursor: {
    position: "absolute",
    right: 0,
    top: -9,
    height: 20,
    width: 3,
  },
  timeToSeek: {
    position: "absolute",
    bottom: "50%",
    left: 0,
  },
});
