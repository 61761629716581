import { Platform } from "react-native";

type EnvName =
  | "internet Explorer"
  | "edge"
  | "chrome"
  | "firefox"
  | "safari"
  | "unknown"
  | "android"
  | "ios";

const isWeb = Platform.OS === "web";
const isAndroid = Platform.OS === "android";
const isIOS = Platform.OS === "ios";

export const getEnvName = (): EnvName => {
  if (isWeb) {
    const userAgent = navigator.userAgent;

    if (/MSIE|Trident/.test(userAgent)) {
      return "internet Explorer";
    } else if (/Edge/.test(userAgent)) {
      return "edge";
    } else if (/Chrome/.test(userAgent)) {
      return "chrome";
    } else if (/Firefox/.test(userAgent)) {
      return "firefox";
    } else if (/Safari/.test(userAgent)) {
      return "safari";
    } else {
      return "unknown";
    }
  } else if (isAndroid) {
    return "android";
  } else if (isIOS) {
    return "ios";
  } else {
    return "unknown";
  }
};
