import { STORAGE_KEYS, storage } from "../../../utils/storage";

export const getFirebaseStorageToken = async (): Promise<string | null> => {
  try {
    const token: string = await storage.load({
      key: STORAGE_KEYS.FIREBASE_TOKEN,
    });
    // debugger;
    console.log("** FB TOKEN", token);
    return token;
  } catch (e) {
    return null;
  }
};

export const setFirebaseStorageToken = async (token: string) =>
  await storage.save({
    key: STORAGE_KEYS.FIREBASE_TOKEN,
    data: token,
  });

export const unsetFirebaseStorageToken = async () =>
  await storage.remove({
    key: STORAGE_KEYS.FIREBASE_TOKEN,
  });
