import React, { ReactNode } from "react";
import variables from "../../styles/variables";
import { useResponsive } from "../../hooks/useResponsive";
import { defaultSizeMap, smallSizeMap } from "./utils";
import { ViewStyle } from "react-native";

const {
  colors: fc,
  weight: fw,
  size: fs,
  family: ff,
  margin: mg,
} = variables.font;

export type RTextFont = keyof typeof ff;
export type RTextColors = keyof typeof fc;
export type RTextWeight = keyof typeof fw;
export type RTextSize = keyof typeof fs;

type Props = {
  children: ReactNode;
  level: "1" | "2" | "3" | "4" | "5" | "6";
  color?: RTextColors;
  uppercase?: boolean;
  capitalize?: boolean;
  boldText?: boolean;
  lightText?: boolean;
  sizeOverride?: RTextSize;
  style?: ViewStyle;
  useSmallSizeMap?: boolean;
};

const RHeading = ({
  children,
  color = "primary",
  level = "1",
  uppercase = false,
  capitalize,
  boldText,
  lightText = false,
  sizeOverride,
  style = undefined,
  useSmallSizeMap = true,
}: Props) => {
  const { isSmallAndDown } = useResponsive();
  const sizeMap =
    isSmallAndDown && useSmallSizeMap ? smallSizeMap : defaultSizeMap;
  const weight = (level === "1" && !lightText) || boldText ? "bold" : "light";
  const headingStyle = {
    fontFamily: `${ff["secondary"]}_${fw[weight]}`,
    color: fc[color],
    fontSize: fs[sizeOverride || sizeMap[`h${level}`]],
    margin: 0,
    marginBottom: mg[level],
    ...style,
  };

  if (uppercase) headingStyle.textTransform = "uppercase";
  if (capitalize) headingStyle.textTransform = "capitalize";
  if (!children) return null;
  const renderHeading = () => {
    switch (level) {
      case "1":
        return <h1 style={headingStyle}>{children}</h1>;
      case "2":
        return <h2 style={headingStyle}>{children}</h2>;
      case "3":
        return <h3 style={headingStyle}>{children}</h3>;
      case "4":
        return <h4 style={headingStyle}>{children}</h4>;
      case "5":
        return <h5 style={headingStyle}>{children}</h5>;
      case "6":
        return <h6 style={headingStyle}>{children}</h6>;
    }
  };

  return renderHeading();
};

export default RHeading;
