import React from "react";
import { StyleSheet, View } from "react-native";
import Gap from "../../../../Gap";
import variables from "../../../../../styles/variables";
import TextLoader from "../shared/TextLoader";
import MultilineTextLoader from "../shared/MultilineTextLoader";
import { useResponsive } from "../../../../../hooks/useResponsive";

const PreferenceHeaderLoader = () => {
  const { isSmallAndDown } = useResponsive();

  return (
    <View style={styles.preferenceHeader}>
      <View style={styles.container}>
        <MultilineTextLoader numberOfLines={isSmallAndDown ? 3 : 1} />
      </View>
      <View style={styles.buttonsContainer}>
        <TextLoader width={isSmallAndDown ? 50 : 110} height={35} />
        <Gap />
        <TextLoader width={isSmallAndDown ? 50 : 110} height={35} />
      </View>
    </View>
  );
};

export default PreferenceHeaderLoader;

const { spacing, colors } = variables;

const styles = StyleSheet.create({
  container: {
    flex: 1.3,
  },
  preferenceHeader: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: spacing.small,
    paddingLeft: spacing.small,
  },
  buttonsContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});
