import React from "react";
import { View } from "react-native";
import SectionHeaderLoader from "../shared/SectionHeader/SectionHeaderLoader";
import ContentScrollBarLoader from "../shared/ContentScrollBar/ContentScrollBarLoader";

const ColumnistsLoader = () => {
  return [0, 1, 2, 3].map((idx) => (
    <View key={idx}>
      <SectionHeaderLoader />
      <ContentScrollBarLoader />
    </View>
  ));
};

export default ColumnistsLoader;
