import { StyleSheet, View } from "react-native";
import React from "react";
import Gap from "../../../../Gap";
import TextLoader from "../shared/TextLoader";
import SquareLoader from "../shared/SquareLoader";
import { useResponsive } from "../../../../../hooks/useResponsive";
import variables from "../../../../../styles/variables";

const { colors, spacing } = variables;

const BettingAccountRowLoader = () => {
  const { isSmallAndDown } = useResponsive();
  const styles = isSmallAndDown ? smallScreenStyles : bigScreenStyles;

  const ButtonLoader = () =>
    isSmallAndDown ? (
      <SquareLoader width={spacing.medium} />
    ) : (
      <TextLoader height="100%" />
    );

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <SquareLoader width={spacing.medium} />
        <View style={styles.account}>
          <TextLoader height="80%" />
        </View>
      </View>
      <View style={styles.buttons}>
        <View style={styles.button}>
          <ButtonLoader />
        </View>
        <Gap />
        <View style={styles.button}>
          <ButtonLoader />
        </View>
      </View>
    </View>
  );
};

export default BettingAccountRowLoader;

const smallScreenStyles = StyleSheet.create({
  container: {
    paddingVertical: spacing.xsmall,
    borderTopColor: colors.palette.neutral.x100,
    borderStyle: "solid",
    borderTopWidth: 1,
  },
  row: {
    flexDirection: "row",
  },
  account: {
    height: 35,
    width: "90%",
    marginBottom: spacing.xxsmall,
    marginLeft: spacing.xxsmall,
  },
  buttons: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    paddingTop: spacing.xxsmall,
  },
  button: {
    width: "10%",
    height: spacing.medium,
  },
});

const bigScreenStyles = StyleSheet.create({
  container: {
    paddingVertical: 15,
    borderTopColor: colors.palette.neutral.x100,
    borderStyle: "solid",
    borderTopWidth: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  row: {
    flexDirection: "row",
    flex: 4.5,
    alignItems: "center",
  },
  account: {
    marginLeft: spacing.xsmall,
    justifyContent: "center",
    width: "90%",
    height: 35,
  },
  buttons: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    flex: 2,
  },
  button: {
    width: "40%",
    height: spacing.medium,
  },
});
