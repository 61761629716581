import React from "react";
import { View, StyleSheet } from "react-native";
import variables from "../../../../../../styles/variables";
import TextLoader from "../TextLoader";

const { spacing } = variables;

const SectionHeaderLoader = () => {
  return (
    <View style={styles.header}>
      <TextLoader width={"50%"} />
      <View style={styles.showMore}>
        <TextLoader />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    marginVertical: spacing.xsmall,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  showMore: {
    alignItems: "flex-end",
    width: "15%",
  },
});

export default SectionHeaderLoader;
