import { Platform } from "react-native";
import React from "react";
import { Helmet } from "react-helmet-async";

type MetaData = {
  name: string;
  content: string;
};

type Props = {
  title?: string;
  metaData?: MetaData[];
};

const MetaData = ({ title, metaData }: Props) => {
  if (Platform.OS !== "web") return;
  return (
    <Helmet>
      {title ? <title>{title}</title> : null}
      {metaData?.map((meta, index) => {
        if (!meta.name || !meta.content) return;
        if (meta.name.includes("og:")) {
          return (
            <meta key={index} property={meta.name} content={meta.content} />
          );
        } else {
          return <meta key={index} name={meta.name} content={meta.content} />;
        }
      })}
    </Helmet>
  );
};

export default MetaData;
