import React from "react"; // This is actually used. It's just not detected by the linter
import { StyleSheet, Image, Pressable, View } from "react-native";
import Icon from "../Icon";
import RImage from "../RImage/RImage";
import RText from "../RText";

type Props = {
  thumbnailUrl?: string; // use an image as the thumnail
  thumbnailText?: string; // overlay some text on the thumbnail
  style?: any;
  onPress?: () => void;
  size?: "small" | "large";
  loading?: "lazy" | "eager";
  alt?: string;
};

const ContentPreviewIcon: React.FC<Props> = ({
  thumbnailUrl,
  thumbnailText,
  style,
  onPress,
  size = "large",
  loading = "lazy",
  alt = undefined,
  ...rest
}) => {
  let resolution = size === "small" ? 300 : 1000;

  const IconComponent = ({ children }) => {
    if (onPress)
      return (
        <Pressable style={[styles.container, style]} onPress={onPress}>
          {children}
        </Pressable>
      );
    return <View style={[styles.container, style]}>{children}</View>;
  };

  return (
    <IconComponent>
      {thumbnailUrl && (
        <RImage
          style={styles.image}
          source={thumbnailUrl}
          resolution={resolution}
          loading={loading}
          alt={alt}
        />
      )}
      {thumbnailText && (
        <>
          <View style={styles.overlay}></View>
          <View style={styles.thumbnailTextView}>
            <RText family="secondary" size="lg" style={styles.text}>
              {thumbnailText}
            </RText>
          </View>
        </>
      )}
    </IconComponent>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 5,
    objectFit: "cover",
  },
  thumbnailTextView: {
    width: "100%",
    height: "100%",
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
  },
  overlay: {
    width: "100%",
    height: "100%",
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    opacity: 0.5,
    position: "absolute",
  },
  text: {
    margin: "auto",
    padding: 10,
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
  },
});

export default React.memo(ContentPreviewIcon);
