import { View, StyleSheet, TouchableOpacity } from "react-native";
import RText from "../RText";
import variables from "../../styles/variables";
import Icon from "../Icon";
import { useResponsive } from "../../hooks/useResponsive";
import React from "react";
import { ContentListItem } from "../../types/content";
import { formatRaceTime } from "../../utils/TimeCalculator";
import CardLabel from "./CardLabel";
import { UpcomingRace } from "../ComingupBar/ComingupBar";
import { prepareParams } from "../Screens/Results/utils";
import lazyImport from "../../utils/lazyImport/lazyImport";
import usePressableProps from "../../hooks/usePressableProps";
import RSuspense from "../../utils/RSuspense/RSuspense";

const Flag = lazyImport(() => import("../Flag/Flag"));

const { colors } = variables;

type Props = {
  race: UpcomingRace;
  description: string;
  RMG?: boolean;
  nextRace?: boolean;
} & ContentListItem;

const Race = ({ race, description, RMG, nextRace, handleLayout }: Props) => {
  const { isMediumAndUp, isMediumAndDown } = useResponsive();
  const cardWidth = isMediumAndUp ? 240 : 190;
  const innerWidth = isMediumAndUp ? "96%" : "95%";
  const outlined = RMG && colors.palette.rtv.tertiary;

  //data for navigation to individual racecard
  const flagName = `flag_${race.country}`;

  const pressableProps = usePressableProps({
    screen: "IndividualRacecard",
    params: prepareParams({
      startTime: race.start_time_scheduled,
      date: race.meeting_date,
      track: race.track_slug,
    }),
  });

  return (
    <TouchableOpacity {...pressableProps}>
      <View
        style={[
          { width: cardWidth, height: 100, backgroundColor: outlined },
          styles.container,
          { borderTopRightRadius: 5 },
        ]}
        onLayout={handleLayout}
      >
        <View
          style={[
            { width: innerWidth, height: "90%" },
            styles.innerContainer,
            styles.innerBorderRadiusTopRight,
            !RMG && styles.nonRMGBorder,
          ]}
        >
          <View style={styles.flexRow}>
            <RSuspense>
              <Flag name={flagName} />
            </RSuspense>
            <RText
              family="primary"
              capitalize
              size={isMediumAndDown ? "sm" : "md"}
              numberOfLines={1}
              weight="bold"
            >
              {`${formatRaceTime(
                race.start_time_scheduled
              )} ${race.track_name.capitalize()}`}
            </RText>
          </View>
          <View style={styles.flexRow}>
            <RText
              numberOfLines={2}
              ellipsizeMode="tail"
              size={isMediumAndDown ? "sm" : "md"}
              lineHeight={isMediumAndDown ? 18 : 20}
            >
              {description?.capitalize()}
            </RText>
          </View>
        </View>
        {RMG && (
          <Icon
            name="rLogo"
            size="small"
            fill="palette.rtv.primary"
            style={{ position: "absolute", right: 8, bottom: 7 }}
          />
        )}
      </View>
      {nextRace && (
        <CardLabel
          name="next off"
          color={colors.palette.rtv.tertiary}
          style={{
            position: "absolute",
            top: RMG ? -16 : -14, // accounts for border
            right: RMG ? 20 : 25, // extra 5 accounts for border
          }}
          textStyle={{
            paddingHorizontal: 8,
            color: colors.palette.neutral.x0,
          }}
        />
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    minWidth: 240,
    maxWidth: 240,
  },
  innerContainer: {
    backgroundColor: "white",
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    // justifyContent: "space-between",
    paddingHorizontal: 8,
    // paddingTop: 8,
  },
  innerBorderRadiusTopRight: {
    borderTopRightRadius: 3,
  },
  nonRMGBorder: {
    borderColor: colors.palette.neutral.x100,
    borderStyle: "solid",
    borderWidth: 1,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 4,
  },
});

export default Race;
