import React from "react";
import { View, StyleSheet } from "react-native";
import Layout from "../../../../Layout/Layout";
import TextLoader from "../shared/TextLoader";
import ContentImagePreviewLoader from "../shared/ContentImagePreviewLoader";
import MultilineTextLoader from "../shared/MultilineTextLoader";
import SocialIconsLoader from "../shared/SocialMediaIconsLoader";
import { useResponsive } from "../../../../../hooks/useResponsive";
import variables from "../../../../../styles/variables";

const { spacing } = variables;

const NewsPostLoader = () => {
  const { isMediumAndDown } = useResponsive();

  const MainContent = ({ width }) => {
    return (
      <View style={{ width: width }}>
        <>
          {/* header */}
          <View style={styles.header}>
            <TextLoader width={"100%"} height={spacing.medium} />
          </View>
          <View>
            {/* publication date*/}
            <View style={styles.date}>
              <TextLoader height={spacing.small} width="120" />
            </View>
          </View>

          <View style={styles.section}>
            {/* article content*/}
            <MultilineTextLoader
              numberOfLines={9}
              style={styles.section}
              lineHeight={spacing.small}
            />
          </View>
          <View
            style={[
              styles.section,
              {
                flexDirection: "row",
                justifyContent: "space-between",
              },
            ]}
          >
            {/* social icons*/}
            <SocialIconsLoader />
            {/* next post button*/}
            <View style={{ justifyContent: "center" }}>
              <TextLoader height={spacing.small} />
            </View>
          </View>
        </>
      </View>
    );
  };

  const RelatedPosts = ({ width }) => {
    return (
      <View style={{ width: width }}>
        <TextLoader height={spacing.medium} style={styles.relatedPostsHeader} />
        <MultilineTextLoader
          numberOfLines={8}
          style={styles.relatedPostsContent}
        />
      </View>
    );
  };

  const Content = () => {
    return isMediumAndDown ? (
      <View>
        <MainContent width="100%" />
        <RelatedPosts width="100%" />
      </View>
    ) : (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <MainContent width="65%" />
        <RelatedPosts width="30%" />
      </View>
    );
  };

  return <Content />;
};

const styles = StyleSheet.create({
  header: {
    marginBottom: spacing.medium,
  },
  section: {
    marginVertical: spacing.xsmall,
  },
  date: {
    marginTop: spacing.xxxsmall,
  },
  relatedPostsHeader: {
    marginTop: spacing.xsmall,
  },
  relatedPostsContent: {
    marginVertical: spacing.small,
  },
});
export default NewsPostLoader;
