import { Article } from "../../../types/news";
import { cleanSlug } from "../../../utils/cleanSlug";
import { DEFAULT_NEWS_IMAGE } from "../../Image/images/defaultImages";

export const newsThumbnail = (post: {
  hero?: any;
  pa_image_url?: string;
  placeholder_image_url?: string;
}): string => {
  return (
    post.hero?.placeholder_image_url ||
    (post.hero?.viewer_sources?.length && post.hero?.viewer_sources[0]?.url) ||
    post.pa_image_url ||
    post.placeholder_image_url ||
    DEFAULT_NEWS_IMAGE
  );
};

export const pinnedPostSummary = (post: Article): string => {
  if (!post) return;
  if (post.summary) return post.summary;
  if (post.slug_line) return post.slug_line;
  if (post.content) return post.content;
};

type Props = {
  currentArticle: {
    slug: string;
    id: number;
  };
};

export const articlesForAtom = (data) => {
  return data.map(({ slug }) => ({
    slug,
  }));
};

export const getNextAndPreviousArticle = (
  currentArticleSlug,
  articlesForNavigation
) => {
  const articles = articlesForNavigation;
  if (!articles || !articles.length) return;

  let nextArticle = null;
  let previousArticle = null;

  const index = articles.findIndex(
    (article) => cleanSlug(article.slug) === cleanSlug(currentArticleSlug)
  );

  if (articles[index + 1]) nextArticle = articles[index + 1];
  if (articles[index - 1]) previousArticle = articles[index - 1];

  return {
    nextArticle,
    previousArticle,
  };
};
