import { fetchGet } from "..";
import { Horse } from "../../types/races/horse";
import { Meta } from "../../types/responses/APIResponse";

type HorseProfileResponse = {
  meta: Meta;
  horse: Horse;
};

export const getHorseProfile = async (
  horseSlug: string
): Promise<HorseProfileResponse> =>
  await fetchGet(`racing/horses/${horseSlug}`);

export const getProfileReplays = async (
  type: string,
  slug: string,
  page: number = 1,
  limit: number = 25
) =>
  await fetchGet(`videos/replays/${type}/${slug}`, {
    "pagination[page]": page,
    "pagination[limit]": limit,
  });
