import React from "react";
import { View } from "react-native";
import Gap from "../Gap";
import { useAtom } from "jotai";
import { advertisingAtom, globalFullscreenAtom } from "../../atoms";
import { CenterAdvert } from "./CenterAdvert";

export const HeaderAdvert = ({
  noPadding,
  gap = false,
}: {
  noPadding?: boolean;
  gap?: boolean;
}) => {
  const [isGlobalFullScreen] = useAtom(globalFullscreenAtom);
  const [adverts] = useAtom(advertisingAtom);
  const adRequestComplete = Object.keys(adverts || {}).length > 0;

  // we assume that this is always set, so the layout shift is minimal.
  // if the advert isn't set, we just return null
  if (isGlobalFullScreen) return null;
  if (!adRequestComplete)
    return (
      <View
        style={{
          width: "100%",
          aspectRatio: 8.14 / 1,
        }}
      ></View>
    );

  // now there may or may not be a specific header ad.
  // if there is, we render it, otherwise we return null
  if (adverts?.header)
    return <CenterAdvert ad={adverts.header} header gap={gap} />;

  // now decide whether to render a small gap or nothing,
  // depending on if the page wants to have edge to edge content, i.e
  // members benefits page
  if (noPadding) return null;
  return <Gap size="small" vertical />;
};
