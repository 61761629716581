import React, { FC, forwardRef, useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Platform,
  useWindowDimensions,
  Modal,
} from "react-native";
import RTextInput from "../../Screens/Account/components/RTextInput";
import Icon from "../../Icon";
import Dropdown from "../../Filters/Dropdown/";
import variables from "../../../styles/variables";
import { useResponsive } from "../../../hooks/useResponsive";
import Gap from "../../Gap";
import SuggestedSearchResultsContainer from "./SuggestedSearchResultsContainer";
import { useCloseFromOutside } from "../../../hooks/useCloseFromOutside";
import AnimatedDropdownWrapper from "../../Animations/DropdownAnimation";
import useUser from "../../../hooks/useUser";

const { colors, spacing } = variables;

const CATEGORIES = [
  "All Categories",
  "Horses",
  "Jockeys",
  "Trainers",
  "Racecourses",
  "News",
  "Pages",
];

type SearchWrapperProps = {
  open: boolean;
  children: React.ReactNode;
  headerHeight?: number;
};

const SearchWrapper: FC<SearchWrapperProps> = ({
  open,
  children,
  headerHeight,
}) => {
  const isWeb = Platform.OS === "web";
  return (
    <Modal
      presentationStyle={isWeb ? "pageSheet" : "overFullScreen"}
      animationType="fade"
      transparent
      visible={open}
      style={{ height: "100%", width: "100%" }}
    >
      <View
        style={{
          marginTop: Platform.select({
            web: headerHeight + 10,
            native: headerHeight,
          }),
        }}
      >
        {children}
      </View>
    </Modal>
  );
};

type SearchProps = {
  open: boolean;
  setOpenSearch: (open: boolean) => void;
  headerHeight?: number;
};

const Search = ({ open, setOpenSearch, headerHeight }: SearchProps) => {
  const { height, width } = useWindowDimensions();
  const { isLargeAndUp } = useResponsive();
  const MIN_SEARCH_LENGTH = 2;
  const searchInputRef = useRef(null);
  const expandedSearchRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(CATEGORIES[0]);
  const [expandedSearch, setExpandedSearch] = useState(false);
  const [searchHeight, setSearchHeight] = useState(0);
  const isPlatformWeb = Platform.OS === "web";

  const { isLoggedIn } = useUser();

  const searchRef = useCloseFromOutside(
    open,
    (value: boolean, e?: MouseEvent | TouchEvent) =>
      handleOutsideSearch(value, e)
  );

  useEffect(() => {
    if (searchTerm.length >= MIN_SEARCH_LENGTH) {
      setExpandedSearch(true);
    } else {
      setExpandedSearch(false);
    }
  }, [searchTerm]);

  const handleOutsideSearch = (isOpen: boolean, e) => {
    if (!isPlatformWeb) {
      setOpenSearch(isOpen);
      setExpandedSearch(isOpen);
      return;
    }

    const node = expandedSearchRef.current;
    if (node) {
      const isClickInside = node.contains(e.target);

      if (isClickInside) return;
      setOpenSearch(isOpen);
      setExpandedSearch(isOpen);
      return;
    }
    setOpenSearch(isOpen);
    setExpandedSearch(isOpen);
  };

  const xPosStyle = isLoggedIn ? { right: 150 } : { right: 132 };

  return (
    <SearchWrapper headerHeight={headerHeight} open={open}>
      <View
        style={[
          styles.backdrop,
          {
            width,
            height: open ? height : 0,
            marginTop: Platform.select({
              web: 0,
            }),
            top: Platform.select({
              native: 0,
            }),
          },
        ]}
      />

      <AnimatedDropdownWrapper open={open} isSearch>
        <View ref={searchRef}>
          <View
            onLayout={(e) => setSearchHeight(e.nativeEvent.layout.height)}
            style={[
              styles.searchContainer,
              {
                backgroundColor: isLargeAndUp
                  ? colors.palette.neutral.x50
                  : colors.palette.rtv.primary,
              },
            ]}
          >
            {isLargeAndUp && <View style={[styles.triangle, xPosStyle]} />}
            <View style={styles.inputContainer}>
              <RTextInput
                ref={searchInputRef}
                placeholder={"Search for..."}
                onChangeText={(e) => setSearchTerm(e)}
                onBlur={() => null}
                value={searchTerm}
                isSecure={false}
                style={{
                  width: isLargeAndUp ? "50%" : "100%",
                  ...styles.searchInput,
                }}
                focusOnMount={open}
                onClear={() => setSearchTerm("")}
                maxLength={256} // users should not be able to enter more than 256 chars
              />
              <View
                style={[
                  styles.iconContainer,
                  {
                    height: Platform.select({
                      web: "93%",
                      ios: "82.5%",
                      android: 50,
                    }),
                  },
                ]}
              >
                <Icon
                  size="larger"
                  name="searchBorderlessPink"
                  fill={"palette.rtv.secondary"}
                />
              </View>
            </View>

            <Gap />

            <View
              style={[
                styles.dropdownContainer,
                {
                  marginTop: isLargeAndUp ? 10 : 13,
                },
              ]}
            >
              <Dropdown
                // colorTheme={isLargeAndUp ? "primary" : "secondary"} fix colors
                data={CATEGORIES}
                selectedOption={selectedCategory}
                onSelect={(e) => setSelectedCategory(e)}
                justifyContent="center"
                // selectedOptionColor={isLargeAndUp ? "black" : "white"} //fix colors
                containerStyle={[
                  styles.dropdown,
                  { width: isLargeAndUp ? "50%" : "100%" },

                  Platform.OS == "android" && {
                    maxHeight: 50.5,
                    backgroundColor: colors.palette.rtv.primary,
                    borderWidth: isLargeAndUp ? 0 : 1,
                    alignItems: "flex-end",
                    left: 0,
                  },
                ]}
                borderStyle={{
                  width: 1,
                  color: isLargeAndUp ? "primary" : "secondary",
                }}
                fontStyle={{
                  size: "md",
                  weight: "semiBold",
                  ...Platform.select({
                    ios: { color: "white" },
                    android: { color: "white" },
                    web: { color: "black" },
                  }),
                }}
              />
            </View>
          </View>

          <View
            style={{
              zIndex: -1,
              minHeight: Platform.select({
                native: 0,
                web: 0,
              }),
            }}
          >
            <AnimatedDropdownWrapper
              open={expandedSearch}
              style={isPlatformWeb && { zIndex: -1, maxHeight: 0 }}
            >
              <SuggestedSearchResultsContainer
                ref={expandedSearchRef}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectedCategory={selectedCategory}
                setOpenSearch={setOpenSearch}
                setExpandedSearch={setExpandedSearch}
                overlayHeight={height - headerHeight - searchHeight}
              />
            </AnimatedDropdownWrapper>
          </View>
        </View>
      </AnimatedDropdownWrapper>
    </SearchWrapper>
  );
};

export default Search;

const styles = StyleSheet.create({
  backdrop: {
    position: "absolute",
    backgroundColor: "rgba(0,0,0,.7)",
    width: "100%",
    zIndex: -5,
    height: "100%",
  },
  searchContainer: {
    position: "relative",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minHeight: 50,
    paddingHorizontal: spacing.small,
    paddingVertical: spacing.xsmall,
    backgroundColor: colors.palette.rtv.primary,
    ...Platform.select({
      web: {},
      ios: {},
      android: {
        paddingVertical: 2,
      },
    }),
  },
  inputContainer: {
    position: "relative",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "50%",
    ...Platform.select({
      web: {},
      ios: {},
      android: { minHeight: 50 },
    }),
  },
  searchInput: {
    backgroundColor: colors.palette.rtv.white,
    alignSelf: "center",
    paddingHorizontal: spacing.xsmall,
    overflow: "hidden",
  },
  iconContainer: {
    position: "absolute",
    width: 36,
    // right: 2,
    // top: 1.4,
    justifyContent: "center",
    alignItems: "center",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    overflow: "hidden",
  },
  triangle: {
    width: 0,
    height: 0,
    top: -6,
    right: 130,
    backgroundColor: "transparent",
    borderStyle: "solid",
    borderLeftWidth: 15,
    borderRightWidth: 15,
    borderBottomWidth: 15,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: colors.palette.neutral.x50,
    position: "absolute",
  },
  dropdownContainer: {
    position: "relative",
    flexDirection: "row",
    justifyContent: "center",
    width: "50%",
    height: "100%",
  },
  dropdown: {
    borderRadius: 4,
    alignSelf: "flex-start",
    justifyContent: "flex-end",
    marginRight: "auto",
    padding: 7,
    ...Platform.select({
      android: {
        padding: 0,
      },
    }),
  },
});
