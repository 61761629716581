import linking, { Screens } from "../Navigation/URLMapper";
import { pathToRegexp } from "path-to-regexp";

export const getScreenFromPath = (path: string): Screens | null => {
  // remove trailing slash
  if (path.endsWith("/")) {
    path = path.slice(0, -1);
  }

  for (const [key, value] of Object.entries(linking.config.screens)) {
    let screenPath: string;

    if (typeof value === "string") {
      screenPath = value;
    } else if (typeof value === "object" && "path" in value) {
      screenPath = value.path;
    } else {
      continue;
    }

    // remove trailing slash from screenPath
    if (screenPath.endsWith("/")) {
      screenPath = screenPath.slice(0, -1);
    }

    // Create a regular expression from the screenPath
    const regex = pathToRegexp(screenPath);

    // Test the path against the regular expression
    if (regex.test(path) || regex.test("/" + path)) {
      return key as Screens;
    }
  }

  return null; // return null if no match found
};

export const nativeNavFromSlug = (slug: string) => {
  // remove base path from slug
  if (slug.startsWith("racingtv://racingtv.com/")) {
    slug = slug.replace("racingtv://racingtv.com/", "");
  }

  const paramsObj = {};
  const screen = getScreenFromPath(slug); // Determine the screen based on the slug
  if (!screen) return { screen: null, paramsObj: {} };

  const screenConfig = linking.config.screens[screen];
  // Determine the path template, accommodating both string and object configurations
  const pathTemplate =
    typeof screenConfig === "string" ? screenConfig : screenConfig.path;
  const templateSegments = pathTemplate.split("/").filter((segment) => segment); // Remove empty segments
  const slugSegments = slug.split("/").filter((segment) => segment); // Remove empty segments

  // Iterate over template segments to extract dynamic parameters
  templateSegments.forEach((templateSegment, index) => {
    if (templateSegment.startsWith(":")) {
      const paramName = templateSegment.substring(1).replace("?", ""); // Remove the ':' to get the parameter name and remove '?' if present
      if (slugSegments.length > index) {
        // Ensure there's a corresponding slug segment
        paramsObj[paramName] = slugSegments[index];
      }
    }
  });

  // Apply any transformations specified in the screen configuration
  if (typeof screenConfig === "object" && screenConfig.stringify) {
    Object.entries(screenConfig.stringify).forEach(
      ([key, transformFunction]) => {
        if (
          paramsObj[key] !== undefined &&
          typeof transformFunction === "function"
        ) {
          paramsObj[key] = transformFunction(paramsObj[key]);
        }
      }
    );
  }

  // console.log("nativeNavFromSlug:: ", JSON.stringify({ screen, paramsObj }));
  return { screen, paramsObj };
};
