import { ImageBackground } from "react-native";
import React from "react";
import { RImageBackgroundProps } from "./types";

const RImageBackground = ({
  ad,
  style,
  resizeMode = "cover",
  setError,
  adUrl,
}: RImageBackgroundProps) => {
  return (
    <ImageBackground
      style={style}
      resizeMode={resizeMode}
      source={{ uri: ad?.asset?.viewer_sources[0]?.url || adUrl }}
      onError={(err) => {
        console.log("Ad Banner Error", err.nativeEvent.error);
        setError(true);
      }}
    />
  );
};

export default RImageBackground;
