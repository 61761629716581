import { Breadcrumb as BreadcrumbT } from "../../types/breadcrumb";
import { removeGUID } from "../../utils/removeGUID";

export const format = (title: string, removeFormat: boolean) => {
  if (!title) return;

  //if breadcrumb includes a GUID, remove it
  title = removeGUID(title);

  if (
    title.toLowerCase() === "non-runner" ||
    title.toLowerCase() === "non-runners"
  )
    return title;

  if (title.includes("-")) return title.split("-").join(" ").capitalize();

  return removeFormat ? title : title.capitalize();
};

// produces the structured data for the breadcrumbs
export const breadcrumbStructuredData = (breadcrumbs: BreadcrumbT[]) => {
  return {
    itemListElement: breadcrumbs.map((crumb: BreadcrumbT, i: number) => {
      const isLastCrumb = i === breadcrumbs.length - 1;

      let url = `${window.location.host}`;
      const fullUrl = window.location.href;

      url = fullUrl
        .split("/")
        .slice(0, 3 + i)
        .join("/");

      const listItem = {
        "@type": "ListItem",
        position: i + 1,
        name: crumb?.label,
      };

      if (!isLastCrumb) {
        listItem["item"] = url;
      }

      return listItem;
    }),
  };
};
