import { StyleSheet, View } from "react-native";
import React from "react";
import RunnerSilk from "../../../../Racecard/RunnerSilk";
import RText from "../../../../RText/RText";
import { useResponsive } from "../../../../../hooks/useResponsive";
import Gap from "../../../../Gap";
import { Silk } from "../../../../../types/races/runner";

type Props = {
  form: string;
  clothNumber?: number;
  barrierNumber?: number;
  silk?: Silk;
  status: any;
};

const RunnerSilkAndNumbers = ({
  form,
  clothNumber,
  barrierNumber,
  silk,
  status,
}: Props) => {
  const { isSmallAndDown } = useResponsive();

  const formattedForm = (form: string) => {
    if (!form) return;
    return form
      ?.split("")
      .reverse()
      .join("")
      .substring(0, 6)
      .split("")
      .reverse()
      .join("");
  };

  const fontSize = isSmallAndDown ? "sm" : "md";

  const clothNumberMap = (clothNumber) => {
    if (!clothNumber) return;
    if (status?.state === "reserve") return `R${clothNumber}`;
    if (status?.state === "scratched") return `NR`;
    if (!status?.state) return;
    return clothNumber;
  };

  return isSmallAndDown ? (
    <View style={[styles.center, styles.container, { marginBottom: 10 }]}>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <View style={styles.center}>
          <View style={{ paddingRight: 5 }}>
            <RunnerSilk silk={silk?.press_association_file} />
          </View>
        </View>

        <View
          style={{
            flexDirection: isSmallAndDown ? "column" : "row",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <RText size={fontSize} style={styles.alignRight} weight="bold">
            {clothNumberMap(clothNumber)}
          </RText>
          <Gap />
          {barrierNumber ? (
            <RText size={fontSize} style={styles.alignRight}>
              ({barrierNumber})
            </RText>
          ) : (
            <RText> </RText>
          )}
        </View>
      </View>
      <View
        style={{
          position: "absolute",
          right: -15,
          bottom: -5,
          paddingTop: 1,
          top: 48,
        }}
      >
        <RText>{formattedForm(form)}</RText>
      </View>
    </View>
  ) : (
    <View style={[styles.center, styles.container]}>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <View style={styles.center}>
          <View style={{ paddingHorizontal: 6 }}>
            <RunnerSilk silk={silk?.press_association_file} />
          </View>
        </View>
        <View>
          <View
            style={{
              flexDirection: isSmallAndDown ? "column" : "row",
              justifyContent: "flex-start",
            }}
          >
            <RText size={fontSize} weight="bold">
              {clothNumberMap(clothNumber)}
            </RText>
            <Gap />
            {barrierNumber ? (
              <RText size={fontSize}>({barrierNumber})</RText>
            ) : null}
          </View>
          <RText>{formattedForm(form)}</RText>
        </View>
      </View>
    </View>
  );
};

export default RunnerSilkAndNumbers;

const styles = StyleSheet.create({
  container: {
    width: "15%",

    minWidth: 55,
  },
  center: {
    alignItems: "center",
  },
  alignRight: {
    alignItems: "flex-end",
  },
});
