import { StyleSheet, ViewStyle, View } from "react-native";
import React from "react";
import Icon from "../Icon";
import RImage from "../RImage/RImage";
import { BASE_URL } from "../../client";

type Props = {
  style?: ViewStyle;
  silk: any;
  width?: number;
  height?: number;
};

const RunnerSilk = ({ style, silk, width = 45, height = 50 }: Props) => {
  return (
    <View style={styles.container}>
      {silk?.path || silk?.press_association_file?.path ? (
        <RImage
          style={{
            width: width,
            height: height,
            objectFit: "cover",
            ...style,
          }}
          source={BASE_URL + (silk.path || silk.press_association_file?.path)}
        />
      ) : (
        <Icon style={style} name="silkDefault" size="xlarge" />
      )}
    </View>
  );
};

export default React.memo(RunnerSilk);

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
});
