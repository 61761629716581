import { StyleSheet, View } from "react-native";
import React from "react";
import variables from "../../../../../styles/variables";
import TextLoader from "../shared/TextLoader";
import WrappedRowLoader from "./WrappedRowLoader";

const { spacing, colors } = variables;

const NotificationsLoader = () => {
  return (
    <View style={styles.container}>
      <View style={styles.topSection}>
        <View style={{ width: "50%" }}>
          <TextLoader />
        </View>
        <View style={{ width: "30%", height: 30 }}>
          <TextLoader height={30} />
        </View>
      </View>

      <TextLoader height={26} style={styles.headerPadding} />
      {Array.from({ length: 4 }).map((_, index: number) => (
        <WrappedRowLoader key={index} />
      ))}

      <TextLoader style={styles.headerPadding} />
      {Array.from({ length: 3 }).map((_, index: number) => (
        <WrappedRowLoader key={index} />
      ))}
    </View>
  );
};

export default NotificationsLoader;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  topSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
  },
  newNotiHeader: {
    backgroundColor: colors.palette.notification,
  },
  oldNotiHeader: {
    backgroundColor: colors.palette.neutral.x200,
  },
  headerPadding: {
    padding: spacing.xxxsmall,
    paddingLeft: spacing.xxsmall,
  },
  btnContainer: {
    flex: 1,
    width: "80%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowBtn: {
    flexGrow: 1,
    borderWidth: 0,
    maxWidth: 100,
  },
  btnText: {
    color: colors.palette.rtv.secondary,
    fontWeight: "bold",
  },
});
