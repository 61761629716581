export const defaultSizeMap = {
  h1: "xl",
  h2: "lg",
  h3: "md",
  h4: "sm",
  h5: "sm",
  h6: "sm",
};

export const smallSizeMap = {
  h1: "lg",
  h2: "md",
  h3: "sm",
  h4: "sm",
  h5: "sm",
  h6: "sm",
};
