import React from "react";
import { View, StyleSheet } from "react-native";
import variables from "../../../../../styles/variables";
import Icon from "../../../../Icon";
import RText from "../../../../RText";
import RacecardRowLoader from "./RacecardRowLoader";

const { spacing, colors } = variables;

type Props = {
  text?: string;
};

const RacecardByDateLoader = ({ text = "AT-A-GLANCE" }: Props) => {
  return (
    <View>
      <View style={styles.header}>
        <RText family="secondary" weight="bold" size="lg">
          {text}
        </RText>
        <View>
          <Icon name="chevronBoldUp" size="small" fill="palette.neutral.x0" />
        </View>
      </View>
      {[0, 1, 2, 3, 4].map((i: number) => (
        <View
          key={`body${i}`}
          style={
            i == 0
              ? { borderTopWidth: 0 }
              : {
                  borderTopWidth: 1,
                  borderTopColor: colors.palette.neutral.x100,
                  borderStyle: "solid",
                }
          }
        >
          <RacecardRowLoader />
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: spacing.xsmall,
    backgroundColor: colors.palette.neutral.x100,
  },
});

export default RacecardByDateLoader;
