import * as Notifications from "expo-notifications"; // Notifications from "expo-notifications";
// https://reactnavigation.org/docs/configuring-links
import * as Linking from "expo-linking";

const removeSpaces = (string: string) =>
  string?.split(" ").join("-").replace(":", "");

// These should be driven by the SEO recommendations below doc
// https://docs.google.com/spreadsheets/d/1_fMewGSE1_Uk1ikA_ayY3skM-1cnEehItqrgyBhVa_I/edit?pli=1#gid=1814533701

export type Screens = keyof typeof linking.config.screens;

export const getPathFromScreen = (screen: Screens) => {
  return linking.config.screens[screen]?.path || linking.config.screens[screen];
};

// Checks if a URL belongs to internal hosts.
export const isInternalLink = (url: string) => {
  const internalHosts = [
    "localhost:",
    "racing-tv.pages.dev",
    "racingtv.com",
    "ngrok",
  ];

  return internalHosts.some((baseUrl) => url.includes(baseUrl));
};

export const linking = {
  prefixes: ["racingtv://", "https://www.racingtv.com"],
  config: {
    screens: {
      Home: "/",
      //   Watch Section
      Watch: "/watch",
      Replays: "/watch/replays/:date?",
      IndividualReplay: "/watch/replays/:date/:track/:time",
      OnDemand: "/watch/on-demand",
      CombinedOnDemand: "/watch/on-demand/:idOrSlug",
      IndividualOnDemand: "/watch/on-demand/:slug/:id",
      CatchUp: "/watch/catch-up/:date?", //?makes param optional
      TvSchedule: {
        path: "/watch/tv-schedule/:date?",
        stringify: { date: (date: string) => date?.toLowerCase() },
      },

      // Races Section
      // Do not add any urls that follow the pattern /racecards/:x/:y
      // as they'll be automatically redirected.
      Racecards: {
        path: "/racecards/:date?",
        stringify: { date: (date: string) => date?.toLowerCase() },
      },
      // Antepost: "/racecards/ante-post/:month?/:year?",
      IndividualRacecard: "/racecards/:date/:track/:time", // /racecards/2022-10-20/wolverhampton/1305
      // IndividualAntepostRacecard: "/racecards/ante-post/:date/:time/:slug",
      NonRunners: "/racecards/non-runners/:date?",

      TomorrowsRunners: "/racecards/runners/tomorrow",
      TodaysRunners: "/racecards/runners/:date?",

      // Results Section
      // Do not add any urls that follow the pattern /results/:a/:b/:c/:d
      // as they'll be automatically redirected.
      FastResults: "/results/fast",
      Results: "/results/:date?",
      IndividualResult: {
        path: "/results/:date/:track/:time", // /results/2022-10-20/wolverhampton/1305
        stringify: {
          track: (track: string) => removeSpaces(track)?.toLocaleLowerCase(),
        },
      },

      //   Tips Sections
      NapOfTheDay: "/tips/nap-of-the-day",
      RTVTipsters: "/tips/:date?",
      // AllTips: "/tips/all/:tabName?",
      IndividualTipster: "/tipsters/:slug/:date?",

      // Profiles
      IndexOrProfile: "/profiles/:type/:slug?",

      //   News Sections
      AllNews: "/news", // used to be /news?date
      // StableTours: "/news/stable-tours",

      //TODO - once news by date and all news are separated - create separate urls to fix page tracking (AllNews: "/news" and NewsByDate: "/news/:date")
      Latest: "/news/latest",
      Highlights: "/news/highlights",
      MostViewed: "/news/most-viewed",
      Columnists: "/news/columnists",
      IndividualColumnist: {
        path: "/news/columnists/:columnist_slug", // columnist slug
      },
      NewsPost: {
        path: "/news/:slug",
      },

      //   Members Section
      Offers: "/offers",
      JoinOffer: "/join/:slug",

      // Benefits section
      Benefits: "/benefits",
      RtvExtra: "/benefits/racing-tv-extra",
      RacecourseOffers: "/benefits/racecourse-offers",
      Rewards4Racing: "/benefits/rewards4racing",
      ClubDays: "/benefits/club-days",
      Magazine: "/benefits/club-magazine",
      BenefitsPage: "/benefits/:slug",
      TvAppAuth: "/members/app",
      // Account Screens
      Tracker: "/my-account/tracker/:tabName?",
      Club: "/my-account/club-days/:year?",
      ForgotPassword: "/my-account/forgot-password",
      ResetPassword: "/my-account/reset-password",
      ActivateDayPass: "/my-account/activate",
      Account: "/my-account/:tabName?",
      // TODO: Other Pages - these should be driven by the CMS
      Competitions: "/competitions",
      IndividualCompetition: "/competitions/:slug",
      Racecourses: {
        path: "/racecourses/:region?",
        stringify: { venue: (venue: string) => removeSpaces(venue) },
      },
      Racecourse: {
        path: "/racecourses/:region/:track/:tabName?",
        stringify: { venue: (venue: string) => removeSpaces(venue) },
      },
      // Racecourses: "/racecourses/:country?/:venue?/:section?",
      // Racecourse: {
      //   path: "/racecourses/:venue/:section?",
      //   stringify: { venue: (venue: string) => removeSpaces(venue) },
      // },
      Podcasts: "/podcasts",
      Podcast: "/podcast",
      //About Section
      ContactUs: "/contact",
      HelpCentre: "/help-centre",
      General: "/help-centre/general",
      Sky: "/help-centre/sky",
      Online: "/help-centre/online",
      Extra: "/help-centre/extra",
      IOS: "/help-centre/ios",
      Virgin: "/help-centre/virgin",
      Vodafone: "/help-centre/vodafone",
      DayPass: "/help-centre/day-pass",
      International: "/help-centre/international",
      Mobile: "/help-centre/mobile",
      ClubDaysHelp: "/help-centre/clubdays",
      Android: "/help-centre/android",
      Freeview: "/help-centre/freeview",
      TermsAndConditions: "/terms-conditions",
      PrivacyPolicy: "/privacy-policy",
      RMGPressRelease: "/press-releases",
      //App Section
      Apps: "/apps",
      //Betting Section
      FreeBets: "/horse-racing-free-bets",
      ResponsibleGambling: "/responsible-gambling",
      HorseRacingCalendar: "/horse-racing-calendar/:month?/:year?",
      FestivalHub: "/:festivalSlug/:tabName?", // ------- i.e /cheltenham/tips
      NotFound: "*",
    },
  },

  async getInitialURL() {
    // Check if app was opened from a deep link
    const url = await Linking.getInitialURL();
    // console.log("\n******* getInitialUrl():: url", url);
    if (url != null) {
      return url;
    }

    // Handle URL from expo push notifications
    const response = await Notifications.getLastNotificationResponseAsync();
    // console.log("\n******* getInitialUrl():: response", response);
    return response?.notification.request.content.data.url;
  },

  subscribe(listener) {
    const onReceiveURL = ({ url }: { url: string }) => listener(url);

    // Listen to incoming links from deep linking
    const eventListenerSubscription = Linking.addEventListener(
      "url",
      onReceiveURL
    );

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        const url = response.notification.request.content.data?.url;

        // Let React Navigation handle the URL
        listener(url);
      }
    );

    return () => {
      // Clean up the event listeners
      eventListenerSubscription.remove();
      subscription.remove();
    };
  },
};

export const screens = linking.config.screens;

export default linking;
