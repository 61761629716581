import { useEffect, useState } from "react";

export const useScript = (
  url,
  attributes = {},
  condition = false,
  onLoad = () => null
) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (condition && !initialized) {
      // Check if the script already exists
      if (!document.querySelector(`script[src="${url}"]`)) {
        const script = document.createElement("script");
        script.src = url;
        script.onload = onLoad;
        Object.keys(attributes).forEach((key) => {
          script.setAttribute(key, attributes[key]);
        });

        document.body.appendChild(script);
        setInitialized(true);
      }
    }
  }, [url, attributes, condition]);
};
