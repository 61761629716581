import { userAgentIsSSR } from "../utils/userAgent";
import { useScript } from "./useScript";

export const useZendesk = (isAuthModalOpen, showTabBar) => {

  if(userAgentIsSSR()) return

  const zendeskScriptUrl =
    "https://v2.zopim.com/?4L2L98iTNdVwnVP2uZbfeMJTewy6xD9K";

  const attributes = {
    async: true,
    charset: "utf-8",
    type: "text/javascript",
  };

  const onLoad = () => {
    window.zESettings = {
      webWidget: {
        offset: {
          horizontal: "0px",
          vertical: showTabBar ? "60px" : "0px",
          mobile: {
            horizontal: "-8px",
            vertical: "60px",
          },
        },
      },
    };
  };

  useScript(zendeskScriptUrl, attributes, isAuthModalOpen, onLoad);
};
