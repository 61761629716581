// Prototype extensions

// capitalize each word in a string with exceptions

// test string
// console.log(
// "WWW.RACINGTV.COM UK AND IRELAND BRITISH STALLION STUDS EBF MAIDEN STAKES (5) (DIV 1) (DIV 2) ROSCOMMONRACECOURSE.IE HANDICAP A AN AND AS BUT BY DE FOR IF IN NOR OF OFF ON ONLY OR PER PLUS SO TO UP VIA WITH YET YO 3YO MCGRADY TV I.N.H. EBF QUINNBET PKF CLASS MKM HKJC HKIR SP TRL GBB (GBB) (GBB YO ALLWEATHER (ALLWEATHER) SPORTYBET BETKING BETPLAY SKYBET MELBET DRAFTKINGS 1XBET FANDUEL PREMIERBET PLAYABETS BETPAWA BETCITY 500.COM F12.BET MRJACK.BET ESTRELABET 22BET CAGNES-SUR-MER ST-JEAN RACINGTV CWM CS HD UK USA N/A NSPCC RE-GEN RE GEN D.I DI (D.I) DII D.II (D.II) BETVICTOR Q.R. (Q.R.) JSR VICKERS.BET UK&IRELAND JTC IHRB BHA BANGOR-ON-DEE NHS AT THE RACES D'AMOUR L'AMOUR M'ENGAGE T'ÉCLAIR O'CLOCK 1F 149Y 67E bha rgb stratford-upon-avon stratford-on-avon qipco pj fbc uko mot 1St 2ND 3rd 4tH we're she'd he'll they've can't won'T abi's qeii ii iii iv vi vii viii ix ‘our “i 'i ’i D'oise l'obelisque D'OR i'll i‘ll i’ll i'm i‘m i’m i'd i‘d i’d i've i‘ve i’ve betmgm/ebf mcnae macgarve betmgm rhino.bet jcb betuk wsb trustatrader jpr ek ccr naf nh caseih".capitalize()
// );

// prettier-ignore
String.prototype.capitalize = function () {
  // convert the entire string to lower case to ensure consistent capitalization
  return this.toLowerCase()
    .split(" ") // split string into array of words assuming words are separatated by spaces
    .map((word, index, array) => {

      // keep urls the same (i.e. don't capitalize them) regardless of their position in the string
      if (isURL(word)) return word;

      // capitalize exceptions (e.g. mcgrady should become McGrady)
      // make sure to include:
      // - words that end in punctuation or that end in "'s"
      // - words that include punctuation (e.g. betmgm/ebf should become BetMGM/EBF)

      if (
        capitalizedExceptions[word] ||
        /[.,\/#!$%^&*;:{}=\-_`~()]/.test(word.slice(-1)) && capitalizedExceptions[word.slice(0, -1)] ||
        word.slice(word.length - 2).startsWith("'s" || "‘s" || "’s") && capitalizedExceptions[word.slice(0, -2)] ||
        /[.,\/#!$%^&*;:{}=\-_`~()]/.test(word) && word.split(/[.,\/#!$%^&*;:{}=\-_`~()]/).some((part) => capitalizedExceptions[part])) {
        return capitalizeExceptions(word)
      };

      // unless a url, always capitalize the first word before checking for other exceptions
      if (index === 0) word = capitalizeWord(word);

      // capitalize words following d', l', m', t', o' (e.g. d'Oise, l'Obelisque, d'Or)
      if ((word[0]?.toLowerCase() !== "i") && (word[1] == "'" || word[1] == "‘" || word[1] == "’")) {
        word = capitalizeAfterApostrophe(word)
        return word
      };

      // capitalize names starting with mc or mac (e.g. McNae, MacGregor)
      if (word[0] === "m" && word[1] === "c" || word[0] === "m" && word[1] === "a" && word[2] === "c") {
        word = capitalizeAfterMcOrMac(word)
        return word
      };

      // capitalize words following a number (e.g. 6F, 1M)
      if (word[0]?.match(/\d+/)) return capitalizeAfterNumber(word);

      // capitalize chars and words following punctuation
      if (word.match(/([./:—!"“&?'‘’])([a-zA-Z]+|\s|$)/g)) {
        return capitalizeAfterPunctuation(word)};

      // capitalize each part of a hyphenated word
      if (word.includes("-")) {
        return word.split("-").map(part => capitalizeWord(part)).join("-");
      } // else capitalize word
      else {
        return capitalizeWord(word);
      }
    })
    // change the array back into a string
    .join(" ");
};

String.prototype.toCamelCase = function () {
  return (this.slice(0, 1).toLowerCase() + this.slice(1))
    .replace(/([-_ ]){1,}/g, " ")
    .split(/[-_ ]/)
    .reduce((cur, acc) => {
      return cur + acc[0].toUpperCase() + acc.substring(1);
    });
};

String.prototype.toKebabCase = function () {
  return this.replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();
};

String.prototype.capitalizeFirst = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

//capitalize the first letter of the word, or the second letter if the first char is a bracket
const capitalizeWord = (word: string) => {
  if (word.charAt(0) === "(") {
    return "(" + word.charAt(1).toUpperCase() + word.slice(2);
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};

//test for a url
const isURL = (word: string) => {
  // define a regular expression pattern to match URLs with optional protocol, "www.", and suffix
  const urlPattern =
    /(?:https?:\/\/)?(?:www\.)?\w+\.(com|org|net|co\.uk|co\.za|ie|info|io|biz|me|tv|ca|us)\b/g;

  // use the match method to find all matches of the pattern in the input text
  const url = word.match(urlPattern);

  // return the array of matched URLs
  return url;
};

const ordinalSuffixes = ["st", "rd", "nd", "th"];

//tests for a number, capitalizes letters after a number
function capitalizeAfterNumber(word: string) {
  // use a regular expression to match a number followed by a letter
  const regex = /(\d)([a-zA-Z-]+)/g;

  // use replace with a callback function to capitalize the matched letter(s)
  const result = word.replace(regex, (match, number, letters) => {
    // don't capitalize if number is followed by the letters 'y' and 'o'
    if (letters === "yo" || ordinalSuffixes.includes(letters)) return match;
    return number + capitalizeWord(letters);
  });

  return result;
}

function capitalizeExceptions(word: string) {
  // capitalize exceptions (e.g. mcgrady should become McGrady)
  if (capitalizedExceptions[word]) return capitalizedExceptions[word];

  //if word ends in punctuation, return capitalization with punctuation
  if (
    /[.,\/#!$%^&*;:{}=\-_`~()]/.test(word.slice(-1)) &&
    capitalizedExceptions[word.slice(0, -1)]
  ) {
    return capitalizedExceptions[word.slice(0, -1)] + word.slice(-1);
  }

  if (
    word.slice(word.length - 2).startsWith("'s" || "‘s" || "’s") &&
    capitalizedExceptions[word.slice(0, -2)]
  ) {
    return capitalizedExceptions[word.slice(0, -2)] + "'s";
  }

  //if word includes a capitalized exception or multiple exceptions, return the word with the exception(s) capitalized
  if (
    /[.,\/#!$%^&*;:{}=\-_`~()]/.test(word) &&
    word
      .split(/[.,\/#!$%^&*;:{}=\-_`~()]/)
      .some((part) => capitalizedExceptions[part])
  ) {
    const parts = word.split(/[.,\/#!$%^&*;:{}=\-_`~()]/);

    //find position of the punctuation
    const punctuationIndex = word.search(/[.,\/#!$%^&*;:{}=\-_`~()]/);

    //find punctuation mark
    const punctuation = word[punctuationIndex];

    const capitalizedParts = parts.map((part) => {
      return capitalizedExceptions[part] || part;
    });

    return capitalizedParts.join(punctuation);
  }
  return word;
}

//tests for a word that starts with d', l', m', t', o' (e.g. d'Oise, l'Obelisque, d'Or, O'Brien), capitalizes the letter after the apostrophe
function capitalizeAfterApostrophe(word) {
  if (word.slice(word.length - 2).startsWith("'" || "‘" || "’")) return;
  // ignore words that end in 's

  //this breaks unless we separate the three types of apostrophe, the api data uses all three types
  if (word.includes("‘")) {
    const [one, two] = word.split("‘");
    return [
      one === "o" ? one.toUpperCase() : one,
      two.charAt(0).toUpperCase() + two.slice(1),
    ].join("'");
  }

  if (word.includes("’")) {
    const [one, two] = word.split("’");
    return [
      one === "o" ? one.toUpperCase() : one,
      two.charAt(0).toUpperCase() + two.slice(1),
    ].join("'");
  }

  if (word.includes("'")) {
    const [one, two] = word.split("'");
    return [
      one === "o" ? one.toUpperCase() : one,
      two.charAt(0).toUpperCase() + two.slice(1),
    ].join("'");
  }
}

function capitalizeAfterPunctuation(inputText) {
  // Use a regular expression to match words and letters following specified punctuation marks
  const regex = /([./:—!"“&?'‘’])([a-zA-Z]+|\s|$)/g;

  // Use replace with a callback function to capitalize matched words and letters
  const result = inputText.replace(regex, (match, punctuation, text) => {
    if (match == "'s" || contractions.includes(match)) {
      return match; // Return the original match without capitalization if word is 'we're' or ends in 's (e.g. Leicester's)
    }
    // Capitalize words
    if (text.trim() !== "") {
      return punctuation + text.charAt(0).toUpperCase() + text.slice(1);
    }
    // Keep punctuation as is
    return match;
  });

  return capitalizeWord(result);
}

function capitalizeAfterMcOrMac(word) {
  // for words starting with mc, capitalize the first and third letters
  if (word[0] === "m" && word[1] === "c") {
    return (
      word.charAt(0).toUpperCase() +
      word.charAt(1) +
      word.charAt(2).toUpperCase() +
      word.slice(3)
    );
  }
  // for words starting with mac, capitalize the first and fourth letters
  if (word[0] === "m" && word[1] === "a" && word[2] === "c")
    return (
      word.charAt(0).toUpperCase() +
      word.charAt(1) +
      word.charAt(2) +
      word.charAt(3).toUpperCase() +
      word.slice(4)
    );
}

const prefixWords = [
  "d'",
  "l'",
  "m'",
  "t'",
  "o'",
  "d’",
  "l’",
  "m’",
  "t’",
  "o’",
  "d‘",
  "l‘",
  "m‘",
  "t‘",
  "o‘",
];

const contractions = [
  "'m",
  "'t",
  "'s",
  "'re",
  "'ve",
  "'d",
  "'ll",
  "‘m",
  "‘t",
  "‘s",
  "‘re",
  "‘ve",
  "‘d",
  "‘ll",
  "’m",
  "’t",
  "’s",
  "’re",
  "’ve",
  "’d",
  "’ll",
];

const capitalizedExceptions = {
  and: "and",
  tv: "TV",
  "i.n.h.": "I.N.H.",
  ebf: "EBF",
  quinnbet: "QuinnBet",
  pkf: "PKF",
  class: "Class",
  mkm: "MKM",
  hkjc: "HKJC",
  hkir: "HKIR",
  sp: "SP",
  trl: "TRL",
  gbb: "GBB",
  "(gbb)": "(GBB)",
  "(gbb": "(GBB",
  yo: "yo",
  allweather: "All-Weather",
  "(allweather)": "(All-Weather)",
  sportybet: "SportyBet",
  betking: "BetKing",
  betplay: "BetPlay",
  skybet: "Sky Bet",
  melbet: "MELbet",
  draftkings: "DraftKings",
  "1xbet": "1xBet",
  fanduel: "FanDuel",
  premierbet: "PremierBet",
  playabets: "PlayaBets",
  betpawa: "BetPawa",
  betcity: "BetCity",
  "500.com": "500.com",
  "f12.bet": "F12.bet",
  "mrjack.bet": "MrJack.bet",
  estrelabet: "EstrelaBet",
  "22bet": "22Bet",
  "cagnes-sur-mer": "Cagnes-sur-Mer",
  "st-jean": "St-Jean",
  racingtv: "RacingTV",
  cwm: "CWM",
  cs: "CS",
  hd: "HD",
  uk: "UK",
  usa: "USA",
  "n/a": "N/A",
  nspcc: "NSPCC",
  "re-gen": "Re-Gen",
  "re gen": "Re-Gen",
  "d.i": "Div 1",
  di: "Div 1",
  "(d.i)": "(Div 1)",
  dii: "Div 2",
  "d.ii": "Div 2",
  "(d.ii)": "(Div 2)",
  betvictor: "BetVictor",
  "q.r.": "Q.R.",
  "(q.r.)": "(Q.R.)",
  jsr: "JSR",
  "vickers.bet": "Vickers.Bet",
  "uk&ireland": "UK & Ireland",
  jtc: "JTC",
  ihrb: "IHRB",
  bha: "BHA",
  "bha:": "BHA:",
  "bangor-on-dee": "Bangor-on-Dee",
  nhs: "NHS",
  "at the races": "At The Races",
  rgb: "RGB",
  "stratford-upon-avon": "Stratford-upon-Avon",
  "stratford-on-avon": "Stratford-on-Avon",
  qipco: "QIPCO",
  pj: "PJ",
  fbc: "FBC",
  uko: "UKO",
  mot: "MOT",
  qeii: "QEII",
  ii: "II",
  iii: "III",
  iv: "IV",
  vi: "VI",
  vii: "VII",
  viii: "VIII",
  ix: "IX",
  betmgm: "BetMGM",
  "rhino.bet": "rhino.bet",
  jcb: "JCB",
  betuk: "BetUK",
  wsb: "WSB",
  trustatrader: "TrustATrader",
  jpr: "JPR",
  ek: "EK",
  ccr: "CCR",
  naf: "NAF",
  nh: "NH",
  caseih: "CaseIH",
};
