import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import RText from "../../../RText";
import { TipTag } from "../types";
import { tagMap } from "../tagMap";
import { useResponsive } from "../../../../hooks/useResponsive";
import variables from "../../../../styles/variables";

//filters123: I moved TipIcon into its own file so I could export and import it into both TipsIcons and AllTips, I wasn't able to import it into AllTips when it was nested inside TipsIcons

const TipIcon = ({
  tipTag,
  partOfList = false,
  overideTagmapLookup = false,
}: {
  tipTag: TipTag;
  partOfList?: boolean; // so we can add a margin to the right
  overideTagmapLookup?: boolean;
}) => {
  const { isSmallAndDown } = useResponsive();
  if (!tagMap[tipTag.capitalize()]?.selected && !overideTagmapLookup) {
    return null;
  }
  const isPlatformWeb = Platform.OS === "web";
  const isTwoLetters = tipTag.length === 2;
  return (
    <View
      style={[
        styles.container,
        overideTagmapLookup
          ? { backgroundColor: variables.colors.palette.rtv.primary }
          : tagMap[tipTag.capitalize()].style,
        partOfList ? { marginRight: 4 } : {},
        isPlatformWeb ? { paddingHorizontal: isSmallAndDown ? 6 : 12 } : {},
      ]}
    >
      <RText weight="bold" color="white" size="sm">
        {overideTagmapLookup ? tipTag : tagMap[tipTag.capitalize()].label}
      </RText>
    </View>
  );
};

export default TipIcon;

const styles = StyleSheet.create({
  container: {
    // marginHorizontal: 1,

    height: 20,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    ...Platform.select({
      web: {
        minWidth: 15,
        maxWidth: 25,
      },
      native: {
        minWidth: 20,
        maxWidth: 30,
      },
    }),
  },
});
