import React from "react";
import { View, StyleSheet, TextStyle } from "react-native";
import TableCell from "./TableCell";

type Props = {
  data: Object;
  columnStyle: TextStyle;
  tableCellGlobalStyle: any;
  tableCellCustomStyle: any;
  navigationIndex?: number;
  tableOnPressDescriptor?: any;
  backgroundColour?: any;
  globalFontStyle?: any;
};

const TableRow = ({
  data,
  columnStyle,
  tableCellGlobalStyle,
  tableCellCustomStyle,
  navigationIndex,
  tableOnPressDescriptor,
  backgroundColour,
  globalFontStyle,
}: Props) => {
  return (
    <View style={styles.tableRowContainer} key={`TableRow${navigationIndex}`}>
      {Object.entries(data).map(([key, value], index) => (
        <View
          style={[
            ...tableCellGlobalStyle,
            columnStyle[key]?.style,
            { backgroundColor: backgroundColour },
          ]}
          key={`TableCell${index}`}
        >
          <TableCell
            customStyle={tableCellCustomStyle?.[key]?.style}
            globalFont={globalFontStyle || tableCellGlobalStyle.font}
            customFont={tableCellCustomStyle?.[key]?.font}
            value={value.value}
            onPress={tableOnPressDescriptor?.[key]?.onPress ?? null}
            navigationLink={tableOnPressDescriptor?.[key]?.link || null}
            index={index}
            navigationIndex={navigationIndex}
          />
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  tableRowContainer: {
    flexDirection: "row",
  },
});

export default TableRow;
