import React from "react";
import Icon from "../Icon";

interface Props {
  type: 'up' | 'down' | 'left' | 'right'
}
const ChevronIcon = ({ type }: Props) => {
  const getRotate = () => {
    const rotate = {
      "up": 270,
      "down": 90,
      "left": 180,
      "right": 0
    }
    return rotate[type]
  }

  return (
    <Icon
      name="chevronRightSmall"
      size={"medium"}
      fill="palette.neutral.x0"
      rotate={getRotate()}
    />
  );
};

export default ChevronIcon;
