import React, { useEffect } from "react";
import { StyleSheet, Pressable, ViewStyle, View, Linking } from "react-native";
import variables from "../../styles/variables";
import RText from "../RText";
import RImage from "../RImage/RImage";
import { Odd } from "../../types/races/runner";
import { PriceFormat } from "../../types/tipster";
import { BASE_URL } from "../../client";
import { useAtom } from "jotai";
import { convertUserPriceFormat } from "../Screens/Account/components/Utils";
import { userAtom } from "../../atoms";
import { OddBookmakers } from "../../types/bookmaker";
import useUser from "../../hooks/useUser";

type Behaviour = "default" | "hover";

type RButtonProps = {
  odd: Odd;
  priceFormat: PriceFormat;
  bookmakers?: OddBookmakers | null | undefined;
  onPress?: Function;
  initialBehaviour?: Behaviour;
  behaviour?: Behaviour;
  style?: ViewStyle;
  size?: "large" | "medium" | "small";
  bold?: boolean;
  isEachWay?: boolean;
  hideImage?: boolean;
};

const { colors, sizes } = variables;

export default function SquarePriceButton({
  initialBehaviour = "default",
  odd,
  priceFormat = "fractional",
  size = "small",
  style,
  bold = false,
  isEachWay = false,
  hideImage = false,
  bookmakers = {},
}: RButtonProps) {
  const [bmLogo, setBmLogo] = React.useState<string>();
  const [contentLoading, setContentLoading] = React.useState<boolean>(false);
  const [behaviour, setBehaviour] = React.useState<Behaviour>("default");

  const [user] = useAtom(userAtom);
  const { isLoggedIn } = useUser();

  //If the user is logged in, use their preferred price format
  const oddsFormat = isLoggedIn ? convertUserPriceFormat(user) : priceFormat;

  const isSP =
    !!odd?.price &&
    odd.price["decimal"] === "0.00" &&
    odd.price["fractional"] === "-1/1" &&
    odd.price["moneyline"] === "+100";

  useEffect(() => {
    if (!odd || hideImage || !Object.keys(bookmakers || {}).length) return;

    const bookmaker = bookmakers[odd.bookmaker_id];
    setBmLogo(
      bookmaker.logo_square?.viewer_sources[0]?.url ||
        bookmaker.logo_square?.placeholder_image_url
    );
    setContentLoading(false);
  }, [odd]);

  if (contentLoading) return;

  return (
    <Pressable
      onHoverIn={() => setBehaviour("hover")}
      onHoverOut={() => setBehaviour(initialBehaviour)}
      onPress={() => {
        !isEachWay &&
          Linking.openURL(
            `${BASE_URL}racing/runner/odds/${odd.id}/clickthrough`
          );
      }}
      style={[
        {
          width: width(size),
        },
        style,
      ]}
    >
      {bmLogo && !hideImage && (
        <View
          style={{
            borderTopRightRadius: 4,
            borderTopLeftRadius: 4,
            backgroundColor: colors.palette.neutral.x200,
            width: width(size),
            height: width(size),
          }}
        >
          <RImage
            source={bmLogo}
            style={{
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
              height: "100%", //needed to show the logos on mobile
            }}
          />
        </View>
      )}
      <View
        style={[
          styles.odds,
          behaviourStyle(behaviour),
          !bmLogo && styles.borderTopRadius,
          bmLogo && styles.removeTopBorder,
          {
            width: width(size),
            height: width(size),
            backgroundColor: backgroundColor(isEachWay, odd, behaviour),
          },
          hideImage && styles.borderTopRadius,
        ]}
      >
        {isEachWay ? (
          <>
            <RText
              size={fontSize(size)}
              color="primary"
              weight={bold ? "bold" : "regular"}
            >
              {odd.each_way_places}
            </RText>
            <RText
              size={fontSize(size)}
              color="primary"
              weight={bold ? "bold" : "regular"}
            >
              1/{odd.each_way_denominator}
            </RText>
          </>
        ) : (
          <RText
            size={fontSize(size)}
            color="primary"
            weight={bold ? "bold" : "regular"}
          >
            {isSP ? "SP" : odd.price[oddsFormat]}
          </RText>
        )}
      </View>
    </Pressable>
  );
}

const backgroundColor = (isEachWay, odd, behaviour) => {
  if (isEachWay) {
    return colors.palette.neutral.x0;
  } else if (odd.fluctuation_type == "drifting") {
    if (behaviour == "hover") {
      return colors.palette.transparentHover.secondary;
    } else return colors.palette.transparent.secondary;
  } else if (odd.fluctuation_type == "shortening") {
    if (behaviour == "hover") {
      return colors.palette.transparentHover.tertiary;
    } else return colors.palette.transparent.tertiary;
  } else if (!odd.fluctuation_type) {
    return colors.palette.neutral.x50;
  }
};

const width = (size) => {
  if (size == "small") return sizes.large.height;
  if (size == "medium") return sizes.xlarge.height;
  if (size == "large") return sizes.xxlarge.height;
};

const fontSize = (size) => {
  if (size == "small") return "xxs";
  if (size == "medium") return "xs";
  if (size == "large") return "xs_sm";
};

const behaviourStyle = (behaviour: Behaviour): ViewStyle => {
  return styles[behaviour];
};

const styles = StyleSheet.create({
  odds: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
  },
  borderTopRadius: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  removeTopBorder: {
    borderTopWidth: 0,
  },
  hover: {
    pointer: "cursor",
  },
  selected: {
    backgroundColor: colors.palette.buttons.alternate.borderColor,
  },
});
