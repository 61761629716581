import {
  Platform,
  View,
  StyleSheet,
  Text,
  useWindowDimensions,
  Linking,
} from "react-native";
import WebView from "react-native-webview";
import RText from "../RText";
import { RLink } from "../RLink/RLink";
import variables from "../../styles/variables";
import React, { ReactChild } from "react";
import RHeading from "../RHeading/RHeading";
import { RAttachment } from "../RAttachment/RAttachment";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { extractTweetId } from "./utils";
import RWebView from "../RWebView";
import { useResponsive } from "../../hooks/useResponsive";
import WebIframe from "../WebIframe";
import { useAtom } from "jotai";
import { scriptsAtom } from "../../atoms";

import AdBanner from "../Layout/AdBanners/AdBanner";

const dayjs = require("dayjs");

export const RCaption = ({ children, type, id, caption }) => (
  <View style={styles.imageCaption}>
    <RText style={styles.imageCaption} weight="light" color="grey">
      {caption}
    </RText>
  </View>
);

export const RBold = ({ children }) => {
  const { isMediumAndDown } = useResponsive();

  return (
    <RText weight="bold" size={isMediumAndDown ? "sm" : "md"}>
      {children}
    </RText>
  );
};

export const Bold = ({ children }) => {
  const { isMediumAndDown } = useResponsive();
  return (
    <RText weight="bold" size={isMediumAndDown ? "sm" : "md"}>
      {children}
    </RText>
  );
};

export const RItalic = ({ children }) => {
  const { isMediumAndDown } = useResponsive();

  return (
    <RText style={styles.italic} size={isMediumAndDown ? "sm" : "md"}>
      {children}
    </RText>
  );
};

export const RStrikeThrough = ({ children }) => {
  const { isMediumAndDown } = useResponsive();

  return (
    <RText style={styles.strikethrough} size={isMediumAndDown ? "sm" : "md"}>
      {children}
    </RText>
  );
};

export const RParagraph = ({ children, color }) => {
  const { isMediumAndDown, isSmallAndUp } = useResponsive();

  return (
    <RText
      size={isMediumAndDown ? "sm" : "md"}
      style={[
        styles.paragraph,

        // remove all margin bottom on tablets
        isSmallAndUp && Platform.OS != "web" ? { marginBottom: 0 } : {},
      ]}
      color={color}
    >
      {children}
    </RText>
  );
};

export const RListOrdered = ({ children }) => (
  <RList type="ol">{children}</RList>
);

export const RListUnordered = ({ children }) => (
  <RList type="ul">{children}</RList>
);

export const RHorizontalRule = () => <View style={styles.hr} />;

export const RList = ({ children, type }) => {
  if (Platform.OS == "android") return; // until we solve formatting issue on android
  const wrappedChildren = children.length ? children : [children];
  return (
    <View style={styles.list}>
      {wrappedChildren.map((child, index) => {
        return (
          <View style={styles.item} key={`wrappedChildren${index}`}>
            {type === "ol" ? (
              <NumberedBullet>{index + 1}.</NumberedBullet>
            ) : (
              <Bullet />
            )}
            {/* another RList or a RListItem */}
            <View style={styles.childWrap}>{child}</View>
          </View>
        );
      })}
    </View>
  );
};

export const NumberedBullet = ({ children }) => {
  const { isMediumAndDown } = useResponsive();
  return (
    <RText style={styles.normalBulletText} size={isMediumAndDown ? "sm" : "md"}>
      {children}
    </RText>
  );
};

export const Bullet = () => {
  const { isMediumAndDown } = useResponsive();

  return (
    <RText style={styles.normalBulletText} size={isMediumAndDown ? "sm" : "md"}>
      •
    </RText>
  );
};

export const RListItem = ({ children }) => {
  const { isMediumAndDown } = useResponsive();
  const wrappedChildren = children.length ? children : [children];
  return wrappedChildren.map((child, index) => {
    if (child?.props?.href) {
      return <>{child}</>;
    } else {
      return <RText size={isMediumAndDown ? "sm" : "md"}>{child}</RText>;
    }
  });
};

export const RBlockQuote = ({ children }) => {
  const { isMediumAndDown } = useResponsive();

  return (
    <RText style={styles.blockquote} size={isMediumAndDown ? "sm" : "md"}>
      {children}
    </RText>
  );
};

export const RDate = ({ date }: { date: string }) => (
  <RText
    size="md"
    color="grey"
    uppercase
    weight="bold"
    style={{ marginBottom: 10, display: "flex" }}
  >
    {dayjs(date).format("ddd DD MMM YYYY")}
  </RText>
);

export const Iframe = ({ type, src, width, height, style }) => {
  if (!height) height = style?.height || "300px";
  if (!width) width = style?.width || "100%";

  const isWeb = Platform.OS === "web";

  const { isSmallAndDown, isSmallAndUp } = useResponsive();
  if (src.includes("youtube") && !src.includes("sociablekit")) {
    // app, phones
    if (Platform.OS === "android" && isSmallAndDown) {
      width = "336px";
      height = "189px";
    }

    if (Platform.OS === "ios" && isSmallAndDown) {
      width = "408px";
      height = "230px";
    }

    // tablets
    if (!isWeb && isSmallAndUp) {
      width = "853px";
      height = "505px";
    }

    // handle overflow if width is greater than screen width
    const { width: screenWidth } = useWindowDimensions();
    width = width > screenWidth ? "100%" : width;
  }

  let source = src;

  // if source includes opening and closing a tags, remove them and just use href
  if (source.includes("<a")) {
    source = source.replace(/<a[^>]*>/g, "");
    source = source.replace(/<\/a>/g, "");
  }

  let heightNumber = height.includes("%")
    ? height
    : parseInt(height.replace("px", ""));
  let widthNumber = width.includes("%")
    ? width
    : parseInt(width.replace("px", ""));
  if (Platform.OS === "android" && src.includes("wufoo"))
    heightNumber = heightNumber + 30;

  if (Platform.OS === "web") {
    if (type == "ad") {
      return <WebIframe width={width} height={height} src={src} />;
    } else {
      return (
        <iframe
          src={src}
          width={width}
          height={height}
          frameBorder="0"
        ></iframe>
      );
    }
  } else {
    const originalUrl = src;
    return (
      <WebView
        allowsFullscreenVideo={true}
        javaScriptEnabled={true}
        source={{ uri: source }}
        originWhitelist={["*"]}
        // scrollEnabled={false} // There's no easy way
        // of knowing at this point whether we're in an ad or something
        // live a wufoo form, so we need to enable scroll.
        style={{
          height: heightNumber || "100%",
          width: !isWeb ? undefined : widthNumber || "100%",
          backgroundColor: "transparent",
          marginTop: -4, // brings ad up a touch
          marginLeft: "4%",
          opacity: 0.99, // yeah really. https://github.com/react-native-webview/react-native-webview/issues/811#issuecomment-614152848
        }} //styles.iFrameMobile}
        onShouldStartLoadWithRequest={(request) => {
          // open external link in browser
          //this only seems to run on ios
          //runs on mount and on every link click

          if (Platform.OS === "ios") {
            const firstLoad =
              decodeURI(request.mainDocumentURL).trim() ===
                decodeURI(originalUrl).trim() && !request.canGoBack;
            if (!request.isTopFrame || firstLoad) return true;
          }

          if (request.mainDocumentURL !== originalUrl) {
            Linking.canOpenURL(request.url).then(
              (supported) => supported && Linking.openURL(request.url)
            );

            return false;
          } else return true;
        }}
      />
    );
  }
};

const Tweet = ({ props }) => {
  const { isSmallAndDown } = useResponsive();
  try {
    const twitterUrl = props.href;
    const match = extractTweetId(twitterUrl);
    if (match)
      return (
        <View
          style={{
            alignItems: !isSmallAndDown ? "center" : undefined,
          }}
        >
          <TwitterTweetEmbed tweetId={match} />
        </View>
      );
  } catch {
    return <></>;
  }
};

const createHTMLTag = (tag: string, child: any) => {
  const { isSmallAndDown } = useResponsive();

  const copy = { ...child.props };
  const iterable = Object.entries(copy);
  const isWeb = Platform.OS === "web";

  const propsAsString = iterable
    .map(([key, val]) => {
      if (key === "children") return;
      if (key === "className") key = "class";
      if (key === "style") {
        const style = Object.entries(val).map(([k, v]) => {
          if (k === "backgroundColor") return `background-color:${v}`;
          if (k === "height" && isSmallAndDown && copy?.mobileHeight && isWeb) {
            return `height:${copy?.mobileHeight}px`;
          }
          if (k === "width" && isSmallAndDown && copy?.mobileWidth && isWeb) {
            return `width:${copy?.mobileWidth}px`;
          }

          return `${k}:${v}`;
        });

        return `style="${style.join(";")}"`;
      }

      return `${key}="${val}"`;
    })
    .join(" ")
    .replace(/,/g, " ");

  return `<${tag} ${propsAsString}></${tag}>`;
};

const createScript = (script: string, index: number) => {
  const splitScript = script.split(" ");
  const source = splitScript
    .find((s) => s.startsWith("src="))
    .replace("src=", "");
  const s = document.createElement("script");
  s.src = `${source}`.replace(/&amp;/g, "&");
  splitScript.includes("async") && (s.async = true);
  splitScript.includes("defer") && (s.defer = true);
  s.id = `script${index}`;
  return s;
};

const returnScripts = () => {
  const [scripts] = useAtom(scriptsAtom);
  return `${scripts
    .map((script, index) => {
      const isDotDigitalScript = script.includes("dotdigital");

      if (Platform.OS === "web") {
        const alreadyExists = !!document.getElementById(`script${index}`);
        if (alreadyExists) return;
        const s = createScript(script, index);
        if (isDotDigitalScript) {
          const dotDigitalWrapper =
            document.getElementById("dotDigitalWrapper");
          if (dotDigitalWrapper) dotDigitalWrapper.appendChild(s);
        } else {
          document.body.appendChild(s);
        }
      }

      return `${script}`;
    })
    .join(" ")}`;
};

const createHTMLString = (wrapper: string, child: any) => {
  return `<!DOCTYPE html>
    <html>
    <head>
    </head>
    <body>
    ${createHTMLTag(wrapper, child)}
    ${returnScripts()}
    </body>
    </html>`;
};

const extractMobileDimensions = (htmlString: string) => {
  if (typeof htmlString !== "string") return;
  const mobileHeight = htmlString
    .split(" ")
    .find((s: string) => s.startsWith(`mobileHeight`));

  const mobileWidth = htmlString
    .split(" ")
    .find((s: string) => s.startsWith(`mobileWidth`));

  const height = parseInt(mobileHeight?.split("=")[1].replace(/"/g, ""));
  const width = parseInt(mobileWidth?.split("=")[1].replace(/"/g, ""));

  return {
    height,
    width,
  };
};

const Pre = ({ children }) => {
  const { isSmallAndDown } = useResponsive();

  //preformatted text strings and tweets
  if (children?.type === "pre") {
    let child = children?.props?.children || children[0]?.props?.children;

    if (Array.isArray(child)) child = child[0];

    //handle tweets
    if (
      child?.type === "blockquote" &&
      child?.props?.className === "twitter-tweet"
    ) {
      return Platform.OS === "web" ? (
        <Tweet props={child.props.children[2].props} />
      ) : (
        <RWebView
          widthOverride="100%"
          heightOverride={640}
          source={children.props.children[0].props.children[2].props.href}
          scrollEnabled={false}
        />
      );
    }

    if (child?.type === "div") {
      if (Platform.OS === "web") {
        returnScripts();
        return <div {...child.props} />;
      } else {
        const htmlString = createHTMLString("div", child);

        const { height, width } = extractMobileDimensions(htmlString);

        return (
          <WebView
            originWhitelist={["*"]}
            allowsFullscreenVideo={true}
            javaScriptEnabled={true}
            source={{
              html: htmlString,
            }}
            style={{ height: height || 300, width: width || "100%" }}
            // injectedJavaScript={jsCode}
            onError={(e) => console.log("error", e)}
            onMessage={(e) => console.log("message", e)}
          />
        );
      }
    }

    // handle ins
    if (child?.type === "ins") {
      const htmlString = createHTMLString("ins", child);
      const isWeb = Platform.OS === "web";
      const { height, width } = extractMobileDimensions(htmlString);
      if (isWeb) {
        return (
          <AdBanner
            heightOffset={20}
            ad={{
              iframe: `${createHTMLTag("ins", child)}
              ${returnScripts()}
              `,
            }}
          />
        );
      } else {
        return (
          <WebView
            originWhitelist={["*"]}
            allowsFullscreenVideo={true}
            javaScriptEnabled={true}
            source={{ html: htmlString }}
            containerStyle={{
              height: height || 200,
              width: width || "100%",
            }}
          />
        );
      }
    }

    //handle widgets
    if (child?.props && child?.props?.src) {
      const mobileHeight = isSmallAndDown && child?.props?.mobileHeight;
      const mobileWidth = isSmallAndDown && child?.props?.mobileWidth;

      return (
        <Iframe
          src={child?.props?.src}
          width={
            mobileWidth ||
            child?.props?.width ||
            child?.props?.style?.width ||
            "100%"
          } // default to 100% if no width is provided
          height={
            mobileHeight ||
            child?.props.height ||
            child?.props.style?.width ||
            300
          } // default to 300 if no height is provided
        />
      );
    }

    //handle preformatted text strings
    const text = child?.props?.children;
    return <Text>{text}</Text>;
  }

  // else return an empty view (to avoid a type bug on mobile when we return <>{children}</> which shouldn't ever be needed)
  return <View />;
};

export const RHtml = ({ children }) => {
  //handle pres

  if (children?.type === "pre" || children[0]?.type === "pre") {
    return <Pre>{children}</Pre>;
  }

  return <>{children}</>;
};

export const SUPPORTED_COMPONENTS = {
  Iframe,
  RHtml,
  RCaption,
  RText,
  RDate,
  RAttachment,
  View,
  RBold,
  Bold,
  RItalic,
  RStrikeThrough,
  RLink,
  RParagraph,
  RBlockQuote,
  RHeading,
  RHorizontalRule,
  RListOrdered,
  RListUnordered,
  RListItem,
};

export const COLOURED_COMPONENTS = (color) => {
  return {
    Iframe,
    RHtml,
    RCaption,
    RText,
    RDate,
    RAttachment,
    View,
    RBold,
    Bold,
    RItalic,
    RStrikeThrough,
    RLink,
    RParagraph: (props) => <RParagraph {...props} color={color} />,
    RBlockQuote,
    RHeading,
    RHorizontalRule,
    RListOrdered,
    RListUnordered,
    RListItem,
  };
};

const { spacing, font, colors } = variables;

export const styles = StyleSheet.create({
  italic: {
    fontStyle: "italic",
  },
  strikethrough: {
    textDecorationLine: "line-through",
  },
  blockquote: {
    marginLeft: spacing.xxsmall,
    borderStyle: "solid",
    borderLeftWidth: 5,
    borderLeftColor: colors.palette.rtv.secondary,
    paddingLeft: spacing.xsmall,
    marginBottom: spacing.xsmall,
  },
  normalText: {
    fontSize: font.size.lg,
  },
  heading: {
    marginBottom: spacing.xsmall,
  },
  h1: {
    fontSize: font.size.xl,
  },
  h2: {
    fontSize: font.size.lg,
  },
  h3: {
    fontSize: 18,
  },
  h4: {
    fontSize: font.size.md,
  },
  h5: {
    fontSize: font.size.sm,
  },
  h6: {
    fontSize: font.size.xs,
  },
  paragraph: {
    marginBottom: spacing.xsmall,
  },
  list: {
    maxWidth: "100%",
    width: "100%",
  },
  item: {
    flexDirection: "row",
    marginTop: spacing.xxxsmall,
    marginLeft: spacing.xxsmall,
  },
  childWrap: {
    width: "100%",
  },
  normalBulletText: {
    paddingRight: spacing.xxsmall,
  },
  hr: {
    borderBottomColor: colors.palette.neutral.x50,
    borderBottomWidth: 1,
    // marginBottom: spacing.xxsmall, // DO NOT UNCOMMENT THIS LINE
    width: "100%",
  },
  imageCaption: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: spacing.xxxsmall,
    marginTop: -spacing.xxxsmall,
  },
});
