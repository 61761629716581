import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import variables from "../../styles/variables";
import RButton from "../Buttons/RButton";

export type SkipAdButtonProps = {
  title: string;
  skipAfterXSeconds?: number;
  isPlaying?: boolean;
  style?: ViewStyle;
  onPress?: () => void;
  onCountDownEnd?: () => void;
};

const SkipAdButton: React.FC<SkipAdButtonProps> = ({
  title,
  skipAfterXSeconds,
  isPlaying,
  style,
  onPress,
  onCountDownEnd,
}) => {
  const interval = useRef<NodeJS.Timeout>();
  const [countDown, setCountDown] = useState(skipAfterXSeconds);

  const displayedTitle = countDown <= 0 ? "Skip" : `${title} ${countDown}`;

  useEffect(() => {
    if (isPlaying) {
      interval.current = setInterval(() => {
        setCountDown((prevCount) => prevCount - 1);
      }, 1000);
    } else clearInterval(interval.current);
    return () => clearInterval(interval.current);
  }, [isPlaying]);

  useEffect(() => {
    if (countDown <= 0 && interval) {
      clearInterval(interval.current);
      onCountDownEnd?.();
    }
  }, [countDown]);

  return (
    <RButton
      style={style}
      onPress={() => countDown <= 0 && onPress?.()}
      title={displayedTitle}
      disabled={countDown <= 0 ? false : true}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: 100,
    position: "absolute",
    bottom: 0,
    right: 0,
    marginRight: variables.spacing.xsmall,
  },
});

export default SkipAdButton;
