export const HorseFormTableDescriptor = {
  table: {
    style: { width: "100%", height: "100%" },
    all: {
      style: {
        paddingHorizontal: 4,
        paddingVertical: 10,
        minWidth: 80,
      },
    },
    fields: {
      web: {
        date: { style: { minWidth: 140, flex: 1 } },
        course: { style: { minWidth: 60, flex: 1 } },
        jockey: { style: { minWidth: 140, flex: 1 } },
        horse: { style: { minWidth: 140, flex: 1 } },
        dist: { style: { minWidth: 90, flex: 1 } },
        going: { style: { minWidth: 60, flex: 1 } },
        cl: { style: { minWidth: 30, flex: 1 } },
        type: { style: { minWidth: 40, flex: 1 } },
        pos: { style: { minWidth: 50, flex: 1 } },
        beaten: { style: { minWidth: 60, flex: 1 } },
        weight: { style: { minWidth: 60, flex: 1 } },
        sp: { style: { minWidth: 60, flex: 1 } },
        or: { style: { minWidth: 50, flex: 1 } },
        rtg: { style: { minWidth: 50, flex: 1 } },
        replay: { style: { minWidth: 60, flex: 1 } },
        runners: { style: { minWidth: 75, flex: 1 } },
        age: { style: { minWidth: 60, flex: 1 } },
        trainer: { style: { minWidth: 140, flex: 1 } },
        injectedData: { style: { display: "none" } },
      },
      mobile: {
        date: {
          style: {
            minWidth: 100,
            maxWidth: 100,
            flex: 0.1,
          },
        },
        course: {
          style: {
            minWidth: 60,
            maxWidth: 60,
            flex: 0.1,
          },
        },
        jockey: {
          style: {
            minWidth: 140,
            maxWidth: 140,
            flex: 0.1,
          },
        },
        horse: {
          style: {
            minWidth: 150,
            maxWidth: 150,
            flex: 0.1,
          },
        },
        dist: {
          style: {
            minWidth: 80,
            maxWidth: 80,
            flex: 0.1,
          },
        },
        going: {
          style: {
            minWidth: 60,
            maxWidth: 60,
            flex: 0.1,
          },
        },
        cl: {
          style: {
            minWidth: 30,
            maxWidth: 30,
            flex: 0.1,
          },
        },
        type: {
          style: {
            minWidth: 40,
            maxWidth: 40,
            flex: 0.1,
          },
        },
        pos: {
          style: {
            minWidth: 50,
            maxWidth: 50,
            flex: 0.1,
          },
        },
        beaten: {
          style: {
            minWidth: 60,
            maxWidth: 60,
            flex: 0.1,
          },
        },
        weight: {
          style: {
            minWidth: 60,
            maxWidth: 60,
            flex: 0.1,
          },
        },
        sp: {
          style: {
            minWidth: 50,
            maxWidth: 50,
            flex: 0.1,
          },
        },
        or: {
          style: {
            minWidth: 40,
            maxWidth: 40,
            flex: 0.1,
          },
        },
        rtg: {
          style: {
            minWidth: 50,
            maxWidth: 50,
            flex: 0.1,
          },
        },
        replay: {
          style: {
            minWidth: 60,
            maxWidth: 60,
            flex: 0.1,
            alignItems: "center",
          },
        },
        runners: {
          style: {
            minWidth: 75,
            maxWidth: 75,
            flex: 0.1,
          },
        },
        age: {
          style: {
            minWidth: 60,
            maxWidth: 60,
            flex: 0.1,
          },
        },
        trainer: {
          style: {
            minWidth: 120,
            maxWidth: 120,
            flex: 0.1,
          },
        },
        injectedData: { style: { display: "none" } },
      },
    },
  },
  header: {
    all: {
      style: {},
      font: { weight: "light", size: "sm", color: "grey" },
    },
    fields: {},
  },
  body: {
    all: {
      style: {
        rowColor: "#e8e8eb",
        alternateRowColor: "white",
      },
      font: { weight: "regular", size: "sm" },
    },
    fields: {
      date: {
        font: { color: "secondary", weight: "semiBold" },
      },
      course: {
        font: { color: "secondary", weight: "semiBold" },
      },
      dist: {
        font: { weight: "semiBold" },
      },
      going: {
        font: { weight: "semiBold" },
      },
      jockey: {
        font: { color: "secondary", weight: "semiBold" },
      },
      horse: {
        font: { color: "secondary", weight: "semiBold" },
      },
      rtg: {
        font: { weight: "semiBold" },
      },
      trainer: {
        font: { color: "secondary", weight: "semiBold" },
      },
    },
  },
};
