import {
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import React, { forwardRef, useEffect, useMemo } from "react";
import {
  getSearchResults,
  getSearchAutoComplete,
  Autocomplete,
  FullSearch,
  FullSearchHit,
} from "./SearchClient";
import { getPages } from "./SearchPages";
import RText from "../../RText";
import variables from "../../../styles/variables";
import { useNavigation } from "@react-navigation/native";
import { cleanSlug } from "../../../utils/cleanSlug";
import SearchSuggestion from "./SearchSuggestion";
import { ContentPreview } from "../../ContentPreview";
import { useResponsive } from "../../../hooks/useResponsive";
import { EmptyState, ErrorState, LoadingState } from "../../States/states";
import RScrollView from "../../RScrollView";
import { DEFAULT_NEWS_IMAGE } from "../../Image/images/defaultImages";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../../types/route";

const AUTOCOMPLETE_TIMEOUT = 400;
const SEARCH_TIMEOUT = 1000;

type Props = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  selectedCategory: string;
  setOpenSearch: (openSearch: boolean) => void;
  setExpandedSearch: (expandedSearch: boolean) => void;
  overlayHeight?: number;
};

export type Page = {
  name: string;
  label: string;
  path: string;
  searchable: boolean;
};

export type Pages = {
  [Key: string]: Page;
};

const SuggestedSearchResultsContainer = (
  {
    searchTerm,
    setSearchTerm,
    selectedCategory,
    setOpenSearch,
    setExpandedSearch,
    overlayHeight,
  }: Props,
  ref
) => {
  const [searchResults, setSearchResults] = React.useState<FullSearch>();
  const [autocompleteSearch, setAutocompleteSearch] =
    React.useState<Autocomplete>();
  const [searchPages, setSearchPages] = React.useState<Pages>();
  const [searchError, setSearchError] = React.useState(null);
  const [searchResultsLoading, setSearchResultsLoading] = React.useState(false);
  const [newsItemWidth, setNewsItemWidth] = React.useState(0);
  const [categoryWidth, setCategoryWidth] = React.useState(0);
  const scrollRef = React.useRef(null);
  const newsArticlesScrollRef = React.useRef(null);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { isSmallAndDown, isXSmallOnly } = useResponsive();
  const isPlatformWeb = Platform.OS === "web";

  useEffect(() => {
    if (searchTerm.length < 2) return;
    setSearchError(null);
    setSearchResultsLoading(true);
    const searchResultsTimer = setTimeout(() => {
      if (selectedCategory == "Pages" || selectedCategory == "All Categories")
        setSearchPages(getPages(searchTerm));

      getSearchResults(searchTerm, selectedCategory)
        .then((res) => {
          setAutocompleteSearch(res.autocomplete);
          setSearchResults(res);
        })
        .catch((err: Error) => {
          setExpandedSearch(true);
          if (!getPages(searchTerm)) setSearchError(err.message);
        })
        .finally(() => setSearchResultsLoading(false));
    }, SEARCH_TIMEOUT);

    return () => {
      clearTimeout(searchResultsTimer);
    };
  }, [searchTerm, selectedCategory]);

  const titleMap = {
    track: "Racecourses",
    jockey: "Jockeys",
    trainer: "Trainers",
    horse: "Horses",
    news_article: "News",
    programmes: "Programmes",
    page: "Pages",
  };

  const renderSearchResults = useMemo(() => {
    const categories = {};
    searchResults?.hits?.map((result) => {
      categories[result.type] = [...(categories[result.type] || []), result];
    });
    return categories;
  }, [searchResults]);

  const handleNavigation = (category: string, hit: FullSearchHit) => {
    const isProfile = ["jockey", "trainer", "horse"].includes(category);

    // pages
    if (!category) return navigation.navigate(`${hit.name}`); //working

    // horsre trainer jockey
    if (isProfile) {
      //working
      return navigation.navigate(`IndexOrProfile`, {
        type: category,
        slug: cleanSlug(hit[category]?.slug),
      });
    }

    if (category === "track") {
      //working
      return navigation.navigate(`Racecourse`, {
        region: hit.track.country_code.iso,
        track: cleanSlug(hit.track.slug),
      });
    }

    if (category === "news_article") {
      //working
      return navigation.navigate(`NewsPost`, {
        slug: cleanSlug(hit.news_article.slug),
      });
    }
  };

  type ResultsProps = {
    results: FullSearch | Pages;
    category?: string;
  };

  const Results = ({ results, category }: ResultsProps) => {
    return (
      <View
        onLayout={(e) => setCategoryWidth(e.nativeEvent.layout.width)}
        style={[styles.category, isSmallAndDown && styles.smallScreenCategory]}
      >
        <RText weight="bold">
          {category
            ? `${titleMap[category]} (${results[category].length})`
            : `Pages (${Object.keys(results).length})`}
        </RText>
        {results[category]
          ? results[category].slice(0, 5).map((result, i) => {
              return (
                <Pressable
                  key={i}
                  style={styles.item}
                  onPress={() => {
                    handleNavigation(category, result);
                    setOpenSearch(false); // close search on navigate
                  }}
                >
                  <RText
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    weight="semiBold"
                  >
                    {result[category].name}
                  </RText>
                </Pressable>
              );
            })
          : Object.entries(results)
              .slice(0, 5)
              .map(([key, result], i) => {
                return (
                  <Pressable
                    key={i}
                    style={styles.item}
                    onPress={() => {
                      handleNavigation(category, result);
                      // navigation.navigate(result.name);
                      setOpenSearch(false); // close search on navigate
                    }}
                  >
                    <RText
                      numberOfLines={1}
                      ellipsizeMode="tail"
                      weight="semiBold"
                    >
                      {result.label}
                    </RText>
                  </Pressable>
                );
              })}
      </View>
    );
  };

  if (searchResultsLoading)
    return (
      <View style={styles.center}>
        <LoadingState name="SearchLoader" />
      </View>
    );

  if (!searchResults?.hits?.length && !searchPages && !searchError)
    return (
      <View style={styles.center}>
        <EmptyState message="No results found" />
      </View>
    );

  if (searchError)
    return (
      <View style={styles.center}>
        <ErrorState message="Sorry, search is currently unavailable." />
      </View>
    );

  const scrollViewHeight = overlayHeight < 600 ? overlayHeight : 600;

  return (
    <ScrollView
      ref={ref}
      alwaysBounceVertical={false}
      style={[
        styles.center,
        isSmallAndDown
          ? { paddingHorizontal: spacing.xxxsmall }
          : { padding: spacing.small },
        isPlatformWeb
          ? {
              minHeight: scrollViewHeight,
            } // maybe try to this dynamically
          : { height: scrollViewHeight }, // maybe try to this dynamically
      ]}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      {searchResults?.search?.did_you_mean ? (
        <View style={styles.autoCompleteContainer}>
          <RText weight="bold" style={{ minWidth: 100 }}>
            DID YOU MEAN{" "}
          </RText>
          <Pressable
            key="did_you_mean"
            onPress={() => setSearchTerm(searchResults?.search?.did_you_mean)}
            style={styles.suggestion}
          >
            <RText weight="semiBold">
              {searchResults?.search?.did_you_mean}
            </RText>
          </Pressable>
        </View>
      ) : null}

      {autocompleteSearch?.length ? (
        <View style={styles.autoCompleteContainer}>
          <RText weight="bold" style={{ minWidth: 100 }}>
            SUGGESTIONS
          </RText>
          {autocompleteSearch?.map((result, i) => (
            <SearchSuggestion
              key={i}
              hit={result}
              setSearchTerm={setSearchTerm}
            />
          ))}
        </View>
      ) : null}

      <RScrollView
        scrollRef={scrollRef}
        elementDistance={categoryWidth + 20}
        childrenWidth={categoryWidth * Object.keys(renderSearchResults)?.length}
        horizontal
        style={
          {
            // flex: 1,
          }
        }
        contentContainerStyle={{
          flexDirection: "column",
          alignItems: "flex-start",
          ...Platform.select({
            web: { width: "100%" },
          }),
        }}
        alwaysBounceHorizontal={false}
      >
        <View style={{ flexDirection: "row" }}>
          {Object.keys(renderSearchResults).map((category) => {
            // temporarily stop races as they come through as
            // undefined, and also we cant link to them
            // because the backend doesn't send the track slug
            // to build the link
            if (category === "race") return;

            // render news articles further down in a scrollbar
            if (category === "news_article") return;
            return (
              <Results
                results={renderSearchResults}
                category={category}
                key={category}
              />
            );
          })}
          {!!searchPages && Object.keys(searchPages).length > 0 && (
            <Results results={searchPages} />
          )}
        </View>
      </RScrollView>
      {/* News Section */}
      {renderSearchResults["news_article"]?.length ? (
        <View style={styles.newsSection}>
          <RText style={styles.newsTitle} weight="bold">
            News ({`${renderSearchResults["news_article"]?.length}`})
          </RText>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <RScrollView
              scrollRef={newsArticlesScrollRef}
              elementDistance={newsItemWidth}
              childrenWidth={
                newsItemWidth * renderSearchResults["news_article"]?.length
              }
              horizontal
              style={{
                flex: 1,
                width: "100%",
              }}
              alwaysBounceHorizontal={false}
            >
              {renderSearchResults["news_article"]?.map((result, i: number) => {
                return (
                  <React.Fragment key={i}>
                    <ContentPreview
                      // key={i}
                      thumbnailUrl={
                        result?.news_article?.hero?.placeholder_image_url ||
                        DEFAULT_NEWS_IMAGE
                      }
                      descriptionHeading={result.news_article.headline}
                      style={[
                        styles.newsItem,
                        isSmallAndDown && styles.smallScreenNewsItem,
                      ]}
                      onPress={() => {
                        handleNavigation("news_article", result);
                        setOpenSearch(false); // close search on navigate
                      }}
                      handleLayout={(e) =>
                        setNewsItemWidth(e.nativeEvent.layout.width)
                      }
                      capitalize={false}
                    />
                  </React.Fragment>
                );
              })}
            </RScrollView>
          </View>
        </View>
      ) : null}
    </ScrollView>
  );
};

export default forwardRef(SuggestedSearchResultsContainer);

const { spacing, colors } = variables;

const styles = StyleSheet.create({
  center: {
    position: "relative",
    backgroundColor: "white",
    zIndex: 10,
    paddingBottom: 10,
  },
  autoCompleteContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    padding: spacing.xxsmall,
    alignItems: "center",
  },
  suggestion: {
    padding: spacing.xxsmall,
    backgroundColor: colors.palette.neutral.x100,
    margin: spacing.xxxsmall,
    justifyContent: "center",
    alignItems: "center",
  },
  category: {
    width: 250,
    padding: spacing.xxsmall,
    margin: spacing.xxxsmall,
  },
  smallScreenCategory: {
    width: 150,
  },
  item: {
    paddingVertical: spacing.xxsmall,
  },
  newsTitle: {
    marginLeft: spacing.xxxsmall,
  },
  newsSection: {
    marginTop: spacing.xxsmall,
  },
  newsItem: {
    marginRight: spacing.xxsmall,
    marginTop: spacing.xxsmall,
    maxWidth: 250,
    backgroundColor: "white",
  },
  smallScreenNewsItem: {
    ...Platform.select({
      native: {
        width: 200,
        maxHeight: 180,
      },
      web: {
        minWidth: 150,
        maxWidth: 150,
        maxHeight: 150,
        minHeight: 150,
        marginBottom: spacing.xxsmall,
      },
    }),
  },
});
