import { fetchGet } from "../..";
import {
  Meeting,
  RaceReplay,
} from "../../../components/Screens/Watch/Replays/MeetingsDropdown";
import { Player, Race } from "../../../types/replay";
import { APIResponse } from "../../../types/responses/APIResponse";
import {
  YYYYMMDD,
  formatTimeForURL,
  isFuture,
} from "../../../utils/TimeCalculator";
import { newsThumbnail } from "../../../components/Screens/News/utils";
import { cleanSlug } from "../../../utils/cleanSlug";
import { prepareParams } from "../../../components/Screens/Results/utils";
import { selectOptimalCodec } from "../../../components/VideoPlayer/selectOptimalCodec";

export const getReplaysFrom = async (
  date: Date | string,
  big?: boolean
): Promise<Meeting[]> => {
  const dateInString = YYYYMMDD(date);

  if (isFuture(date)) throw new Error("Invalid date");

  const replays: APIResponse<Race[]> = await fetchGet(
    `videos/replays/${dateInString}${big ? "/big" : ""}`
  );
  return formatAndGatherReplaysByMeeting(replays.races);
};

export const getMostWatchedReplays = async (
  sortedBy: "meeting" | "race" = "race"
): Promise<RaceReplay[] | Meeting[]> => {
  const mostWatched: APIResponse<Race[]> = await fetchGet(
    "videos/replays/most-watched"
  );
  if (sortedBy === "meeting")
    return formatAndGatherReplaysByMeeting(mostWatched.races);
  else return organizeRacesData(mostWatched.races);
};

const formatAndGatherReplaysByMeeting = (races: Race[]): Meeting[] => {
  if (!races) throw new Error("No replays found");
  const replayGatheredByMeeting: Record<string, Race[]> = races.reduce(
    (acc: { [key: string]: Race[] }, race: Race) => {
      const meeting = race.meeting;
      const meetingName = meeting.track.name;
      if (acc[meetingName]) {
        acc[meetingName].push(race);
      } else {
        acc[meetingName] = [race];
      }
      return acc;
    },
    {}
  );

  const meetings: Meeting[] = Object.keys(replayGatheredByMeeting).map(
    (meetingName) => {
      const races: RaceReplay[] = [];
      const meeting = replayGatheredByMeeting[meetingName];
      meeting.forEach((race) => {
        const resultsParams = prepareParams({
          startTime: race.start_time_scheduled,
          track: race.meeting.track.slug,
        });
        // Frontend workaround to resolve https://www.pivotaltracker.com/n/projects/2586474/stories/186565980
        if (!race.replay_video?.placeholder_image_url) return;
        races.push({
          replayURL: {
            date: YYYYMMDD(race.start_time_scheduled),
            track: cleanSlug(race.meeting.track.slug),
            time: formatTimeForURL(race.start_time_scheduled),
          },
          startTime: race.start_time_scheduled,
          description: race.title, // description is not always available
          contentType: race.replay_video.content_type,
          raceNumber: race.race_number,
          linkToFullResults: resultsParams,
          thumbnailUrl: newsThumbnail(race.replay_video),
          trackName: meetingName,
        });
      });
      races.sort((a, b) => Date.parse(a.startTime) - Date.parse(b.startTime));

      return {
        trackName: meetingName,
        trackCondition: meeting[0].meeting.track_condition,
        flagCode: meeting[0].meeting.track.country_code?.iso,
        startTime: meeting[0].start_time_scheduled,
        endTime: meeting[meeting.length - 1].start_time_scheduled,
        races,
        slug: meeting[0].meeting.track.slug,
      };
    }
  );

  return meetings;
};

export const getReplay = async ({
  date,
  time,
  prerollToken,
  track,
  withSource = false,
}): Promise<RaceReplay> => {
  const dateInString = YYYYMMDD(date);

  // const dateTime = `${date}T${time.slice(0, 2)}:${time.slice(2, 4)}:00Z`;
  // if (isFuture(dateTime)) throw new Error("Cant get replay from the future");

  const { race }: APIResponse<Race> = await fetchGet(
    `videos/replays/${dateInString}/${track}/${time}`
  );

  if (!race) throw new Error("Cannot find this race");
  if (!withSource) return organizeRacesData([race])[0];

  const { player, meta }: APIResponse<Player> = await fetchGet(
    `member/watch/replays/${dateInString}/${track}/${time}`,
    prerollToken ? { preroll_token: prerollToken } : {}
  );
  if (meta.status === 401)
    throw {
      status: meta.status,
      message: "You must be logged in to watch this video",
    };
  const raceWithSource = organizeRacesData([race]);
  raceWithSource[0].sourcePlayer = selectOptimalCodec(player.sources);

  if (meta.status === 402 || meta.status === 403) {
    raceWithSource[0].preroll = player.preroll;
    raceWithSource[0].prerollToken = player.preroll_token;
  }
  return raceWithSource[0];
};

export const organizeRacesData = (races: Race[]): RaceReplay[] => {
  return races.map((race) => {
    const { start_time_scheduled, meeting, replay_video, race_number, title } =
      race;

    const [hh, mm] = start_time_scheduled.split("T")[1].split(":");
    const resultsParams = prepareParams({
      date: meeting.date,
      startTime: start_time_scheduled,
      track: meeting.track.slug,
    });
    return {
      replayURL: {
        date: YYYYMMDD(start_time_scheduled),
        track: cleanSlug(meeting.track.slug),
        time: hh + mm,
      },
      trackName: meeting.track.name,
      startTime: start_time_scheduled,
      thumbnailUrl: replay_video?.placeholder_image_url,
      linkToFullResults: resultsParams,
      raceNumber: race_number,
      title,
      description: replay_video?.description,
      contentType: replay_video?.content_type,
      createdAt: replay_video?.created_at,
      updatedAt: replay_video?.updated_at,
    };
  });
};

export const getLatestReplays = async (
  page: number = 1,
  limit: number = 25
) => {
  const latest = await fetchGet(`videos/replays/latest`, {
    "pagination[page]": page,
    "pagination[limit]": limit,
  });
  return latest.races;
};
