import { StyleSheet, View, ViewStyle } from "react-native";
import React from "react";
import variables from "../../../../../styles/variables";
import TextLoader from "../shared/TextLoader";
import CircleLoader from "../shared/CircleLoader";
import SquareLoader from "../shared/SquareLoader";

type Props = {
  label?: boolean;
  icon?: boolean;
};
const RRadioButtonLoader = ({ label, icon }: Props) => {
  return (
    <View style={styles.container}>
      <View style={styles.radioButton}>
        <CircleLoader width={"100%"} height={"100%"} />
      </View>
      {label ? (
        <View style={{ width: 80 }}>
          <TextLoader />
        </View>
      ) : null}
      {icon ? <SquareLoader style={styles.icon} width={30} /> : null}
    </View>
  );
};

export default RRadioButtonLoader;

const { spacing } = variables;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 48,
  },
  radioButton: {
    height: spacing.medium,
    width: spacing.medium,
    margin: spacing.xxsmall,
    justifyContent: "center",
    alignItems: "center",
    marginRight: spacing.xsmall,
  },
  icon: {
    marginRight: spacing.small,
    borderRadius: spacing.xxsmall,
  },
});
