import React from "react";
import {
  Linking,
  Platform,
  Pressable,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import variables from "../../styles/variables";
import RText from "../RText";
import RImage from "../RImage/RImage";
import { useResponsive } from "../../hooks/useResponsive";
import { DEFAULT_BOOKMAKER_IMAGE } from "../Image/images/defaultImages";
import { BASE_URL, fetchGet } from "../../client";

export type PrerollButtonProps = {
  title?: string;
  link?: string;
  logo?: string;
  style?: ViewStyle | ViewStyle[];
  prerollsId?: number;
};

const { colors, spacing, sizes } = variables;

const PrerollButton: React.FC<PrerollButtonProps> = ({
  title = "Add a title here",
  link = "addalinkhere.com",
  logo = DEFAULT_BOOKMAKER_IMAGE,
  style,
  prerollsId,
}) => {
  const { isSmallAndDown, isMediumAndDown } = useResponsive();
  const [btnHovered, setBtnHovered] = React.useState(false);
  return (
    <View style={[styles.container, style]}>
      <View
        style={[
          styles.logoContainer,
          isSmallAndDown && { padding: variables.spacing.xxxsmall },
        ]}
      >
        <RImage style={styles.logo} source={logo} />
      </View>
      <View
        style={[
          styles.textContainer,
          isSmallAndDown && { padding: variables.spacing.xxxsmall },
        ]}
      >
        <View style={styles.titleAndLinkContainer}>
          <RText
            size={isMediumAndDown ? "sm" : "md"}
            lineHeight={isMediumAndDown ? 18 : 20}
            weight="bold"
            numberOfLines={1}
            style={{ overflow: "hidden" }}
          >
            {title}
          </RText>
        </View>
        <View style={styles.titleAndLinkContainer}>
          <RText
            size={isMediumAndDown ? "xs_sm" : "sm"}
            lineHeight={isMediumAndDown ? 18 : 20}
            color="grey"
            numberOfLines={1}
          >
            {link}
          </RText>
        </View>
      </View>
      <Pressable
        onPress={() =>
          Linking.openURL(
            `${BASE_URL}member/watch/prerolls/clickthrough/${prerollsId}`
          )
        }
        onHoverOut={() => setBtnHovered(false)}
        onHoverIn={() => setBtnHovered(true)}
        style={[styles.button, btnHovered && styles.hoveredButton]}
      >
        <RText size="xs_sm" color="white" weight="semiBold">
          View offer
        </RText>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.palette.neutral.x0,
    borderRadius: 2,
    height: 40,
    maxWidth: "95%",
  },
  logoContainer: {
    padding: spacing.xxsmall,
  },
  logo: {
    height: sizes.large.height,
    width: sizes.large.width,
  },
  textContainer: {
    padding: spacing.xxsmall,
    overflow: "hidden",
    flexShrink: 1,
  },
  titleAndLinkContainer: {},
  button: {
    backgroundColor: colors.palette.buttons.default.backgroundColor,
    borderRadius: 5,
    marginHorizontal: 5,
    padding: 4,
  },
  hoveredButton: {
    backgroundColor: colors.palette.buttons.default.hovered.backgroundColor,
  },
});

export default PrerollButton;
