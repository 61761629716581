import { StyleSheet, View } from "react-native";
import React from "react";
import { useResponsive } from "../../../../../hooks/useResponsive";
import variables from "../../../../../styles/variables";
import TextLoader from "../shared/TextLoader";

const AccountDetailsRowLoader = () => {
  const { isLargeAndUp } = useResponsive();

  if (isLargeAndUp) {
    return (
      <View style={webStyles.container}>
        <View style={[webStyles.flexAndCenter]}>
          <TextLoader width={"80%"} />
        </View>
        <View style={webStyles.flexAndCenterAndWrap}>
          <View style={styles.text}>
            <TextLoader />
          </View>
        </View>
        <View style={styles.buttonsContainer}>
          <View style={{ width: "80%" }}>
            <TextLoader />
          </View>
        </View>
      </View>
    );
  }

  return (
    <View style={mobileStyles.container}>
      <View style={mobileStyles.titleContainer}>
        <TextLoader />
      </View>
      <View style={mobileStyles.contentCon}>
        <View style={{ width: "80%" }}>
          <View style={{ width: "50%" }}>
            <TextLoader />
          </View>
        </View>
        <View style={{ width: "20%" }}>
          <TextLoader style={{ width: "30%" }} />
        </View>
      </View>
    </View>
  );
};

export default AccountDetailsRowLoader;

const { colors, spacing } = variables;

const webStyles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: "row",
    minHeight: 60,
    borderBottomColor: colors.palette.neutral.x50,
    borderBottomWidth: 2,
    paddingVertical: spacing.xsmall,
  },
  flexAndCenter: {
    flex: 1,
    justifyContent: "center",
  },
  flexAndCenterAndWrap: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 2,
  },
});

const mobileStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  titleContainer: {
    paddingVertical: spacing.xxxsmall,
  },
  contentCon: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginVertical: 15,
  },
});

const styles = StyleSheet.create({
  text: {
    paddingRight: spacing.xxsmall,
  },
  buttonsContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  btn: {
    flexGrow: 1,
    borderRadius: 5,
    maxHeight: 40,
    minWidth: 70,
    maxWidth: 100,
  },
});
