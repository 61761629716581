/**
 * Type representing valid ISO codes.
 */
type IsoCode = "gb" | "gbr" | "ie" | "ire" | string;

/**
 * Function that maps ISO codes to their respective country or region names.
 *
 * @param {IsoCode} isoCode - The ISO code to convert.
 * @return {string} The name of the country or region.
 *
 * @throws {Error} Throws an error if the provided ISO code is not a valid two or three character code.
 *
 * @example
 * // returns 'uk'
 * getCountry('gb');
 *
 * @example
 * // returns 'ireland'
 * getCountry('IE');
 *
 * @example
 * // returns 'international'
 * getCountry('us');
 *
 * @example
 * // throws Error: 'Invalid ISO code provided'
 * getCountry('abcd');
 */
export const getRegion = (isoCode: IsoCode): string => {
  switch (isoCode.toLowerCase()) {
    case "gb":
    case "gbr":
      return "uk";
    case "ie":
    case "ire":
      return "ireland";
    default:
      // Ensure the isoCode is a valid two or three digit code
      if (!/^([a-z]{2,3})$/.test(isoCode)) {
        throw new Error("Invalid ISO code provided");
      }
      return "international";
  }
};

export const getRegionFromCountryName = (countryName: string): string => {
  switch (countryName?.toLowerCase()) {
    case "united kingdom":
      return "uk";
    case "ireland":
      return "ireland";
    default:
      return "international";
  }
};
