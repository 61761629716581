import { View } from "react-native";
import { ContentSection } from "../cmsTypes";
import RImage from "../../../RImage/RImage";
import { DEFAULT_BENEFITS_IMAGE } from "../../../Image/images/defaultImages";
import variables from "../../../../styles/variables";
import RHeading from "../../../RHeading/RHeading";
import Gap from "../../../Gap";
import RText from "../../../RText";
import { PlansSection } from "./PlansSection";
import { useResponsive } from "../../../../hooks/useResponsive";
import RCorner from "../../../RCorner/RCorner";
import RVideoPlayer from "../../../RVideoPlayer";
import VideoAsset from "../../../RAttachment/components/VideoAsset";
import { useAtom } from "jotai";
import { getUserState } from "../../../../utils/auth";
import { userAtom } from "../../../../atoms";

export const CmsHero = ({ section }: { section: ContentSection }) => {
  const { isSmallAndDown, isMediumAndDown } = useResponsive();
  const [user] = useAtom(userAtom);
  const userState = getUserState(user);
  const alreadyPaid = userState == "paid_account";

  // if its a blob, then is essentially always a 16/9 thing. we can show image/video further in
  return (
    <View>
      <View
        style={{
          width: "100%",
          backgroundColor: variables.colors.palette.rtv.primary,
          borderRadius: 5,
          justifyContent: "space-between",
          flexDirection: isMediumAndDown
            ? "column"
            : `row${
                section?.content_subtype == "hero_right" ? "-reverse" : ""
              }`,
        }}
      >
        {!!(section?.asset?.asset_type == "blob") && (
          <>
            <View
              style={{
                // flex: 1,
                // minHeight: 420,
                width: isMediumAndDown ? "100%" : "65%",
                aspectRatio: 16 / 9,
                borderRadius: 5,
                backgroundColor: variables.colors.palette.rtv.primary,
                padding: 15,
                marginBottom: 15,
                // paddingTop: section.asset.content_type == "video" ? 25 : 15,
              }}
            >
              {!!(section.asset.content_type == "video") && (
                <VideoAsset metadata={section.asset} />
                /* <RVideoPlayer
                    source={
                      section.asset.viewer_sources?.length
                        ? section.asset.viewer_sources[0].url
                        : "xyz"
                    }
                    displayType="embedded"
                  /> */
              )}
              {!!(section.asset.content_type == "image") && (
                <RImage
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 5,
                    objectFit: "cover",
                  }}
                  source={
                    section.asset.placeholder_image_url ||
                    DEFAULT_BENEFITS_IMAGE
                  }
                />
              )}
            </View>

            <View
              style={{
                padding: 15,
                minHeight: alreadyPaid ? 100 : 250,
                maxWidth: isMediumAndDown ? "100%" : "35%",
                borderRadius: 5,
                backgroundColor: variables.colors.palette.rtv.primary,
              }}
            >
              <View
                style={{
                  alignSelf: "flex-start",
                  height: isMediumAndDown ? undefined : "48%", // 48 instead of 50 to account for padding
                }}
              >
                <RHeading level="1" color="white">
                  {section.title}
                </RHeading>
                <Gap vertical size="xsmall" />
                <RText color="white" weight="semiBold" size="lg">
                  {section.description}
                </RText>
              </View>
              {!alreadyPaid && (
                <View style={{ alignSelf: "flex-end", flexShrink: 1 }}>
                  <PlansSection limit={isMediumAndDown ? undefined : 2} />
                </View>
              )}
            </View>
          </>
        )}
      </View>
      <RCorner size="large" borderTopRightRadius />
    </View>
  );
};

export default CmsHero;
