import { Platform } from "react-native";
import { DeepLink, ExpoNotification } from "../types/expoNotification";
import { FirebaseMessagingTypes } from "@react-native-firebase/messaging";
import Bugsnag from "@bugsnag/expo";

type AnyNotification = ExpoNotification | FirebaseMessagingTypes.RemoteMessage;

const getNotifcationType = (notification: AnyNotification): string => {
  // FCM is something the system passes to our app, i.e from closed app state.
  if (!notification.notification.request) {
    return "FCM";
  }

  // ExpoScheduled is a notification we can create via expo.
  if (
    !notification.notification.request.trigger ||
    notification.notification.request.trigger.type === "unknown"
  ) {
    return "ExpoScheduled";
  }

  // ExpoAPNS created by third party
  if (notification.notification.request.trigger.payload) {
    return "ExpoAPNS";
  }

  // ExpoFCM created by third party / firebase. We don't get these anymore, but
  // written here for completeness.
  if (notification.notification.request.trigger.remoteMessage) {
    return "ExpoFCM";
  }

  Bugsnag.notify(
    new Error(
      `Got Unknown notification type: ${JSON.stringify(notification, null, 2)}`
    )
  );

  return "Unknown";
};

export const parseDeepLink = (notification: AnyNotification): DeepLink => {
  let deeplink: string | object | null = null;

  try {
    switch (getNotifcationType(notification)) {
      case "FCM":
        deeplink = notification?.data?.dd_deepLink;
        break;
      case "ExpoScheduled":
        deeplink =
          notification?.notification?.request?.content?.data?.dd_deepLink;
        break;
      case "ExpoAPNS":
        deeplink =
          notification?.notification?.request?.trigger?.payload?.dd_deepLink;
        break;
      case "ExpoFCM":
        deeplink =
          notification?.notification?.request?.trigger?.remoteMessage?.data
            ?.dd_deepLink;
        break;
      default:
        // Handle unknown types
        break;
    }
  } catch (error) {
    console.error(`Error parsing ${Platform.OS} deep link data:`, error);
    // If parsing fails, url and trackingUrl will remain null
  }

  let parsedLink: { url: string | null; trackingUrl: string | null } = {
    url: null,
    trackingUrl: null,
  };

  // Check if deeplink is an object or a parseable string
  if (deeplink) {
    if (typeof deeplink === "object") {
      // If deeplink is already an object, use it directly
      parsedLink = {
        url: deeplink.url ?? null,
        trackingUrl: deeplink.trackingUrl ?? null,
      };
    } else if (typeof deeplink === "string") {
      try {
        // Try to parse the deeplink if it's a string
        const parsed = JSON.parse(deeplink);
        parsedLink = {
          url: parsed.url ?? null,
          trackingUrl: parsed.trackingUrl ?? null,
        };
      } catch (parseError) {
        console.error("Failed to parse deeplink string:", parseError);
      }
    }
  }

  return parsedLink;
};
