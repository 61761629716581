import React from "react";
import RText from "../../RText";
import variables from "../../../styles/variables";
import { Platform } from "react-native";
import { useResponsive } from "../../../hooks/useResponsive";

const HighlightedTitle = ({
  title,
  emphasisedTitle,
  accentColor,
  fullScreen,
}) => {
  const { isSmallAndUp, isXSmallAndUp, isLargeAndUp } = useResponsive();

  const isWeb = Platform.OS === "web";

  let fontSize = "xl";
  if (isXSmallAndUp) fontSize = "xxl";
  if (isLargeAndUp) fontSize = "xxxl";
  if (fullScreen && isWeb && isSmallAndUp) fontSize = "supersize";

  let lineHeight = 36;
  if (fullScreen && isSmallAndUp) lineHeight = 80;

  // If there is no emphasisedTitle or it is not found in the title
  if (!emphasisedTitle || !title.includes(emphasisedTitle)) {
    return (
      <RText
        size={fontSize}
        uppercase
        color={accentColor}
        style={{ textAlign: "center" }}
        weight="semiBold"
      >
        {title}
      </RText>
    );
  }

  // Split the title by the emphasised part
  const parts = title.split(emphasisedTitle);

  return (
    <RText
      size={fontSize}
      style={[
        {
          textAlign: "center",
        },
        isWeb && { lineHeight: lineHeight },
      ]}
      color={accentColor}
    >
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {index < parts.length - 1 && (
            <RText weight="bold" size={fontSize} color={accentColor} uppercase>
              {emphasisedTitle}
            </RText>
          )}
        </React.Fragment>
      ))}
    </RText>
  );
};

export default HighlightedTitle;
