import React, { useRef } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { useResponsive } from "../../hooks/useResponsive";
import variables from "../../styles/variables";
import CardLabel from "../Card/CardLabel";
import { useScrollControl } from "../../hooks/useScrollControl";

const { colors } = variables;

type Props = {
  children: any;
  childWidth: number;
  racesBar?: boolean;
  RMGRace?: boolean;
};
/* This component assumes all children will be the same
   width. Gaps are fixed to 20px and doubled for large screens.
*/

const GAP_SIZE = 20;

const ScrollBar = ({ children, childWidth, racesBar, RMGRace }: Props) => {
  const scrollRef = useRef<ScrollView>(null);
  const { isMediumAndUp } = useResponsive();

  let elementDistance = childWidth + GAP_SIZE;
  if (isMediumAndUp) elementDistance = elementDistance * 2;

  const {
    position,
    handleLayout,
    handleHideArrow,
    hideLeftArrow,
    hideRightArrow,
    setPosition,
    ScrollIndicator,
  } = useScrollControl({
    scrollRef,
    elementDistance,
  });

  return (
    <View style={{ flexDirection: "row" }}>
      {racesBar && position <= 0 && (
        <View>
          {/* todo: Made simpler the positionning of the next off label */}
          {/* But this guys should be attached to its children */}
          <CardLabel
            name="next off"
            color={colors.palette.rtv.tertiary}
            style={{
              position: "absolute",
              top: RMGRace ? -20 : -16,
              left: 0,
              paddingBottom: 4,
            }}
            textStyle={{
              paddingHorizontal: 8,
              color: colors.palette.neutral.x0,
            }}
          />
        </View>
      )}
      {!hideLeftArrow && <ScrollIndicator type="left" />}
      <ScrollView
        snapToInterval={null}
        ref={scrollRef}
        contentContainerStyle={styles.container}
        horizontal
        showsHorizontalScrollIndicator={false}
        onLayout={({ nativeEvent }) =>
          handleLayout(nativeEvent.layout.width, React.Children.count(children))
        }
        onScroll={({ nativeEvent }) => {
          setPosition(nativeEvent.contentOffset.x);
          handleHideArrow(nativeEvent);
        }}
        scrollEventThrottle={50}
        removeClippedSubviews={true} // Unmount components when outside of window
      >
        {children}
      </ScrollView>
      {!hideRightArrow && <ScrollIndicator type="right" />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
  },
});

export default ScrollBar;
