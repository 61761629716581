import React, { useState, useEffect } from "react";
import { fetchGet } from "../../../client";
import { APIResponse } from "../../../types/responses/APIResponse";
import RAccordion from "../../RAccordion/RAccordion";
import RAccordionLoader from "../../States/Loading/Loaders/shared/RAccordionLoader";

const FaqList = ({ id }) => {
  const [content, setContent] = useState("xyz");
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      type Faq = { title: string; content: string };
      type AllFaqs = { list: { items: Faq[] } };

      try {
        const res: APIResponse<AllFaqs> = await fetchGet(`faqs/lists/${id}`);
        const faqs = res.list.items.map((faq, idx) => (
          <RAccordion text={faq.title} content={faq.content} key={idx} />
        ));
        setContent(faqs);
      } catch (err) {
        setError("Error loading FAQs: " + err?.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <RAccordionLoader />;
  }

  if (!content || error) return;

  return <>{content}</>;
};

export default FaqList;
