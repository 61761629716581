import { Platform, Share, TouchableOpacity } from "react-native";
import React from "react";
import Icon from "../Icon";
import RText from "../RText";
import Gap from "../Gap";
import { FRONTEND_URL } from "../../client";
import { RTextColors, RTextFont, RTextSize, RTextWeight } from "../RText/RText";
import { sendAnalyticsEventAsync } from "../../client/firebase/analytics";
import Bugsnag from "@bugsnag/expo";

type Props = {
  type: "Article" | "Tip" | "Result";
  fontWeight?: RTextWeight;
  fontColor?: RTextColors;
  fontFamily?: RTextFont;
  fontSize?: RTextSize;
  shareData: {
    title: string;
    text?: string;
    url: string;
  };
};

const ShareButton = ({
  type,
  shareData,
  fontWeight = "bold",
  fontColor = "secondary",
  fontFamily = "primary",
  fontSize = "md",
}: Props) => {
  if (Platform.OS === "web") return null; // hide button if on web platform

  const share = async () => {
    try {
      const result = await Share.share({
        [Platform.OS === "ios" ? "message" : "title"]: shareData.title,
        url: `${FRONTEND_URL}/${shareData.url}`,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          sendAnalyticsEventAsync(`Shared_${Platform.OS}`, {
            title: shareData?.title,
            url: `${FRONTEND_URL}/${shareData.url}`,
            sharedBy: result.activityType,
          });
        }
      } else if (result.action === Share.dismissedAction) {
        sendAnalyticsEventAsync(`Shared_Dismissed_${Platform.OS}`);
      }
    } catch (error) {
      console.warn(error.message);
      if (error.message !== "Share canceled") {
        Bugsnag.notify(error);
      }
    }
  };

  return (
    <TouchableOpacity
      nativeID={`share-${type}`}
      fsTagName={`tag-share-button-${type}`}
      fsClass={`class-share-button-${type}`}
      fsAttribute={`attribute-share-button-${type}`}
      onPress={share}
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <RText
        family={fontFamily}
        color={fontColor}
        weight={fontWeight}
        size={fontSize}
      >
        Share {`${type}`}
      </RText>
      <Gap size="xxsmall" />
      <Icon name="share" size="medium" />
    </TouchableOpacity>
  );
};

export default ShareButton;
