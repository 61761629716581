import { Asset } from "./asset";

export type AdPlacements =
  | "skyscraper"
  | "header"
  | "footer"
  | "overlay_primary"
  | "overlay_secondary"
  | "carousel"
  | "banner"
  | "tab";

export type Placements = {
  [key in AdPlacements]: AdvertDetails;
};

export type Advert = {
  path: string;
  placements: Placements;
  takeover: TakeoverAd | null;
};

export type BaseAd = {
  campaign_id: number;
  banner_priority: number;
};

export type InternalAd = BaseAd & {
  asset: Asset;
  link_url: string;
  moatads_script_src: string | null;
};

export type TakeoverAd = {
  takeover_id: number;
  color_primary: string;
  color_secondary: string;
  color_accent: string;
  header_icons_logo: boolean;
  close_text: string;
  close_logo: boolean;
  border: boolean;
  fullscreen: boolean;
  background_asset: Asset;
  contents: {
    content_type: string;
    link_url: string;
    asset: Asset;
  }[];
  title: string;
  emphasised_title: string;
};

export type IframeAd = BaseAd & {
  iframe: string;
};

export type AdvertDetails = InternalAd | IframeAd;

// type narrowing functions
export function isIframeAd(ad: AdvertDetails): ad is IframeAd {
  return (ad as IframeAd).iframe !== undefined;
}

export function isInsAd(ad: AdvertDetails): boolean {
  return isIframeAd(ad) && ad?.iframe?.startsWith("<ins");
}

export function isInternalAd(ad: AdvertDetails): ad is InternalAd {
  return (ad as InternalAd).asset !== undefined;
}
