// RHeader.tsx
import RText from "./RText";
import { StyleSheet } from "react-native";

const RHeader = ({ children }) => {
  return (
    <RText
      color="white"
      family="secondary"
      weight="bold"
      uppercase={true}
      style={styles.header}
    >
      {children}
    </RText>
  );
};

const styles = StyleSheet.create({
  header: {
    textAlign: "center",
    paddingBottom: 12,
  },
});
export default RHeader;
