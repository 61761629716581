import { Platform } from "react-native";
import { Player } from "../../types/replay";

const isWeb = Platform.OS === "web";

export const selectOptimalCodec = (codecs: Player["sources"]): string => {
  if (!codecs || !codecs.length) return "";
  else {
    if (isWeb) {
      const results = parseSources(codecs);
      return results[0].url;
    } else {
      return codecs[0].url;
    }
  }
};

const canPlaySource = (sourceObject): string => {
  let tester = document.createElement("video");

  let contentType = sourceObject.contentType;
  if (typeof contentType !== "string" || contentType.length === 0)
    return "unknown";

  if (sourceObject.hlsjs) {
    contentType = contentType.replace(
      "application/vnd.apple.mpegurl",
      "video/mp4"
    );
  }
  console.log("contentType", contentType);
  return tester.canPlayType(contentType);
};

const parseSources = (sourceObjects: Player["sources"]) => {
  let parser = document.createElement("a");

  let probablySources = [];
  let maybeSources = [];
  let unknownSources = [];

  sourceObjects.forEach((sourceObject) => {
    let contentType =
      sourceObject.contentType || sourceObject.content_type || null;

    if (contentType) {
      let codecs = [];

      let videoCodec = sourceObject.videoCodec || sourceObject.video_codec;
      if (videoCodec) {
        codecs.push(videoCodec);
      }

      let audioCodec = sourceObject.audioCodec || sourceObject.audio_codec;
      if (audioCodec) {
        codecs.push(audioCodec);
      }

      if (codecs.length > 0) {
        contentType += `; codecs="${codecs.join(",")}"`;
      }
    }

    let parsedSource = {
      url: sourceObject.url,
      contentType: contentType,
    };

    const Hls = window.Hls;
    if (Hls.isSupported()) {
      parser.href = parsedSource.url;
      parsedSource.hlsjs = parser.pathname.endsWith(".m3u8");
    } else {
      parsedSource.hlsjs = false;
    }

    switch (canPlaySource(parsedSource)) {
      case "probably":
        probablySources.push(parsedSource);
        break;
      case "maybe":
        maybeSources.push(parsedSource);
        break;
      case "unknown":
        unknownSources.push(parsedSource);
        break;
    }
  });

  return [...probablySources, ...maybeSources, ...unknownSources];
};
