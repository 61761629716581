import React from "react";
import { StyleSheet, View } from "react-native";
import variables from "../../styles/variables";
import Gap from "../Gap";
import Icon from "../Icon";
import RText from "../RText";
import RButton from "./RButton";

export type LiveButtonProps = {
  initialBehaviour?: "hover" | "selected" | "default";
  size?: "small" | "medium" | "large" | "xlarge";
  onPress?: Function;
};

export default function LiveButton({
  onPress = () => console.log("pressed."),
}: LiveButtonProps) {
  return (
    <RButton
      onPress={onPress}
      style={styles.buttonRound}
      customTextStyle={{ display: "none" }}
    >
      <View style={styles.livePlayContentContainer}>
        <RText
          family="secondary"
          weight="bold"
          size="md"
          color="white"
          style={styles.liveText}
        >
          Live
        </RText>
        <Gap size="xxsmall" />
        <Icon name="play" size="medium" />
      </View>
    </RButton>
  );
}

const styles = StyleSheet.create({
  buttonRound: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: variables.spacing.xxsmall,
    borderRadius: 20,
    height: 40,
  },
  livePlayContentContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  liveText: {
    textTransform: "uppercase",
  },
});
