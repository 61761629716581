import { Platform } from "react-native";
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { cmsDataAtom } from "../atoms";
import { nativeNavFromSlug } from "../components/Promotion/Utils";

const useCmsMoreMenu = () => {
  const [CmsData] = useAtom(cmsDataAtom);
  const [moreMenuItems, setMoreMenuItems] = useState([]);

  useEffect(() => {
    if (!CmsData) return;
    const menuItems = CmsData?.settings?.navigation_items[Platform.OS]; // extract links for platform
    if (!menuItems) return;
    const formatted = menuItems.map((item) => {
      const { screen: route, paramsObj: params } = nativeNavFromSlug(
        item.location
      );

      if (!route) return; // if no route, don't add to menu, avoid dead links

      return {
        title: item.title,
        route: route,
        params: params,
      };
    });

    setMoreMenuItems(formatted.filter(Boolean));
  }, [CmsData]);

  return { moreMenuItems };
};

export default useCmsMoreMenu;
