import Bugsnag from "@bugsnag/expo";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { APP_ENV } from "..";

export const CacheManager = {
  cleanupInterval: null, // Holds the interval ID for cleanup

  startCleanupTask(interval = 3600000) {
    // Default cleanup interval of 1 hour
    if (this.cleanupInterval) {
      console.log("[Cache Manager] Cleanup task is already running.");
      return;
    }

    this.cleanupInterval = setInterval(() => {
      console.log("[Cache Manager] Started storage cleanup task.");
      this.cleanupStorage()
        .then(() => {
          console.log("[Cache Manager] Storage cleanup completed.");
        })
        .catch((error) => {
          console.error("Error during storage cleanup:", error);
        });
    }, interval);
  },

  stopCleanupTask() {
    if (this.cleanupInterval) {
      clearInterval(this.cleanupInterval);
      this.cleanupInterval = null;
      console.log("[Cache Manager] Stopped storage cleanup task.");
    }
  },

  async setItem(key, data, maxAgeInSecs = 300) {
    const expiry = Date.now() + maxAgeInSecs * 1000; // Convert seconds to milliseconds
    const cacheValue = JSON.stringify({ data, expiry });

    const sizeInBytes = new Blob([cacheValue]).size;
    const sizeInKB = sizeInBytes / 1024;

    if (sizeInKB > 1000) {
      if (APP_ENV !== "production" && APP_ENV !== "staging") {
        console.error(
          `Cache item with key: ${key} exceeded 1000kB size limit with size: ${sizeInKB}kB`
        );

        return;
      }

      Bugsnag.notify(
        new Error(
          `Cache item with key: ${key} exceeded 1000kB size limit with size: ${sizeInKB}kB`
        )
      );

      return;
    }

    try {
      await AsyncStorage.setItem(key, cacheValue);
    } catch (e) {
      console.log(`Setting cache item key-${key}: ${e}`);
      await this.cleanupStorage();
      console.log("[Cache Manager] Storage clear finished");
    }
  },

  async getItem(key) {
    try {
      const cached = await AsyncStorage.getItem(key);
      if (!cached) return null;

      const { data, expiry } = JSON.parse(cached);
      if (Date.now() < expiry) {
        return data;
      }

      // If the data is expired, remove it from the cache
      await AsyncStorage.removeItem(key);
    } catch (e) {
      try {
        await AsyncStorage.removeItem(key);
        console.log(`Successfully removed bad key-${key}`);
      } catch (e) {
        if (APP_ENV !== "production" && APP_ENV !== "staging") {
          console.error(`Failed to remove bad key-${key}: ${e}`);
        } else {
          Bugsnag.notify(new Error(`Failed to remove bad key-${key}: ${e}`));
        }
      }
    }
    return null;
  },

  parseCacheControl(cacheControl) {
    if (!cacheControl) return null;
    const maxAgeInSecsMatch = cacheControl.match(/max-age=(\d+)/);
    return maxAgeInSecsMatch ? parseInt(maxAgeInSecsMatch[1], 10) : null;
  },

  // runs when every 30 mins (see App.tsx) or setItem fails for any reason
  async cleanupStorage() {
    try {
      const keys = await AsyncStorage.getAllKeys();
      // this can fail if the entry is too big. we handle it in the catch.
      const cacheEntries = await AsyncStorage.multiGet(keys);
      const expiredKeys = [];

      cacheEntries.forEach(([key, value]) => {
        if (value) {
          try {
            const { expiry } = JSON.parse(value);
            if (expiry && Date.now() > expiry) {
              expiredKeys.push(key);
              console.log(`Identified expired cache item with key: ${key}`);
            } else if (!expiry) {
              console.log(
                `Identified non-expirable cache item with key: ${key}`
              );
            }
          } catch (e) {
            console.log(
              `Attempting to read cached item key-${key}: Error: ${e}`
            );
          }
        }
      });

      if (expiredKeys.length > 0) {
        await AsyncStorage.multiRemove(expiredKeys);
        console.log(`Removed ${expiredKeys.length} expired cache item(s).`);
      }
    } catch (e) {
      console.error(`Failed to get a key as its too big. deleting it: ${e}`);
      await this.removeGiantEntry(); // Call to remove the entry that causes errors
    }
  },

  async removeGiantEntry() {
    try {
      const allKeys = await AsyncStorage.getAllKeys();

      await allKeys.map(async (key) => {
        try {
          await AsyncStorage.getItem(key);
          console.log("[Cache Manager] skipping valid key, value: ", key);
        } catch {
          console.log("[Cache Manager] found giant entry for key: ", key);

          await AsyncStorage.removeItem(key);

          console.log("[Cache Manager] APPEVN", APP_ENV);
          if (APP_ENV !== "production" && APP_ENV !== "staging") {
            console.error(
              `Just removed a giant entry in the cache, with key: ${key}`
            );

            return;
          }

          // log to bugsnag
          Bugsnag.notify(
            new Error(
              `Just removed a giant entry in the cache, with key: ${key}`
            )
          );
        }
      });

      console.log(`Finished removing problematic cache item.`);
    } catch (e) {
      if (APP_ENV !== "production" && APP_ENV !== "staging") {
        console.error(`Totally unhandled. back to drawing board: ${e}`);
      } else {
        Bugsnag.notify(
          new Error(`Totally unhandled. back to drawing board: ${e}`)
        );
      }
    }
  },
};
