/**
 * Remove a GUID (Globally Unique Identifier) from a string in JavaScript
 *
 * @example
 * removeGuid('k-t-o-neill-c52f94e4-4764-4de6-958d-aa8032ad5905') // 'k-t-o-neill'
 *
 * @param string The string containing a GUID.
 * @returns The string without the GUID.
 */

export function removeGUID(inputString) {
  // Define a regular expression pattern to match a GUID
  const guidPattern =
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

  if (guidPattern.test(inputString)) {
    // If a GUID is found, use the replace method to remove it
    return inputString.replace(guidPattern, "");
  } else {
    // If no GUID is found, return the input string as-is
    return inputString;
  }
}

// TESTS....
// console.log(removeGUID("k-t-o-neill-c52f94e4-4764-4de6-958d-aa8032ad5905")); // returns 'k-t-o-neill-'
