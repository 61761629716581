import { fetchGet } from "..";
import { ActiveFestivals, Festival } from "../../types/festival";
import { CmsData } from "../../types/cms";
import { APIResponse } from "../../types/responses/APIResponse";

export const getCmsData = async (): Promise<CmsData> => {
  try {
    return await fetchGet("/settings");
  } catch (error) {
    console.error("Error getting CMS data", error.message);
  }
};

export const getActiveFestivals = async (): Promise<ActiveFestivals> => {
  try {
    return await fetchGet("track-hubs");
  } catch (error) {
    console.log("Error getting active festivals", error.message);
  }
};
