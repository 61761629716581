import * as ScreenOrientation from "expo-screen-orientation";
import { Platform, StatusBar } from "react-native";

const isNative = Platform.OS !== "web";

type Orientation = "portrait" | "landscape";

export const useMobileScreenOrientation = () => {
  const setOrientation = async (orientation: Orientation) => {
    if (!isNative) {
      console.log("setOrientation not supported on web");
      return;
    }
    if (orientation === "landscape") {
      StatusBar.setHidden(true);
      await ScreenOrientation.lockAsync(
        ScreenOrientation.OrientationLock.LANDSCAPE_RIGHT
      );
    } else {
      StatusBar.setHidden(false);
      await ScreenOrientation.lockAsync(
        ScreenOrientation.OrientationLock.PORTRAIT_UP
      );
    }
  };

  return { setOrientation };
};
