import React, { useEffect, useState, MouseEvent } from "react";
import {
  Animated,
  GestureResponderEvent,
  Platform,
  StyleSheet,
  Pressable,
  View,
} from "react-native";
import { useAnimatedTranslation } from "../../hooks/useAnimatedTranslation";
import variables from "../../styles/variables";
import Icon from "../Icon";
import useSystemVolume from "../../hooks/useSystemVolume";

export type VolumeControlProps = {
  isVisible: boolean;
  isMuted: boolean;
  onVolumeChange: (volume: number) => void;
};

const heightOfVolumeControl = 150;
const isWeb = Platform.OS === "web";

const VolumeControl: React.FC<VolumeControlProps> = ({
  isVisible,
  isMuted,
  onVolumeChange,
}) => {
  const { volume } = useSystemVolume();
  const { panResponder, pan, startAnimation } =
    useAnimatedTranslation("vertical");

  const [level, setLevel] = useState(0);
  const [isCursorHovered, setIsCursorHovered] = useState(false);
  const containerRef = React.useRef(null);

  const handlePress = (e: GestureResponderEvent & MouseEvent) => {
    const toValue =
      isWeb && containerRef.current
        ? e.nativeEvent.offsetY
        : e.nativeEvent.locationY;
    startAnimation(-heightOfVolumeControl + toValue);
  };

  useEffect(() => {
    pan.addListener(({ y }) => {
      const yValue = -y;
      const volumeLevel = yValue / heightOfVolumeControl;
      if (volumeLevel > 1 || volumeLevel < 0) return;
      setLevel(yValue);
      onVolumeChange?.(volumeLevel);
    });
    pan.y.setValue(-heightOfVolumeControl + 10);
    return () => pan.removeAllListeners();
  }, []);

  useEffect(() => {
    if (isMuted) {
      startAnimation(0);
    } else {
      startAnimation(-heightOfVolumeControl + 10);
    }
  }, [isMuted]);

  const transformCursor = [
    { rotate: "90deg" },
    isCursorHovered ? { scale: 2 } : { scale: 1 },
  ];

  return (
    <Pressable
      ref={containerRef}
      onPress={handlePress}
      style={[styles.container, isVisible && { display: "flex" }]}
    >
      {isVisible && <View style={styles.triangle} />}
      <View style={styles.line}>
        <View
          style={[
            styles.level,
            {
              height: level,
            },
          ]}
        />
      </View>
      <Animated.View
        {...panResponder.panHandlers}
        style={[
          styles.cursorContainer,
          {
            transform: [
              {
                translateY: pan.y.interpolate({
                  inputRange: [-heightOfVolumeControl + 10, 0],
                  outputRange: [-heightOfVolumeControl + 10, 0],
                  extrapolate: "clamp",
                }),
              },
            ],
          },
        ]}
      >
        <Pressable
          onHoverIn={() => setIsCursorHovered(true)}
          onHoverOut={() => setIsCursorHovered(false)}
        >
          <Icon
            style={{ transform: transformCursor }}
            name="playHead"
            fill="palette.rtv.primary"
            size="small"
          />
        </Pressable>
      </Animated.View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    height: heightOfVolumeControl,
    width: 35,
    position: "absolute",
    top: -heightOfVolumeControl,
    marginRight: "50%",
    marginLeft: "50%",
    display: "none",
    backgroundColor: variables.colors.palette.rtv.white,
    borderRadius: variables.spacing.xxsmall,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    shadowRadius: variables.spacing.xxsmall,
    shadowColor: variables.colors.palette.rtv.black,
    shadowOpacity: 0.9,
    shadowOffset: {
      width: variables.spacing.xxsmall,
      height: variables.spacing.xxsmall,
    },
    elevation: variables.spacing.xxsmall,
    alignItems: "center",
    paddingTop: variables.spacing.xsmall,
  },
  line: {
    width: 2,
    height: "100%",
    backgroundColor: variables.colors.palette.neutral.x200,
    justifyContent: "flex-end",
  },
  level: {
    width: 2,
    backgroundColor: variables.colors.palette.rtv.secondary,
    minHeight: "0%",
    maxHeight: "100%",
  },
  cursorContainer: {
    position: "absolute",
    bottom: -6,
    left: 9.5,
  },
  triangle: {
    position: "absolute",
    bottom: -variables.spacing.xsmall,
    left: 0.4,
    width: 0,
    height: 0,
    borderLeftWidth: 17,
    borderRightWidth: 17,
    borderBottomWidth: 12,
    borderStyle: "solid",
    backgroundColor: "transparent",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: variables.colors.palette.rtv.white,
    transform: [{ rotate: "180deg" }],
  },
});

export default VolumeControl;
