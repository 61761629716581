import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { Iframe } from "../cms/components";
import WebView from "react-native-webview";

const YouTubeVideoAsset = ({ id, metadata }) => {
  if (!metadata) return;

  return Platform.OS === "web" ? (
    <View style={styles.webContainer}>
      <iframe
        src={metadata.viewer_sources[0].url}
        width="100%"
        style={styles.iframe}
        frameBorder="0"
        title={metadata.title}
      ></iframe>
    </View>
  ) : (
    <WebView
      source={{ uri: metadata.viewer_sources[0].url }}
      scrollEnabled={false}
      originWhitelist={["*"]}
      style={styles.webView}
      allowsFullscreenVideo
    />
  );
};

const styles = StyleSheet.create({
  webContainer: {
    width: "100%",
  },
  iframe: {
    aspectRatio: 16 / 9,
    marginRight: "auto",
    marginLeft: "auto",
  },
  webView: {
    width: "100%",
    aspectRatio: 16 / 9,
    opacity: 0.99, // yeah really. https://github.com/react-native-webview/react-native-webview/issues/811#issuecomment-614152848
  },
});
export default YouTubeVideoAsset;
