import { Race } from "../types/replay";
import { prepareParams } from "../components/Screens/Results/utils";
import { ResultedRace } from "../types/resultedRaces";
import { Platform } from "react-native";
import { cleanSlug } from "./cleanSlug";
import { Columnist } from "../types/news";

// This method is used to save the current page in the browser history
// It should be called just before we navigate somewhere else, IF we want
// the user to come back to this page when pressing the back button.
//
// The default behaviour of the back button is to go back to the previous screen,
// see (URLMapper). That means for certain pages like IndividualRacecard,
// if we click through a say 3 individual racecards, and then press the back button,
// by default, we'll go back to the previous screen, which could be News for example.
//
// By calling savePageInHistory, pressing back will instead cycle back to the last racecard
// the user looked at.
export const savePageInHistory = () => {
  if (Platform.OS == "web") {
    // Send a page view to GA4
    window.gtag?.("config", "G-B99FYT9GF0", {
      page_path: window.location.pathname,
    });
    console.log("Page saved in history");

    history.pushState({}, "", window.location.pathname);
  }
};

export const handlePressReplay = async (
  race: Race | ResultedRace,
  navigation: any
) => {
  const { date, time, track } = prepareParams({
    date: race.meeting?.date || race.meeting_date,
    time: race.start_time_scheduled,
    track: race?.meeting?.track?.slug || race?.track_slug,
  });

  navigation.navigate("IndividualReplay", {
    date,
    track,
    time,
  });
};

export const pressReplayParams = (race: Race | ResultedRace) => {
  const { date, time, track } = prepareParams({
    date: race.meeting?.date || race.meeting_date,
    time: race.start_time_scheduled,
    track: race?.meeting?.track?.slug || race?.track_slug,
  });

  return { screen: "IndividualReplay", params: { date, track, time } };
};

export const handlePressOnDemand = async (
  params: { id: number; slug: string },
  navigation
) => {
  navigation.navigate("IndividualOnDemand", params);
};

export const navigateToArticle = (navigation: any, slug: string) => {
  savePageInHistory();

  navigation.navigate("NewsPost", {
    slug: cleanSlug(slug),
  });
};
