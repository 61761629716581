import { ToastProvider } from "react-native-toast-notifications";
import React from "react";
import { View } from "react-native";
import RText from "../RText";
import variables from "../../styles/variables";
import { StyleSheet } from "react-native";
import { useResponsive } from "../../hooks/useResponsive";
import { useAtom } from "jotai";
import { headerEndYAtom } from "../../atoms";

export default function ToastManager({ children }) {
  const [headerEndY] = useAtom(headerEndYAtom);
  const { isMediumAndUp } = useResponsive();

  const position = isMediumAndUp
    ? { alignSelf: "flex-end", marginRight: 10 }
    : {};

  return (
    <ToastProvider
      offsetTop={headerEndY + 8}
      offsetBottom={80}
      placement="top"
      duration={4000}
      animationType="slide-in"
      renderType={{
        error: (toast) => (
          // @ts-ignore - position is wrongly highlighted
          <View style={[styles.shape, styles.error, position]}>
            <RText family="secondary" weight="bold" style={styles.title}>
              {toast.data.title}
            </RText>
            <RText family="primary" style={styles.body}>
              {toast.data.subtext || ""}
            </RText>
          </View>
        ),
        info: (toast) => (
          // @ts-ignore - position is wrongly highlighted
          <View style={[styles.shape, styles.info, position]}>
            <RText family="secondary" weight="bold" style={styles.title}>
              {toast.data.title}
            </RText>
            <RText style={styles.body}>{toast.data.subtext || ""}</RText>
          </View>
        ),
      }}
    >
      {children}
    </ToastProvider>
  );
}

const styles = StyleSheet.create({
  shape: {
    width: "97%",
    maxWidth: 400,
    paddingHorizontal: 15,
    paddingVertical: 10,
    backgroundColor: "#fff",
    marginTop: 4,
    marginVertical: 4,
    borderRadius: 8,
    borderLeftWidth: 6,
    justifyContent: "center",
    paddingLeft: 16,
    shadowColor: variables.colors.palette.rtv.black,
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
  },
  title: {
    fontSize: 14,
    color: "#333",
    fontWeight: "bold",
  },
  body: {
    color: variables.colors.palette.neutral.x600,
    marginTop: 2,
  },
  error: {
    borderLeftColor: variables.colors.palette.watchAndBet.red,
  },
  info: {
    borderLeftColor: variables.colors.palette.rtv.yellow,
  },
});
