import { fetchGet, fetchPost } from "..";
import { Notifications, NotificationsCount } from "../../types/notifications";
import { APIResponse, Meta } from "../../types/responses/APIResponse";

export const getNotificationsCount = async (): Promise<number> => {
  const { unread_count }: APIResponse<NotificationsCount["unread_count"]> =
    await fetchGet(`member/notifications/count`, {}, true);
  return unread_count;
};

export const getNotifications = async (
  page: number
): Promise<Notifications> => {
  const { unread_count, notifications, pagination }: Notifications =
    await fetchGet(`member/notifications`, {
      "pagination[page]": page,
    });
  return { unread_count, notifications, pagination };
};

export const postTestNotifications = async (): Promise<void> => {
  await fetchPost(`member/notifications/test`, {}, {}, "POST");
};

export const putNotificationRead = async (id: string): Promise<number> => {
  const { unread_count } = await fetchPost(
    `member/notifications/${id}/mark/read/`,
    {},
    { id },
    "PUT"
  );
  return unread_count;
};

export const putNotificationUnread = async (id: string): Promise<number> => {
  const { unread_count } = await fetchPost(
    `member/notifications/${id}/mark/unread/`,
    {},
    { id },
    "PUT"
  );
  return unread_count;
};

export const putNotificationsAllRead = async (): Promise<number> => {
  const { unread_count } = await fetchPost(
    `member/notifications/all/mark/read/`,
    {},
    {},
    "PUT"
  );
  return unread_count;
};

export const putNotificationsAllUnread = async (): Promise<number> => {
  const { unread_count } = await fetchPost(
    `member/notifications/all/mark/unread/`,
    {},
    {},
    "PUT"
  );
  return unread_count;
};

type GetRaceNotificationResponse = {
  meta: Meta;
  registrations: number[];
};

export const getRaceNotifications = async (
  raceIds: number[]
): Promise<GetRaceNotificationResponse> => {
  const ids = raceIds.map((id) => `ids[]=${id}`).join("&");
  return await fetchGet(`member/notifications/registrations/race?${ids}`);
};

type PostRaceNotificationResponse = {
  meta: Meta;
};

export const addRaceNotification = async (
  raceId: number
): Promise<PostRaceNotificationResponse> =>
  await fetchPost(
    `member/notifications/registrations/race/${raceId}`,
    {},
    {},
    "POST"
  );

export const deleteRaceNotification = async (
  raceId: number
): Promise<PostRaceNotificationResponse> =>
  await fetchPost(
    `member/notifications/registrations/race/${raceId}`,
    {},
    {},
    "DELETE"
  );

export const getTvSlotNotifications = async (
  slotIds: number[]
): Promise<GetRaceNotificationResponse> => {
  const ids = slotIds.map((id) => `ids[]=${id}`).join("&");
  return await fetchGet(
    `member/notifications/registrations/television-slot?${ids}`,
    {},
    true
  );
};

export const addTvSlotNotification = async (
  slotId: number
): Promise<GetRaceNotificationResponse> =>
  await fetchPost(
    `member/notifications/registrations/television_slot/${slotId}`,
    {},
    {},
    "POST"
  );

export const deleteTvSlotNotification = async (
  slotId: number
): Promise<PostRaceNotificationResponse> =>
  await fetchPost(
    `member/notifications/registrations/television_slot/${slotId}`,
    {},
    {},
    "DELETE"
  );
