import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import variables from "../../../../../styles/variables";
import { useResponsive } from "../../../../../hooks/useResponsive";
import CircleLoader from "../shared/CircleLoader";
import TextLoader from "../shared/TextLoader";
import RectangleLoader from "../shared/RectangleLoader";
import MultilineTextLoader from "../shared/MultilineTextLoader";

const { colors, spacing } = variables;

const ROffer: React.FC = () => {
  const { isMediumAndDown } = useResponsive();

  type ButtonsProps = {
    smallScreen: boolean;
  };

  const Buttons = ({ smallScreen }: ButtonsProps) => {
    return (
      <View
        style={[
          buttonsStyles.container,
          {
            justifyContent: smallScreen ? "space-between" : "flex-end",
          },
        ]}
      >
        <View
          style={[
            buttonsStyles.info,
            !smallScreen && { justifyContent: "flex-end" },
          ]}
        >
          <CircleLoader style={buttonsStyles.spacing} />
          <View style={{ width: "40%" }}>
            <TextLoader style={buttonsStyles.spacing} />
          </View>
        </View>
        <RectangleLoader
          width={120}
          height={40}
          style={!smallScreen && { marginLeft: spacing.xsmall }}
        />
      </View>
    );
  };

  return (
    <TouchableOpacity style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <View style={styles.bmLogo} />
            <View style={{ minWidth: "90%", maxWidth: "100%" }}>
              <TextLoader height={25} />
            </View>
          </View>
          {!isMediumAndDown && <Buttons smallScreen={false} />}
        </View>
        <MultilineTextLoader
          numberOfLines={isMediumAndDown ? 4 : 3}
          style={styles.description}
        />
        {!!isMediumAndDown && <Buttons smallScreen />}
      </View>
    </TouchableOpacity>
  );
};

export default ROffer;

const styles = StyleSheet.create({
  container: {
    borderStyle: "solid",
    borderColor: colors.palette.rtv.primary,
    borderRadius: 5,
    borderWidth: 1,
    width: "100%",
  },
  innerContainer: {
    borderStyle: "solid",
    borderColor: "transparent",
    padding: spacing.xsmall,
    borderRadius: 5,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerLeft: {
    flexDirection: "row",
    alignItems: "center",
  },
  bmLogo: {
    marginRight: spacing.xsmall,
    width: 50,
    height: 50,
    backgroundColor: colors.palette.neutral.x100,
    borderRadius: 5,
  },
  description: {
    // paddingVertical: spacing.xxsmall,
    marginVertical: spacing.xxsmall,
  },
});

const buttonsStyles = StyleSheet.create({
  container: { flexDirection: "row", alignItems: "center" },
  info: { flexDirection: "row", alignItems: "center" },
  spacing: { marginRight: spacing.xsmall },
  text: { fontWeight: "bold" },
});
