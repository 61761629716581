import React from "react";
import { StyleSheet, View, Platform } from "react-native";
import variables from "../../../../../styles/variables";
import TextLoader from "../shared/TextLoader";
import MultilineTextLoader from "../shared/MultilineTextLoader";
import SquareLoader from "../shared/SquareLoader";
import { useResponsive } from "../../../../../hooks/useResponsive";
import Gap from "../../../../Gap/Gap";

const { colors, spacing } = variables;

const PodcastsLoader = () => {
  const { isMediumAndDown } = useResponsive();

  const Podcast = () => {
    return (
      <View style={styles.container}>
        <View style={styles.podcastHeading}>
          <TextLoader />
        </View>
        <View style={styles.podcastDescription}>
          <MultilineTextLoader numberOfLines={isMediumAndDown ? 3 : 2} />
          <View style={{ flexDirection: "row", width: "100%" }}>
            <SquareLoader width={80} height={80} style={{ marginRight: 10 }} />
            <View style={{ width: "75%" }}>
              <View style={{ width: "60%" }}>
                <TextLoader />
              </View>
              <Gap vertical size="xxsmall" />
              <View style={{ width: "20%" }}>
                <TextLoader />
              </View>
              <Gap vertical size="medium" />
              <View style={{ width: "100%" }}>
                <TextLoader />
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  return (
    <>
      <View style={styles.pageDescription}>
        <MultilineTextLoader numberOfLines={isMediumAndDown ? 3 : 2} />
      </View>
      {[0, 1, 2].map((idx) => (
        <Podcast key={idx} />
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  pageDescription: {
    marginBottom: spacing.small,
  },
  container: {
    paddingVertical: Platform.OS == "web" ? "1rem" : spacing.xsmall,
    borderStyle: "solid",
    borderColor: colors.palette.neutral.x50,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  podcastHeading: {
    width: "60%",
  },
  podcastDescription: {
    marginTop: spacing.xxsmall,
  },
  podcast: {
    marginTop: spacing.xsmall,
    height: 100,
  },
  iFrameContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

export default PodcastsLoader;
