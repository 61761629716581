import { Platform } from "react-native";
import { useNavigation, useLinkProps } from "@react-navigation/native";
import { To } from "@react-navigation/native/lib/typescript/src/useLinkTo";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../types/route";

export type RPressableProps = {
  screen: ReactNavigation.RootParamList;
  params?: any;
  openInNewTab?: boolean;
  preNavAction?: () => void;
};

// This only handles internal linking. For external
// linking, use RLink or RLinkButton components

// Custom hook using ES6 arrow function syntax
// Best used with either either a TouchableOpacity or Pressable. It does not work on a View on ios.
// Preference would be TouchableOpacity as it gives user feedback on press on native.
const usePressableProps = ({
  screen,
  params,
  openInNewTab = false,
  preNavAction = () => {}, // Do something before navigating. Does not work on web.
}: RPressableProps) => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>(); // Access the navigation prop with an arrow function
  const to: To<ReactNavigation.RootParamList> = {
    screen: screen,
    params: params,
  };

  const linkProps = useLinkProps({ to });
  // Modify linkProps onPress to handle opening in new tab
  const webProps = {
    href: linkProps.href,
    accessibilityRole: linkProps.accessibilityRole,
    onPress: (e) => {
      e.preventDefault(); // Prevent default to manually handle navigation
      window?.open(linkProps.href, openInNewTab ? "_blank" : "_self"); // Use `href` from `useLinkProps` to open in a new tab
    },
  };

  // Determine pressableProps based on the platform with ternary operator
  const pressableProps =
    Platform.OS === "web"
      ? { ...webProps }
      : {
          onPress: () => {
            preNavAction();
            navigation?.navigate(to.screen, to.params);
          },
        };

  return pressableProps;
};

export default usePressableProps;
