import React, { useState, useEffect } from "react";
import { View, StyleSheet, Platform, TouchableOpacity } from "react-native";
import variables from "../../../styles/variables";
import { useResponsive } from "../../../hooks/useResponsive";
import { LoadingState } from "../../States/states";
import RText from "../../RText";
import TermsModal from "../../ContentPreview/TermsModal";
import RImage from "../../RImage/RImage";
import { APIResponse } from "../../../types/responses/APIResponse";
import { fetchGet } from "../../../client";
import { Offer } from "../../../types/offer";
import Gap from "../../Gap";
import { RLink } from "../../RLink/RLink";
import RLinkButton from "../../RLink/RLinkButton";
import CmsContent from "../../cms/CmsContent";

const { colors, spacing } = variables;

type Props = {
  id?: string;
  hasData?: boolean;
  data?: Offer;
};

const ROffer = ({ id, hasData = false, data = null }: Props) => {
  const [offer, setOffer] = useState<Offer>();
  const [openTerms, setOpenTerms] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const { isMediumAndDown } = useResponsive();

  useEffect(() => {
    if (hasData) {
      setOffer(data);
      return;
    }
    const fetchData = async () => {
      try {
        const offersRes: APIResponse<any> = await fetchGet(
          `offers/attachment?id=${id}`
        );

        setOffer(offersRes.offers[0]);
      } catch (err) {
        setError("Error loading offers." + err?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  if (loading) return <LoadingState name="ROfferLoader" />;

  if (!offer || error) return;

  const removeFullStop =
    offer.description[offer.description.length - 1] === "." ||
    offer.description[offer.description.length - 1] === "!" ||
    (offer.description[offer.description.length - 1] === " " &&
      offer.description[offer.description.length - 2] === ".") ||
    (offer.description[offer.description.length - 1] === " " &&
      offer.description[offer.description.length - 2] === "!");

  return (
    <View
      style={[
        styles.container,
        {
          borderWidth: hover ? 4 : 1,
          borderColor: hover
            ? colors.palette.rtv.secondary
            : colors.palette.neutral.x200,
        },
      ]}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <View
        style={[
          styles.innerContainer,
          {
            borderWidth: hover ? 1 : 4,
          },
        ]}
      >
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <RImage
              style={styles.bmLogo}
              source={offer.bookmaker?.logo_square?.placeholder_image_url}
            />
            <RText
              uppercase
              weight="bold"
              size={isMediumAndDown ? "sm" : "lg"}
              family="secondary"
              style={Platform.OS !== "web" ? androidStyles.offerText : null}
            >
              {offer.text}
            </RText>
          </View>
          {!isMediumAndDown && (
            <Buttons
              smallScreen={false}
              setOpenTerms={setOpenTerms}
              offer={offer}
            />
          )}
        </View>
        {offer?.description.includes("<RParagraph") ? (
          <CmsContent
            style={
              Platform.OS === "android"
                ? androidStyles.description
                : styles.description
            }
            content={offer.description}
            allContent={false}
          />
        ) : (
          <RText
            style={
              Platform.OS === "android"
                ? androidStyles.description
                : styles.description
            }
          >
            {offer.description}
            {!removeFullStop && "."}
          </RText>
        )}

        {isMediumAndDown && (
          <Buttons smallScreen setOpenTerms={setOpenTerms} offer={offer} />
        )}
      </View>
      {openTerms ? (
        <TermsModal
          openModal={openTerms}
          setOpenModal={setOpenTerms}
          data={offer}
        />
      ) : null}
    </View>
  );
};

type ButtonsProps = {
  smallScreen: boolean;
  setOpenTerms?: (prev) => void;
  offer?: any;
};

const Buttons = ({ smallScreen, offer, setOpenTerms }: ButtonsProps) => {
  return (
    <View
      style={[
        buttonsStyles.container,
        {
          justifyContent: smallScreen ? "space-between" : "flex-end",
        },
      ]}
    >
      {offer.review_page_link ? (
        <RLink
          type="href"
          href={offer.review_page_link}
          pressableStyle={buttonsStyles.info}
          color="secondary"
          weight="bold"
          style={buttonsStyles.spacing}
          size={"sm"}
        >
          Read Review
        </RLink>
      ) : Platform.OS != "web" ? (
        <Gap size="xlarge" />
      ) : null}
      <TouchableOpacity onPress={() => setOpenTerms(true)}>
        <RText
          color="secondary"
          weight="bold"
          style={[buttonsStyles.spacing]}
          dynamicTextStyle={{
            onHover: "underline",
          }}
        >
          Terms & Conditions
        </RText>
      </TouchableOpacity>
      <RLinkButton href={offer.link} text="Claim Now" />
    </View>
  );
};

export default ROffer;

const styles = StyleSheet.create({
  container: {
    borderStyle: "solid",
    borderColor: colors.palette.rtv.secondary,
    borderRadius: 5,
    minWidth: "100%",
    maxWidth: "100%",
  },
  innerContainer: {
    borderStyle: "solid",
    borderColor: "transparent",
    padding: spacing.xsmall,
    borderRadius: 5,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerLeft: {
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "100%",
    flex: 2,
  },
  bmLogo: {
    marginRight: spacing.xsmall,
    backgroundColor: colors.palette.neutral.x100,
    borderRadius: 5,
    aspectRatio: 1 / 1,
    width: 40,
    height: 40,
  },
  description: {
    paddingVertical: spacing.xsmall,
    marginBottom: 4, // good for ios
  },
});

const androidStyles = StyleSheet.create({
  offerText: {
    flex: 1,
  },
  description: {
    paddingVertical: spacing.xsmall,
    flex: 1,
  },
});

const buttonsStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  info: { flexDirection: "row" },
  spacing: {
    marginRight: spacing.xsmall,
    paddingVertical: 8,
  },
  text: { fontWeight: "bold" },
});
