import {
  StyleSheet,
  TextInput,
  KeyboardType,
  Platform,
  ViewStyle,
} from "react-native";
import React, { forwardRef, useEffect, useState } from "react";
import variables from "../../../../styles/variables";

type Props = {
  onChangeText: (e) => void;
  keyboardType?: KeyboardType;
  placeholder: string;
  onBlur?: any;
  value: any;
  isSecure: boolean;
  style?: ViewStyle;
  focusOnMount?: boolean;
  onSubmitEditing?: () => void;
  multiline?: boolean;
  onClear?: () => void;
  isEditable?: boolean;
  maxLength?: number;
  blurOnSubmit?: boolean;
};

const RTextInput = (
  {
    onChangeText,
    keyboardType = "default",
    placeholder,
    onBlur,
    value,
    isSecure,
    style,
    focusOnMount = false,
    onSubmitEditing,
    multiline,
    onClear,
    isEditable = true,
    maxLength,
    blurOnSubmit = true,
  }: Props,
  ref
) => {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (focusOnMount) ref?.current?.focus();
    return () => {
      onClear?.();
    };
  }, [focusOnMount]);

  return (
    <TextInput
      ref={ref}
      multiline={multiline}
      editable={isEditable}
      keyboardType={keyboardType}
      secureTextEntry={isSecure}
      onChangeText={onChangeText}
      style={[styles.textInput, isFocused && styles.focused, style]}
      placeholder={placeholder}
      placeholderTextColor={colors.palette.neutral.x500}
      onBlur={() => {
        onBlur?.();
        setIsFocused(false);
      }}
      value={value}
      onFocus={() => setIsFocused(true)}
      onSubmitEditing={() => {
        setIsFocused(false);
        onSubmitEditing && onSubmitEditing();
      }}
      maxLength={maxLength}
      blurOnSubmit={blurOnSubmit}
    />
  );
};

export default forwardRef(RTextInput);

const { colors, spacing } = variables;

const styles = StyleSheet.create({
  textInput: {
    borderRadius: 5,
    borderWidth: 2,
    borderColor: colors.palette.neutral.x100,
    height: 40,
    minWidth: 50,
    margin: spacing.xxxsmall,
    padding: spacing.xxsmall,
  },
  focused: {
    ...Platform.select({
      web: {
        outlineColor: colors.palette.buttons.outline.borderColor,
      },
      native: { borderColor: colors.palette.buttons.outline.borderColor },
    }),
  },
});
