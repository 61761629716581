import React, { useEffect, useState } from "react";
import Constants, { ExecutionEnvironment } from "expo-constants";
import { Platform } from "react-native";
const isBareApp = Constants.executionEnvironment === ExecutionEnvironment.Bare;
const isWebApp = Platform.OS === "web";
let RNSS;
if (isBareApp && !isWebApp) {
  RNSS = require("react-native-system-setting").default;
}

const useSystemVolume = () => {
  const [volume, setVolume] = useState<number>(1);

  if (RNSS) {
    useEffect(() => {
      RNSS.getVolume().then((volume) => {
        console.log("Current volume is " + volume);
        setVolume(volume);
      });
      const volumeListener = RNSS.addVolumeListener((data) => {
        const volume = data.value;
        console.log(volume);
        setVolume(volume);
      });
      return () => {
        RNSS.removeVolumeListener(volumeListener);
      };
    }, []);
  }

  return { volume };
};

export default useSystemVolume;
