import { useAtom } from "jotai";
import { useEffect, useState, useMemo } from "react";
import { currentRouteAtom } from "../atoms";

export interface MenuProps {
  title: string;
  route: string;
  params: Object;
}

export const useShowSubmenu = () => {
  const [menuToShow, setMenuToShow] = useState(null);
  const [currentRoute] = useAtom(currentRouteAtom);

  const watchSubmenu = useMemo(
    () => ({
      routes: [
        "Watch",
        "Replays",
        "OnDemand",
        "CatchUp",
        "TvSchedule",
        "IndividualReplay",
        "IndividualOnDemand",
        "CombinedOnDemand",
      ],
      menu: [
        { title: "Live", route: "Watch" },
        { title: "Replays", route: "Replays" },
        { title: "On Demand", route: "OnDemand" },
        { title: "Catch Up", route: "CatchUp" },
        { title: "Tv Schedule", route: "TvSchedule" },
      ],
    }),
    []
  );

  const tipsSubmenu = useMemo(
    () => ({
      routes: [
        "RTVTipsters",
        //  "AllTips",
        "NapOfTheDay",
        "IndividualTipster",
      ],
      menu: [
        { title: "Racing TV Tipsters", route: "RTVTipsters" },
        // { title: "All Tips", route: "AllTips" },
        { title: "NAP of the Day", route: "NapOfTheDay" },
      ],
    }),
    []
  );

  const newsSubmenu = useMemo(
    () => ({
      routes: [
        "AllNews",
        "Latest",
        "Highlights",
        "Columnists",
        "MostViewed",
        "IndividualColumnist",
        "NewsPost",
        "StableTours",
      ],
      menu: [
        { title: "All", route: "AllNews" },
        { title: "Latest", route: "Latest" },
        { title: "Highlights", route: "Highlights" },
        { title: "Columnists", route: "Columnists" },
        { title: "Most Viewed", route: "MostViewed" },
        // { title: "Stable Tours", route: "StableTours" },
      ],
    }),
    []
  );

  const racesSubmenu = useMemo(
    () => ({
      routes: [
        "Racecards",
        // "Antepost",
        "TodaysRunners",
        "TomorrowsRunners",
        "NonRunners",
        // "IndividualAntepostRacecard",
        "IndividualRacecard",
        "HorseRacingCalendar",
      ],
      menu: [
        { title: "Racecards", route: "Racecards" },
        // { title: "Antepost", route: "Antepost" },
        { title: "Today's Runners", route: "TodaysRunners" },
        { title: "Non-Runners", route: "NonRunners" },
        {
          title: "Tomorrow's Runners",
          route: "TomorrowsRunners",
        },
        {
          title: "Racing Calendar",
          route: "HorseRacingCalendar",
        },
      ],
    }),
    []
  );

  const resultsSubmenu = useMemo(
    () => ({
      routes: ["Results", "FastResults", "IndividualResult"],
      menu: [
        { title: "Results", route: "Results" },
        { title: "Fast Results", route: "FastResults" },
      ],
    }),
    []
  );

  const membersSubMenu = useMemo(
    () => ({
      routes: [
        "Benefits",
        "RtvExtra",
        "ClubDays",
        "Magazine",
        "Rewards4Racing",
        "BenefitsPage",
      ],
      menu: [
        { title: "Benefits", route: "Benefits" },
        { title: "Racing TV extra", route: "RtvExtra" },
        { title: "Club Days", route: "ClubDays" },
        { title: "Magazine", route: "Magazine" },
        { title: "Rewards4Racing", route: "Rewards4Racing" },
      ],
    }),
    []
  );

  useEffect(() => {
    const submenus = [
      tipsSubmenu,
      newsSubmenu,
      watchSubmenu,
      racesSubmenu,
      resultsSubmenu,
      membersSubMenu,
    ];

    const determineSubMenu = () => {
      for (let submenu of submenus) {
        if (submenu.routes.includes(currentRoute)) {
          return submenu.menu;
        }
      }
      return null;
    };

    setMenuToShow(determineSubMenu());
  }, [currentRoute]);

  return menuToShow;
};
