import { Animated, Pressable, StyleSheet } from "react-native";
import React, { useEffect } from "react";
import RText from "../../RText";
import { AutocompleteHit } from "./SearchClient";
import variables from "../../../styles/variables";

type Props = {
  hit: AutocompleteHit;
  setSearchTerm: (searchTerm: string) => void;
};

const SearchSuggestion = ({ hit, setSearchTerm }: Props) => {
  const [hover, setHover] = React.useState(false);
  const animatedValue = new Animated.Value(0);

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: hover ? 1 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start();

    return () => {};
  }, [hover]);

  const scale = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 1.2],
  });

  return (
    <Animated.View
      style={{
        transform: [{ scale: scale }],
        maxWidth: "100%",
        zIndex: hover ? 100 : 0,
      }}
    >
      <Pressable
        onHoverIn={() => setHover(true)}
        onHoverOut={() => setHover(false)}
        onPress={() => setSearchTerm(hit.name)}
        style={styles.suggestion}
      >
        <RText weight={hover ? "bold" : "semiBold"}>
          {hit.type !== "news_article" ? hit.name.capitalize() : hit.name}
        </RText>
      </Pressable>
    </Animated.View>
  );
};

export default SearchSuggestion;

const { colors, spacing } = variables;

const styles = StyleSheet.create({
  suggestion: {
    padding: spacing.xxsmall,
    backgroundColor: colors.palette.neutral.x100,
    margin: spacing.xxsmall,
    justifyContent: "center",
    alignItems: "center",
  },
});
