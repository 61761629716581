import { View } from "react-native";
import React from "react";
import { useResponsive } from "../../../../../hooks/useResponsive";
import variables from "../../../../../styles/variables";
import SquareLoader from "../shared/SquareLoader";
import TextLoader from "../shared/TextLoader";
import MultilineTextLoader from "../shared/MultilineTextLoader";
import TipLoader from "./TipsterLoader";

const IndividualTipsterLoader = () => {
  const { isMediumAndDown } = useResponsive();

  const { colors, spacing } = variables;

  const TipsterBio = () => {
    const Image = () => {
      return (
        <View style={{ marginRight: spacing.xsmall }}>
          <SquareLoader width={80} />
        </View>
      );
    };

    const Name = () => {
      return (
        <View style={{ marginBottom: spacing.xxsmall }}>
          <TextLoader width={"70%"} />
        </View>
      );
    };

    const Description = () => {
      return (
        <View>
          <MultilineTextLoader numberOfLines={4} />
        </View>
      );
    };

    if (isMediumAndDown)
      return (
        <View style={{ flexDirection: "row" }}>
          <Image />
          <View style={{ flex: 1 }}>
            <Name />
            <Description />
          </View>
        </View>
      );

    if (!isMediumAndDown)
      return (
        <>
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: colors.separatorLight,
              borderBottomWidth: 1,
              borderStyle: "solid",
              paddingBottom: spacing.small,
              alignItems: "center",
            }}
          >
            <Image />
            <Name />
          </View>
          <View style={{ width: "100%" }}>
            <Description />
          </View>
        </>
      );
  };

  return (
    <>
      <TipsterBio />
      <TipLoader />
      <TipLoader />
      <TipLoader />
    </>
  );
};

export default IndividualTipsterLoader;
