import { View } from "react-native";
import { useResponsive } from "../../../hooks/useResponsive";
import { TextLoader } from "../../States/Loading/Loaders";

const arr7 = Array(7).fill(0);
const arr3 = Array(3).fill(0);

const DetailsLoader = () => {
  const { isLargeAndUp } = useResponsive();
  return (
    <View
      style={[
        {
          marginVertical: 10,
          justifyContent: "space-between",
          alignItems: "flex-start",
        },
        isLargeAndUp && { flexDirection: "row" },
      ]}
    >
      <View
        style={{
          justifyContent: "flex-start",
          width: isLargeAndUp ? "50%" : "100%",
        }}
      >
        {arr7.map((_, index) => {
          return (
            <View style={{ marginVertical: 5 }} key={`left${index}`}>
              <TextLoader height={20} width={200} />
            </View>
          );
        })}
      </View>
      <View
        style={{
          justifyContent: "flex-start",
          width: isLargeAndUp ? "50%" : "100%",
        }}
      >
        {arr7.map((_, index) => {
          return (
            <View style={{ marginVertical: 5 }} key={`right${index}`}>
              <TextLoader height={20} width={200} />
            </View>
          );
        })}
      </View>
    </View>
  );
};

const FutureEntrieLoader = () => {
  const { isLargeAndUp } = useResponsive();
  return (
    <>
      {arr3.map((_, index) => {
        return (
          <View
            style={{
              flexDirection: "row",
              paddingHorizontal: 10,
              height: 30,
              alignItems: "center",
            }}
            key={`FutureEntrieLoader${index}`}
          >
            <TextLoader
              style={{ flex: isLargeAndUp ? 1 : 0.9 }}
              height={20}
              width={50}
            />
            <TextLoader
              style={{ flex: isLargeAndUp ? 0.5 : 0.7 }}
              height={20}
              width={30}
            />
            <TextLoader
              style={{ flex: isLargeAndUp ? 0.5 : 0.7 }}
              height={20}
              width={30}
            />
            {isLargeAndUp && (
              <TextLoader style={{ flex: 3 }} height={20} width={400} />
            )}
            <TextLoader
              style={{ flex: isLargeAndUp ? 1.5 : 1.7 }}
              height={20}
              width={200}
            />
            <TextLoader
              style={{ flex: isLargeAndUp ? 1 : 1.2 }}
              height={20}
              width={200}
            />
          </View>
        );
      })}
    </>
  );
};

const FormLoader = () => {
  const { isLargeAndUp } = useResponsive();
  return (
    <>
      {arr3.map((_, index) => {
        return (
          <View
            style={{
              flexDirection: "row",
              padding: 10,
              height: 30,
              alignItems: "center",
            }}
            key={`formLoader${index}`}
          >
            {isLargeAndUp ? (
              <>
                <TextLoader style={{ flex: 1 }} height={20} width={50} />
                <TextLoader style={{ flex: 0.6 }} height={20} width={50} />
                <TextLoader style={{ flex: 1 }} height={20} width={50} />
                <TextLoader style={{ flex: 1 }} height={20} width={50} />
                <TextLoader style={{ flex: 0.7 }} height={20} width={50} />
                <TextLoader style={{ flex: 1.2 }} height={20} width={50} />
                <TextLoader style={{ flex: 1.2 }} height={20} width={50} />
                <TextLoader style={{ flex: 1 }} height={20} width={50} />
                <TextLoader style={{ flex: 1 }} height={20} width={50} />
                <TextLoader style={{ flex: 3 }} height={20} width={200} />
                <TextLoader style={{ flex: 1 }} height={20} width={100} />
                <TextLoader style={{ flex: 0.5 }} height={20} width={50} />
                <TextLoader style={{ flex: 0.5 }} height={20} width={50} />
                <TextLoader style={{ flex: 0.7 }} height={20} width={50} />
              </>
            ) : (
              <>
                <TextLoader style={{ flex: 1.5 }} height={20} width={50} />
                <TextLoader style={{ flex: 1.5 }} height={20} width={50} />
                <TextLoader style={{ flex: 1.5 }} height={20} width={50} />
                <TextLoader style={{ flex: 1 }} height={20} width={50} />
                <TextLoader style={{ flex: 0.5 }} height={20} width={50} />
                <TextLoader style={{ flex: 1 }} height={20} width={50} />
                <TextLoader style={{ flex: 0.7 }} height={20} width={50} />
              </>
            )}
          </View>
        );
      })}
    </>
  );
};

const RecordLoader = () => {
  return (
    <>
      {arr3.map((_, index) => {
        return (
          <View
            style={{
              flexDirection: "row",
              paddingHorizontal: 10,
              height: 30,
              alignItems: "center",
              justifyContent: "space-between",
            }}
            key={`RecordLoader${index}`}
          >
            <TextLoader style={{ flex: 3 }} height={20} width={"90%"} />
            <TextLoader style={{ flex: 5 }} height={20} width={"90%"} />
            <TextLoader style={{ flex: 5 }} height={20} width={"90%"} />
            <TextLoader style={{ flex: 5 }} height={20} width={"90%"} />
            <TextLoader style={{ flex: 5 }} height={20} width={"100%"} />
          </View>
        );
      })}
    </>
  );
};

export { DetailsLoader, FutureEntrieLoader, FormLoader, RecordLoader };
