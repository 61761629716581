/**
 * Remove comma and any characters following the comma from the input string
 *
 * @example
 * removeCommaAndCharactersAfter('T: Joseph O'Brien, Ireland') // 'T: Joseph O'Brien'
 *
 * @param string The string to shorten.
 * @returns The formatted string.
 */

export const removeCommaAndChars = (string: string): string => {
  if (typeof string !== "string") return;

  if (!string.includes(",")) return string;

  const commaIndex = string.indexOf(",");

  if (commaIndex !== -1) {
    return string.substring(0, commaIndex);
  }

  return string;
};

// TESTS....
// console.log(removeCommaAndCharactersAfter("T: Joseph O'Brien, Ireland")); // "returns T: Joseph O'Brien"
