import React from "react";
import { ScrollView, ScrollViewProps, View, ViewStyle } from "react-native";
import { useScrollControl } from "../../hooks/useScrollControl";

type Props = ScrollViewProps & {
  elementDistance: number;
  style?: ViewStyle;
  childrenWidth: number;
  hideArrows?: boolean;
  scrollRef?: React.RefObject<ScrollView>;
};

const RScrollView = ({
  elementDistance,
  style,
  childrenWidth,
  hideArrows,
  scrollRef,
  ...rest
}: Props) => {
  const [parentWidth, setParentWidth] = React.useState<number>();
  const {
    handleLayout,
    handleHideArrow,
    hideLeftArrow,
    hideRightArrow,
    setPosition,
    ScrollIndicator,
  } = useScrollControl({
    scrollRef,
    elementDistance,
    childrenWidth,
    parentWidth,
  });

  if (rest.horizontal)
    return (
      <View
        onLayout={({ nativeEvent }) => {
          handleLayout(nativeEvent.layout.width, childrenWidth);
          setParentWidth(nativeEvent.layout.width);
        }}
        style={style && style}
      >
        {!(hideArrows || hideLeftArrow) && <ScrollIndicator type="left" />}
        <ScrollView
          {...rest}
          ref={scrollRef}
          showsHorizontalScrollIndicator={false}
          scrollEventThrottle={400}
          onScroll={({ nativeEvent }) => {
            setPosition(nativeEvent.contentOffset.x);
            handleHideArrow(nativeEvent);
          }}
        />
        {!hideArrows && !hideRightArrow && <ScrollIndicator type="right" />}
      </View>
    );

  return <ScrollView style={style && style} {...rest} />;
};

export default React.memo(RScrollView);
