import { PriceFormat } from "../../../../types/tipster";
import { UserPreferences } from "../../../../types/user";
import { STORAGE_KEYS, storage } from "../../../../utils/storage";

export const getDataFromStorage = async () => {
  try {
    const userData = await storage.load({
      key: STORAGE_KEYS.USER_DETAILS,
    });
    return userData;
  } catch (e) {
    return undefined;
  }
};

export const preferenceFormat = {
  fractions: "fractional" as PriceFormat,
  fractional: "fractional" as PriceFormat,
  decimals: "decimal" as PriceFormat,
  decimal: "decimal" as PriceFormat,
};

export const DEFAULT_PRICE_FORMAT = "fractional" as PriceFormat;

export const convertUserPriceFormat = (user: UserPreferences) => {
  return (
    (user?.member?.odds_display &&
      preferenceFormat[user.member.odds_display]) ||
    DEFAULT_PRICE_FORMAT
  );
};
