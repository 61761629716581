import React from "react";
import Icon from "../Icon";
import {
  IconBadge,
  JockeyIconBadge,
  TrainerIconBadge,
} from "../../types/races/runner";
import { StyleSheet } from "react-native";

interface Props {
  badge: IconBadge | JockeyIconBadge | TrainerIconBadge;
  jockey?: boolean;
  trainer?: boolean;
}

const TimeformIcon = ({ badge, jockey = false, trainer = false }: Props) => {
  let iconName = null;

  // only show uplift next to jockey when jockey icon badge is uplift
  if (jockey) iconName = "uplift";

  // only show specific badges next to trainer name
  const nextToTrainer =
    badge === "headgear_first" ||
    badge === "uplift" ||
    badge === "in_form" ||
    badge === "out_of_form";
  if (trainer && nextToTrainer) iconName = iconType[badge];

  // show all other badges next to the timeform comment
  return <Icon name={iconName || iconType[badge]} style={styles.icon} />;
};

export const iconType: {
  [Key in IconBadge | JockeyIconBadge | TrainerIconBadge]: string;
} = {
  break_return: "breakReturn",
  course_negative: "courseNegative",
  course_positive: "coursePositive",
  handicap_debut: "handicapDebut",
  headgear_first: "headgearFirst",
  focus: "horseInFocus",
  sire_course_strike_rate: "sireCourseStrikeRate",
  sire_distance_strike_rate: "sireDistanceStrikeRate",
  sire_going_strike_rate: "sireGoingStrikeRate",
  in_form: "trainerInForm",
  out_of_form: "trainerOutOfForm",
  uplift: "uplift",
  warning: "warningHorse",
  sectional_timing: "sectionalTiming",
};

const styles = StyleSheet.create({
  icon: {
    marginLeft: 5,
  },
});

export default TimeformIcon;
