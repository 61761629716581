import React from "react";
import { View, StyleSheet } from "react-native";
import SubMenu from "./SubMenu";

const TopBar = ({ navOverride = undefined, setSubmenuHeight }) => {
  // TODO (major)- temporary fix for keeping submenu below the popups.
  // We need another way to do this, otherwise every element bar the popup will have negative z-index's attached.
  // setSubmenuHeight is used on native devices to set the height of the submenu.

  return (
    <View
      onLayout={(e) => {
        !!setSubmenuHeight && setSubmenuHeight(e.nativeEvent.layout.height);
      }}
      style={{ width: "100%" }}
    >
      <SubMenu navOverride={navOverride} />
    </View>
  );
};

export default TopBar;
