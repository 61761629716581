import { View } from "react-native";
import TextLoader from "../shared/TextLoader";

const ResultsLoader = () => {
  return (
    <View style={{ marginBottom: 10 }}>
      <TextLoader height={40} style={{ marginBottom: 5 }} />
      <TextLoader height={40} style={{ marginBottom: 5 }} />
      <TextLoader height={40} style={{ marginBottom: 5 }} />
      <TextLoader height={40} style={{ marginBottom: 5 }} />
      <TextLoader height={40} />
    </View>
  );
};

export default ResultsLoader;
