import { useEffect, useState } from "react";
import { Dimensions, ScaledSize } from "react-native";

// IMPORTANT: Do not change the order. The order is used to determine which breakpoint is active.
export const breakpoints = [
  "base",
  "small",
  "medium",
  "large",
  "xlarge",
  "xxlarge",
  "xxxlarge",
  "xxxxlarge",
] as const;

export type BreakpointName = (typeof breakpoints)[number];

export type BreakpointSizes = Record<BreakpointName, number>;

export const breakpointSizes: BreakpointSizes = {
  base: 0,
  small: 576,
  medium: 767,
  large: 992,
  xlarge: 1200,
  xxlarge: 1600,
  xxxlarge: 3100,
  xxxxlarge: 3400,
};

export const useResponsive = () => {
  const [activeBreakpoint, setActiveBreakpoint] = useState<BreakpointName>(
    getCurrentBreakpoint(Dimensions.get("window").width)
  );

  function getCurrentBreakpoint(width: number) {
    return breakpoints.reduce((previous: BreakpointName, current) => {
      const breakpointSize = breakpointSizes[current];
      if (breakpointSize < width) {
        if (!previous || breakpointSize > breakpointSizes[previous]) {
          return current;
        }
      }
      return previous;
    }, "base");
  }

  useEffect(() => {
    function handleChange({ window: { width } }: { window: ScaledSize }) {
      const breakpoint = getCurrentBreakpoint(width);
      if (breakpoint != activeBreakpoint) {
        setActiveBreakpoint(breakpoint);
      }
    }

    const subscription = Dimensions.addEventListener("change", handleChange);
    handleChange({ window: Dimensions.get("window") });
    return () => {
      subscription.remove();
    };
  }, [activeBreakpoint]);

  // Only one breakpoint can be active at a time
  const isXSmallOnly = activeBreakpoint === "base";
  const isSmallOnly = activeBreakpoint === "small";
  const isMediumOnly = activeBreakpoint === "medium";
  const isLargeOnly = activeBreakpoint === "large";
  const isXLargeOnly = activeBreakpoint === "xlarge";
  const isXXLargeOnly = activeBreakpoint === "xxlarge";
  const isXXXLargeOnly = activeBreakpoint === "xxxlarge";
  const isXXXXLargeOnly = activeBreakpoint === "xxxxlarge";

  // Multiple breakpoints can be active at the same time ranging from the current breakpoint and up
  const isXSmallAndUp = activeBreakpoint !== "base";
  const isSmallAndUp =
    activeBreakpoint !== "base" && activeBreakpoint !== "small";
  const isMediumAndUp =
    activeBreakpoint !== "base" &&
    activeBreakpoint !== "small" &&
    activeBreakpoint !== "medium";
  const isLargeAndUp =
    activeBreakpoint !== "base" &&
    activeBreakpoint !== "small" &&
    activeBreakpoint !== "medium" &&
    activeBreakpoint !== "large";
  const isXLargeAndUp =
    activeBreakpoint !== "base" &&
    activeBreakpoint !== "small" &&
    activeBreakpoint !== "medium" &&
    activeBreakpoint !== "large" &&
    activeBreakpoint !== "xlarge";
  const isXXLargeAndUp =
    activeBreakpoint !== "base" &&
    activeBreakpoint !== "small" &&
    activeBreakpoint !== "medium" &&
    activeBreakpoint !== "large" &&
    activeBreakpoint !== "xlarge" &&
    activeBreakpoint !== "xxlarge";
  const isXXXLargeAndUp =
    activeBreakpoint !== "base" &&
    activeBreakpoint !== "small" &&
    activeBreakpoint !== "medium" &&
    activeBreakpoint !== "large" &&
    activeBreakpoint !== "xlarge" &&
    activeBreakpoint !== "xxlarge" &&
    activeBreakpoint !== "xxxlarge";

  // Multiple breakpoints can be active at the same time ranging from the current breakpoint and down
  const isSmallAndDown =
    activeBreakpoint === "base" || activeBreakpoint === "small";
  const isMediumAndDown =
    activeBreakpoint === "base" ||
    activeBreakpoint === "small" ||
    activeBreakpoint === "medium";
  const isLargeAndDown =
    activeBreakpoint === "base" ||
    activeBreakpoint === "small" ||
    activeBreakpoint === "medium" ||
    activeBreakpoint === "large";
  const isXLargeAndDown =
    activeBreakpoint === "base" ||
    activeBreakpoint === "small" ||
    activeBreakpoint === "medium" ||
    activeBreakpoint === "large" ||
    activeBreakpoint === "xlarge";

  return {
    isXSmallOnly,
    isSmallOnly,
    isMediumOnly,
    isLargeOnly,
    isXLargeOnly,
    isXXLargeOnly,
    isXXXLargeOnly,
    isXXXXLargeOnly,

    isXSmallAndUp,
    isSmallAndUp,
    isMediumAndUp,
    isLargeAndUp,
    isXLargeAndUp,
    isXXLargeAndUp,
    isXXXLargeAndUp,

    isSmallAndDown,
    isMediumAndDown,
    isLargeAndDown,
    isXLargeAndDown,
  };
};
