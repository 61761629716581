import React from "react";
import { StyleSheet, View } from "react-native";
import Layout from "../../../../Layout/Layout";
import variables from "../../../../../styles/variables";
import TextLoader from "../shared/TextLoader";
import MultilineTextLoader from "../shared/MultilineTextLoader";
import SquareLoader from "../shared/SquareLoader";
import { useResponsive } from "../../../../../hooks/useResponsive";

//TODO: style loader

const RacecourseLoader = () => {
  const { isMediumAndDown } = useResponsive();

  return (
    <>
      <View>
        <MultilineTextLoader numberOfLines={isMediumAndDown ? 3 : 2} />
      </View>
    </>
  );
};

const styles = StyleSheet.create({});

export default RacecourseLoader;
