import React, { useCallback, useState } from "react";
import * as Updates from "expo-updates";
import {
  Modal,
  Platform,
  View,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import RText from "../components/RText";
import variables from "../styles/variables";
import LoadingSpinner from "../components/LoadingSpinner";
import Gap from "../components/Gap";
import { FRONTEND_URL } from "../client";
import Bugsnag from "@bugsnag/expo";
import { isStoreVersionNewer, openStore } from "../utils/store";

// We can simulate either update here.
const TEST_STORE_UPDATE = false;
const TEST_OTA_UPDATE = false;

const UseUpdates = (showUpdateModal, setShowUpdateModal) => {
  const [loading, setLoading] = useState(false);
  const [storeUpdateAvailable, setStoreUpdateAvailable] =
    useState<boolean>(false);

  // Check app stores for any update
  const checkForStoreUpdate = useCallback(async () => {
    // assumes this endpoint sets a max-age header of 0
    // so updates can be received immediately
    try {
      if ((await isStoreVersionNewer()) || TEST_STORE_UPDATE) {
        console.log("[useUpdates] store update is available!");
        setStoreUpdateAvailable(true);
        return true;
      } else {
        console.log("[useUpdates] store update is not available!");
        return false;
      }
    } catch (e) {
      const error = new Error(`Error checking for app store updates: ${e}`);
      Bugsnag.notify(error);

      return false;
    }
  }, [FRONTEND_URL, Platform.OS]);

  // Show modal if store or OTA update is available
  const checkForUpdateAsync = async () => {
    try {
      const storeUpdateAvailable = await checkForStoreUpdate();

      // checking for OTA update in development mode will crash this function
      // so we avoid that, and just set to false if in dev mode
      const OTAUpdate = !__DEV__
        ? await Updates.checkForUpdateAsync()
        : { isAvailable: TEST_OTA_UPDATE };

      if (storeUpdateAvailable || OTAUpdate?.isAvailable)
        setShowUpdateModal(true);
    } catch (e) {
      console.log("[useUpdates] Error checking for updates: ", e);
      const error = new Error(`Error checking for updates: ${e}`);
      Bugsnag.notify(error);
    }
  };

  const applyOTAUpdate = async () => {
    try {
      setLoading(true);
      await Updates.fetchUpdateAsync();
      await Updates.reloadAsync();
    } catch (err) {
      if (__DEV__) {
        console.log(`[useUpdates] Simulated updated fetch and reload: `, err);
      } else {
        console.log(`Error fetching updates: ${err}`);
        const error = new Error(`Error applying OTA update: ${err}`);
        Bugsnag.notify(error);
      }
    } finally {
      setLoading(false);
      setShowUpdateModal(false);
    }
  };

  const updateModal = () => {
    return (
      <Modal
        style={styles.modal}
        animationType="slide"
        transparent={true}
        visible={showUpdateModal}
      >
        {/* overlay */}
        <View style={styles.overlay} />
        <View style={styles.container}>
          <RText
            weight="semiBold"
            size="lg"
            color="white"
            style={{ width: "100%", textAlign: "center" }}
          >
            There's an update available! 🎉
          </RText>
          <Gap vertical size={"small"} />
          <RText weight="semiBold" size="md" color="white">
            To keep using the app, please update now
          </RText>
          <Gap vertical size={"large"} />
          {loading ? (
            <View style={styles.loadingContainer}>
              <LoadingSpinner />
            </View>
          ) : (
            <TouchableOpacity
              onPress={() =>
                storeUpdateAvailable
                  ? openStore("useUpdates")
                  : applyOTAUpdate()
              }
              style={styles.contentContainer}
            >
              <RText size="md_lg" color="white">
                Update
              </RText>
            </TouchableOpacity>
          )}
        </View>
      </Modal>
    );
  };

  return {
    updateModal,
    checkForUpdateAsync,
  };
};

export default UseUpdates;

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  overlay: {
    height: "100%",
    width: "100%",
    backgroundColor: "black",
    opacity: 0.5,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    height: 230,
    width: "90%",
    backgroundColor: variables.colors.palette.rtv.primary,
    borderRadius: 5,
    padding: 20,
    position: "absolute",
    top: "40%",
    alignSelf: "center",
  },
  loadingContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
  },
  contentContainer: {
    backgroundColor: variables.colors.palette.rtv.secondary,
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    marginTop: 10,
  },
});
