import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { useResponsive } from "../../../../../hooks/useResponsive";
import variables from "../../../../../styles/variables";
import TextLoader from "../shared/TextLoader";
import Gap from "../../../../Gap";

type Props = {};

const RacecardRowLoader = (props: Props) => {
  const { isMediumAndDown } = useResponsive();

  return isMediumAndDown ? (
    <View style={[styles.rowContainer, styles.rowContainerSmallScreen]}>
      <View style={styles.col1SmallScreen}>
        <TextLoader height={30} />
      </View>
      <View style={styles.col2SmallScreen}>
        <TextLoader height={30} />
      </View>
    </View>
  ) : (
    <View style={[styles.rowContainer, styles.rowContainerBigScreen]}>
      <View style={[styles.row, styles.col1BigScreen]}>
        <TextLoader height={30} />
        <Gap size={"xsmall"} />
        <TextLoader height={30} />
        <Gap size={"xsmall"} />
        <TextLoader height={30} width={"100%"} />
      </View>
      <View style={[styles.row, styles.col2BigScreen]}>
        <TextLoader height={20} />
      </View>
      <View style={[styles.row, styles.col3BigScreen, styles.viewAll]}>
        <TextLoader />
      </View>
    </View>
  );
};

export default RacecardRowLoader;

const { spacing } = variables;

const styles = StyleSheet.create({
  rowContainerBigScreen: {
    alignItems: "flex-start",
    height: 50,
  },
  rowContainer: {
    flexDirection: "row",
    marginTop: spacing.xxsmall,
    marginBottom: spacing.xxsmall,
    justifyContent: "space-between",
  },
  rowContainerSmallScreen: {
    alignItems: "center",
    height: 50,
  },
  col1SmallScreen: {
    width: "35%",
  },
  col1BigScreen: {
    width: "20%",
    height: "100%",
    alignItems: "center",
  },
  col2SmallScreen: {
    width: "60%",
    justifyContent: "center",
  },
  col2BigScreen: {
    flexDirection: "row",
    width: "55%",
    flexWrap: "wrap",
    height: "100%",
    alignItems: "center",
  },
  col3BigScreen: {
    width: "15%",
  },
  iconMargin: {
    marginHorizontal: spacing.xsmall,
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  viewAll: {
    alignItems: "center",
    height: "100%",
  },
});
