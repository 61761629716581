import React from "react";
import { View, StyleSheet } from "react-native";
import variables from "../../styles/variables";
import RText from "../RText";
import { Breadcrumb as BreadcrumbT } from "../../types/breadcrumb";
import { removeCountry } from "../../utils/removeCountry";
import { RLink } from "../RLink/RLink";

type BreadcrumbProps = {
  title: string;
  crumb: BreadcrumbT;
  isLastCrumb: boolean;
};

export const Breadcrumb = ({ title, crumb, isLastCrumb }: BreadcrumbProps) => {
  const to = { screen: crumb?.component, params: crumb?.routeParams };
  const type = crumb?.routeParams?.type;

  // if horse/trainer/jockey slug includes a country, remove it from the breadcrumb
  if (
    crumb?.component &&
    crumb?.routeParams &&
    (type === "trainer" || type === "jockey" || type === "horse")
  ) {
    title = removeCountry(title).capitalize();
  }

  if (title?.toLowerCase() === "uk") title = title?.toUpperCase();

  return (
    <View
      style={[styles.breadcrumbsContainer, { marginBottom: spacing.small }]}
    >
      {!!isLastCrumb ? (
        <RText color={"x600"} style={text.breadcrumbsText}>
          {title}
        </RText>
      ) : (
        <>
          <RLink
            to={to}
            size="sm"
            weight="semiBold"
            family="primary"
            dynamicTextStyle={{
              onHover: "underline",
            }}
            style={[text.breadcrumbsText, text.breadcrumb]}
            uppercase={false}
            pressableStyle={{}}
          >
            {title}
          </RLink>
          <RText color="x600">{"  >  "}</RText>
        </>
      )}
    </View>
  );
};

const { colors, spacing } = variables;

const text = StyleSheet.create({
  breadcrumbsText: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  breadcrumb: {
    color: colors.palette.rtv.secondary,
  },
});

const styles = StyleSheet.create({
  breadcrumbsContainer: {
    flexDirection: "row",
  },
});
