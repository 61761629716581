import { View } from "react-native";
import TextLoader from "../shared/TextLoader";

const SingleRaceLoader = () => {
  return (
    <View style={{ flexDirection: "column", marginTop: 5, width: "100%" }}>
      <TextLoader width={"40%"} style={{ marginVertical: 5 }} />
      <TextLoader width={"50%"} style={{ marginVertical: 5 }} />
      <TextLoader width={"80%"} style={{ marginVertical: 5 }} />
    </View>
  );
};

const LastFiveRacesLoader = () => {
  return (
    <View style={{ marginBottom: 10 }}>
      <TextLoader height={40} />
      <View
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SingleRaceLoader />
        <SingleRaceLoader />
        <SingleRaceLoader />
        <SingleRaceLoader />
        <SingleRaceLoader />
      </View>
    </View>
  );
};

export default LastFiveRacesLoader;
