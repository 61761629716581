import React from "react";
import {
  DEFAULT_PREDICTOR_IMAGE,
  DEFAULT_VIDEO_ANALYSIS_IMAGE,
  DEFAULT_OFFER_IMAGE,
  DEFAULT_BOOKMAKER_IMAGE,
  DEFAULT_BENEFITS_IMAGE,
  DEFAULT_NEWS_IMAGE,
  DEFAULT_MAGAZINE_IMAGE,
  DEFAULT_REPLAY_IMAGE,
  DEFAULT_RTV_LIVE_IMAGE,
  DEFAULT_RTV_JUMPS_IMAGE,
  DEFAULT_RTV_FLAT_IMAGE,
  DEFAULT_PRESENTERS_IMAGE,
} from "../Image/images/defaultImages";
type Props = {
  source: string;
  style: any;
  defaultSource?: string;
  resolution?: number | undefined;
  loading?: "lazy" | "eager";
  alt?: string;
};

// IMPORTANT: objectFit MUST have a value of "cover" injected. Images look f*cked otherwise.
const RImage = ({
  source,
  style,
  defaultSource,
  resolution = undefined,
  loading = "lazy",
  alt = undefined,
}: Props) => {
  const [imageSource, setImageSource] = React.useState(source);

  const isDefaultImage: boolean = [
    DEFAULT_PREDICTOR_IMAGE,
    DEFAULT_VIDEO_ANALYSIS_IMAGE,
    DEFAULT_OFFER_IMAGE,
    DEFAULT_BOOKMAKER_IMAGE,
    DEFAULT_BENEFITS_IMAGE,
    DEFAULT_NEWS_IMAGE,
    DEFAULT_MAGAZINE_IMAGE,
    DEFAULT_REPLAY_IMAGE,
    DEFAULT_RTV_LIVE_IMAGE,
    DEFAULT_RTV_JUMPS_IMAGE,
    DEFAULT_RTV_FLAT_IMAGE,
    DEFAULT_PRESENTERS_IMAGE,
  ].includes(imageSource);

  let src = imageSource;

  //add width as a query string if resolution is provided (except for default images since this sometimes causes issues with cloudflare caching and stops the default image from displaying)

  if (resolution && !isDefaultImage) {
    try {
      // add width to existing query params if they exist, then concatenate the original url with the new query string
      const url = new URL(imageSource);
      const searchParams = Object.fromEntries(url.searchParams);
      searchParams.width = resolution.toString();
      const queryString = new URLSearchParams(searchParams).toString();
      src = `${url.href}?${queryString}`;
    } catch (err) {
      // There was an error reading the URL - possible imageSource is not a valid URL - we just won't handle this and unsafely concatenate the width to the qs
      src = `${src}?width=${resolution}`;
    }
  }

  return (
    <img
      style={style}
      onError={() => setImageSource(defaultSource)}
      loading={loading}
      src={src}
      alt={alt}
    />
  );
};

export default RImage;
