import React, { forwardRef, useState, useEffect, useRef, useMemo } from "react";
import {
  StyleSheet,
  View,
  Modal,
  Platform,
  useWindowDimensions,
  TouchableWithoutFeedback,
  LayoutChangeEvent,
  ViewStyle,
  TouchableOpacity,
  Animated,
  Easing,
} from "react-native";
import Icon from "../../components/Icon";
import { useResponsive } from "../../hooks/useResponsive";

type Props = {
  visible: boolean;
  setVisibility: (visible: boolean) => void;
  iframeMode?: boolean;
  children: React.ReactNode;
  hideCloseBtn?: boolean;
  doneButton?: React.ReactNode;
  style?: {
    content?: ViewStyle;
    container?: ViewStyle;
    closeBtn?: ViewStyle;
  };
  fullSize?: boolean;
  authModal?: boolean;
  adOverlay?: boolean;
};

const RModal = (
  {
    visible,
    setVisibility,
    iframeMode,
    children,
    hideCloseBtn,
    doneButton,
    style,
    fullSize,
    authModal = false,
    adOverlay = false,
  }: Props,
  ref
) => {
  const { height } = useWindowDimensions();
  const { isSmallAndDown } = useResponsive();
  const [childrenHeight, setChildrenHeight] = useState(null);
  const [overlayHeight, setOverlayHeight] = useState(null);
  const SAFE_AREA_BUFFER = Platform.OS == "web" ? 0 : 30;

  const communicateHeight = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setChildrenHeight(height + SAFE_AREA_BUFFER);
  };

  const childrenArray = useMemo(
    () => React.Children.toArray(children),
    [children]
  );

  const translateY = useRef(new Animated.Value(height)).current;

  useEffect(() => {
    Animated.timing(translateY, {
      toValue: visible ? 0 : height,
      duration: 500, // Replace with the desired duration in milliseconds
      // easing: Easing.inOut(Easing.cubic), // Add the easing function here
      // easing: Easing.bezier(0.66, -0.07, 0.6, 0.13),
      useNativeDriver: true,
    }).start();
  }, [visible, height]);

  const CloseButton = () => {
    return (
      <TouchableOpacity
        onPress={() => setVisibility(false)}
        style={[styles.closeBtn, { ...style?.closeBtn }]}
      >
        <Icon
          name="close"
          size="small"
          weight="bold"
          fill="palette.buttons.default.hovered.backgroundColor"
          padding={10}
        />
      </TouchableOpacity>
    );
  };

  if (!visible) return null;

  const modalStyles = [
    // TODOS
    // - see why overlay is over-firing, particularly when opening drawer nav
    // possible refactor:
    // - fullSize is only used by magazine, so we can extract and return that early.
    // - we should be able to do similar things with ad overlay and auth modal

    // ensure the modal sits well at the bottom of the screen
    // for phone size
    isSmallAndDown &&
      !fullSize &&
      !authModal && {
        bottom: Platform.OS == "web" ? 0 : 80,
        position: "absolute",
        opacity: 1,
        minHeight: childrenHeight,
        paddingHorizontal: 10,
      },

    // phones and !fullSize set it'll come from bottom
    // otherwise it'll float (ipad, desktop)
    isSmallAndDown && !fullSize && !adOverlay
      ? styles.bottomPosition
      : styles.floatingPosition,

    // if it's not fullSize and not phone size
    // (ipad, desktop)
    // adOverlay && // true should become adOverlay
    !fullSize && !isSmallAndDown
      ? Platform.OS != "web" // ... and ipad only:
        ? authModal
          ? {
              minHeight: "100%",
              maxWidth: "100%",
              justifyContent: "center",
            }
          : {
              minHeight: "90%",
              justifyContent: adOverlay ? "flex-start" : "flex-end",
              paddingBottom: 80, //childrenHeight,
            }
        : { maxWidth: 500 } // ... do nothing on desktop.
      : // otherwise it'll be 95% of the screen centered.
      !adOverlay
      ? [
          Platform.OS == "web"
            ? {
                minWidth: "98%",
                maxWidth: "98%",
                alignSelf: "center",
                ...(fullSize ? { height: "93%" } : {}),
              }
            : {
                minWidth: "100%",
                maxWidth: "100%",
                alignSelf: "center",
              },
          overlayHeight && Platform.OS == "web" && !fullSize
            ? { marginTop: -overlayHeight }
            : {},
        ]
      : {},
    // { backgroundColor: "red" }, // x
  ];
  // console.log("modalStyles: ", JSON.stringify(modalStyles));
  return (
    <Modal
      ref={ref}
      animationType="none"
      transparent={true}
      visible={visible}
      onRequestClose={() => setVisibility(false)}
    >
      {Platform.OS == "android" && (authModal || fullSize) ? (
        <>
          {hideCloseBtn ? null : <CloseButton />}
          {childrenArray.map((child, idx) =>
            React.cloneElement(child, {
              key: idx,
              onLayout: communicateHeight,
            })
          )}
        </>
      ) : (
        <TouchableWithoutFeedback>
          <>
            <TouchableOpacity
              style={[styles.modalOverlay, style?.container]}
              onPress={() => setVisibility(false)}
              onLayout={(event) =>
                setOverlayHeight(event.nativeEvent.layout.height)
              }
            ></TouchableOpacity>
            {/* TODO - check ipad for login sizes. its not right  */}
            <TouchableOpacity
              style={modalStyles}
              onPress={() => setVisibility(false)}
            >
              <TouchableWithoutFeedback>
                <Animated.View
                  nativeID="actual-modal-content"
                  style={[
                    styles.modalView,
                    {
                      ...Platform.select({
                        web: {
                          margin: fullSize ? 0 : 20,
                          marginBottom: 80,
                          height: fullSize ? "100%" : undefined,
                        },
                        native: {
                          width: "100%",
                          height: fullSize ? "100%" : undefined,
                        },
                      }),
                    },
                    childrenHeight ? { minHeight: childrenHeight } : {},
                    {
                      maxHeight: height - 80,
                      padding: iframeMode ? 0 : 8,
                      transform: [{ translateY }],
                      ...style?.content,
                    },
                    { justifyContent: "flex-start" },
                    // { backgroundColor: "blue" }, // x
                  ]}
                >
                  {!hideCloseBtn && (
                    <TouchableOpacity
                      onPress={() => setVisibility(false)}
                      style={[styles.closeBtn, { ...style?.closeBtn }]}
                    >
                      <Icon
                        name="close"
                        size="small"
                        weight="bold"
                        fill="palette.buttons.default.hovered.backgroundColor"
                      />
                    </TouchableOpacity>
                  )}
                  {childrenArray.map((child, idx) =>
                    React.cloneElement(child, {
                      key: idx,
                      onLayout: communicateHeight,
                    })
                  )}
                  {doneButton && (
                    <View style={styles.doneButton}>{doneButton}</View>
                  )}
                </Animated.View>
              </TouchableWithoutFeedback>
            </TouchableOpacity>
          </>
        </TouchableWithoutFeedback>
      )}
    </Modal>
  );
};

export default forwardRef(RModal);

const styles = StyleSheet.create({
  modalOverlay: {
    height: "100%",
    backgroundColor: "black",
    opacity: 0.5,
  },
  floatingPosition: {
    position: "absolute",
    top: "10%",
    bottom: "10%",
    right: 0,
    left: 0,
    margin: "auto",
  },
  bottomPosition: {
    justifyContent: "flex-end",
  },
  modalView: {
    opacity: 1,
    backgroundColor: "white",
    borderRadius: 10,
    alignItems: "center",
  },
  closeBtn: {
    position: "absolute",
    zIndex: 1,
    padding: 10,
    top: 5,
    right: 5,
  },
  doneButton: {
    width: "100%",
    minWidth: "100%",
    zIndex: 1,
  },
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});
