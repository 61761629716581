// annoyingly we can't dynamically import the icons, so we have to do this
// prettier-ignore
export const importIcon = (iconName) => {
  switch (iconName) {
    case 'alertBell': return import('./alertBell.svg');
    case 'binocularsSquare': return import('./binocularsSquare.svg');
    case 'play': return import('./play.svg');
    case 'playCircled': return import('./playCircled.svg');
    case 'playCircledUnavailable': return import('./playCircledUnavailable.svg');
    case 'radial': return import('./radial.svg');
    case 'smallLogo': return import('./smallLogo.svg');
    case 'longLogo': return  import('./longLogo.svg');
    case 'chevronDownCircled': return import('./chevronDownCircled.svg');
    case 'chevronRight': return import('./chevronRight.svg');
    case 'chevronLeft': return import('./chevronLeft.svg');
    case 'chevronRightSmall': return import('./chevronRightSmall.svg');
    case 'chevronBoldUp': return import('./chevronBoldUp.svg');
    case 'chevronBoldDown': return import('./chevronBoldDown.svg');
    case 'chevronBoldRightWhite': return import('./chevronBoldRightWhite.svg');
    case 'chevronLightRight': return import('./chevronLightRight.svg');
    case 'chevronBoldRight': return import('./chevronBoldRight.svg');
    case 'calendar': return import('./calendar.svg');
    case 'calendarCircled': return import('./calendarCircled.svg');
    case 'chevronPinkLeft': return import('./chevronPinkLeft.svg');
    case 'clubDay': return import('./clubDay.svg');
    case 'scottishSeason': return import('./scottishSeason.svg');
    case 'weekend': return import('./weekend.svg');
    case 'lock': return import('./lock.svg');
    case 'menu': return import('./menu.svg');
    case 'search': return import('./search.svg');
    case 'searchBorderlessPink': return import('./searchBorderlessPink.svg');
    case 'watch': return import('./watch.svg');
    case 'results': return import('./results.svg');
    case 'races': return import('./races.svg');
    case 'tick': return import('./tick.svg');
    case 'notificationBadge': return import('./notificationBadge.svg');
    case 'menuSelected': return import('./menuSelected.svg');
    case 'racesSelected': return import('./racesSelected.svg');
    case 'resultsSelected': return import('./resultsSelected.svg');
    case 'watchSelected': return import('./watchSelected.svg');
    case 'stopWatch': return import('./stopWatch.svg');
    case 'facebookDark': return import('./facebookDark.svg');
    case 'facebookLight': return import('./facebookLight.svg');
    case 'twitterDark': return import('./twitterDark.svg');
    case 'twitterLight': return import('./twitterLight.svg');
    case 'instagramDark': return import('./instagramDark.svg');
    case 'instagramLight': return import('./instagramLight.svg');
    case 'youtubeDark': return import('./youtubeDark.svg');
    case 'youtubeLight': return import('./youtubeLight.svg');
    case 'logoLong': return  import('./logoLong.svg');
    case 'logoSquare': return import('./logoSquare.svg');
    case 'beGambleAware': return import('./beGambleAware.svg');
    case 'oddscheckerLogo': return import('./oddscheckerLogo.svg');
    case 'gamCare': return import('./gamCare.svg');
    case 'gamStop': return import('./gamStop.svg');
    case 'gordonMoody': return import('./gordonMoody.svg');
    case 'saferGamblingStandard': return import('./saferGamblingStandard.svg');
    case 'ageLimit': return import('./ageLimit.svg');
    case 'info': return import('./info.svg');
    case 'infoPink': return import('./infoPink.svg');
    case 'rLogo': return import('./rLogo.svg');
    case 'profile': return import('./profile.svg');
    case 'cross': return import('./cross.svg');
    case 'closeCircled': return import('./closeCircled.svg');
    case 'cog': return import('./cog.svg');
    case 'sort': return import('./sort.svg');
    case 'filter': return import('./filter.svg');
    case 'notification': return import('./notification.svg');
    case 'freeBetPlaceholder': return import('./freeBetPlaceholder.svg');
    case 'eye': return import('./eye.svg');
    case 'hat': return import('./hat.svg');
    case 'silkDefault': return import('./silkDefault.svg');
    case 'timeform': return import('./timeform.svg');
    case 'playVideo': return import('./playVideo.svg');
    case 'pauseVideo': return import('./pauseVideo.svg');
    case 'enterFullScreen': return import('./enterFullScreen.svg');
    case 'exitFullScreen': return import('./exitFullScreen.svg');
    case 'close': return import('./close.svg');
    case 'playHead': return import('./playHead.svg');
    case 'volume': return import('./volume.svg');
    case 'mutedVolume': return import('./mutedVolume.svg');
    case 'popout': return import('./popout.svg');
    case 'rtvLogo': return import('./rtvLogo.svg');
    case 'cloudy': return import('./cloudy.svg');
    case 'cloudy_windy': return import('./cloudy_windy.svg');
    case 'cold': return import('./cold.svg');
    case 'partly_cloudy': return import('./partly_cloudy.svg');
    case 'rainy': return import('./rainy.svg');
    case 'snowy': return import('./snowy.svg');
    case 'sunny': return import('./sunny.svg');
    case 'wind': return import('./wind.svg');
    case 'sunny_windy': return import('./sunny_windy.svg');
    case 'rainy_windy': return import('./rainy_windy.svg');
    case 'home': return import('./home.svg');
    case 'homeSelected': return import('./homeSelected.svg');
    case 'tips': return import('./tips.svg');
    case 'tipsSelected': return import('./tipsSelected.svg');
    case 'channelOption': return import('./channelOption.svg');
    case 'pencil': return import('./pencil.svg');
    case 'scrollDOwn': return import('./scrollDOwn.svg');
    case 'notificationSelected': return import('./notificationSelected.svg');
    case 'notificationUnselected': return import('./notificationUnselected.svg');
    case 'binocularsSelected': return import('./binocularsSelected.svg');
    case 'binocularsUnselected': return import('./binocularsUnselected.svg');
    case 'breakReturn': return import('./breakReturn.svg');
    case 'courseNegative': return import('./courseNegative.svg');
    case 'coursePositive': return import('./coursePositive.svg');
    case 'handicapDebut': return import('./handicapDebut.svg');
    case 'headgearFirst': return import('./headgearFirst.svg');
    case 'horseInFocus': return import('./horseInFocus.svg');
    case 'sectionalTiming': return import('./sectionalTiming.svg');
    case 'sireCourseStrikeRate': return import('./sireCourseStrikeRate.svg');
    case 'sireDistanceStrikeRate': return import('./sireDistanceStrikeRate.svg');
    case 'sireGoingStrikeRate': return import('./sireGoingStrikeRate.svg');
    case 'trainerInForm': return import('./trainerInForm.svg');
    case 'trainerOutOfForm': return import('./trainerOutOfForm.svg');
    case 'uplift': return import('./uplift.svg');
    case 'warningHorse': return import('./warningHorse.svg');
    case 'tiktokDark': return import('./tiktokDark.svg');
    case 'tiktokLight': return import('./tiktokLight.svg');
    case 'share': return import('./share.svg');
    // Flags
    case 'flag_ae': return import('./flag_ae.svg');
    case 'flag_au': return import('./flag_au.svg');
    case 'flag_bh': return import('./flag_bh.svg');
    case 'flag_ca': return import('./flag_ca.svg');
    case 'flag_de': return import('./flag_de.svg');
    case 'flag_fr': return import('./flag_fr.svg');
    case 'flag_gb': return import('./flag_gb.svg');
    case 'flag_ie': return import('./flag_ie.svg');
    case 'flag_jp': return import('./flag_jp.svg');
    case 'flag_sa': return import('./flag_sa.svg');
    case 'flag_se': return import('./flag_se.svg');
    case 'flag_sg': return import('./flag_sg.svg');
    case 'flag_us': return import('./flag_us.svg');
    case 'flag_za': return import('./flag_za.svg');
    default: return import('./info.svg');// null; //throw new Error(`Unknown icon: ${iconName}`);
  }
};
