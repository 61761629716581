import { Platform } from "react-native";
import Constants from "expo-constants";
import * as ActionCable from "@rails/actioncable";
import { OnspaceCableAtom } from "../atoms";
import { useAtom } from "jotai";
import { userAgentIsSSR } from "../utils/userAgent";

const API_KEY = Constants.expoConfig.extra.API_KEY;
const env = Constants.expoConfig.extra.API_ENV;

const websocketUrls = {
  test: "wss://cable.new-staging.racingtv.com",
  staging: "wss://cable.new-staging.racingtv.com",
  production: "wss://cable.racingtv.com",
};

const blockedUserAgents = ["doobie"];
const blockedUserStrings = ["HeadlessChrome"];

const activeChannels = [];

const useWebsocket = () => {

  if(userAgentIsSSR()) return {
    subscribeToChannel: () => Promise.resolve(),
    createCable: () => {},
    unsubscribeFromChannel: () => {},
  }

  const [onSpaceCable, setOnspaceCable] = useAtom(OnspaceCableAtom);
  const SOCKET_ADDRESS = `${websocketUrls[env]}?api_key=${API_KEY}&requested_with=racingtv-${Platform.OS}/${Constants.expoConfig.version}`;
  const userAgent = navigator.userAgent;
  const isWeb = Platform.OS === "web";

  const subscribeToChannel = async (
    channel: string,
    identifier: Object,
    callBack: Function,
    errorCallback?: Function
  ) => {
    if (!channel || !identifier) return;
    const cable = createCable();
    if (!cable) return;
    const sub = cable.subscriptions.create(
      { channel: channel, ...identifier },
      {
        received: (data) => callBack(data),
        connected: () => console.log(`connected to channel ${channel}`),
        disconnected: () => console.log(`disconnected to channel ${channel}`),
        rejected: () => console.log(`rejected from channel ${channel}`),
        error: (error) => errorCallback(error),
      }
    );

    activeChannels.push(sub);
  };

  const unsubscribeFromChannel = (channel: string) => {
    if (!channel) return;
    const chan = activeChannels.find((sub) => sub.identifier.includes(channel));
    if (!chan) return;
    chan.unsubscribe();
  };

  const createCable = () => {
    if (isWeb && blockedUserAgents?.includes(userAgent)) return;
    if (
      isWeb &&
      blockedUserStrings?.some((string) => userAgent.includes(string))
    )
      return;

    if (Object.keys(onSpaceCable).length) return onSpaceCable; // return existing cable if it exists
    const cable = ActionCable.createConsumer(SOCKET_ADDRESS);
    setOnspaceCable(cable);
    cable.connect();
    return cable;
  };

  return {
    subscribeToChannel,
    createCable,
    unsubscribeFromChannel,
  };
};

export default useWebsocket;
