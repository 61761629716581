import { StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import { getDataFromStorage } from "./Utils";
import RText from "../../../RText";
import variables from "../../../../styles/variables";
import RButton from "../../../Buttons/RButton";
import RTextInput from "./RTextInput";
import Gap from "../../../Gap";
import { fetchPost } from "../../../../client";
import useAppContext from "../../../../hooks/useAppContext";
import { STORAGE_KEYS, storage } from "../../../../utils/storage";
import { useToast } from "react-native-toast-notifications";
import { useAtom } from "jotai";
import { changeDetailsModalAtom, userAtom } from "../../../../atoms";
import { toastSuccess } from "../../../Toasts/Toasts";
import LoadingSpinner from "../../../LoadingSpinner";

type Props = {
  cancelFunction: Function;
};

const UpdateDetailsModal = ({ cancelFunction }: Props) => {
  const [user, setUser] = useAtom(userAtom);
  const [_, setIsEditDetailsModalOpen] = useAtom(changeDetailsModalAtom);

  const [storageUser, setStorageUser] = useState();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const toast = useToast();

  useEffect(() => {
    getDataFromStorage().then((data) => {
      setStorageUser(data);
    });
    return () => {};
  }, []);

  const updateDetails = () => {
    setLoading(true);

    const formattedData = {
      customer: {
        ...user.detail,
        ...storageUser.detail,
        password,
        address_attributes: {
          ...user.address,
          ...storageUser.address,
        },
      },
    };

    if (!storageUser) return;

    fetchPost("member/accounts", {}, formattedData, "PUT")
      .then(async () => {
        // update context user here, we do not get the updated user from the api
        setUser({
          ...user,
          detail: {
            ...user.detail,
            ...storageUser.detail,
          },
          address: {
            ...user.address,
            ...storageUser.address,
          },
        });

        // remove tempUser from storage
        await storage.remove({
          key: STORAGE_KEYS.USER_DETAILS,
        });

        // close modal
        setIsEditDetailsModalOpen(false);

        // pop success toast
        toastSuccess(toast, "Details updated successfully");
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => setLoading(false));
  };

  const keyMapper = {
    name_prefix: "Title",
    name_given: "First Name",
    name_family: "Last Name",
    email: "Email",
    phone: "Phone Number",
    address1: "Address Line 1",
    address2: "Address Line 2",
    address3: "Address Line 3",
    city: "City",
    area: "Area",
    postcode: "Postcode",
    country_code: "Country",
  };

  if (!storageUser) return null;

  return (
    <View>
      <View style={styles.headingContainer}>
        <RText size="lg" weight="bold">
          Update your Details
        </RText>
      </View>
      <View style={styles.contentContainer}>
        {storageUser?.detail &&
          Object.keys(storageUser?.detail).map((key) => {
            if (key === "customer_id") return;
            if (!storageUser.detail[key]) return;
            return (
              <View key={key} style={styles.contentRow}>
                <RText weight="semiBold">{keyMapper[key]}: </RText>
                <RText>{storageUser.detail[key]}</RText>
              </View>
            );
          })}
        {storageUser?.address &&
          Object.keys(storageUser?.address).map((key) => {
            if (key === "customer_id") return;
            if (!storageUser.address[key] || key === "address_type") return;
            return (
              <View key={key} style={styles.contentRow}>
                <RText weight="semiBold">{keyMapper[key]}: </RText>
                <RText>{storageUser.address[key]}</RText>
              </View>
            );
          })}
        <View style={styles.inputContainer}>
          <RText>Enter Password to submit</RText>
          <Gap size="small" />
          <RTextInput
            isSecure
            value={password}
            onBlur={() => {}}
            placeholder="Password..."
            onChangeText={(e) => setPassword(e)}
            style={{ borderWidth: 2, minWidth: 200 }}
          />
        </View>
        {error ? (
          <View style={styles.errorContainer}>
            <RText weight="semiBold" uppercase color="secondary">
              {error.message}
            </RText>
          </View>
        ) : null}
        {loading ? (
          <LoadingSpinner />
        ) : (
          <View style={styles.buttonsContainer}>
            <RButton
              onPress={updateDetails}
              style={styles.btn}
              disabled={password.length < 8}
              title="Submit"
            />
            <RButton
              onPress={cancelFunction}
              style={styles.btn}
              title="Cancel"
            />
          </View>
        )}
      </View>
    </View>
  );
};

export default UpdateDetailsModal;

const styles = StyleSheet.create({
  headingContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: variables.spacing.small,
  },
  contentContainer: {
    paddingHorizontal: variables.spacing.small,
  },
  contentRow: {
    flexDirection: "row",
    paddingVertical: variables.spacing.xxsmall,
  },
  inputContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    paddingVertical: variables.spacing.xsmall,
  },
  errorContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    maxHeight: 30,
  },
  buttonsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  btn: {
    height: 50,
    width: 140,
    margin: variables.spacing.xxxsmall,
  },
});
