import {
  ViewStyle,
  View,
  StyleSheet,
  Platform,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import React, { useState } from "react";
import { TakeoverAd } from "../../../types/advert";
import { useResponsive } from "../../../hooks/useResponsive";
import variables from "../../../styles/variables";
import RText from "../../RText/RText";
import Icon from "../../Icon";
import HighlightedTitle from "./HighlightedTitle";
import RImageBackground from "../RImageBackground/RImageBackground";
import { useAtom } from "jotai";
import { headerHeightAtom, tabBarHeightAtom } from "../../../atoms";
import { advertClick } from "../../../client/adverts";
import { RTextSize } from "../../RText/RText";
import calculateAdImageHeight from "../../../utils/calculateAdImageHeight";

const { colors, spacing } = variables;

type Props = {
  ad: TakeoverAd;
  style: ViewStyle;
  adOverlayStyle?: ViewStyle;
  onPress?: Function;
  resizeMode?: "cover" | "contain" | "stretch" | "repeat" | "center";
  header?: boolean;
  skyscraper?: boolean;
  heightOffset?: number;
  closeFunction?: Function;
};

const TakeoverAdBanner = ({ ad, closeFunction }: Props) => {
  const [error, setError] = useState(false);
  const [headerHeight] = useAtom(headerHeightAtom);
  const [tabBarHeight] = useAtom(tabBarHeightAtom);
  const {
    isSmallAndDown,
    isMediumAndDown,
    isMediumOnly,
    isMediumAndUp,
    isLargeOnly,
    isLargeAndUp,
  } = useResponsive();

  if (error || !ad) return null;

  const {
    color_primary,
    color_secondary,
    color_accent,
    header_icons_logo,
    title,
    emphasised_title,
    contents,
    close_text,
    close_logo,
    border,
    fullscreen: fullScreen,
    background_asset,
  } = ad as TakeoverAd;

  const isWeb = Platform.OS === "web";
  const isMobile = Platform.OS === "android" || Platform.OS === "ios";

  const { height: _windowHeight, width: windowWidth } = useWindowDimensions();

  //Ad shouldn't appear if there are no images
  if (!contents || contents?.length === 0) return null;

  //Padding for the overlay/takeover container
  let containerPadding = spacing.xsmall;
  if (fullScreen || !isMediumAndDown) {
    containerPadding = spacing.medium;
  }

  //Check if all images are 16/9
  const allImagesAre16_9 = contents.every((content) => {
    const [, aspectWidth, aspectHeight] = content.content_type.split("_");
    return Number(aspectWidth) / Number(aspectHeight) === 16 / 9;
  });

  //Function to calculate icon size
  const getIconSize = () => {
    if (Platform.OS === "web") {
      return isMediumAndDown
        ? "takeoverAdWebMediumAndDown"
        : "takeoverAdWebLargeAndUp";
    } else {
      return "takeoverAdMobile";
    }
  };

  //Close button which gets rendered either within or outside of scroll view depending on screen size and if it is full screen
  const showCloseButton = close_text && close_logo;
  const showCloseButtonInScrollView =
    showCloseButton && fullScreen && (isLargeOnly || isLargeAndUp);

  let closeButtonFontSize: RTextSize;

  if (isMobile || (isSmallAndDown && isWeb)) {
    closeButtonFontSize = "sm";
  } else if (fullScreen) {
    closeButtonFontSize = "xl";
  } else {
    closeButtonFontSize = "md";
  }

  return (
    <>
      <TouchableOpacity // Overlay
        style={baseStyles.overlay}
        onPress={() => closeFunction(false)}
      ></TouchableOpacity>
      {/* Top spacer */}
      {!fullScreen && isMobile && (
        <View
          style={{
            height: isMediumAndUp ? headerHeight : "10%",
          }}
        />
      )}

      <View
        style={[
          !fullScreen && baseStyles.container,
          isWeb && webStyles.container,
          border && isMediumAndDown ? { borderWidth: 5 } : {},
          border && !isMediumAndDown ? { borderWidth: 10 } : {},
          border && {
            borderColor: color_accent || colors.palette.rtv.primary,
            backgroundColor: color_secondary || colors.palette.rtv.tertiary,
          },
          {
            padding:
              fullScreen || !isMediumAndDown ? spacing.medium : spacing.xsmall,
            paddingVertical: 10,
            marginVertical: "auto",
          },
          !fullScreen &&
            windowWidth <= variables.width4K && {
              maxHeight: "92%",
            },

          fullScreen && {
            height: "100%",
            width: "100%",
          },
        ]}
      >
        <RImageBackground
          setError={() => null}
          adUrl={background_asset?.viewer_sources[0]?.url}
          resizeMode="cover"
          style={baseStyles.backgroundImage}
        />
        <ScrollView
          contentContainerStyle={[
            baseStyles.scrollView,
            fullScreen &&
              showCloseButton && {
                justifyContent: "space-between",
              },
          ]}
          showsVerticalScrollIndicator
        >
          {/* HEADER */}
          {header_icons_logo && (
            <View
              style={[
                baseStyles.header,
                isMobile && { marginTop: 10 },
                {
                  marginBottom: fullScreen ? 30 : 15,
                },
              ]}
            >
              <View style={baseStyles.headerTextContainer}>
                <RText
                  color={color_primary || colors.palette.rtv.white}
                  weight="bold"
                  size={"xs"}
                  style={isWeb && { lineHeight: 12 }}
                >
                  18+
                </RText>
              </View>
              <View
                style={[
                  baseStyles.topRTVLogoContainer,
                  isWeb
                    ? webStyles.topRTVLogoContainer
                    : mobileStyles.topRTVLogoContainer,
                  {
                    backgroundColor: color_primary || colors.palette.rtv.white,
                    height: isSmallAndDown ? 24 : 32,
                    maxHeight: isSmallAndDown ? 24 : 32,
                  },
                ]}
              >
                <Icon
                  name="logoLong"
                  fill={color_accent || colors.palette.rtv.primary}
                  style={{
                    width: isSmallAndDown ? 90 : 140,
                    height: isSmallAndDown ? 24 : 32,
                  }}
                />
              </View>
              <View style={baseStyles.bGALogoContainer}>
                <RText
                  color={"#000000"}
                  weight="bold"
                  size={"xs"}
                  style={isWeb && { lineHeight: 12 }}
                >
                  Gamble{" "}
                </RText>
                <RText
                  color={"#ef681c"}
                  weight="bold"
                  size={"xs"}
                  style={isWeb && { lineHeight: 12 }}
                >
                  Aware
                </RText>
              </View>
            </View>
          )}
          {/* TITLE */}
          {title && (
            <View
              style={[
                isSmallAndDown && { width: isMobile ? "97%" : "100%" },
                { marginBottom: fullScreen ? 30 : 15 },
              ]}
            >
              <HighlightedTitle
                title={title}
                emphasisedTitle={emphasised_title}
                accentColor={color_primary || colors.palette.rtv.white}
                fullScreen={fullScreen}
              />
            </View>
          )}
          {/* IMAGES */}
          <View
            style={[
              { flexDirection: isMediumAndUp ? "row" : "column" },
              {
                paddingBottom: fullScreen && !showCloseButton ? 280 : undefined,
              },
              isMobile && { marginRight: "auto", marginLeft: "auto" },
              isWeb && webStyles.imagesContainer,
            ]}
          >
            {contents.map((content, index) => {
              if (!content?.asset?.viewer_sources[0]?.url) return null;
              const [, aspectWidth, aspectHeight] =
                content.content_type.split("_");
              const aspectRatio = Number(aspectWidth) / Number(aspectHeight);

              let imageHeight: number;
              imageHeight = calculateAdImageHeight(
                windowWidth,
                contents?.length,
                isMediumAndDown,
                allImagesAre16_9,
                fullScreen
              );

              if (windowWidth >= variables.width4K && Platform.OS === "web")
                return (
                  <div className="exclude-zoom-img">
                    <AdImage
                      content={content}
                      index={index}
                      imageHeight={imageHeight}
                      aspectRatio={aspectRatio}
                      windowWidth={windowWidth}
                      isMediumAndDown={isMediumAndDown}
                      isMediumAndUp={isMediumAndUp}
                      isMediumOnly={isMediumOnly}
                      setError={setError}
                      closeFunction={closeFunction}
                      contents={contents?.length}
                    />
                  </div>
                );
              else
                return (
                  <AdImage
                    content={content}
                    index={index}
                    imageHeight={imageHeight}
                    aspectRatio={aspectRatio}
                    windowWidth={windowWidth}
                    isMediumAndDown={isMediumAndDown}
                    isMediumAndUp={isMediumAndUp}
                    isMediumOnly={isMediumOnly}
                    setError={setError}
                    closeFunction={closeFunction}
                    contents={contents?.length}
                  />
                );
            })}
          </View>

          {/* A view to center the ad on mobile if in full screen mode and if there's only one ad */}
          {isMobile && fullScreen && contents.length === 1 && (
            <View
              style={{
                flex: Platform.OS === "ios" ? 0.5 : 0.4,
                justifyContent: "center",
                alignItems: "center",
              }}
            ></View>
          )}

          {/* Close button */}
          {showCloseButton && showCloseButtonInScrollView && (
            <TouchableOpacity
              onPress={async () => {
                window?.dataLayer?.push({
                  // Fire GA Close takeOver event
                  event: "close_v2_overlay",
                  adId: ad?.takeover_id || 1,
                });
                closeFunction && closeFunction(false);
              }}
              style={baseStyles.closeBtnContainer}
            >
              <View
                style={[
                  baseStyles.closeBtn,
                  {
                    backgroundColor: color_primary || colors.palette.rtv.white,
                    paddingHorizontal: fullScreen ? 8 : spacing.xsmall,
                    paddingVertical: fullScreen
                      ? spacing.xxsmall
                      : spacing.xxxsmall,
                  },
                ]}
              >
                {close_text && (
                  <RText
                    color={color_accent || colors.palette.rtv.primary}
                    uppercase
                    weight="bold"
                    size={closeButtonFontSize}
                    style={
                      close_logo && {
                        marginRight: spacing.xxsmall,
                      }
                    }
                  >
                    {close_text}
                  </RText>
                )}
                {close_logo && (
                  <View
                    style={[
                      baseStyles.closeBtnIconBg,
                      {
                        backgroundColor:
                          color_accent || colors.palette.rtv.primary,
                      },
                    ]}
                  >
                    <Icon
                      name="logoLong"
                      fill={color_primary || colors.palette.rtv.white}
                      style={[
                        baseStyles.closeBtnIconBg,
                        isWeb && {
                          paddingHorizontal: 2,
                          paddingTop: 3,
                          paddingBottom: 2,
                        },
                      ]}
                      size={getIconSize()}
                    />
                  </View>
                )}
              </View>
            </TouchableOpacity>
          )}
        </ScrollView>
        {/* Close button */}
        {showCloseButton && !showCloseButtonInScrollView && (
          <TouchableOpacity
            onPress={async () => {
              window?.dataLayer?.push({
                // Fire GA Close takeOver event
                event: "close_v2_overlay",
                adId: ad?.takeover_id || 1,
              });
              closeFunction && closeFunction(false);
            }}
            style={baseStyles.closeBtnContainer}
          >
            <View
              style={[
                baseStyles.closeBtn,
                {
                  backgroundColor: color_primary || colors.palette.rtv.white,
                  paddingLeft: fullScreen ? 8 : 7,
                  paddingRight: fullScreen ? 8 : 3,
                  paddingVertical: fullScreen ? spacing.xxsmall : 2,
                },
                close_logo
                  ? { paddingRight: 2 }
                  : { paddingRight: spacing.xsmall },
              ]}
            >
              {close_text && (
                <RText
                  color={color_accent || colors.palette.rtv.primary}
                  uppercase
                  weight="bold"
                  size={closeButtonFontSize}
                  style={
                    close_logo && {
                      marginRight: spacing.xxsmall,
                    }
                  }
                >
                  {close_text}
                </RText>
              )}
              {close_logo && (
                <View
                  style={[
                    baseStyles.closeBtnIconBg,
                    {
                      backgroundColor:
                        color_accent || colors.palette.rtv.primary,
                    },
                  ]}
                >
                  <Icon
                    name="logoLong"
                    fill={color_primary || colors.palette.rtv.white}
                    style={[
                      baseStyles.closeBtnIconBg,
                      isWeb && {
                        paddingHorizontal: 2,
                        paddingTop: 4.5,
                        paddingBottom: 2,
                      },
                      isMobile && {
                        marginTop: 2,
                        marginBottom: 2,
                        marginHorizontal: 2,
                      },
                      { width: 80, height: 16 },
                    ]}
                    size={getIconSize()}
                  />
                </View>
              )}
            </View>
          </TouchableOpacity>
        )}
      </View>
      {fullScreen && (
        <View style={baseStyles.XButton}>
          <TouchableOpacity onPress={() => closeFunction(false)}>
            <Icon name="cross" size="medium" fill={color_primary} />
          </TouchableOpacity>
        </View>
      )}
      {/* bottom spacer */}
      <View style={isMediumAndDown && { height: tabBarHeight }} />
    </>
  );
};

type AdImageProps = {
  content: any;
  index: number;
  imageHeight: number;
  aspectRatio: number;
  windowWidth: number;
  isMediumAndDown: boolean;
  isMediumAndUp: boolean;
  isMediumOnly: boolean;
  setError: Function;
  closeFunction: Function;
  contents: number;
};

const AdImage = ({
  content,
  index,
  imageHeight,
  aspectRatio,
  windowWidth,
  isMediumAndDown,
  isMediumAndUp,
  isMediumOnly,
  setError,
  closeFunction,
  contents,
}: AdImageProps) => (
  <TouchableOpacity
    onPress={async () => {
      await advertClick(content.link_url);
      closeFunction(false);
    }}
    style={[
      isMediumAndDown && { marginBottom: spacing.xsmall },
      {
        marginHorizontal: spacing.xxsmall,
      },
    ]}
    key={index}
  >
    <RImageBackground
      adUrl={content.asset.viewer_sources[0].url}
      setError={setError}
      style={[
        {
          ...(isMediumAndUp
            ? { height: imageHeight }
            : {
                width:
                  contents === 1 && Platform.OS === "web"
                    ? isMediumOnly
                      ? (windowWidth / 10) * 5
                      : (windowWidth / 10) * 7
                    : (windowWidth / 10) * 8,
              }),
        },
        {
          aspectRatio: aspectRatio,
        },
      ]}
    />
  </TouchableOpacity>
);

const calculateImageHeightOnLargeScreens = (width) => {
  const breakpoints = [
    { min: 2600, factor: 1.5 },
    { min: 2300, factor: 1.8 },
    { min: variables.width4K, factor: 2 },
    { min: 1600, factor: 2.5 },
  ];

  const defaultFactor = 3;

  const matchedBreakpoint = breakpoints.find((b) => width >= b.min);
  const factor = matchedBreakpoint ? matchedBreakpoint.factor : defaultFactor;

  return (width / 10) * factor;
};

const baseStyles = StyleSheet.create({
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "black",
    opacity: 0.5,
    height: "100%",
  },
  container: {
    overflow: "hidden",
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  border: {
    borderWidth: 10,
    borderRadius: 5,
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    opacity: 0.3,
  },
  scrollView: {
    flexGrow: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerTextContainer: {
    flex: 1,
  },
  topRTVLogoContainer: {
    borderRadius: 5,
    alignItems: "center",
    maxWidth: 200,
  },
  bGALogoContainer: {
    flex: 1,
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
  },
  closeBtnContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 16,
  },
  closeBtn: {
    borderRadius: 5,
    flexDirection: "row",
    bottom: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  closeBtnIconBg: {
    borderRadius: 3,
  },
  closeBtnLogo: {
    width: 100,
    height: 20,
  },
  XButton: {
    alignItems: "flex-end",
    justifyContent: "center",
    paddingRight: 15,
    paddingTop: spacing.xsmall,
    paddingBottom: spacing.xxsmall,
    position: "absolute",
    top: 0,
    right: 0,
  },
});

const webStyles = StyleSheet.create({
  container: { alignSelf: "center" },
  imagesContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  topRTVLogoContainer: {
    paddingHorizontal: 12,
    paddingTop: 5, // these are slightly different to accomdate slightly higher centered logo
    paddingBottom: 2,
  },
  scrollView: {
    maxWidth: "90%",
  },
});

const mobileStyles = StyleSheet.create({
  topRTVLogoContainer: {
    flex: 1,
    paddingVertical: 4,
  },
});

export default TakeoverAdBanner;
