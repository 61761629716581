import { StyleSheet, View } from "react-native";
import React, { FC } from "react";
import { useResponsive } from "../../../../hooks/useResponsive";
import RText from "../../../RText";
import RButton from "../../../Buttons/RButton";
import Gap from "../../../Gap";
import variables from "../../../../styles/variables";
import { RLink } from "../../../RLink/RLink";
import { FRONTEND_URL } from "../../../../client";
import {
  displayCancelBtnAtom,
  preferencesButtonReducerAtom,
} from "../../../../atoms";
import { useAtom } from "jotai";

const PreferenceHeader: FC = () => {
  const { isSmallAndDown, isMediumAndDown } = useResponsive();
  const [displayCancelBtn] = useAtom(displayCancelBtnAtom);
  const [_, dispatch] = useAtom(preferencesButtonReducerAtom);

  const handleSave = () => dispatch({ type: "SAVE" });
  const handleReset = () => dispatch({ type: "RESET" });
  const handleCancel = () => dispatch({ type: "CANCEL" });

  return (
    <View
      style={[
        styles.preferenceHeader,
        isMediumAndDown && mobileStyles.preferenceHeader,
        {
          borderTopWidth: isMediumAndDown && 0.5,
          borderTopColor: isMediumAndDown && colors.palette.neutral.x100,
          paddingLeft: isSmallAndDown ? 0 : spacing.small,
        },
      ]}
    >
      <View style={isMediumAndDown ? mobileStyles.container : styles.container}>
        <RText>
          <RText size={isSmallAndDown ? "sm" : "md"}>
            For more information about how Racing TV use your data please read
            our{" "}
          </RText>
          <RLink href={`${FRONTEND_URL}/privacy-policy`}>
            <RText
              size={isSmallAndDown ? "sm" : "md"}
              weight="bold"
              color="secondary"
            >
              Privacy Policy
            </RText>
          </RLink>
        </RText>
      </View>
      <View style={styles.buttonsContainer}>
        <RButton
          onPress={() => (displayCancelBtn ? handleCancel() : handleReset())}
          title={displayCancelBtn ? "Cancel" : "Reset"}
          color="tertiary"
        />
        <Gap size={isMediumAndDown ? "xsmall" : "small"} />
        <RButton
          onPress={handleSave}
          title="Save"
          disabled={!displayCancelBtn}
        />
      </View>
    </View>
  );
};

export default PreferenceHeader;

const { spacing, colors } = variables;

const styles = StyleSheet.create({
  container: {
    flex: 1.3,
  },
  preferenceHeader: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: spacing.small,
  },
  buttonsContainer: {
    maxWidth: 300,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: spacing.xxsmall,
  },
});

const mobileStyles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 7,
  },
  preferenceHeader: {
    paddingRight: 7,
  },
});
