import { StyleSheet, View } from "react-native";
import React from "react";
import { useResponsive } from "../../../../../hooks/useResponsive";
import variables from "../../../../../styles/variables";
import SquareLoader from "../shared/SquareLoader";
import TextLoader from "../shared/TextLoader";
type Props = {
  label?: boolean;
};

const RCheckBoxLoader = ({ label }: Props) => {
  const { isSmallAndDown } = useResponsive();

  return (
    <View
      style={[
        styles.container,
        isSmallAndDown && styles.containerSmallScreen,
        isSmallAndDown ? styles.marginRightSmall : styles.marginRightBig,
      ]}
    >
      <View style={styles.checkBox}>
        <SquareLoader width="100%" />
      </View>
      {label ? (
        <View style={isSmallAndDown && styles.labelSmallScreen}>
          <TextLoader />
        </View>
      ) : null}
    </View>
  );
};

export default RCheckBoxLoader;

const { spacing } = variables;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
  containerSmallScreen: {
    justifyContent: "space-between",
  },
  marginRightBig: {
    marginRight: 48,
  },
  marginRightSmall: {
    marginRight: spacing.xxsmall,
  },
  labelSmallScreen: {
    width: 50,
  },
  checkBox: {
    height: spacing.medium,
    width: spacing.medium,
    borderRadius: 6,
    margin: spacing.xxsmall,
    justifyContent: "center",
    alignItems: "center",
    marginRight: spacing.xsmall,
  },
});
