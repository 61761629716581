import React from "react";
import PinnedPostLoader from "./PinnedPostLoader";
import GridLayoutLoader from "../shared/GridLayout/GridLayoutLoader";

const CategoryLoader = () => {
  return (
    <>
      <PinnedPostLoader />
      <GridLayoutLoader />
    </>
  );
};

export default CategoryLoader;
