import React from "react";
import { StyleSheet } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import variables from "../../styles/variables";
export type RTVGradientProps = {
  children?: React.ReactNode;
  height?: number;
};

const { colors } = variables;

const RTVGradient = ({ height, children }: RTVGradientProps) => {
  return (
    <LinearGradient
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 1 }}
      style={[styles.container, { maxHeight: height }]}
      colors={[
        colors.palette.rtv.primary,
        colors.palette.rtv.tertiary,
        colors.palette.rtv.secondary,
        colors.palette.rtv.primary,
      ]}
    >
      {children}
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 5, //min height required if children not provided
    zIndex: -10,
  },
});

export default RTVGradient;
