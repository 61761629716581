import * as Updates from "expo-updates";

let Config = {
  // APP_ENV: "staging",
  // API_ENV: "https://api.new-staging.racingtv.com/",
  // API_KEY: "d2d01b9a-a7b3-4dfc-8534-8b15615a54cd",
};

if (Updates.channel === "production") {
  Config.APP_ENV = "production";
  Config.API_ENV = "https://api.racingtv.com/";
  Config.API_KEY = "936fdbdf-1e46-4f82-9d18-d81b4803537d";
  Config.CLUB_DAYS_URL = "https://cms.racingtv.com/members/club-days/forms";
} else if (Updates.channel === "staging") {
  // use prod env on staging:
  Config.APP_ENV = "production";
  Config.API_ENV = "https://api.racingtv.com/";
  Config.API_KEY = "936fdbdf-1e46-4f82-9d18-d81b4803537d";
  Config.CLUB_DAYS_URL = "https://cms.racingtv.com/members/club-days/forms";

  // use staging env on staging:
  // Config.APP_ENV = "staging";
  // Config.API_ENV = "https://api.new-staging.racingtv.com/";
  // Config.API_KEY = "d2d01b9a-a7b3-4dfc-8534-8b15615a54cd";
  // Config.CLUB_DAYS_URL =
  //   "https://cms.new-staging.racingtv.com/members/club-days/forms";
}

export default Config;
