import { StyleSheet, View, ViewStyle } from "react-native";
import React from "react";
import RCheckBoxLoader from "./RCheckBoxLoader";
import variables from "../../../../../styles/variables";
import { useResponsive } from "../../../../../hooks/useResponsive";

type Props = {
  isInColumnLayout?: boolean;
  label?: boolean;
  items: number[];
  style: ViewStyle;
};

const CheckBoxesLoader = ({
  isInColumnLayout = false,
  label,
  items,
  style,
}: Props) => {
  const { isSmallAndDown } = useResponsive();

  return (
    <View
      style={[
        !isInColumnLayout && styles.container,
        isSmallAndDown && styles.containerSmallScreen,
        style,
      ]}
    >
      {items.map((idx) => (
        <RCheckBoxLoader label={label} key={idx} />
      ))}
    </View>
  );
};

export default CheckBoxesLoader;

const { colors } = variables;

const styles = StyleSheet.create({
  container: {
    flexWrap: "wrap",
    flexDirection: "row",
  },
  containerSmallScreen: {
    width: "70%",
  },
  bottomBorder: {
    borderBottomColor: colors.palette.neutral.x50,
    borderBottomWidth: 2,
  },
});
