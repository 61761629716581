// The text to display for the runner's finish state.
//
// This will indicate the following:
// - Void race
// - Runner casualty
// - Runner disqualification

export const finishStates: {
  [key in FinishStateChar]: {
    casualty?: Casualty;
    description: "casualty" | "race_void" | "disqualified";
    text: string;
  };
} = {
  V: {
    description: "race_void",
    text: "VOI",
  },
  D: {
    description: "disqualified",
    text: "DSQ",
  },
  F: {
    description: "casualty",
    casualty: "fell",
    text: "F",
  },
  P: {
    description: "casualty",
    casualty: "pulled_up",
    text: "PU",
  },
  R: {
    description: "casualty",
    casualty: "refused_to_race",
    text: "RR",
  },
  B: {
    description: "casualty",
    casualty: "brought_down",
    text: "BD",
  },
  O: {
    description: "casualty",
    casualty: "ran_out",
    text: "RO",
  },
  U: {
    description: "casualty",
    casualty: "unseated_rider",
    text: "UR",
  },
  S: {
    description: "casualty",
    casualty: "slipped_up",
    text: "S",
  },
  HR: {
    description: "casualty",
    casualty: "hit_rails",
    text: "HR",
  },
  C: {
    description: "casualty",
    casualty: "carried_out",
    text: "C",
  },
};

// the backend returns finish_text which is either a position (1st, 2nd, 3rd) or a single character indicating a casualty, but we need to display different acronyms in the app
export type FinishStateChar =
  | string
  | "V"
  | "D"
  | "F"
  | "P"
  | "R"
  | "B"
  | "O"
  | "U"
  | "S"
  | "HR"
  | "C";

type Casualty =
  | "fell"
  | "pulled_up"
  | "refused_to_race"
  | "refused"
  | "brought_down"
  | "ran_out"
  | "unseated_rider"
  | "slipped_up"
  | "hit_rails"
  | "carried_out";
