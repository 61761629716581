import React from "react";
import { View } from "react-native";
import * as LoaderList from "./Loaders";

// If the response from a data request is pending, display this component

export type LoaderName = keyof typeof LoaderList;

type Props = {
  name: LoaderName;
  containerStyle?: {};
  [key: string]: any;
  rest?: any;
};

export const ListOfLoaders = Object.keys(LoaderList);

export default function LoadingState({ name, containerStyle, ...rest }: Props) {
  const LoadingComponent = LoaderList[name];
  if (!LoadingComponent) return null;

  return containerStyle ? (
    <View style={containerStyle}>
      <LoadingComponent {...rest} />
    </View>
  ) : (
    <LoadingComponent {...rest} />
  );
}
