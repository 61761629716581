import { View } from "react-native";
import { useResponsive } from "../../../../../hooks/useResponsive";
import TextLoader from "../shared/TextLoader";
import CircleLoader from "../shared/CircleLoader";
import MultilineTextLoader from "../shared/MultilineTextLoader";
import React from "react";
import variables from "../../../../../styles/variables";
const { spacing } = variables;

const TipLoader = () => {
  const { isLargeAndUp } = useResponsive();

  return (
    <View style={{ marginVertical: spacing.xsmall }}>
      {/* smaller screens */}
      {!isLargeAndUp ? (
        <View>
          <View
            style={{
              flexDirection: "row",
              marginVertical: spacing.xsmall,
            }}
          >
            <View style={{ flex: 3, flexDirection: "row" }}>
              <CircleLoader width={40} height={40} />
              <View style={{ width: "80%" }}>
                <MultilineTextLoader />
              </View>
            </View>
          </View>
          <MultilineTextLoader />
        </View>
      ) : null}

      {/* bigger screens */}
      {isLargeAndUp ? (
        <View style={{ flexDirection: "row", marginTop: spacing.xsmall }}>
          <View style={{ flex: 3 }}>
            <View style={{ flexDirection: "row" }}>
              <CircleLoader width={40} height={40} />
              <View>
                <MultilineTextLoader numberOfLines={4} />
              </View>
            </View>
          </View>
          <View style={{ flex: 3 }}>
            <MultilineTextLoader />
          </View>
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <TextLoader width={80} height={40} />
          </View>
        </View>
      ) : null}
    </View>
  );
};

export default TipLoader;
