import React from "react";
import { View, StyleSheet } from "react-native";
import variables from "../../../../../styles/variables";
import PinnedPostLoader from "./PinnedPostLoader";
import SectionHeaderLoader from "../shared/SectionHeader/SectionHeaderLoader";
import ContentScrollBarLoader from "../shared/ContentScrollBar/ContentScrollBarLoader";

const { medium } = variables.spacing;

const AllNewsTopSectionLoader = () => {
  return (
    <>
      <SectionHeaderLoader />
      <View style={styles.pinnedPost}>
        <PinnedPostLoader />
      </View>
      <ContentScrollBarLoader />
    </>
  );
};

const styles = StyleSheet.create({
  pinnedPost: {
    marginBottom: medium,
  },
});

export default AllNewsTopSectionLoader;
