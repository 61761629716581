import React, { useState } from "react"; // This is actually used. It's just not detected by the linter
import { StyleSheet, View, ViewStyle } from "react-native";
import variables from "../../../styles/variables";
import { useResponsive } from "../../../hooks/useResponsive";

type Props = {
  children: any;
  flexBasis?: { gap: number; item: number };
  styleOverrides?: ViewStyle;
  titleMaxWidth?: number;
};

// calculates the width of the items in the grid based on the number of items per row
// items per row is determined by the screen size
// the width of the grid container is divided by the number of items per row
// and the margin is subtracted from the width
// this is to ensure that the items fit in the grid container without overflowing

const GridLayout: React.FC<Props> = ({
  children,
  flexBasis = { gap: 300, item: 300 },
  styleOverrides = {},
}) => {
  const [containerWidth, setContainerWidth] = useState(undefined);
  const { isXLargeAndDown, isMediumAndDown, isXSmallOnly, isXXLargeAndUp } =
    useResponsive();

  let itemsPerRow;
  if (isMediumAndDown && isXSmallOnly && isXLargeAndDown) itemsPerRow = 1;
  else if (isMediumAndDown && isXLargeAndDown) itemsPerRow = 2;
  else itemsPerRow = 3;

  return (
    <View
      onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
      style={[styles.grid, styleOverrides]}
    >
      {containerWidth &&
        children?.map((child, idx) => {
          return React.cloneElement(child, {
            key: idx,
            style: [
              styles.gridItem,
              child.props.style,
              {
                maxWidth:
                  containerWidth / itemsPerRow -
                  (variables.spacing.xsmall + variables.spacing.xsmall), // 20
              },
            ],
            inGrid: true,
          });
        })}
    </View>
  );
};

const styles = StyleSheet.create({
  grid: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: 16,
  },
  gridItem: {
    marginVertical: variables.spacing.xsmall,
    marginHorizontal: variables.spacing.xsmall,
  },
  gap: { marginHorizontal: variables.spacing.xxsmall },
});

export default GridLayout;
