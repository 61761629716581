import {
  Pressable,
  TouchableOpacity,
  View,
  StyleSheet,
  Platform,
} from "react-native";
import { getUserState } from "../../../../utils/auth";
import { Plan as PlanT } from "../../../../types/memberPlans";
import { useState } from "react";
import variables from "../../../../styles/variables";
import { useAtom } from "jotai";
import {
  openAuthModalAtom,
  selectedPlanAtom,
  userAtom,
} from "../../../../atoms";
import RCorner from "../../../RCorner/RCorner";
import RText from "../../../RText";
import { useResponsive } from "../../../../hooks/useResponsive";

const { colors, spacing } = variables;

export const Plan = ({
  id,
  name,
  description,
  payment_required,
  cost,
}: PlanT) => {
  const [hover, setHover] = useState(false);
  const [user] = useAtom(userAtom);
  const [selectedPlan, setSelectedPlan] = useAtom(selectedPlanAtom);
  const [_, setIsAuthModalOpen] = useAtom(openAuthModalAtom);

  const { isMediumAndDown } = useResponsive();

  const handleBgColor = () => {
    if (payment_required) {
      if (hover) return "#33324c";
      return colors.palette.rtv.primary;
    } else {
      if (hover) return colors.palette.neutral.x100;
      return colors.palette.rtv.white;
    }
  };

  return (
    <TouchableOpacity
      onPress={async () => {
        const userState = getUserState(user);
        if (userState == "anonymous") setIsAuthModalOpen("join");
        if (userState == "free_account") {
          setSelectedPlan(id);
          setIsAuthModalOpen("upgrade");
        }
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={styles.item}
    >
      <View
        style={[
          styles.planContainer,
          {
            borderColor: payment_required
              ? colors.palette.rtv.yellow
              : colors.palette.neutral.x100,
            backgroundColor: handleBgColor(),
          },
        ]}
      >
        <View style={styles.planInnerContainer}>
          <RText
            color={payment_required ? "white" : "primary"}
            family="secondary"
            size={isMediumAndDown ? "md" : "lg"}
            weight="bold"
            uppercase
            style={{
              textAlign: "center",
            }}
          >
            {name}
            {payment_required && cost !== 0 ? " £" + cost : null}
          </RText>
          <RText
            size={isMediumAndDown ? "xs" : "md"}
            weight="semiBold"
            color={payment_required ? "yellow" : "secondary"}
            // href="#"
          >
            Select
          </RText>
        </View>
      </View>
      <RCorner
        size="xsmall"
        color={payment_required ? "yellow" : "grey"}
        borderTopRightRadius
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  item: {
    justifyContent: "space-between",
    margin: 10,
    minWidth: 250,
    ...Platform.select({
      web: {
        flexGrow: 1,
      },
    }),
  },
  planContainer: {
    minHeight: 80,
    borderWidth: 2,
    borderRadius: 5,
    borderStyle: "solid",
    alignItems: "center",
    justifyContent: "center",
  },
  planInnerContainer: {
    paddingVertical:
      Platform.OS == "android" ? spacing.xxxsmall : spacing.xsmall,
    alignItems: "center",
    maxWidth: Platform.OS == "android" ? "91%" : "80%",
    justifyContent: "center",
    ...Platform.select({
      web: {
        marginLeft: "auto",
        marginRight: "auto",
      },
      native: {
        maxWidth: "100%",
        minWidth: "100%",
      },
    }),
    height: Platform.OS == "android" ? 60 : "auto",
  },
});
