import { ToastType } from "react-native-toast-notifications";
import { ToastOptions } from "react-native-toast-notifications/lib/typescript/toast";

const BASIC_TOAST: ToastOptions = {
  animationType: "slide-in",
  duration: 5000,
  placement: "bottom",
};

export const toastSuccess = (toast: ToastType, message: string) => {
  toast.show(message, {
    ...BASIC_TOAST,
    type: "success",
  });
  return null;
};

export const toastError = (toast: ToastType, message: string) => {
  toast.show(message, {
    ...BASIC_TOAST,
    type: "danger",
  });
  return null;
};

export const toastNotification = (
  toast: ToastType,
  message: string,
  icon?: JSX.Element
) => {
  toast.show(message, {
    ...BASIC_TOAST,
    type: "normal",
  });
  return null;
};
