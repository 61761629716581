import { useEffect } from "react";
import { useAtom } from "jotai";
import { userAtom, isLoggedInAtom } from "../atoms";

function useUser() {
  const [user] = useAtom(userAtom);
  const [isLoggedIn, setIsLoggedIn] = useAtom(isLoggedInAtom);

  useEffect(() => {
    if (!!user) setIsLoggedIn(Object.keys(user).length > 0);
  }, [user]);

  return { user, isLoggedIn, member: user?.member };
}

export default useUser;
