// Footer.tsx
import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import Icon from "../components/Icon";
import variables from "../styles/variables";
import { useResponsive } from "../hooks/useResponsive";
import RTVGradient from "./Gradients/RTVGradient";
import RText from "./RText";
import RHeader from "./RText/RHeader";
import RFooterLink from "./RText/RFooterLink";
import Socials from "./Socials";

const { colors } = variables;

const Footer = React.forwardRef(({ navOverride }, ref) => {
  const { isSmallAndDown } = useResponsive();

  if (Platform.OS !== "web") {
    return null;
  }

  const copyrightText = `Copyright ${new Date().getFullYear()}. All rights reserved`;

  return (
    <View
      onLayout={(e) => ref?.setValue(e.nativeEvent.layout.height)}
      style={styles.container}
    >
      <View style={styles.topGradient}>
        <RTVGradient />
      </View>
      <View style={styles.innerContainer}>
        {/*logo and socials*/}
        <View style={styles.top}>
          <View>
            <Icon
              name="logoLong"
              fill={"palette.neutral.x0"}
              style={{
                width: 140,
                height: 32,
                paddingTop: 30,
                paddingBottom: 20,
              }}
            />
          </View>
          <View style={styles.socials}>
            <Socials type="secondary" />
          </View>
        </View>

        {/*all sections*/}
        <View style={isSmallAndDown ? styles.flexCol : styles.flexRow}>
          {/*apps section*/}
          <View style={[styles.section, isSmallAndDown ? styles.wrap : null]}>
            <RHeader>APPS</RHeader>
            <RFooterLink navOverride={navOverride} path="Apps">
              Racing TV App Center
            </RFooterLink>
            <RFooterLink externalWebPath="https://play.google.com/store/apps/details?id=com.racinguk.app">
              Download Android App
            </RFooterLink>
            <RFooterLink externalWebPath="https://apps.apple.com/gb/app/racing-uk-live-horse-racing/id352776768?platform=iphone">
              Download iPhone App
            </RFooterLink>
            <RFooterLink externalWebPath="https://apps.apple.com/gb/app/racing-uk-live-horse-racing/id352776768">
              Download iPad App
            </RFooterLink>
          </View>
          {/*about section*/}
          <View style={[styles.section, isSmallAndDown ? styles.wrap : null]}>
            <RHeader>ABOUT US</RHeader>
            <RFooterLink navOverride={navOverride} path="ContactUs">
              Contact Us
            </RFooterLink>
            <RFooterLink navOverride={navOverride} path="HelpCentre">
              Racing TV Help Centre
            </RFooterLink>
            <RFooterLink navOverride={navOverride} path="RMGPressRelease">
              RMG Press Releases
            </RFooterLink>
            <RFooterLink
              navOverride={navOverride}
              externalWebPath="https://www.racecoursemediagroup.com/jobs/"
            >
              Jobs
            </RFooterLink>
          </View>
          <View style={[styles.section, isSmallAndDown ? styles.wrap : null]}>
            <RHeader>Data & Privacy</RHeader>
            <RFooterLink navOverride={navOverride} path="TermsAndConditions">
              Terms and Conditions
            </RFooterLink>
            <RFooterLink navOverride={navOverride} path="PrivacyPolicy">
              Privacy Policy
            </RFooterLink>
            <button
              className="ot-sdk-show-settings manage-cookie-link"
              id="ot-sdk-btn"
            >
              Manage Cookies
            </button>
          </View>
          <View style={[styles.section, isSmallAndDown ? styles.wrap : null]}>
            <RHeader>Racing TV</RHeader>
            <RFooterLink navOverride={navOverride} path="Competitions">
              Competitions
            </RFooterLink>
            <RFooterLink navOverride={navOverride} path="Podcasts">
              Podcasts
            </RFooterLink>
            <RFooterLink navOverride={navOverride} path="ResponsibleGambling">
              Responsible Gambling
            </RFooterLink>
            <RFooterLink navOverride={navOverride} path="FreeBets">
              Free Bets
            </RFooterLink>
            <RFooterLink
              navOverride={navOverride}
              path="IndexOrProfile"
              params={{ type: "horse" }}
            >
              Profiles
            </RFooterLink>
          </View>
        </View>
      </View>
      <View style={styles.bottomGradient}>
        <RTVGradient>
          <RText color="white" weight="bold" uppercase={true} size="md">
            Please bet responsibly
          </RText>
        </RTVGradient>
      </View>
      <View style={styles.iconsSection}>
        <View style={styles.icons}>
          <Icon name="ageLimit" size="medium" style={styles.icon} />
          <Icon name="gamCare" size="xxlarge" style={styles.icon} />
          <Icon name="gamStop" size="xxlarge" style={styles.icon} />
          <Icon name="gordonMoody" size="xxlarge" style={styles.icon} />
          <Icon name="beGambleAware" size="xxlarge" style={styles.icon} />
          <Icon
            name="saferGamblingStandard"
            size="xxlarge"
            style={styles.icon}
          />
        </View>
        <View style={styles.copyright}>
          <RText family="primary" color="primary">
            {copyrightText}
          </RText>
        </View>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.palette.rtv.primary,
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  topGradient: {
    margin: 0,
    width: "100%",
    height: 4,
  },
  innerContainer: {
    padding: 20,
    width: "100%",
  },
  top: { alignItems: "center" },
  socials: {
    flexDirection: "row",
    justifyContent: "space-between",
    // width: 150,
    paddingBottom: 40,
  },
  flexCol: {
    flexDirection: "column",
    alignItems: "center",
  },
  flexRow: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    flexWrap: "wrap",
  },
  section: {
    paddingBottom: 40,
    flexDirection: "column",
  },
  wrap: {
    flexGrow: 1,
    // flexBasis: "40%",
  },
  bottomGradient: {
    width: "100%",
    height: 40,
    textAlign: "center",
  },
  iconsSection: {
    backgroundColor: "white",
    width: "100%",
    alignItems: "center",
  },
  icons: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    marginHorizontal: 10,
  },
  copyright: {
    marginBottom: 20,
    marginHorizontal: "auto",
    textAlign: "center",
  },
});

export default React.memo(Footer);
