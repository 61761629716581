import { NavigationContainerRefWithCurrent } from "@react-navigation/native";
import { FRONTEND_URL, fetchPost } from "..";
import { APIResponse } from "../../types/responses/APIResponse";
import { Dimensions, Platform } from "react-native";
import { buildPath } from "../../utils/buildPath";
import linking from "../../components/Navigation/URLMapper";

export const postPageView = async (
  width: string,
  height: string,
  url: string,
  route: string
): Promise<APIResponse<any>> => {
  try {
    return fetchPost(
      "onspace/analytics/tracking/page-view",
      {},
      {
        session: {
          width: width,
          height: height,
        },
        page: {
          url: url,
          route: route.replaceAll("?", ""),
        },
      }
    );
  } catch (e) {
    console.log("error tracking pageview: ", route);
  }
};

export const trackPageView = (
  navRef: NavigationContainerRefWithCurrent<ReactNavigation.RootParamList>
) => {
  let url = `${FRONTEND_URL}${buildPath(navRef)}`;
  let path =
    linking.config.screens[navRef.getCurrentRoute()?.name]?.path ||
    linking.config.screens[navRef.getCurrentRoute()?.name];

  const homeUrl = `${FRONTEND_URL}/`;

  if (url == homeUrl) {
    url = `${FRONTEND_URL}/home`;
    path = "/home";
  }
  // debugger;
  return postPageView(
    // screenWidth,
    Dimensions.get("window").width.toString(),
    Dimensions.get("window").height.toString(),
    url,
    path
  );
};
