import React from "react";
import { View, TouchableOpacity, TextStyle } from "react-native";
import RText from "../RText";
import { useNavigation } from "@react-navigation/native";
import variables from "../../styles/variables";
import { removeCommaAndChars } from "../../utils/removeCommaAndChars";

const { colors: fc, weight: fw, size: fs, family: ff } = variables.font;

export type RTextFont = keyof typeof ff;
export type RTextColors = keyof typeof fc;
export type RTextWeight = keyof typeof fw;
export type RTextSize = keyof typeof fs;

type FontProps = {
  family?: RTextFont;
  weight?: RTextWeight;
  color?: RTextColors;
  size?: RTextSize;
  uppercase?: boolean;
  capitalize?: boolean;
  underlineOnHover?: boolean;
};
type Props = {
  value: string;
  customStyle: TextStyle;
  globalFont: FontProps;
  customFont: FontProps;
  onPress?: Function;
  navigationLink?: {
    route: string;
    queryParams?: string;
  };
  index: number;
  navigationIndex: number;
};

const TableCell = ({
  value,
  customStyle,
  globalFont,
  customFont,
  onPress,
  navigationLink,
  index,
  navigationIndex,
}: Props) => {
  const navigation = useNavigation();
  // console.log("VALUE", navigationLink[navigationIndex]?.route);

  return (
    <View key={`TableCell${index}`} style={[customStyle]}>
      {onPress || navigationLink ? (
        <TouchableOpacity
          onPress={
            navigationLink
              ? () =>
                  navigation.navigate(
                    navigationLink[navigationIndex]?.route,
                    navigationLink[navigationIndex]?.queryParams
                  )
              : () => onPress(value)
          }
        >
          <RText
            weight={customFont?.weight ?? globalFont?.weight}
            color={customFont?.color ?? globalFont?.color}
            size={customFont?.size ?? globalFont?.size}
          >
            {typeof value === "string" ? removeCommaAndChars(value) : value}
          </RText>
        </TouchableOpacity>
      ) : (
        <RText
          weight={customFont?.weight ?? globalFont?.weight}
          color={customFont?.color ?? globalFont?.color}
          size={customFont?.size ?? globalFont?.size}
        >
          {typeof value === "string" ? removeCommaAndChars(value) : value}
        </RText>
      )}
    </View>
  );
};

export default TableCell;
