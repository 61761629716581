import { useEffect, useState } from "react";

export type Advertisement = {
  actionDomain?: string;
  actionText?: string;
  duration?: number;
  source: string;
  skippableAfterXSeconds?: number;
  logo?: string;
  prerollsId?: number;
  onEnd: () => void;
};
type AdvertisementState = "playing" | "ended" | "skipped" | "off" | "error";

export const useAdvertiser = (advertisement: Advertisement) => {
  const [adState, setAdState] = useState<AdvertisementState>("off");

  useEffect(() => {
    if (advertisement) setAdState("playing");
  }, [advertisement]);

  const handleEndVideo = () => {
    if (advertisement !== undefined) {
      setAdState("ended");
      advertisement.onEnd();
    }
  };

  const handleSkipAd = () => {
    setAdState("skipped");
    advertisement.onEnd();
  };

  return { adState, handleEndVideo, handleSkipAd };
};
