import React from "react";
import { View, StyleSheet } from "react-native";
import variables from "../../../../../styles/variables";
import TextLoader from "../shared/TextLoader";
import { useResponsive } from "../../../../../hooks/useResponsive";

const { spacing, colors } = variables;

const ProfileLoader = () => {
  return (
    <View style={{ width: "100%" }}>
      <AlphabetSearch />
      {Array.from({ length: 10 }, (_, key) => (
        <Item key={key} />
      ))}
    </View>
  );
};

const Item = () => {
  return (
    <View style={styles.item}>
      <TextLoader height={30} />
    </View>
  );
};

const AlphabetSearch = () => {
  const { isMediumAndDown } = useResponsive();
  return (
    <View style={styles.alphabetSearchContainer}>
      <View style={{ width: "100%" }}>
        <TextLoader height={30} />
      </View>
      {isMediumAndDown && (
        <View style={{ width: "50%", marginTop: spacing.xsmall }}>
          <TextLoader height={30} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    paddingVertical: spacing.xsmall,
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    borderTopColor: colors.palette.neutral.x100,
    borderTopWidth: 1,
    borderStyle: "solid",
  },
  itemInfo: {
    flexDirection: "row",
    alignItems: "center",
  },
  alphabetSearchContainer: {
    marginVertical: spacing.xsmall,
  },
});

export default ProfileLoader;
