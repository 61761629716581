import AsyncStorage from "@react-native-async-storage/async-storage";

export const localStorageShim = {
  async getItem(key) {
    try {
      return await AsyncStorage.getItem(key);
    } catch (e) {
      console.error("Failed to get item from AsyncStorage", e);
      return null;
    }
  },
  async setItem(key, value) {
    try {
      await AsyncStorage.setItem(key, value);
    } catch (e) {
      console.error("Failed to set item in AsyncStorage", e);
    }
  },
  async removeItem(key) {
    try {
      await AsyncStorage.removeItem(key);
    } catch (e) {
      console.error("Failed to remove item from AsyncStorage", e);
    }
  },
  // Implement other localStorage methods as needed
};
