import React from "react";
import { View } from "react-native";
import { useResponsive } from "../../../../../hooks/useResponsive";
import CircleLoader from "../shared/CircleLoader";
import SquareLoader from "../shared/SquareLoader";
import TextLoader from "../shared/TextLoader";

const UpcomingClubDaysLoader = () => {
  const { isSmallAndDown } = useResponsive();

  const Row = () => (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 8,
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <CircleLoader width="30" style={{ paddingHorizontal: 10 }} />
        <View>
          <TextLoader
            width={isSmallAndDown ? "200" : "300"}
            height="20"
            style={{ paddingBottom: 10 }}
          />
          <TextLoader width="100" height="20" />
        </View>
      </View>
      <View>
        <SquareLoader width="45" />
      </View>
    </View>
  );

  return (
    <View>
      {[1, 2, 3].map((i) => (
        <Row key={i} />
      ))}
    </View>
  );
};

export default UpcomingClubDaysLoader;
