import { View } from "react-native";
import { cleanSlug } from "../../../../../utils/cleanSlug";
import { removeCommaAndChars } from "../../../../../utils/removeCommaAndChars";
import Gap from "../../../../Gap";
import RTouchableOpacity from "../../../../RLink/RTouchableOpacity";
import RText from "../../../../RText";
import { Tip as TipT } from "../../../../../types/tipster";

type Props = {
  tip: TipT;
};

const TipTeam = ({ tip }: Props) => {
  return (
    <View
      style={{
        flexDirection: "column",
      }}
    >
      <RTouchableOpacity
        navScreen="IndexOrProfile"
        navParams={{
          type: "jockey",
          slug: cleanSlug(tip?.runner?.jockey?.slug),
        }}
        shouldOpenInNewTab
        style={{ flexDirection: "row" }}
      >
        <RText color="secondary">J: </RText>
        <RText color="primary">
          {removeCommaAndChars(tip.runner.jockey.name)}
        </RText>
      </RTouchableOpacity>
      <Gap size="xxxsmall" />
      <RTouchableOpacity
        navScreen="IndexOrProfile"
        navParams={{
          type: "trainer",
          slug: cleanSlug(tip?.runner?.trainer?.slug),
        }}
        shouldOpenInNewTab
        style={{ flexDirection: "row" }}
      >
        <RText color="secondary">T: </RText>
        <RText color="primary">
          {removeCommaAndChars(tip.runner.trainer.name)}{" "}
        </RText>
      </RTouchableOpacity>
    </View>
  );
};

export default TipTeam;
