import { View, StyleSheet, Platform, Image } from "react-native";
import variables from "../../../styles/variables";
import Icon from "../../Icon";
import { TipTag, TipTagView } from "./types";

const { yellow, blue, red, green } = variables.colors.palette.watchAndBet;

const styles = StyleSheet.create({
  tipsters: {
    backgroundColor: yellow,
  },
  market: {
    backgroundColor: blue,
  },
  value: {
    backgroundColor: red,
  },
  form: {
    backgroundColor: green,
  },
  rtvTipper: {
    backgroundColor: "black",
  },
  expertEyeIcon: {
    ...Platform.select({
      web: { paddingTop: 5 },
      native: { paddingTop: 25 },
    }),
    marginRight: 0.5,
  },
  expertEye: {
    backgroundColor: "black",
  },
  timeformLabel: {
    backgroundColor: "black",
    width: 20,
  },
  timeform: {
    width: 60,
    ...Platform.select({
      web: { height: 15, marginTop: 5 },
      native: {
        height: 10,
        marginTop: -5,
      },
    }),
  },
});

// console.log("## require()::: ", require("../../Image/images/timeform.png"));

export type TagMap = { [key in TipTag]: TipTagView };

export const tagMap: TagMap = {
  // Tipsters: {
  //   name: "Tipsters",
  //   label: "T",
  //   style: styles.tipsters,
  //   description: "Most selected by newspaper tipsters",
  //   selected: true,
  // }, // currently not supported
  // Market: {
  //   name: "Market",
  //   label: "M",
  //   style: styles.market,
  //   description: "Based on how the odds have changed",
  //   selected: true,
  // }, // currently not supported
  // Value: {
  //   name: "Value",
  //   label: "V",
  //   style: styles.value,
  //   description: "Form-based value odds",
  //   selected: true,
  // }, // currently not supported
  // Form: {
  //   name: "Form",
  //   label: "F",
  //   style: styles.form,
  //   description: "Based on likely effect of key race factors",
  //   selected: true,
  // }, // currently not supported
  Timeform: {
    name: "Timeform",
    label: "TF",
    style: styles.timeformLabel,
    description: "Racing data provider since 1984",
    selected: true,
    image: (
      <Image
        source={require("../../Image/images/timeform.png")}
        style={styles.timeform}
      />
    ),
  },
  "Expert Eye": {
    name: "Expert Eye",
    label: <Icon name="eye" size="tipTag" style={styles.expertEyeIcon} />,
    style: styles.expertEye,
    description: "Horses which have caught our experts eye",
    selected: true,
  },
};
