import React from "react";
import { View } from "react-native";
import { useResponsive } from "../../hooks/useResponsive";
import variables from "../../styles/variables";
import Icon from "../Icon/Icon";
import { RLink } from "../RLink/RLink";

const platforms = ["twitter", "youtube", "instagram", "facebook", "tiktok"];

const shareHeadline = {
  twitter: "text=",
  facebook: "quote=",
};

const shareUrl = {
  twitter: "url=",
  facebook: "u=",
};

type Props = {
  type?: "primary" | "secondary" | "tertiary";
  postContent?: {
    headline: string;
    url: any;
  };
  size?: string;
};

const { spacing } = variables;

const Socials = ({ type = "primary", postContent, size }: Props) => {
  const { isLargeAndUp } = useResponsive();

  const shareUrl = (app) => {
    if (
      !postContent ||
      app == "youtube" ||
      app == "instagram" ||
      app == "tiktok"
    ) {
      if (app === "tiktok") return "https://www.tiktok.com/@racing_tv";
      return `https://${app}.com/racingtv`;
    }

    // if the user presses Twitter or Facebook on a page where information is to be shared (i.e. a news post), open the platform and pass the headline and url into the params in order to prepopulate the post with the headline and url
    const shareToPlatform = {
      twitter: "https://twitter.com/intent/tweet?",
      facebook: "https://www.facebook.com/sharer/sharer.php?",
    };

    let params = [shareHeadline[app] + encodeURI(postContent.headline)];
    app == "twitter" && params.push("via=" + encodeURI("RacingTV"));
    params.push(shareUrl[app] + encodeURI(postContent.url));
    return shareToPlatform[app] + params.join("&");
  };

  const SocialIcon = ({ app, last }) => {
    return (
      <RLink
        href={shareUrl(app)}
        style={{ marginRight: last ? 0 : spacing.xsmall }}
      >
        <Icon
          name={type == "primary" ? `${app}Light` : `${app}Dark`}
          size={size ? size : isLargeAndUp ? "xlarge" : "large"}
          fill="palette.neutral.x0"
        />
      </RLink>
    );
  };

  return (
    <View style={{ flexDirection: "row" }}>
      {platforms.map((app, index) => (
        <SocialIcon
          app={app}
          key={index}
          last={index == platforms.length - 1}
        />
      ))}
    </View>
  );
};

export default Socials;
