import { StyleSheet, Text, View } from "react-native";
import React from "react";
import RModal from "../RModal/RModal";
import PopupHeader from "../Popup/PopupHeader";
import RText from "../RText";
import variables from "../../styles/variables";
import CmsContent from "../cms/CmsContent";

type Props = {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  data: any;
};

const TermsModal = ({ openModal, setOpenModal, data }: Props) => {
  return (
    <RModal
      visible={openModal}
      setVisibility={setOpenModal}
      hideCloseBtn={false}
    >
      <View style={styles.modal}>
        <PopupHeader text="Terms and Conditions" icon="infoPink" />
        {data?.overlay?.includes("<RParagraph") ? (
          <CmsContent content={data.overlay} />
        ) : (
          <RText style={styles.terms}>{data.terms || data.overlay}</RText>
        )}
      </View>
    </RModal>
  );
};

export default TermsModal;

const styles = StyleSheet.create({
  modal: {
    minHeight: 80,
    marginRight: "auto",
    marginLeft: "auto",
  },
  terms: {
    padding: variables.spacing.xsmall,
  },
});
