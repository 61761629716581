import { fetchGet } from "../..";
import { Category, OnDemandVideo } from "../../../types/onDemand";
import { Player } from "../../../types/replay";
import { APIResponse } from "../../../types/responses/APIResponse";
import { Video as OnDemandVideoInfos } from "../../../types/onDemand";
import { cleanSlug } from "../../../utils/cleanSlug";
import { selectOptimalCodec } from "../../../components/VideoPlayer/selectOptimalCodec";

export const getLatestCategoryVideos = async (categoryId: number) => {
  const response = await fetchGet(
    `videos/on-demand/categories/${categoryId}/videos`
  );

  return response.videos;
};

export const fetchCategoryVideosByMonth = async (
  categoryId: number, // category id
  date: string
): Promise<APIResponse<Category[]>> => {
  // console.log(
  //   "/videos/on-demand/categories/${categoryId}/videos/${date}",
  //   `/videos/on-demand/categories/${categoryId}/videos/${date}`
  // );

  const response = await fetchGet(
    `/videos/on-demand/categories/${categoryId}/videos/${date}`
  );
  return response;
};

export const getCategory = async (slug: string): Promise<Category> => {
  const { categories } = await fetchGet(`videos/on-demand/categories/all`);
  console.log(
    "all categories:: ",
    categories.map((c) => c.slug)
  );
  console.log("cat slug is:: ", slug);
  const foundCategory = categories.find(
    (cat) => cleanSlug(cat.slug) === cleanSlug(slug)
  );

  if (!foundCategory) {
    return;
  }

  foundCategory.videos.forEach((video) => {
    video.categories = categories
      .filter((cat) => cat.videos.some((catVideo) => catVideo.id === video.id))
      .map((cat) => ({ title: cat.title, id: cat.id, slug: cat.slug }));
  });
  return foundCategory;
};

export const getOnDemandVideo = async (
  id: number,
  prerollToken?: string
): Promise<OnDemandVideo> => {
  const response: APIResponse<Player> = await fetchGet(
    `member/watch/on-demand/videos/${id}`,
    prerollToken ? { preroll_token: prerollToken } : {}
  );
  const { player, meta } = response;
  if (meta.status === 401)
    throw {
      status: meta.status,
      message: response.error.text,
    };
  const video: OnDemandVideo = {
    sources: selectOptimalCodec(player.sources),
  };
  if (meta.status === 402 || meta.status === 403) {
    video.preroll = player.preroll;
    video.prerollToken = player.preroll_token;
  }
  return video;
};

export const getMostWatchedOnDemand = async (): Promise<
  OnDemandVideoInfos[]
> => {
  const mostWatched: APIResponse<OnDemandVideoInfos[]> = await fetchGet(
    "videos/on-demand/most-watched"
  );
  return mostWatched.videos;
};

export const getOnDemandVideoInfos = async (
  id: number
): Promise<OnDemandVideoInfos> => {
  const response: APIResponse<OnDemandVideoInfos> = await fetchGet(
    `videos/on-demand/${id}`
  );
  return response.video;
};
