import { StyleSheet, View, ViewStyle } from "react-native";
import React, { ReactNode } from "react";
import { useResponsive } from "../../../../../hooks/useResponsive";
import variables from "../../../../../styles/variables";
import TextLoader from "../shared/TextLoader";

type Props = {
  children: ReactNode;
  childContainerStyle?: ViewStyle;
  additionalComponent?: ReactNode;
  titleContainerStyle?: ViewStyle;
};

const PreferenceRowLoader = ({
  additionalComponent,
  children,
  childContainerStyle,
  titleContainerStyle,
}: Props) => {
  const { isSmallAndDown } = useResponsive();

  return isSmallAndDown ? (
    <View style={mobileStyles.container}>
      <View>
        <TextLoader />
      </View>
      <View style={mobileStyles.childContainer}>{children}</View>
    </View>
  ) : (
    <View style={styles.container}>
      <View style={titleContainerStyle || styles.titleContainer}>
        <TextLoader width={"90%"} />
      </View>
      <View style={childContainerStyle || styles.childContainer}>
        {children}
      </View>
    </View>
  );
};

export default PreferenceRowLoader;

const { colors, spacing } = variables;

const styles = StyleSheet.create({
  container: {
    padding: spacing.small,
    flexDirection: "row",
    borderBottomColor: colors.palette.neutral.x50,
    borderBottomWidth: 2,
  },
  titleContainer: {
    flex: 1,
    alignItems: "flex-start",
    paddingTop: spacing.xxsmall,
    justifyContent: "center",
  },
  childContainer: {
    flexDirection: "row",
    flex: 4,
    alignItems: "center",
  },
  row: {
    flexDirection: "row",
  },
});

const mobileStyles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingTop: spacing.xxsmall,
  },
  childContainer: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingLeft: spacing.xsmall,
    paddingVertical: spacing.xsmall,
    flexWrap: "wrap",
  },
});
