export const allCountries = [
  { code: "au", name: "Australia", supported: true },
  { code: "bh", name: "Bahrain", supported: true },
  { code: "ca", name: "Canada", supported: true },
  { code: "fr", name: "France", supported: true },
  { code: "de", name: "Germany", supported: true },
  {
    code: "ie",
    name: "Ireland",
    supported: true,
    includeInRacingCalendar: true,
  },
  { code: "jp", name: "Japan", supported: true },
  { code: "sa", name: "Saudi Arabia", supported: true },
  { code: "sg", name: "Singapore", supported: true },
  { code: "za", name: "South Africa", supported: true },
  { code: "se", name: "Sweden", supported: true },
  { code: "ae", name: "United Arab Emirates", supported: true },
  {
    code: "gb",
    name: "United Kingdom",
    supported: true,
    includeInRacingCalendar: true,
  },
  { code: "us", name: "United States", supported: true },
];
