import { Pressable, View, StyleSheet, Platform } from "react-native";
import { DEFAULT_MAGAZINE_IMAGE } from "../../../Image/images/defaultImages";
import RImage from "../../../RImage/RImage";
import RCorner from "../../../RCorner/RCorner";
import { useEffect, useState } from "react";
import RText from "../../../RText";
import Dropdown from "../../../Filters/Dropdown";
import RWebView from "../../../RWebView";
import RModal from "../../../RModal/RModal";
import { useAtom } from "jotai";
import { openAuthModalAtom, userAtom } from "../../../../atoms";
import { getUserState } from "../../../../utils/auth";
import variables from "../../../../styles/variables";
import { fetchGet } from "../../../../client";
import SectionHeader from "../../../SectionHeader/SectionHeader";
import { useResponsive } from "../../../../hooks/useResponsive";
import RSuspense from "../../../../utils/RSuspense/RSuspense";

type Magazine = {
  title: string;
  date: string;
  url: string;
  summary: string;
};

import lazyImport from "../../../../utils/lazyImport/lazyImport";

const CmsContent = lazyImport(() => import("../../../cms/CmsContent"));

export const MagazineComponent = () => {
  const [magazineLoading, setMagazineLoading] = useState(false);
  const [magazines, setMagazines] = useState<Magazine[]>(null);
  const [magazineError, setMagazineError] = useState(null);
  const [selectedMagazine, setSelectedMagazine] = useState<Magazine>(null);
  const [magazineOpen, setMagazineOpen] = useState(false);
  const [user] = useAtom(userAtom);
  const userState = getUserState(user);
  const [_, setIsAuthModalOpen] = useAtom(openAuthModalAtom);
  const { isXSmallOnly, isMediumAndDown } = useResponsive();

  useEffect(() => {
    fetchGet(`member/magazines/issues?page=1`)
      .then((res) => {
        setMagazines(res.issues);
        setSelectedMagazine(res.issues[0]);
      })
      .catch((err) => setMagazineError(err.message))
      .finally(() => setMagazineLoading(false));
  }, []);

  if (magazineLoading) return <RText>Loading...</RText>;
  if (magazineError)
    return <RText>Error loading magazine: {magazineError}</RText>;
  if (!magazines) return null;

  const SMALL_IMAGE_HEIGHT = 200;
  const LARGE_IMAGE_HEIGHT = 400;

  // we don't get the url unless the user is logged in
  const imageStyle = {
    ...styles.image,
    ...(isXSmallOnly
      ? { maxHeight: SMALL_IMAGE_HEIGHT }
      : { maxHeight: LARGE_IMAGE_HEIGHT }),
  };

  return (
    <View key={`magazine`}>
      <RModal
        style={{
          container: { width: "100%", alignSelf: "center", minWidth: "100%" },
          content: { width: "100%", minWidth: "100%" },
        }}
        visible={magazineOpen}
        setVisibility={setMagazineOpen}
        fullSize
        // hideCloseBtn
      >
        <RWebView source={selectedMagazine?.url} />
      </RModal>
      <View>
        <Pressable
          onPress={() =>
            userState != "paid_account" // there are cases when this fails :(
              ? setIsAuthModalOpen("fdp")
              : setMagazineOpen(true)
          }
          style={[
            styles.imageContainer,
            isXSmallOnly
              ? { maxHeight: SMALL_IMAGE_HEIGHT }
              : { maxHeight: LARGE_IMAGE_HEIGHT },
          ]}
        >
          <RImage style={imageStyle} source={DEFAULT_MAGAZINE_IMAGE} />
          <RCorner size={isMediumAndDown ? "medium" : "large"} />
        </Pressable>
      </View>
      <View
        style={{
          flexDirection: isMediumAndDown ? "column-reverse" : "row",
          display: "flex",
          paddingVertical: 4,
          justifyContent: "space-between",
        }}
      >
        <SectionHeader
          heading={`Inside the ${selectedMagazine?.title} issue`}
          colorTheme="secondary"
          containerStyle={{ maxWidth: isMediumAndDown ? "100%" : "50%" }}
        />
        <Dropdown
          data={magazines.map((m) => m.title)}
          onSelect={(magazineTitle) =>
            userState != "paid_account"
              ? setIsAuthModalOpen("fdp")
              : setSelectedMagazine(
                  magazines.find((m) => m.title.toLowerCase() == magazineTitle)
                )
          }
          selectedOption={selectedMagazine?.title}
          defaultText="Select an issue"
          fontStyle={{
            size: "md",
            weight: "bold",
          }}
          containerStyle={{
            paddingHorizontal: 8,
            width: isMediumAndDown ? "100%" : 250,
            marginLeft: isMediumAndDown ? 0 : 4,
            marginVertical: isMediumAndDown ? 4 : 10,
          }}
          justifyContent="space-between"
          borderStyle={{
            width: 1,
            color: "primary",
          }}
        />
      </View>
      <RSuspense>
        <CmsContent
          content={selectedMagazine?.summary}
          allContent={false}
        ></CmsContent>
      </RSuspense>
    </View>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    width: "100%",
    alignSelf: "center",
    backgroundColor: variables.colors.palette.rtv.primary,
    borderRadius: 5,
    ...Platform.select({
      web: {},
      default: {
        maxHeight: 200,
      },
    }),
  },
  image: {
    width: "100%",
    alignSelf: "center",
    resizeMode: "contain",
    borderRadius: 5,
    objectFit: "contain",
    ...Platform.select({
      web: {
        maxHeight: 400,
      },
      default: {
        maxHeight: 200, // should do this for small devices only
      },
    }),
  },
});
