import React from "react";
import { Platform, Pressable, StyleSheet, View, ViewStyle } from "react-native";
import Icon from "../Icon";
import RText from "../RText";

const isWeb = Platform.OS === "web";

export type TitleBarProps = {
  title?: string;
  style?: { close?: ViewStyle | ViewStyle[]; title?: ViewStyle | ViewStyle[] };
  onClose?: () => void;
};

const TitleBar: React.FC<TitleBarProps> = ({ title, style, onClose }) => {
  return (
    <View style={styles.container}>
      <View style={[styles.title, style?.title && style.title]}>
        {!!title && <RText color="white">{title}</RText>}
      </View>
      <Pressable
        style={[styles.close, style?.close && style.close]}
        onPress={() => onClose && onClose()}
      >
        <Icon
          name="close"
          fill="palette.rtv.white"
          size={isWeb ? "medium" : "small"}
        />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flex: 1,
  },
  close: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    flex: 11,
    justifyContent: "center",
    paddingLeft: 10,
  },
});

export default TitleBar;
