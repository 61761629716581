import React from "react"; // This is actually used. It's just not detected by the linter
import { StyleSheet, View } from "react-native";
import variables from "../../../../../styles/variables";
import Icon from "../../../../Icon";
import { ContentPreviewProps } from "../../../../ContentPreview/ContentPreview";
import ContentImagePreviewLoader from "./ContentImagePreviewLoader";
import MultilineTextLoader from "./MultilineTextLoader";

const ContentPreviewLoader: React.FC<ContentPreviewProps> = ({
  displayAs,
  style,
  handleLayout = () => {},
  video,
}) => {
  const displayAsList = displayAs === "list";
  return (
    <View
      style={[styles.container, displayAsList && stylesAsList.container, style]}
      onLayout={handleLayout}
    >
      <View
        style={[
          styles.imageContainer,
          displayAsList && stylesAsList.imageContainer,
        ]}
      >
        <ContentImagePreviewLoader />
        {video ? (
          <Icon
            style={styles.icon}
            name="play"
            size="xlarge"
            fill="palette.neutral.x0"
          />
        ) : null}
      </View>
      <View
        style={[
          styles.descriptionContainer,
          displayAsList && stylesAsList.descriptionContainer,
        ]}
      >
        <MultilineTextLoader numberOfLines={2} lineHeight={6} />
      </View>
      {displayAsList && <View style={stylesAsList.iconContainer} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // display: "flex",
    // flex: 1,
    flexGrow: 1,
    flexBasis: 300, //grow + basis - responsive dimensions but last items are much bigger than items in full rows above
    borderWidth: 1,
    borderRadius: 5,
    borderColor: variables.colors.palette.neutral.x100,
    padding: variables.spacing.xsmall,
    backgroundColor: variables.colors.palette.neutral.x0,
  },
  hovered: {
    backgroundColor: variables.colors.palette.neutral.x50,
  },
  imageContainer: {
    aspectRatio: 16 / 9,
    backgroundColor: variables.colors.palette.neutral.x100,
    justifyContent: "center",
    alignItems: "center",
  },
  descriptionContainer: {
    flex: 1,
    marginTop: variables.spacing.xsmall,
  },
  description: {},
  icon: {
    position: "absolute",
  },
});

const stylesAsList = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderWidth: 0,
    borderBottomWidth: 1,
    width: "100%",
    height: 100,
    borderRadius: 0,
  },
  imageContainer: {
    aspectRatio: 16 / 9,
  },
  descriptionContainer: {
    flex: 2,
    marginLeft: variables.spacing.xsmall,
    marginTop: 0,
  },
  iconContainer: {
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-end",
    flex: 0.3,
  },
});

export default ContentPreviewLoader;
