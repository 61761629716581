import { AVPlaybackStatus } from "expo-av";
import React, {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { StyleSheet } from "react-native";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
import { VideoPlayerHandle, VideoPlayerProps } from "./types/";

// We copied that config from the original RTV rails site
const hlsConfig = {
  capLevelToPlayerSize: true,
  defaultAudioCodec: "mp4a.40.5",
  debug: false,
};

const hlsInstances: { [pid: string]: any } = {};
const VideoPlayer = forwardRef<VideoPlayerHandle, VideoPlayerProps>(
  (
    {
      source,
      style,
      autoPlay = false,
      onStatusUpdate,
      onFullScreenChange,
      pid,
      onHover,
      poster,
      ...rest
    },
    ref
  ) => {
    const [isReady, setIsReady] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const fullScreenEventListenerHandler = (e) => {
      onFullScreenChange(document.fullscreenElement !== null);
    };

    const hoverEventListenerHandler = (e) => {
      onHover();
    };

    useImperativeHandle(ref, () => {
      const ref = videoRef.current;
      return {
        play: async () => ref.play(),
        pause: async () => ref.pause(),
        seekTo: (time: number) => {
          ref.currentTime = time;
        },
        setVolumeTo: (volume: number) => {
          ref.volume = volume;
        },
        setIsMuted: (isMuted: boolean) => {
          ref.muted = isMuted;
        },
        requestPictureInPicture: async () => {
          try {
            await ref?.requestPictureInPicture();
            return true;
          } catch (err) {
            return false;
          }
        },
        exitPictureInPicture: document.exitPictureInPicture,
        requestFullscreen: async () => {
          try {
            const container = document.querySelector(
              `#${pid} #video-player-container`
            );
            container.addEventListener(
              "fullscreenchange", // or onwebkitfullscreenchange
              fullScreenEventListenerHandler
            );

            const video = document.getElementsByTagName("video")[0];

            const fullscreenApi =
              container.requestFullscreen ||
              container.webkitRequestFullscreen ||
              container.mozRequestFullScreen ||
              container.msRequestFullscreen;

            const videoFullScreenApi = video.webkitEnterFullscreen;

            if (fullscreenApi != null) {
              fullscreenApi.call(container);
              return true;
            } else if (videoFullScreenApi != null) {
              videoFullScreenApi.call(video);
              return true;
            }

            return false;
          } catch (err) {
            console.log(err);
            return false;
          }
        },
        exitFullscreen: async () => {
          await document.exitFullscreen().then((_) => {
            const container = document.querySelector(
              `#${pid} #video-player-container`
            );
            container.removeEventListener(
              "fullscreenchange",
              fullScreenEventListenerHandler
            );
          });
        },
        isPlaying: async () => !ref.paused,
      };
    });

    const handleReadyForDisplay = (e: any) => {
      if (e.type === "loadeddata") {
        setIsReady(true);
      }
    };

    const handleTimeUpdate = (e: any) => {
      const videoEvent = e.target as HTMLMediaElement;
      const { currentTime, duration } = videoEvent;
      onStatusUpdate &&
        onStatusUpdate({
          positionMillis: currentTime * 1000,
          durationMillis: duration * 1000,
          isLoaded: isReady,
          isPlaying: !videoEvent.paused,
          didJustFinish: duration && currentTime >= duration,
        } as AVPlaybackStatus);
    };

    useEffect(() => {
      handleTimeUpdate({ target: videoRef.current as HTMLMediaElement });
    }, [isReady]);

    const createHlsInstance = () => {
      const Hls = window.Hls;
      const video = videoRef.current as HTMLMediaElement;
      hlsInstances[pid] = new Hls(hlsConfig);
      hlsInstances[pid].on(Hls.Events.MEDIA_ATTACHED, () => {
        // console.log("video and hls.js are now bound together !", source);
      });
      hlsInstances[pid].on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        // console.log(
        //   "manifest loaded, found " + data.levels.length + " quality level",
        //   source
        // );
      });

      // ################################
      //   DON'T REMOVE THIS CODE BELOW
      // ################################
      // It used to fix an error happening when some packets are missing.
      // The player stopped and nerver played again. The code below fixed that issue, by reloading the player.
      // On chrome web, the video replayed at the same position, but on chrome android, it replayed from the beginning.
      // Removing it fixed the issue on chrome android, and I didn't notice any issue on chrome web anymore.
      // My guess is that sources from Onspace have a better quality now. Or another change somewhere else fixed the issue.
      // I think it's safe to remove it, but I'm keeping it here just in case.

      // hlsInstances[pid].on(Hls.Events.ERROR, (event, data) => {
      //   console.error("HLS ERROR", data);
      //   hlsInstances[pid].destroy();
      //   // We noticed that sometimes hls.js fails to load the m3u8 file. So we try to load it again after 500ms
      //   // Weird 🤷‍♂️
      //   setTimeout(() => {
      //     createHlsInstance();
      //   }, 500);
      // });
      // ################################
      //   DON'T REMOVE THIS CODE ABOVE
      // ################################

      hlsInstances[pid].attachMedia(video);
      hlsInstances[pid].loadSource(source);
    };

    const removeInstance = () => {
      if (hlsInstances[pid]) {
        hlsInstances[pid].stopLoad();
        delete hlsInstances[pid];
      }
    };

    useEffect(() => {
      if (!source) return;
      if (!videoRef.current) return;
      const video = videoRef.current as HTMLMediaElement;
      const Hls = window.Hls;
      if (Hls.isSupported() && source.includes(".m3u8")) {
        if (hlsInstances[pid]) {
          hlsInstances[pid].destroy();
        }
        createHlsInstance();
      } else {
        // console.log("Hls is not supported or source is not m3u8");
        video.src = source;
      }
    }, [source]);

    useEffect(() => {
      videoRef.current?.addEventListener(
        "mousemove",
        hoverEventListenerHandler
      );
      videoRef.current?.addEventListener(
        "seeking",
        () => setIsReady(false),
        false
      );
      videoRef.current?.addEventListener(
        "seeked",
        () => setIsReady(true),
        false
      );
      return () => {
        videoRef.current?.removeEventListener(
          "mousemove",
          hoverEventListenerHandler
        );
        videoRef.current?.removeEventListener("seeking", () =>
          setIsReady(false)
        );
        videoRef.current?.removeEventListener("seeked", () => setIsReady(true));
        removeInstance();
      };
    }, [videoRef]);

    return (
      <video
        ref={videoRef}
        crossOrigin="true"
        playsInline // required to tell iOS safari to play inline and not in fullscreen
        poster={poster}
        onDurationChange={handleTimeUpdate}
        onTimeUpdate={handleTimeUpdate}
        onLoadedData={handleReadyForDisplay}
        autoPlay={autoPlay}
        onPause={handleTimeUpdate}
        controls={false}
        style={{ ...styles.container, ...style }}
        src={source}
        {...rest}
      />
    );
  }
);

export default VideoPlayer;

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
});
