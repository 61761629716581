import React from "react";
import RText from "../RText";
import { View, StyleSheet, Pressable } from "react-native";
import Icon from "../Icon";
import variables from "../../styles/variables";
import Gap from "../Gap";

interface Props {
  text: string;
  icon?: string;
}

const PopupHeader = ({ text, icon }: Props) => {
  return (
    <View style={styles.container}>
      <View style={styles.iconAndText}>
        <Icon
          name={icon}
          style={styles.icon}
          fill="palette.rtv.secondary"
          size="medium"
        />
        <RText
          family="secondary"
          weight="bold"
          uppercase
          size="md"
          style={{ alignSelf: "center", width: "100%" }}
        >
          {text}
        </RText>
        <Gap size="xsmall" />
      </View>
      <Gap vertical size="xsmall" />
    </View>
  );
};

const { colors, spacing } = variables;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderRadius: 4,
    padding: spacing.xxsmall,
    // justifyContent: "space-between",
    width: "100%",
    alignSelf: "flex-start",
    borderBottomColor: colors.palette.neutral.x50,
    borderBottomWidth: 2,
  },
  iconAndText: {
    flexDirection: "row",
    flex: 1,
    // maxWidth: "96%",
    // width: "100%",
  },
  icon: {
    maxHeight: 30,
    maxWidth: 30,
    marginRight: spacing.xsmall,
  },
});

export default PopupHeader;
