import React, { FC, useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/core";
import Icon from "../Icon";
import { formatRaceTime, isFuture } from "../../utils/TimeCalculator";
import RText from "../RText";
import Race from "../../types/races/race";
import variables from "../../styles/variables";
import { useResponsive } from "../../hooks/useResponsive";
import { StackNavigationProp } from "@react-navigation/stack";
import { cleanSlug } from "../../utils/cleanSlug";
import { getRace } from "../../client/races";
import { prepareParams } from "../Screens/Results/utils";
import { isNullified } from "../../utils/race";
import { RootStackParamList } from "../../types/route";
import {
  addRaceNotification,
  deleteRaceNotification,
} from "../../client/notifications/notifications";
import { toastError, toastSuccess } from "../Toasts/Toasts";
import { useToast } from "react-native-toast-notifications";
import { useAtom } from "jotai";
import { openAuthModalAtom } from "../../atoms";
import usePressableProps from "../../hooks/usePressableProps";
import Gap from "../Gap";
import useUser from "../../hooks/useUser";

type RaceRowProps = {
  track: { name: string; slug: string };
  race: Race;
  meetingDate?: string;
  firstRace?: boolean;
  isRaceNotified: boolean;
  fetchRaceDetails?: boolean;
};

const RaceRow: FC<RaceRowProps> = ({
  track,
  race,
  meetingDate,
  firstRace,
  isRaceNotified,
  fetchRaceDetails = false,
}) => {
  const { isMediumAndDown, isSmallAndDown } = useResponsive();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const [raceData, setRaceData] = useState<Race>(
    fetchRaceDetails ? null : race
  );
  //TODO: add states
  const [error, setError] = useState(null);
  const [contentLoading, setContentLoading] = useState(false);

  const raceTime = formatRaceTime(race.start_time_scheduled);
  const isFutureRaceTime = isFuture(race.start_time_scheduled);
  const toast = useToast();
  const [, setAuthModalOpen] = useAtom(openAuthModalAtom);
  const [raceNowNotified, setRaceNowNotified] = useState(isRaceNotified);
  const { isLoggedIn } = useUser();

  const pressableProps = usePressableProps({
    screen: isFutureRaceTime ? "IndividualRacecard" : "IndividualResult",
    params: prepareParams({
      startTime: race.start_time_scheduled,
      date: meetingDate,
      track: cleanSlug(track.slug),
    }),
  });

  useEffect(() => {
    setRaceNowNotified(isRaceNotified);
  }, [isRaceNotified]);

  useEffect(() => {
    if (!fetchRaceDetails) return;
    getRace(meetingDate, cleanSlug(track.slug), race.start_time_scheduled)
      .then((res) => setRaceData(res.race))
      .catch((err) => setError(err))
      .finally(() => setContentLoading(false));
  }, [track]);

  const handleRaceNotification = async (
    raceId: number,
    raceNowNotified: boolean,
    setRaceNowNotified: (raceNowNotified: boolean) => void
  ) => {
    if (!isLoggedIn) {
      setAuthModalOpen("login");
      return;
    }

    try {
      if (!raceNowNotified) {
        const response = await deleteRaceNotification(raceId);

        if (response.meta.status !== 200) {
          throw new Error();
        }

        toastSuccess(toast, "Race notification has been removed");
      } else {
        const response = await addRaceNotification(raceId);
        if (response.meta.status !== 200) {
          throw new Error();
        }

        toastSuccess(toast, "Race notification has been set");
      }

      setRaceNowNotified(raceNowNotified);
    } catch (error) {
      toastError(toast, "Could not update race notification");
    }
  };

  if (!raceData || contentLoading) return;

  const RaceDetails = ({
    race,
    isMediumAndDown,
  }: {
    race: Race;
    isMediumAndDown: boolean;
  }) => {
    if (isNullified(race?.status?.state))
      return (
        <RText color="disabled" uppercase>
          {race.status.state}
        </RText>
      );
    return (
      <View style={styles.col2}>
        <RText size={isSmallAndDown ? "sm" : "md"} weight="bold">
          {headingdetailsToShow(race, isMediumAndDown)
            .filter((x) => x != null)
            .join(" ")}
        </RText>
        <View style={styles.raceDetails}>
          <RText size={isSmallAndDown ? "sm" : "md"}>
            {subHeadingdetailsToShow(race, isMediumAndDown)
              .filter((x) => x != null)
              .join(", ")}
          </RText>
        </View>
      </View>
    );
  };

  return (
    <View
      style={[
        styles.rowContainer,
        firstRace && !isMediumAndDown
          ? { borderTopWidth: 0 }
          : { borderTopWidth: 1 },
      ]}
    >
      <TouchableOpacity
        {...pressableProps}
        style={{ flex: 1, flexDirection: "row", alignItems: "center" }} // Ensure items are aligned properly
      >
        <View
          style={[
            styles.col1,
            {
              marginTop:
                isMediumAndDown || isNullified(race?.status?.state) ? 4 : 2,
            },
            isMediumAndDown && { height: "100%", justifyContent: "flex-start" },
          ]}
        >
          <RText
            family="secondary"
            style={{
              color:
                isFutureRaceTime && !isNullified(race?.status?.state)
                  ? variables.colors.palette.rtv.secondary
                  : variables.colors.palette.neutral.x500,
            }}
            weight={isFutureRaceTime ? "bold" : "regular"}
          >
            {raceTime}
          </RText>
        </View>
        <RaceDetails race={raceData} isMediumAndDown={isMediumAndDown} />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() =>
          handleRaceNotification(race.id, !raceNowNotified, setRaceNowNotified)
        }
        style={{ flexDirection: "row", alignItems: "center" }}
      >
        <Icon
          size="medium"
          name={
            raceNowNotified ? "notificationSelected" : "notificationUnselected"
          }
          style={[
            isMediumAndDown ? styles.iconSmallScreen : styles.iconBigScreen,
          ]}
        />
      </TouchableOpacity>
      <Gap size="xsmall" />
      <TouchableOpacity
        {...pressableProps}
        style={{ flexDirection: "row", alignItems: "center" }} // Ensure items are aligned properly
      >
        <Icon
          name="chevronBoldRight"
          size="small"
          fill="palette.neutral.x0"
          style={[
            isMediumAndDown ? styles.iconSmallScreen : styles.iconBigScreen,
          ]}
        />
      </TouchableOpacity>
    </View>
  );
};

const subHeadingdetailsToShow = (race: Race, isMediumAndDown): string[] => {
  const someDetails = [
    race.eligibility_formatted,
    raceClass(race, isMediumAndDown),
    race.distance_formatted,
  ];

  const allDetails = [
    race.eligibility_formatted,
    race.distance_formatted,
    race.rating_limit_range,
    race.prize_text?.substring(0, race.prize_text.length - 3),
    race.runner_count_text,
  ];
  return isMediumAndDown
    ? someDetails.map((w) => w?.capitalize())
    : allDetails.map((w) => w?.capitalize());
};

const headingdetailsToShow = (
  race: Race,
  isMediumAndDown: boolean
): string[] => {
  const headingDetails = isMediumAndDown
    ? [race.title]
    : [
        raceTitle(race),
        raceClass(race, isMediumAndDown),
        trackType(race, isMediumAndDown),
      ];
  return headingDetails.map((w) => w?.capitalize());
};

const raceTitle = (race: Race): string => {
  // check if class is already included in the title
  const indexOfClassInTitle = race?.title?.indexOf(race.race_class);

  // if class is included in the title check if it is in brackets
  const classIncludedInTitle =
    indexOfClassInTitle &&
    race.title[indexOfClassInTitle - 1] === "(" &&
    race.title[indexOfClassInTitle + 1] === ")";

  if (classIncludedInTitle) {
    // strip class from title
    return (
      race.title.substring(0, indexOfClassInTitle - 2) +
      race.title.substring(indexOfClassInTitle + 2)
    );
  } else {
    return race.title;
  }
};

const raceClass = (race: Race, isMediumAndDown: boolean): string => {
  if (!race.race_class) return null;
  let raceClass = "Class " + race.race_class;
  raceClass = isMediumAndDown ? raceClass : `(${raceClass})`;
  return raceClass;
};

const trackType = (race: Race, isMediumAndDown: boolean): string => {
  if (!race.track_type) return null;

  let type = isMediumAndDown ? race.track_type : `(${race.track_type})`;
  return type;
};

const { spacing, colors } = variables;

const styles = StyleSheet.create({
  rowContainer: {
    paddingHorizontal: spacing.xxsmall,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: spacing.xxsmall,
    borderTopWidth: 1,
    borderTopColor: colors.palette.neutral.x100,
  },
  col1: {
    maxWidth: 50,
    justifyContent: "flex-start",
    marginRight: spacing.xsmall,
  },
  col2: {
    flex: 6,
  },
  col3: {
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "flex-end",
  },
  col3SmallScreen: {
    width: "10%",
  },
  col3BigScreen: {
    flex: 1,
  },
  iconSmallScreen: {
    marginTop: 4,
  },
  iconBigScreen: {
    paddingTop: 4,
    marginLeft: spacing.xsmall,
  },
  raceDetails: {
    flexDirection: "row",
  },
});

export default RaceRow;
