import React, { useState, useEffect, FC, Suspense } from "react";
import { StyleSheet, Pressable, Platform } from "react-native";
import lazyImport from "../../utils/lazyImport/lazyImport";
// import { Calendar } from "react-native-calendars";
const Calendar = lazyImport(() => import("react-native-calendars"), "Calendar");
import { useCloseFromOutside } from "../../hooks/useCloseFromOutside";
import Icon from "../Icon";
import variables from "../../styles/variables";
import { useResponsive } from "../../hooks/useResponsive";
import RText from "../RText";
import SlideInAnimation from "../Animations/SlideInAnimation";
import { YYYYMMDDWithTimeZone } from "../../utils/TimeCalculator";
import { useAtom } from "jotai";
import {
  maxDateAtom,
  minDateAtom,
  openCalendarAtom,
  pageTitleAtom,
} from "../../atoms";
import { savePageInHistory } from "../../utils/nav";
import LoadingSpinner from "../LoadingSpinner";

type CalendarPickerProps = {
  size?: "small" | "medium" | "large" | "xlarge" | "xxlarge";
  style?: {};
  text?: string;
  displayFutureDates?: boolean;
  limitToLastSevenDays?: boolean;
};

// We use AppStateContext to determine whether the calendar is open or not
// CalendarPickerCalender is the actual calendar that pops up
const CalendarPicker: FC<CalendarPickerProps> = ({
  size = "large",
  style,
  text,
  displayFutureDates,
  limitToLastSevenDays = false,
}) => {
  const [, setIsCalendarOpen] = useAtom(openCalendarAtom);
  const [, setIsMaxDate] = useAtom(maxDateAtom);
  const [, setIsMinDate] = useAtom(minDateAtom);

  const { isLargeAndUp } = useResponsive();

  useEffect(() => {
    if (!displayFutureDates) {
      setIsMaxDate(true);
    }
    if (limitToLastSevenDays) {
      setIsMinDate(true);
    }
  }, []);

  return (
    <Pressable
      nativeID="calendarToggle"
      onPress={() => setIsCalendarOpen((prev) => !prev)}
      style={[style, styles.alignCenter]}
    >
      {text ? <RText>{text}</RText> : null}
      <Icon
        name={isLargeAndUp ? "calendar" : "calendarCircled"}
        size={size}
        fill="palette.rtv.secondary"
      />
    </Pressable>
  );
};

export const CalendarPickerCalendar = ({ navRef }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useAtom(openCalendarAtom);
  const [isMaxDate] = useAtom(maxDateAtom);
  const [isMinDate] = useAtom(minDateAtom);
  const [selectedDate, setSelectedDate] = useState("");
  const { isLargeAndUp } = useResponsive();

  const [, setPageTitle] = useAtom(pageTitleAtom);

  const closeModalRef = useCloseFromOutside(
    isCalendarOpen,
    (value: boolean) => setIsCalendarOpen(value),
    "calendarToggle"
  );

  const SEVEN_DAYS_AGO = new Date(
    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
  ).toDateString();

  return (
    <SlideInAnimation
      ref={closeModalRef}
      direction="vertical"
      trigger={isCalendarOpen}
      style={[styles.slideInView, { paddingBottom: isLargeAndUp ? 0 : 20 }]}
    >
      <Suspense fallback={<LoadingSpinner />}>
        <Calendar
          minDate={isMinDate ? SEVEN_DAYS_AGO : undefined} // 7 days ago
          onDayPress={(day) => {
            setSelectedDate(day.dateString);
            setIsCalendarOpen(false);
            navRef.setParams({ date: YYYYMMDDWithTimeZone(day.dateString) });
            savePageInHistory();
            setPageTitle(day.dateString);
          }}
          // hideExtraDays={true}
          renderArrow={(direction) => {
            return (
              <Icon
                name="chevronRight"
                size={"small"}
                fill={"palette.rtv.secondary"}
                rotate={direction == "left" && 180}
              />
            );
          }}
          maxDate={isMaxDate ? new Date().toDateString() : null}
          markedDates={{
            [selectedDate]: {
              selected: true,
              selectedColor: colors.palette.rtv.secondary,
              selectedTextColor: "white",
            },
          }}
          markingType="custom"
          style={styles.calendar}
          headerStyle={{
            backgroundColor: colors.palette.rtv.mainBackground,
          }}
          theme={{
            // backgroundColor: colors.palette.rtv.mainBackground,
            // calendarBackground: colors.palette.rtv.mainBackground,
            dayTextColor: colors.palette.rtv.primary,
            todayTextColor: colors.palette.rtv.secondary,
            textDisabledColor: colors.palette.neutral.x200,
            monthTextColor: colors.palette.rtv.secondary,
            textDayFontWeight: "700",
            textMonthFontWeight: "bold",
            textMonthFontFamily: "Montserrat_700Bold",
            textDayFontFamily: "Montserrat_700Bold",
            textDayHeaderFontWeight: "bold",
            textDayFontSize: font.size.md,
            textMonthFontSize: font.size.lg,
            selectedDayTextColor: colors.palette.rtv.secondary,
            textDayHeaderFontSize: font.size.sm,
          }}
        />
      </Suspense>
    </SlideInAnimation>
  );
};

const { colors, font } = variables;

const styles = StyleSheet.create({
  calendar: {
    height: 370,
    borderRadius: 4,
    shadowColor: colors.palette.rtv.black,
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: 5,
  },
  alignCenter: {
    alignItems: "center",
  },
  slideInView: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
});

export default CalendarPicker;
