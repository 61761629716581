import { useEffect, useState } from "react";
import { MemberPlans, Plan as PlanT } from "../../../../types/memberPlans";
import { getMemberPlans } from "../../../../client/watch/live";
import { ErrorState, LoadingState } from "../../../States/states";
import { Platform } from "react-native";
import { Plan } from "./Plan";
import GridLayout from "../../../Layout/GridLayout/GridLayout";
import CmsContent from "../../../cms/CmsContent";
import { useAtom } from "jotai";
import { cmsDataAtom } from "../../../../atoms";

type Props = {
  limit?: number;
};

export const PlansSection = ({ limit }: Props) => {
  // hide plans on ios
  if (Platform.OS === "ios") return;

  const [plans, setPlans] = useState<PlanT[]>();
  const [error, setError] = useState(null);
  const [contentLoading, setContentLoading] = useState(true);
  const [cmsData] = useAtom(cmsDataAtom);

  const fetchPlans = async () => {
    getMemberPlans()
      .then((res: MemberPlans) => setPlans(res.available_plans as PlanT[]))
      .catch((err) => setError(err))
      .finally(() => setContentLoading(false));
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  // show loading state if data fetch is not complete
  if (contentLoading) return <LoadingState name="MemberPlansLoader" />;

  // show error state if data fetch is complete and there is an error
  if (error)
    return <ErrorState message="Sorry, could not retrieve member plans." />;

  // hide plans if there are no plans returned from the api
  // grid layout doesn't play so nice on native :(
  if (!plans) return;

  if (Platform.OS === "web")
    return (
      <GridLayout styleOverrides={{ paddingTop: 0 }}>
        {plans
          .slice(0, limit || plans.length)
          .filter((p) => {
            if (
              p.name === "Free Day Pass" &&
              !cmsData?.settings?.free_day_pass_enabled
            ) {
              return false;
            } else return true;
          })
          .map((plan, index) => (
            <Plan key={index} {...plan} />
          ))}
      </GridLayout>
    );

  // show content if data fetch is complete and data is available
  return plans
    .slice(0, limit || plans.length)
    .map((plan, index) => <Plan key={index} {...plan} />);
};
