import React from "react";
import { View, ScrollView, Platform, TouchableOpacity } from "react-native";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Bugsnag from "@bugsnag/expo";
import RText from "../components/RText/RText";
import Gap from "../components/Gap/Gap";
import Icon from "../components/Icon/Icon";
import RButton from "../components/Buttons/RButton";
import * as Application from "expo-application";
import { openStore, isStoreVersionNewer } from "./store";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, isStoreVersionNewer: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // don't log in dev
    if (__DEV__) return;

    // log to bugsnag
    // error string eg: Cannot read properties of undefined (reading 'fractional') in Antepost with params {"month":"nov","year":"2023"}
    Bugsnag.notify(
      new Error(
        `${error.message} in ${
          this.props.currentRoute
        } with params ${JSON.stringify(
          this.props.nav.current?.getCurrentRoute()?.params
        )}`
      )
    );
  }

  componentDidMount() {
    if (Platform.OS !== "web") {
      isStoreVersionNewer().then((isNewer) => {
        this.setState({ isStoreVersionNewer: isNewer });
      });
    }
  }

  navOverride = (screen) => {
    this.setState({ hasError: false }, () => {
      setTimeout(() => {
        this.props.nav.current?.navigate(screen);
      }, 1);
    });
  };

  render() {
    if (this.state.hasError) {
      return (
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1 }}>
          <Header navOverride={this.navOverride} />
          <View
            style={{
              minHeight: 500,
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <View
              style={{
                maxHeight: 200,
                width: "60%",
                maxWidth: 200,
                flex: 1,
              }}
            >
              <Gap vertical size="small" />
              <Icon name="rLogo" />
              <Gap vertical size="small" />
            </View>
            <Gap vertical size="small" />
            <RText size="md" family="secondary" weight="bold">
              Apologies, something went wrong
            </RText>
            {Platform.OS != "web" && isStoreVersionNewer ? (
              <>
                <Gap vertical size="small" />
                <RButton
                  title="Get Latest Update"
                  onPress={() => openStore("ErrorBoundary")}
                />
                <Gap vertical size="small" />
                <RText>
                  Application Version {Application.nativeApplicationVersion}
                </RText>
              </>
            ) : (
              <>
                <Gap vertical size="small" />
                <TouchableOpacity onPress={() => this.navOverride("Home")}>
                  <RText color="secondary" weight="semiBold" size="lg">
                    Return to Home
                  </RText>
                </TouchableOpacity>
              </>
            )}
          </View>
          <Footer navOverride={this.navOverride} />
        </ScrollView>
      );
    }

    return this.props.children;
  }
}
