import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import ScrollBar from "../../../../ScrollBar";
import Gap from "../../../../Gap";
import variables from "../../../../../styles/variables";
import MultilineTextLoader from "./MultilineTextLoader";

const { spacing } = variables;

export default function UpcomingRacesLoader() {
  const [contentListItemWidth] = useState(0);

  return (
    <View style={styles.contentScrollBar}>
      <ScrollBar childWidth={contentListItemWidth}>
        {[0, 1, 2, 3, 4].map((idx) => (
          <View style={styles.scrollBarItem} key={idx}>
            <MultilineTextLoader
              numberOfLines={1}
              lineHeight={15}
              width={"80%"}
            />
            <MultilineTextLoader numberOfLines={2} lineHeight={12} />
            <Gap vertical />
          </View>
        ))}
      </ScrollBar>
    </View>
  );
}

const styles = StyleSheet.create({
  contentScrollBar: {
    marginBottom: spacing.xsmall,
    minHeight: 115,
    maxHeight: 115,
  },
  scrollBarItem: {
    backgroundColor: variables.colors.palette.neutral.x0,
    minHeight: 100,
    maxHeight: 100,
    borderRadius: 4,
    marginRight: 20,
    padding: 8,
    minWidth: 240,
    maxWidth: 240,
    marginTop: 15,
  },
});
