import Constants from "expo-constants";
import { APIResponse } from "../types/responses/APIResponse";
import { Platform } from "react-native";
import { getJwtToken, removeJwtToken } from "../components/RWebView/Utils";
import Config from "../../Config";
import { STORAGE_KEYS, storage } from "../utils/storage";
import { getDataFromStorage } from "../components/Screens/Account/components/Utils";
import { CacheManager } from "./cache/cache-manager";

const envs = {
  test: "test",
  staging: "https://api.new-staging.racingtv.com/", //"http://192.168.1.4:4100/", // ,
  production: "https://api.racingtv.com/",
};

const frontendUrls = {
  test: "http://localhost:19006",
  staging: "https://main.racing-tv.pages.dev",
  production: "https://racingtv.com",
};

const membersAppUrl = {
  test: "http://localhost:19007",
  staging: "https://members-staging.racingtv.com",
  production: "https://members-pre-prod.onpace-aws.racingtv.com", // TODO - Launch: this will need to be updated to http://members.racingtv.com
};

const clubDaysUrl = {
  test: "http://localhost:19006",
  staging: "https://cms.new-staging.racingtv.com/members/club-days/forms",
  production: "https://cms.racingtv.com/members/club-days/forms",
};

const API_KEY = Config.API_KEY || Constants.expoConfig.extra.API_KEY;
export const APP_ENV = Config.APP_ENV || Constants.expoConfig.extra.API_ENV;

// prettier-ignore
export const BASE_URL = Constants.expoConfig.extra.API_URL ||
                        envs[APP_ENV];

export const FRONTEND_URL = frontendUrls[APP_ENV];

// prettier-ignore
export const MEMBERS_APP_URL = Constants.expoConfig.extra.API_URL_MEMBERS ||
                               membersAppUrl[APP_ENV];

// prettier-ignore
export const CLUB_DAYS_URL = Constants.expoConfig.extra.CLUB_DAYS_URL ||
                             clubDaysUrl[APP_ENV];

export const formatQueryParams = (
  queryParams: { [key: string]: any | any[] } = {}
) => {
  const items: { [key: string]: any | any[] } = { ...queryParams };

  // remove any values which are undefined
  Object.keys(items).forEach((key) =>
    items[key] === undefined ? delete items[key] : {}
  );

  // Create a flat array of key-value pairs
  const keyValuePairs = Object.entries(items).flatMap(([key, value]) => {
    if (Array.isArray(value)) {
      return value.map((val) => [`${key}[]`, val]);
    } else {
      return [[key, value]];
    }
  });

  // return as url encoded string
  return keyValuePairs
    .map(
      ([k, v]) => encodeURIComponent(k) + "=" + encodeURIComponent(String(v))
    )
    .join("&");
};

export const formatJsonParams = (queryParams: { [key: string]: any } = {}) => {
  const items: any = { ...queryParams };

  // remove any values which are undefined
  Object.keys(items).forEach((key) =>
    items[key] === undefined ? delete items[key] : {}
  );

  // Convert object to JSON string and then URL-encode the string
  const planStr = encodeURIComponent(JSON.stringify(items));

  return planStr;
};

const getHeaders = async () => {
  const JWT_TOKEN = await getJwtToken();
  const headers: any = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "API-KEY": API_KEY,
    "X-Requested-With": `racingtv-${Platform.OS}/${Constants.expoConfig.version}`,
    Authorization: JWT_TOKEN ? `Bearer ${JWT_TOKEN}` : "",
  };

  // remove api key from headers in on web
  if (Platform.OS === "web") delete headers["API-KEY"];

  return headers;
};

const handleError = async (res: APIResponse<any>, url: string = undefined) => {
  if (res.meta.status === 401) {
    // Here we validate the token and remove it if it's invalid, and remove any user details we've got
    // so that login buttons show
    const jwtToken = await getJwtToken();
    if (jwtToken) {
      // token is invalid, remove it and user details
      console.log("REMOVING TOKEN", jwtToken);
      await removeJwtToken();
      await storage.remove({
        key: STORAGE_KEYS.USER_DETAILS,
      });
    } else {
      // no jwt
      const userDataObj = await getDataFromStorage();
      const userData = userDataObj && Object.keys(userDataObj).length;
      if (userData) {
        // but we've got user data, remove user data so login buttons show etc.
        await storage.remove({
          key: STORAGE_KEYS.USER_DETAILS,
        });
      }
    }
    // handle member endpoint errors
    if (url && url.includes("member")) {
      throw new Error("UNAUTHORISED");
    }
  }

  // error code and message can come in different shapes, unpack here
  if (res.meta.status > 299 && res.meta.status !== 402) {
    // throw new Error(res.error?.text || res.error?.message || res.meta.code);
    console.log(
      "ERROR",
      res.error?.text || res.error?.message || res.meta.code
    );
  }

  // TODO: //once staging is online, integrate bugsnag and push any 400^ errors to it
};

export const fetchGet = async (
  url,
  queryParams = {},
  mustBeAuthed = false,
  additionalHeaders: { [key: string]: any } = {}
) => {
  const headers = await getHeaders();

  const isAuthed = !!headers.Authorization;
  if (mustBeAuthed && !isAuthed) return;

  const useQuestionMark = url.includes("?") ? "&" : "?";
  const queryString = formatQueryParams(queryParams);
  const reqUrl = `${BASE_URL}${url}${useQuestionMark}${queryString}`;

  if (Platform.OS === "web") {
    return await fetchGetWeb({ ...headers, ...additionalHeaders }, reqUrl);
  } else {
    return await fetchGetNative({ ...headers, ...additionalHeaders }, reqUrl);
  }
};

const fetchGetWeb = async (headers, reqUrl) => {
  // Make the network request if no cache is available or it's expired
  const response = await fetch(reqUrl, { headers });
  const res = await response.json();
  return res;
};

const fetchGetNative = async (headers, reqUrl) => {
  const cacheKey = `cache_${encodeURIComponent(reqUrl)}`;

  // Attempt to fetch from cache first
  const cachedData = await CacheManager.getItem(cacheKey);
  if (cachedData !== null) {
    console.log("[NetClient] Returning cached response for:", reqUrl);
    return cachedData;
  }

  // Make the network request if no cache is available or it's expired
  const response = await fetch(reqUrl, { headers });
  const res = await response.json();

  // Assuming handleError is defined elsewhere
  await handleError(res, response.url);

  // Parse cache-control header and cache the response accordingly
  const cacheControl = response.headers.get("Cache-Control");
  const maxAge = CacheManager.parseCacheControl(cacheControl);
  // console.log(
  //   `will ${
  //     maxAge && maxAge != 0 ? "" : "not"
  //   } cache : ${reqUrl} : maxAge ${maxAge} : cacheControl ${cacheControl}`
  // );
  if (maxAge && maxAge != 0) {
    await CacheManager.setItem(cacheKey, res, maxAge);
  }

  return res;
};

type Method = "PATCH" | "PUT" | "DELETE" | "POST";
export const fetchPost = async (
  url: string,
  queryParams: { [key: string]: any } = {},
  body: any,
  method: Method = "POST"
): Promise<any> => {
  const headers = await getHeaders();
  const reqUrl = `${BASE_URL}${url}?${formatQueryParams(queryParams)}`;

  return fetch(reqUrl, {
    method: method,
    headers: headers,
    body: JSON.stringify(body),
  }).then(async (r) => {
    const url = r.url;
    const res = await r.json();
    await handleError(res, url);
    return res;
  });
};
