import { StyleSheet, Pressable, Linking, View, Platform } from "react-native";
import React from "react";
import { useResponsive } from "../../hooks/useResponsive";
import { PromotionalMenuItem } from "../../types/cms";
import variables from "../../styles/variables";
import { RLink } from "../RLink/RLink";

const { spacing, colors } = variables;

const PromotionMessage = ({
  title,
  slug,
  handleLayout,
}: PromotionalMenuItem & { handleLayout?: Function }) => {
  const { isLargeAndUp, isMediumAndDown } = useResponsive();
  const [isHovered] = React.useState(false);

  return (
    <RLink
      href=""
      slug={slug}
      style={{ paddingRight: isMediumAndDown ? 10 : 0 }} // Apply title text styles here
      pressableStyle={[
        styles.container,
        isLargeAndUp && { marginRight: spacing.xsmall },
      ]}
      pressableOnLayout={handleLayout}
      size="sm"
      color="yellow"
      weight="bold"
      family="secondary"
      dynamicTextStyle={{
        onHover: "changeTextColor",
        onPressed: isHovered ? "changeTextColor" : "none",
      }}
      uppercase={true}
    >
      {title}
    </RLink>
  );
};

export default PromotionMessage;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: spacing.xxxsmall,
    backgroundColor: colors.palette.rtv.primary,
    width: "100%",
  },
});
