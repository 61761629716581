import React, { useCallback, useState } from "react";
import { useSetAtom } from "jotai";
import { LayoutChangeEvent, Platform } from "react-native";
import { headerHeightAtom } from "../atoms";

type UseSearchMarginTopHeight = (
  submenuHeight: number
) => [number, (event: LayoutChangeEvent) => void];

export const useSearchMarginTopHeight: UseSearchMarginTopHeight = (
  submenuHeight
) => {
  const [searchMarginTop, setSearchMarginTop] = useState(0);
  const setAtomHeaderHeight = useSetAtom(headerHeightAtom);
  const GRADIENT_HEIGHT = 5;
  const PLATFORM_ADDITIONAL_HEIGHT =
    Platform.OS === "web" ? GRADIENT_HEIGHT : 0;

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { height } = event.nativeEvent.layout;
      setSearchMarginTop(height - submenuHeight - PLATFORM_ADDITIONAL_HEIGHT);
      setAtomHeaderHeight(height);
    },
    [submenuHeight]
  );

  return [searchMarginTop, onLayout];
};
