// This component is used to animate the dropdown menu
// Props:
//  - children: React.ReactNode
//  - open?: boolean
//  - style?: ViewStyle
//  - startPoint?: number - this defines where the start of the dropdown occurs. It
//                          is usually half of the child height, but is different on web/mobile.
//                          I don't think its necessary for absolutely positioned children but may be wrong
//                          infact we need to calculate this dynamically. TODO
// Usage:
//  <AnimatedDropdownWrapper style={{ height: 0 }} open={open}>
//    {sampleData}
//  </AnimatedDropdownWrapper>
//
//  where sampleData is an array of React.ReactNode
//  The component will animate the height of the dropdown menu based on the number of children passed in
// see example usage in cosmos fixtures

import { Animated, Platform, View, ViewStyle } from "react-native";
import React, { useEffect, useRef, useState } from "react";

interface Props {
  children: React.ReactNode;
  open?: boolean;
  style?: ViewStyle;
  isSearch?: boolean;
  alwaysMountedOnWeb?: boolean;
}

const AnimatedDropdownWrapper = ({
  children,
  open,
  style,
  isSearch = false,
  alwaysMountedOnWeb = false,
}: Props) => {
  const [startPoint, setStartPoint] = useState<number | undefined>(undefined);
  const animatedValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (startPoint !== undefined) {
      Animated.timing(animatedValue, {
        toValue: open ? 1 : 0,
        duration: 150,
        useNativeDriver: true,
      }).start();
    }
  }, [open, startPoint]);

  const translateY = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [isSearch ? 0 : startPoint || 0, 0],
  });

  const scaleY = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });

  const opacity = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });

  const renderWrapper = () => (
    <View onLayout={(e) => setStartPoint(-e.nativeEvent.layout.height / 2)}>
      <Animated.View
        style={[
          {
            transform: [{ translateY }, { scaleY }],
            opacity,
          },
          style,
        ]}
      >
        {children}
      </Animated.View>
    </View>
  );

  if (alwaysMountedOnWeb) {
    return (
      <>
        {/* On web we always want the Dropdown to be mounted so wrap it in a view and make it hidden*/}
        {Platform.OS === "web" && (
          <View style={{ display: open ? "flex" : "none" }}>
            {renderWrapper()}
          </View>
        )}
        {Platform.OS !== "web" && open && renderWrapper()}
      </>
    );
  } else {
    return <>{open && renderWrapper()}</>;
  }
};

export default AnimatedDropdownWrapper;
