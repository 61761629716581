import { Dimensions, Platform, StyleSheet } from "react-native";
import { useResponsive } from "../../hooks/useResponsive";
import { AdvertDetails } from "../../types/advert";
import AdBanner from "./AdBanners/AdBanner";
import Gap from "../Gap";

type CenterAdvertProps = {
  gap?: boolean;
  ad?: AdvertDetails;
  onPress?: Function;
  header?: boolean;
};

export const CenterAdvert = ({
  gap,
  ad,
  onPress,
  header = false,
}: CenterAdvertProps) => {
  const { isSmallAndDown } = useResponsive();

  const fullNativeWidth =
    Platform.OS == "web" ? "100vw" : Dimensions.get("window").width + 6;

  const width = () => {
    // small screens
    if (Platform.OS === "ios")
      return { width: fullNativeWidth, marginLeft: -13 };

    // large screens
    return { maxWidth: 1160, width: "100%", alignSelf: "center" };
  };

  return (
    <>
      {gap && <Gap vertical />}
      <AdBanner
        onPress={onPress}
        style={[styles.centerAdvert, width()]}
        ad={ad}
        header={header}
      />
    </>
  );
};

const styles = StyleSheet.create({
  centerAdvert: {
    // justifyContent: "center",
    // alignItems: "center",
    aspectRatio: 8.14 / 1,
    marginVertical: 10,
  },
});
