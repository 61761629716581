import { useRef } from "react";
import { Animated, PanResponder } from "react-native";

export const useAnimatedTranslation = (
  direction: "horizontal" | "vertical" = "horizontal"
) => {
  const isVertical = direction === "vertical";
  const pan = useRef(new Animated.ValueXY()).current;

  // Not used atm but might be useful in the future
  const limits = useRef({ min: 0, max: 0 }).current;

  const startAnimation = (toValue: number) => {
    const value = isVertical ? { x: 0, y: toValue } : { x: toValue, y: 0 };
    pan.setValue(value);
    pan.flattenOffset();
  };

  const panResponder = useRef(
    PanResponder.create({
      onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        const { x, y } = pan;
        const value = isVertical
          ? { x: 0, y: y._value } // todo: find out another way to get the value
          : { x: x._value, y: 0 };
        pan.setOffset(value);
      },
      onPanResponderMove: Animated.event(
        [null, isVertical ? { dy: pan.y } : { dx: pan.x }],
        {
          useNativeDriver: false,
        }
      ),
      onPanResponderRelease: () => {
        pan.flattenOffset();
      },
    })
  ).current;
  return { panResponder, pan, limits, startAnimation };
};
