import { View } from "react-native";
import SectionHeader from "../../../SectionHeader/SectionHeader";
import ContentScrollBar from "../../../ScrollBar/ContentScrollBar";
import { fetchGet } from "../../../../client";
import { ContentSection, ContentType } from "../cmsTypes";
import CmsHero from "./CmsHero";
import { MagazineComponent } from "./MagazineComponent";
import { useResponsive } from "../../../../hooks/useResponsive";
import { YYYYMMDD } from "../../../../utils/TimeCalculator";
import Gap from "../../../Gap";
import { useRoute } from "@react-navigation/native";
import React from "react";

export const ContentSections = ({
  sections,
  only,
  except,
  loadingOverride,
}: {
  sections: ContentSection[];
  only?: ContentType;
  except?: ContentType[];
  loadingOverride?: boolean;
}) => {
  const { isMediumAndUp } = useResponsive();

  const today = new Date();
  const yesterday = today.setDate(today.getDate() - 1);
  const route = useRoute();
  const isOnHomePage = route.name === "Home";

  if (!sections?.length) return null;
  if (only) sections = sections.filter((s) => s.content_type == only);
  if (except)
    sections = sections.filter((s) => !except.includes(s.content_type));

  return (
    <React.Fragment>
      {sections
        .map((section) => {
          switch (section.content_type) {
            case "free_bets":
              return (
                <View key={`free-bets-${section.title}`}>
                  <SectionHeader
                    heading={section.title}
                    colorTheme={isOnHomePage ? "tertiary" : "secondary"}
                    showMore={{ navigateTo: "FreeBets", text: "View All" }}
                  />
                  <ContentScrollBar
                    fetchGet={fetchGet("offers")}
                    type={"FreeBets"}
                    key={`free-bets-${section.title}-scroll-bar`}
                    contentLoadingOverride={loadingOverride}
                  />
                </View>
              );

            case "hero":
              return (
                <View key={`member-hero-${section.description}`}>
                  <CmsHero section={section} />
                </View>
              );
            case "club_days":
              return <View key={`club-days-${section.description}`}></View>;
            case "members_benefits":
              return (
                <View key={`member-benefit-${section.title}`}>
                  <SectionHeader
                    heading={section.title}
                    colorTheme={isOnHomePage ? "tertiary" : "secondary"}
                    showMore={{
                      navigateTo: "Benefits",
                      text: "View All",
                    }}
                  />
                  <ContentScrollBar
                    fetchGet={fetchGet("member/benefits")}
                    type={"MembersBenefits"}
                    key={`member-benefit-${section.title}-scroll-bar`}
                    contentLoadingOverride={loadingOverride}
                  />
                </View>
              );
            case "members_magazine":
              return (
                <View key={`magazine-view-${section.title}`}>
                  <MagazineComponent />
                </View>
              );
            case "racecards":
              return (
                <View key={`racecards-${section.title}`}>
                  <SectionHeader
                    heading={section.title}
                    showMore={{ navigateTo: "Racecards", text: "View All" }}
                    size={isMediumAndUp ? "xl" : "lg"}
                    colorTheme="tertiary"
                  />
                  <ContentScrollBar
                    type="UpcomingRaces"
                    fetchGet={fetchGet("racing/racecards/upcoming/v2")}
                    key={`racecards-${section.title}-scroll-bar`}
                    contentLoadingOverride={loadingOverride}
                  />
                </View>
              );
            case "race_replays":
              return (
                <View key={`race-replays-${section.title}`}>
                  <SectionHeader
                    heading={section.title}
                    showMore={{ navigateTo: "Replays", text: "View All" }}
                    size={isMediumAndUp ? "xl" : "lg"}
                    colorTheme="tertiary"
                  />
                  <ContentScrollBar
                    type="Replays"
                    fetchGet={fetchGet(`videos/replays/latest`)}
                    key={`race-replays-${section.title}-scroll-bar`}
                  />
                </View>
              );

            case "demand_videos":
              return (
                <View key={`on-demand-videos-${section.title}`}>
                  <SectionHeader
                    heading={section.title}
                    showMore={{ navigateTo: "OnDemand", text: "View All" }}
                    size={isMediumAndUp ? "xl" : "lg"}
                    colorTheme="tertiary"
                  />
                  <ContentScrollBar
                    type="OnDemand"
                    fetchGet={fetchGet(`videos/on-demand/most-watched`)}
                    key={`on-demand-videos-${section.title}-scroll-bar`}
                    contentLoadingOverride={loadingOverride}
                  />
                </View>
              );
            case "news_articles":
              return (
                <View key={`news_articles-${section.title}`}>
                  <SectionHeader
                    heading={section.title}
                    showMore={{ navigateTo: "Latest", text: "View All" }}
                    colorTheme="tertiary"
                  />
                  <ContentScrollBar
                    type="NewsArticles"
                    fetchGet={fetchGet(`news/articles/latest`)}
                    contentLoadingOverride={loadingOverride}
                  />
                </View>
              );
            default:
              return <React.Fragment key={Math.random()}></React.Fragment>;
          }
        })
        // add gaps between sections - its not possible with a join
        .reduce(
          (prev: JSX.Element, curr: JSX.Element, idx: number): any => [
            prev || [],
            curr,
            <Gap vertical size="small" key={idx} />,
          ],
          []
        )}
    </React.Fragment>
  );
};
