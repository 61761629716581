import { isDevice } from "expo-device";
import {
  getPermissionsAsync,
  requestPermissionsAsync,
} from "expo-notifications";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

import { FIREBASE_VAPID_KEY, FIREBASE_CONFIG } from "../config";
import { registerMemberDevice } from "./registration";
import {
  getFirebaseStorageToken,
  setFirebaseStorageToken,
  unsetFirebaseStorageToken,
} from "./storage";
import { getJwtToken } from "../../../components/RWebView/Utils";

export const app = initializeApp(FIREBASE_CONFIG);
export const firebaseMessaging = () => {
  try {
    return getMessaging(app);
  } catch (error) {
    return null;
  }
};

export const requestDevicePermission = async () => {
  try {
    const firebaseMessagingInstance = firebaseMessaging();
    if (!firebaseMessagingInstance) return;

    const authToken = await getJwtToken();

    if (!authToken) return;
    if (!isDevice) return;

    const currentToken = await getFirebaseStorageToken();
    const { status: currentStatus } = await getPermissionsAsync();
    let finalStatus = currentStatus;

    if (currentStatus !== "granted") {
      const { status } = await requestPermissionsAsync();
      finalStatus = status;

      if (currentToken) {
        unsetFirebaseStorageToken();
      }
      return;
    }

    if (finalStatus !== "granted") return;

    if (currentToken) return;

    console.log("IS CONTINUED", currentToken, currentStatus);

    const token = await getToken(firebaseMessagingInstance, {
      vapidKey: FIREBASE_VAPID_KEY,
    });

    if (!token) return;

    const response = await registerMemberDevice(token);
    if (response.meta.status !== 200) {
      throw new Error();
    }

    setFirebaseStorageToken(token);
    console.log(">>>>>>>>>>>>>>>>>>>>>> STORED", token, currentStatus);
  } catch (error) {
    return;
  }
};
