import { View, StyleSheet } from "react-native";
import RText from "../RText";

type Props = {
  name: string;
  color: any;
  style?: any;
  textStyle?: any;
};

const CardLabel = ({ name, color, style, textStyle }: Props) => {
  return (
    <View style={[styles.cardLabel, { backgroundColor: color }, style]}>
      <RText size="xs" weight="semiBold" uppercase={true} style={textStyle}>
        {name}
      </RText>
    </View>
  );
};

const styles = StyleSheet.create({
  cardLabel: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: "auto",
  },
});

export default CardLabel;
