import React, { FC, ReactElement, useEffect, useRef } from "react";
import { StyleSheet, View, ScrollView, Animated, Platform } from "react-native";
import { useResponsive } from "../../hooks/useResponsive";
import Gap from "../Gap";
import variables from "../../styles/variables";
import Footer from "../Footer";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Filter from "../Filters/Filter";
import { FilterProps } from "../Filters/Filter";
import RHeading from "../RHeading/RHeading";
import { useAtom } from "jotai";
import {
  advertisingAtom,
  globalFullscreenAtom,
  appScrollRefAtom2,
} from "../../atoms";

import RText from "../RText";
import { useShowSubmenu } from "../../hooks/useShowSubmenu";
import { CenterAdvert } from "./CenterAdvert";
import { LoadingState } from "../States/states";
import lazyImport from "../../utils/lazyImport/lazyImport";
import { HeaderAdvert } from "./HeaderAdvert";
const SkyscraperAdvert = lazyImport(() => import("./SkyscraperAdvert"));

const { spacing, colors } = variables;

// handle sticky ad top margin on larger screens
const Wrapper = ({ children, footerHeight, noPadding }) => {
  const wrapperHeight = useRef(new Animated.Value(0)).current;
  const [adverts] = useAtom(advertisingAtom);
  const [isGlobalFullScreen] = useAtom(globalFullscreenAtom);
  const appScrollRef2 = useRef<ScrollView>(undefined);

  const [, setAppScrollRef2] = useAtom(appScrollRefAtom2);
  const menu = useShowSubmenu();
  const {
    isXSmallOnly,
    isXSmallAndUp,
    isSmallAndUp,
    isMediumAndUp,
    isLargeAndUp,
    isXLargeAndUp,
  } = useResponsive();

  const gutterMargin = () => {
    if (noPadding) return 0;
    if (isXLargeAndUp) return spacing.medium;
    if (isMediumAndUp) return spacing.small;
    if (isSmallAndUp) return spacing.small;
    if (isXSmallAndUp || isXSmallOnly) return spacing.xsmall;
  };

  const adaptivePadding = { paddingHorizontal: gutterMargin() };
  const adRequestComplete = Object.keys(adverts || {}).length > 0;

  // set appScrollRef2 once its defined inside a useEffect
  useEffect(() => {
    if (appScrollRef2?.current) setAppScrollRef2(appScrollRef2);
  }, [appScrollRef2]);

  if (Platform.OS === "web") {
    return (
      <View
        onLayout={(e) => wrapperHeight.setValue(e.nativeEvent.layout.height)}
        style={[
          styles.container,
          styles.webContainer,
          menu ? { marginTop: 0 } : { marginTop: 50 },
          adaptivePadding,
          !isLargeAndUp && { marginBottom: 20 },
          isLargeAndUp && { minWidth: 1200, maxWidth: 1400 },
        ]}
      >
        <View style={[styles.contentContainerWeb]}>{children}</View>

        {!adRequestComplete || (adRequestComplete && adverts.skyscraper) ? (
          <SkyscraperAdvert
            ad={adverts?.skyscraper}
            containerHeight={wrapperHeight}
            footerHeight={footerHeight}
          />
        ) : null}
      </View>
    );
  } else {
    const contentStyles = [styles.contentContainerMobile];

    isGlobalFullScreen
      ? contentStyles.push({ margin: 0 })
      : contentStyles.push({ marginHorizontal: spacing.xsmall });

    return (
      <ScrollView
        style={[styles.container, styles.nativeContainer]}
        contentContainerStyle={contentStyles}
        ref={appScrollRef2}
        scrollEventThrottle={16}
        overScrollMode="never"
        scrollEnabled={!isGlobalFullScreen}
      >
        {children}
        {!isGlobalFullScreen && (
          <RText
            size="xs"
            color="grey"
            family="secondary"
            style={{
              marginTop: -20,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              height: 40,
              width: "100%",
              textAlign: "center",
              paddingVertical: 20,
              marginBottom: 20,
            }}
          >
            Copyright {new Date().getFullYear()} Racing TV - All Rights
            Reserved.
          </RText>
        )}
      </ScrollView>
    );
  }
};

type LayoutProps = {
  content?: ReactElement;
  heading?: string | React.ReactNode;
  headingComponent?: ReactElement;
  subHeading?: string | React.ReactNode;
  filter?: FilterProps;
  pagination?: React.ReactNode;
  noPadding?: boolean;
  headingLoader?: boolean;
};

export const Layout: FC<LayoutProps> = ({
  content,
  heading,
  headingComponent,
  subHeading,
  pagination = null,
  filter,
  noPadding = false,
  headingLoader = true,
}) => {
  // handle sticky ad top margin on larger screens
  const footerHeight = useRef(new Animated.Value(0)).current;
  const [isGlobalFullScreen] = useAtom(globalFullscreenAtom);
  const { isMediumAndDown } = useResponsive();
  const [adverts] = useAtom(advertisingAtom);
  return (
    <>
      <Wrapper footerHeight={footerHeight} noPadding={noPadding}>
        <HeaderAdvert noPadding={noPadding} />
        <Breadcrumbs />
        <View>
          <View>
            {heading && !isGlobalFullScreen ? (
              <View style={[subHeading && styles.subHeading]}>
                <View
                  style={[
                    {
                      flexDirection: "row",
                      justifyContent: "space-between",
                    },
                    filter && isMediumAndDown ? { maxWidth: "60%" } : {},
                  ]}
                >
                  <RHeading level="1" uppercase>
                    {heading}
                  </RHeading>
                  {filter && filter.type != "dropdown" ? (
                    <Filter filter={filter} />
                  ) : null}
                </View>
                <Gap vertical size="xsmall" />

                {!!subHeading && (
                  <>
                    <RHeading level="2" color="grey">
                      {subHeading}
                    </RHeading>
                    <Gap vertical size="small" />
                  </>
                )}
              </View>
            ) : null}

            {headingComponent && !isGlobalFullScreen && (
              <View style={{ marginBottom: 20 }}>
                {headingComponent}
                {filter && filter.type != "dropdown" && (
                  <Filter filter={filter} />
                )}
              </View>
            )}

            {!heading &&
              !headingComponent &&
              headingLoader &&
              !isGlobalFullScreen && <LoadingState name="HeadingLoader" />}
          </View>

          {content}

          {!isGlobalFullScreen && filter && filter.type == "dropdown" ? (
            <Filter filter={filter} />
          ) : null}
        </View>

        {/* footer ad */}
        {!isGlobalFullScreen && adverts?.footer ? (
          <>
            <Gap vertical />
            <CenterAdvert ad={adverts.footer} />
          </>
        ) : (
          <Gap />
        )}
        {pagination && !isGlobalFullScreen && pagination}
        {Platform.OS == "web" && !isGlobalFullScreen ? (
          <RText
            size="xs"
            color="grey"
            family="secondary"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              height: 30,
              paddingTop: 10,
            }}
          >
            Copyright {new Date().getFullYear()} Racing TV - All Rights
            Reserved.
          </RText>
        ) : null}
      </Wrapper>

      {!isGlobalFullScreen && <Footer ref={footerHeight} />}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: colors.palette.rtv.mainBackground,
  },
  nativeContainer: {
    flexGrow: 1,
  },
  webContainer: {
    flexDirection: "row",
    height: "auto",
    minHeight: "70%",
    alignContent: "center",
    justifyContent: "center",
    borderRadius: 10,
    margin: "auto",
    marginBottom: 50, // height of sub menu
  },
  contentContainerWeb: {
    flex: 1,
    maxWidth: variables.layoutMaxWidth,
  },
  contentContainerMobile: {
    flexGrow: 1,
  },
});

export default Layout;
