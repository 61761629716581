import { Platform, StyleSheet, View } from "react-native";
import React, { FC } from "react";
import RText from "../RText";
import { useResponsive } from "../../hooks/useResponsive";
import Gap from "../Gap";
import { Runner } from "../../types/races/runner";
import { cleanSlug } from "../../utils/cleanSlug";
import TipIcon from "../Screens/Tips/components/TipIcon";
import { removeCommaAndChars } from "../../utils/removeCommaAndChars";
import RTouchableOpacity from "../RLink/RTouchableOpacity";
import { formatCountryCode } from "../../utils/race";
import { TrainerIconBadge, JockeyIconBadge } from "../../types/races/runner";
import TimeformIcon from "../Icon/TimeformIcon";

type RunnerDetailsProps = {
  runner: Runner;
  trainerBadges: TrainerIconBadge[];
  jockeyBadges: JockeyIconBadge[];
  displaysPremiumData: boolean;
};

const RunnerDetails: FC<RunnerDetailsProps> = ({
  runner,
  trainerBadges,
  jockeyBadges,
  displaysPremiumData,
}) => {
  const { isSmallAndDown } = useResponsive();

  const fontSize = isSmallAndDown ? "sm" : "md";

  const countryCode =
    runner.horse?.country_code?.racing &&
    runner?.horse?.country_code?.iso !== "gb" &&
    `(${formatCountryCode(runner.horse.country_code.racing)})`;

  return (
    <View
      style={[
        styles.container,
        {
          paddingTop: isSmallAndDown ? 7 : undefined,
          width: isSmallAndDown ? 160 : 228,
        },
      ]}
    >
      <View
        style={{
          justifyContent: "flex-start",
        }}
      >
        <RTouchableOpacity
          style={{
            flexDirection: "row",
          }}
          navScreen="IndexOrProfile"
          navParams={{
            type: "horse",
            slug: cleanSlug(runner?.horse?.slug),
          }}
          shouldOpenInNewTab={true}
        >
          <RText
            size={fontSize}
            numberOfLines={1}
            style={{
              ...(isSmallAndDown &&
                Platform.select({
                  web: { maxWidth: countryCode ? "70%" : "85%" },
                  native: { maxWidth: countryCode ? "80%" : "100%" },
                })),
            }}
            weight="bold"
          >
            {/* stonerabsfortomorrow */}
            {runner?.horse_name || runner.horse.name}
          </RText>
          <RText
            size={fontSize}
            style={{ alignSelf: "flex-start" }}
            weight="bold"
            uppercase
          >
            {" "}
            {countryCode}
          </RText>
          <RText weight="bold" size="xxs">
            {runner.days_since_last_run}
          </RText>
          <Gap size="xxsmall" />
          {runner?.text_badges?.length && !isSmallAndDown ? (
            <View style={{ flexDirection: "row" }}>
              {runner?.text_badges.map((tipTag, i) => (
                <TipIcon
                  tipTag={tipTag}
                  partOfList
                  key={`${runner?.horse_name || runner.horse.name}-${i}`}
                  overideTagmapLookup
                />
              ))}
            </View>
          ) : null}
        </RTouchableOpacity>
        {runner?.text_badges?.length && isSmallAndDown ? (
          <View style={{ flexDirection: "row" }}>
            {runner?.text_badges.map((tipTag, i) => (
              <TipIcon
                tipTag={tipTag}
                partOfList
                key={`${runner?.horse_name || runner.horse.name}-${i}`}
                overideTagmapLookup
              />
            ))}
          </View>
        ) : null}
        <View
          nativeID="trainerJockey"
          style={{
            flexDirection: isSmallAndDown ? "column" : "row",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <RTouchableOpacity
            style={styles.trainerJockey}
            navScreen="IndexOrProfile"
            navParams={{
              type: "jockey",
              slug: cleanSlug(runner?.jockey?.slug),
            }}
            shouldOpenInNewTab={true}
          >
            <RText color="secondary" style={{ marginRight: 4 }}>
              J:
            </RText>
            <RText numberOfLines={2}>
              {removeCommaAndChars(runner?.jockey_name || runner?.jockey?.name)}
              {!!runner?.apprentice_claim && runner.apprentice_claim != 0 && (
                <RText> ({runner.apprentice_claim}lb)</RText>
              )}
            </RText>
            {displaysPremiumData &&
              jockeyBadges?.map((badge, i) => (
                <TimeformIcon badge={badge} key={i} jockey />
              ))}
          </RTouchableOpacity>
          <RTouchableOpacity
            style={styles.trainerJockey}
            navScreen="IndexOrProfile"
            navParams={{
              type: "trainer",
              slug: cleanSlug(runner?.trainer?.slug),
            }}
            shouldOpenInNewTab={true}
          >
            <RText color="secondary" style={{ marginRight: 4 }}>
              T:
            </RText>
            <RText numberOfLines={2}>
              {removeCommaAndChars(runner?.trainer_name || runner.trainer.name)}
            </RText>
            <View style={{ flexDirection: "row" }}>
              {displaysPremiumData &&
                trainerBadges?.map((badge, i) => (
                  <TimeformIcon badge={badge} key={i} trainer />
                ))}
            </View>
          </RTouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default RunnerDetails;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingLeft: 10,
  },
  trainerJockey: {
    flexDirection: "row",
    marginRight: 10,
    maxWidth: 165,
  },
});
