import React from "react";
import { ActivityIndicator } from "react-native";
import variables from "../../styles/variables";

const LoadingSpinner = (props) => {
  return (
    <ActivityIndicator
      size="large"
      color={variables.colors.palette.rtv.secondary}
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginVertical: 20,
      }}
      {...props}
    />
  );
};

export default LoadingSpinner;
