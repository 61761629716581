import React, { useState, useEffect } from "react";
import { fetchGet } from "../../../client";
import { APIResponse } from "../../../types/responses/APIResponse";
import RacecardRunnerRow from "../../Screens/Races/Racecards/components/RacecardRunnerRow";
import RacecardRowLoader from "../../States/Loading/Loaders/Races/RacecardRowLoader";

const RunnerAttachment = ({ id }) => {
  const [data, setData] = useState<any>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res: APIResponse<any> = await fetchGet(
          `/racing/runners/${id}/attachment`
        );
        setData(res);
      } catch (err) {
        setError("Error loading runner: " + err?.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) return <RacecardRowLoader />;

  if (!data || error) return;

  return <RacecardRunnerRow runner={data.runner} />;
};

export default RunnerAttachment;
