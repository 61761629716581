import React, { useState } from "react";
import { Image, View, Linking, Pressable, Platform } from "react-native";
import RText from "../../RText";

type Props = {
  id: string;
  metadata: any;
  caption?: string;
  href?: string;
};

const MediaAsset = ({ id, metadata, caption, href }: Props) => {
  const [imageWidth, setImageWidth] = useState(500); //Dimensions.get("window").width);
  const [imageHeight, setImageHeight] = useState(300); // default height

  const handleImageLoaded = (event) => {
    if (!event.nativeEvent.source) return;
    const width = event.nativeEvent.source.width;
    const height = event.nativeEvent.source.height;
    if (width && height) {
      const aspectRatio = height / width;
      setImageHeight(imageWidth * aspectRatio);
    }
  };

  if (href && href.length) {
    if (Platform.OS === "web") {
      return (
        <a href={href} target="_blank" style={{ textDecoration: "none" }}>
          <ImageComponent
            metadata={metadata}
            caption={caption}
            imageWidth={imageWidth}
            handleImageLoaded={handleImageLoaded}
          />
        </a>
      );
    }
    return (
      <Pressable
        onPress={() => Linking.openURL(href)}
        style={({ pressed }) => [
          pressed ? { opacity: 0.5 } : {}, // Apply lower opacity when pressed
        ]}
      >
        <ImageComponent
          metadata={metadata}
          caption={caption}
          imageWidth={imageWidth}
          handleImageLoaded={handleImageLoaded}
        />
      </Pressable>
    );
  } else
    return (
      <ImageComponent
        metadata={metadata}
        caption={caption}
        imageWidth={imageWidth}
        handleImageLoaded={handleImageLoaded}
      />
    );
};

const ImageComponent = ({
  metadata,
  caption,
  imageWidth,
  handleImageLoaded,
}: {
  metadata: any;
  caption?: string;
  imageWidth: number;
  handleImageLoaded: (event: any) => void;
}) => {
  return (
    <>
      <Image
        source={{
          uri:
            metadata?.viewer_sources && metadata?.viewer_sources.length
              ? metadata?.viewer_sources[0]?.url
              : metadata?.placeholder_image_url || null,
        }}
        style={{
          width: imageWidth,
          minWidth: "100%",
          maxWidth: "100%",
          aspectRatio: 16 / 9,
          // maxHeight: imageHeight, <-- the images look better without this on benefits pages, see rewards4racing.
          backgroundColor: "#f2f2f2",
        }}
        onLoad={handleImageLoaded}
        resizeMode="contain"
      />
      <View style={{ display: "flex", alignItems: "center" }}>
        {caption && (
          <RText size="xs" color="grey">
            {caption || "caption"}
          </RText>
        )}
      </View>
    </>
  );
};

export default React.memo(MediaAsset);
