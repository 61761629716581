import { onMessage } from "firebase/messaging";
import { firebaseMessaging } from "../device/index";

export const onMessageListener = () => {
  const firebaseMessagingInstance = firebaseMessaging();
  if (!firebaseMessagingInstance) return;

  onMessage(firebaseMessagingInstance, async (payload) => {
    if (!("Notification" in window)) {
      console.error("This browser does not support notifications");
      return false;
    }

    if (Notification.permission === "denied") return false;

    const { notification } = payload;

    const showNotification = () => {
      const message = new Notification(notification.title, {
        body: notification.body,
        icon: notification.image,
      });
      return message;
    };

    if (Notification.permission === "granted") {
      const notification = showNotification();
      return {
        nativeToastRef: notification,
      };
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const notification = showNotification();
          return {
            nativeToastRef: notification,
          };
        }
      });
    }
    return true;
  });
};
