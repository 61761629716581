import React from "react";
import { useEffect, useState } from "react";
import { Platform, View, StyleSheet } from "react-native";

// This system scales iframe banner and skyscraper ads only for any web platform, including
// ios/android web.
//
// To be clear, that means:
//   ios native (unsupported - can't support due to lack of required transformOrigin css rule)
//   android native (unsupported - can't support due to lack of required transformOrigin css rule)
//
// It works by scaling the ad itself by a factor of its container. i.e if the add is 200x100px,
// and the container is 400 px wide, then the ad is scaled by 2x. the height of the container
// is calculated as the original ad height multiplied by scale factor, so that there's no cut
// off or gap.

// TODO's:
// - any way of fixing when zoom is running from root html css?
// - add notes about how exactly this works
const WebIframe = ({ src, width, height }) => {
  const [scale, setScale] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);

  if (Platform.OS !== "web") return null;

  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call the handler to ensure correct scaling on mount
    handleResize();

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerWidth]); // Dependency on containerWidth to recreate the handler when containerWidth changes

  const handleResize = () => {
    updateScale(containerWidth);
  };

  const updateScale = (containerWidth) => {
    const scaleX = containerWidth / width;

    setScale(scaleX);
  };

  return (
    <View
      style={[
        styles.container,
        {
          maxHeight: `${height * scale}px`,
          minHeight: `${height * scale}px`,
          maxWidth: "100%",
          minWidth: "100%",
        },
      ]}
      onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
    >
      <iframe
        src={src}
        frameBorder={0}
        scrolling="no"
        style={{
          // backgroundColor: "blue", // debug
          transform: `scale(${scale})`,
          transformOrigin: "top left",
          width: `${width}px`,
          height: `${height}px`,
        }}
      />
    </View>
  );
};

export default WebIframe;

const styles = StyleSheet.create({
  container: {
    overflow: "hidden",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginVertical: 10,
    // backgroundColor: "green", // debug
  },
});
