import React from "react";
import { View, Text, Platform } from "react-native";
import RHeading from "../RHeading/RHeading";
import Gap from "../Gap";
import variables from "../../styles/variables";
import {
  formatRaceTime,
  getFriendlyDateString,
} from "../../utils/TimeCalculator";
import { removeCountry } from "../../utils/removeCountry";

const { weight: fw, family: ff } = variables.font;

type Props = {
  titleFull: string;
  trackCondition: string;
  startime?: string;
  trackName?: string;
};

export const formatTitleShort = (
  startTime: string,
  trackName: string
): string => {
  if (!startTime || !trackName) return "";
  return `${formatRaceTime(startTime)} ${removeCountry(
    trackName
  )} - ${getFriendlyDateString(startTime)}`;
};

// To display short title we need to define startime AND trackName as props
// If not, only full title will be displayed as needed for individualResults

const RaceSubHeading = ({
  titleFull,
  trackCondition,
  startime,
  trackName,
}: Props) => {
  trackCondition = !!trackCondition ? trackCondition : "";
  titleFull = !!titleFull ? titleFull : "";

  const regex = /\([^)]*\)/g;
  const parenthesis = titleFull?.match(regex);
  const up = parenthesis?.filter((p) => !p.includes("runner"));
  const boldTitle = titleFull?.split("(")[0];
  const titleUp = boldTitle + up?.join(" ");
  const titleDown = trackCondition + " " + titleFull?.slice(titleUp.length);

  return (
    <View>
      {startime && trackName && (
        <>
          <RHeading level="1" uppercase>
            {formatTitleShort(startime, trackName)}
          </RHeading>
          <Gap vertical size="xxsmall" />
        </>
      )}
      <View>
        <Text>
          <RHeading boldText level="3">
            {boldTitle.capitalize()}
            <Text style={{ fontFamily: `${ff["secondary"]}_${fw["light"]}` }}>
              {up?.join(" ")}
            </Text>
          </RHeading>
        </Text>
        <Gap size="xxsmall" vertical />
        <RHeading level="3">{titleDown}</RHeading>
      </View>
      {Platform.OS == "web" ? <Gap vertical size="small" /> : null}
    </View>
  );
};

export default RaceSubHeading;
