import { useEffect } from "react";
import { Buffer } from "buffer";
import { Platform } from "react-native";
import { Environment, Foundation } from "@comapi/sdk-js-foundation";
import { ComapiConfig } from "@comapi/sdk-js-foundation";
import { useAtom } from "jotai";
import JWT from "expo-jwt";
import { dotDigitalSdkAtom, userAtom } from "../atoms";
import { getDeviceId } from "../utils/getDeviceId";
import { localStorageShim } from "../utils/localStorageShim";
import useRefreshUser from "./useRefreshUser";
import useUser from "./useUser";
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";

const ALG = "HS256";
const SPACE_ID = "3a277b82-c569-42da-8fa7-67ecac566971";
const SHARED_SECRET = "06794a0bcabe429985d8a985ddd69681";

// Assign your shim to global scope if the library you're adapting uses global localStorage
if (Platform.OS != "web") global.localStorage = localStorageShim;

global.atob = (data) => Buffer.from(data, "base64").toString("binary");
global.btoa = (binary) => Buffer.from(binary, "binary").toString("base64");

async function checkNotificationPermissions() {
  const settings = await Notifications.getPermissionsAsync();
  const hasNotificationPermissions =
    settings.granted ||
    settings.ios?.status === Notifications.IosAuthorizationStatus.PROVISIONAL;

  console.log("[Dot Digital] PERMISSIONS::::", hasNotificationPermissions);
  return hasNotificationPermissions;
}

async function getPushToken(retryCount = 5, delay = 4000) {
  let nativeToken = null;

  while (retryCount > 0) {
    try {
      const result = await Notifications.getDevicePushTokenAsync();
      nativeToken = result.data;

      if (nativeToken) {
        break; // Token obtained, exit the loop
      }
    } catch (error) {
      console.error("[Dot Digital] Error obtaining native token:", error);
    }

    retryCount--;

    if (retryCount > 0) {
      await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
    }
  }

  if (!nativeToken) {
    console.error(
      "[Dot Digital] Failed to obtain registration ID for push notifications"
    );
  }

  return nativeToken;
}

const useDotDigital = () => {
  if (!Device.isDevice) {
    console.log("[DotDigital] not available on simulators.");
    return;
  }

  const [dotDigitalSdk, setDotDigitalSdk] = useAtom(dotDigitalSdkAtom);
  const [user, setUser] = useAtom(userAtom);
  const { isLoggedIn } = useUser();
  const { refreshUser } = useRefreshUser();

  useEffect(() => {
    (async function updateDotDigitalProfile() {
      // return early if dotDigitalSdk is not available
      if (!dotDigitalSdk) return;

      const profile = await dotDigitalSdk.services.profile.getMyProfile();
      const isAnon = profile?.email?.startsWith("anon-");

      // update anon contact with email
      if (isLoggedIn && isAnon) {
        await dotDigitalSdk.current?.services.profile.updateMyProfile(
          user.detail.email
        );
      }
    })();
  }, [user, dotDigitalSdk]);

  useEffect(() => {
    if (Platform.OS === "web") return;
    const hasNotificationPermissions = checkNotificationPermissions();

    if (!hasNotificationPermissions) {
      console.error(
        "[Dot Digital] User does not have notification permissions"
      );
      return;
    }

    const prepare = async () => {
      await refreshUser();

      const sdk = await Foundation.initialise(comapiConfig);
      if (sdk?.session?.isActive) {
        await sdk
          .endSession()
          .then((sessionEnded) => {
            console.log("[Dot Digital] Session Ended:::", sessionEnded);
          })
          .catch((e) => {
            console.error(
              "[Dot Digital] Error ending session::::",
              JSON.stringify(e, null, 2)
            );
          });
      }

      setDotDigitalSdk(sdk);

      const deviceId = await getDeviceId();

      const session = await sdk.startSession();

      const profile = await sdk.services.profile.getMyProfile();

      if (user?.detail?.email) {
        profile.email = user.detail.email;
      } else {
        profile.email = `anon-${deviceId}@yopmail.com`;
      }

      console.log("[Dot Digital] Email: ", `${profile.email}`);
      console.log("[Dot Digital] Profile ids: ", profile.id, profile.profileId);
      await sdk.services.profile.updateMyProfile(profile);

      const registrationId = await getPushToken();
      if (!registrationId) {
        console.error(
          "[Dot Digital] Failed to obtain registration ID for push notifications"
        );
        return;
      }

      if (Platform.OS === "ios") {
        try {
          await sdk.device.setAPNSPushDetails(
            "com.racinguk.RacingUK",
            Environment.production,
            registrationId
          );
          console.log("[Dot Digital] setAPNSPushDetails() succeeded");
        } catch (error) {
          console.error("[Dot Digital] setAPNSPushDetails() failed: ", error);
        }
      } else if (Platform.OS === "android") {
        try {
          await sdk.device.setFCMPushDetails(
            "com.racinguk.app",
            registrationId
          );
          console.log("[Dot Digital] setFCMPushDetails() succeeded");
        } catch (error) {
          console.error(
            "setFCMPushDetails() failed: ",
            JSON.stringify(error, null, 2)
          );
        }
      }
    };

    prepare();
  }, [user?.email]);
};

async function challengeHandler(options, answerAuthenticationChallenge) {
  const deviceId = await getDeviceId();

  var oPayload = {
    sub: deviceId,
    nonce: options.nonce,
    aud: "https://api.comapi.com",
    iss: "https://api.comapi.com/defaultauth",
    iat: Date.now(),
    exp: Date.now() + 24 * 60 * 60 * 1000,
  };

  const jwt = JWT.encode(oPayload, SHARED_SECRET, { algorithm: ALG });

  answerAuthenticationChallenge(jwt);
}

let comapiConfig = new ComapiConfig()
  .withApiSpace(SPACE_ID)
  .withLogPersistence(0)
  .withAuthChallenge(challengeHandler);

export default useDotDigital;
