export const FIREBASE_VAPID_KEY = `BKurwrIXigxd5A9qCAusxQ6rFbU5pYkP5t626iRYy2HOqnquKrtLwqjjm6mKBzJOB6fuUbQPG8phK1bF3F0KqO8`;
export const FIREBASE_PROJECT_ID = "racing-uk";
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyB3yvc6pfd0VqHJlJvuK7-q3YwEdqAUFYg",
  authDomain: "racing-uk.firebaseapp.com",
  databaseURL: "https://racing-uk.firebaseio.com",
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: "racing-uk.appspot.com",
  messagingSenderId: "272531473920",
  appId: "1:272531473920:web:2ea835186cdf6fe720bae5",
  measurementId: "G-6JRYSW472J",
};
