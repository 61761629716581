import React from "react";
import { View, StyleSheet } from "react-native";
import variables from "../../../../styles/variables";
import TextLoader from "./shared/TextLoader";

const { spacing } = variables;

const HeadingLoader = () => {
  return (
    <View style={styles.header}>
      {/* heading */}
      <TextLoader width={"70%"} height={20} />
      {/* subheading */}
      <TextLoader />
      <TextLoader />
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "column",
    marginVertical: spacing.xsmall,
    justifyContent: "space-between",
    width: "100%",
    height: 70,
  },
});

export default HeadingLoader;
