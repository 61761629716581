import { View, StyleSheet } from "react-native";
import React from "react";
import { useResponsive } from "../../../../../hooks/useResponsive";
import PreferenceHeaderLoader from "./PreferenceHeaderLoader";
import PreferenceRowLoader from "./PreferenceRowLoader";
import RadioButtonsLoader from "./RadioButtonsLoader";
import RRadioButtonLoader from "./RRadioButtonLoader";
import CheckBoxesLoader from "./CheckBoxesLoader";
import TextLoader from "../shared/TextLoader";
import variables from "../../../../../styles/variables";

const { spacing } = variables;

const PreferencesLoader = () => {
  const { isSmallAndDown } = useResponsive();

  return (
    <View>
      <PreferenceHeaderLoader />
      <PreferenceRowLoader>
        <RadioButtonsLoader label items={[0, 1]} />
      </PreferenceRowLoader>
      <PreferenceRowLoader>
        <RadioButtonsLoader label items={[0, 1]} />
      </PreferenceRowLoader>
      {isSmallAndDown ? (
        <PreferenceRowLoader>
          <RadioButtonsLoader label items={[0, 1]} />
        </PreferenceRowLoader>
      ) : (
        <PreferenceRowLoader>
          <RadioButtonsLoader label items={[0]} />
          <View style={styles.bookmakerContainer}>
            {[0, 1, 2, 3, 4, 5, 6].map((_, idx) => (
              <RRadioButtonLoader key={idx} icon />
            ))}
          </View>
        </PreferenceRowLoader>
      )}

      <PreferenceRowLoader>
        <CheckBoxesLoader
          label
          items={[0, 1, 2]}
          style={isSmallAndDown && styles.notificationsMobile}
        />
        {isSmallAndDown ? (
          <View style={styles.sendTestMobile}>
            <TextLoader />
          </View>
        ) : (
          <TextLoader />
        )}
      </PreferenceRowLoader>
    </View>
  );
};

const styles = StyleSheet.create({
  bookmakerContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    paddingTop: spacing.xsmall,
    paddingLeft: spacing.xsmall,
    flex: 3,
  },
  notificationsMobile: {
    // maxWidth: "100%",
    flexWrap: "nowrap",
  },
  sendTestMobile: {
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: spacing.small,
  },
});

export default PreferencesLoader;
