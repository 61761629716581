import React from "react"; // This is actually used. It's just not detected by the linter
import GridLayout from "../../../../../Layout/GridLayout/GridLayout";
import ContentPreviewLoader from "../ContentPreviewLoader";

const GridLayoutLoader: React.FC = () => {
  return (
    <GridLayout>
      {Array.from({ length: 5 }).map((_, idx: number) => (
        <ContentPreviewLoader key={idx} />
      ))}
    </GridLayout>
  );
};

export default GridLayoutLoader;
