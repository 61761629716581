import variables from "../styles/variables";
import { Platform } from "react-native";

const calculateAdImageHeight = (
  windowWidth: number,
  contentsLength: number,
  isMediumAndDown: boolean,
  allImagesAre16_9: boolean,
  fullScreen: boolean
): number => {
  const breakpoints = [
    { min: 2600, factor: 1.5 },
    { min: 2300, factor: 1.8 },
    { min: variables.width4K, factor: 2 },
    { min: 1600, factor: 2.5 },
  ];

  const calculateImageHeightOnLargeScreens = (width: number) => {
    const matchedBreakpoint = breakpoints.find((b) => width >= b.min);
    return (width / 10) * (matchedBreakpoint?.factor ?? 3);
  };

  if (contentsLength === 1) {
    if (isMediumAndDown) {
      return (windowWidth / 10) * (Platform.OS === "web" ? 2.5 : 3);
    }
    return windowWidth >= 1600
      ? calculateImageHeightOnLargeScreens(windowWidth)
      : (windowWidth / 10) * 3;
  }

  if (contentsLength === 2) {
    return (windowWidth / 10) * 2.4;
  }

  // Handle multiple images, and use different height factors for full screen and aspect ratio
  return allImagesAre16_9
    ? (windowWidth / 10) * 1.6
    : (windowWidth / 10) * (fullScreen ? 1.8 : 1.7);
};

export default calculateAdImageHeight;
