import { StyleSheet, View } from "react-native";
import React from "react";
import RText from "../../../../RText";
import Gap from "../../../../Gap";
import variables from "../../../../../styles/variables";
import { useResponsive } from "../../../../../hooks/useResponsive";

type Props = {
  starRating: number;
  formattedRating?: string;
  displaysPremiumData?: boolean;
};

const { colors, spacing } = variables;

const TimeformStarRating = ({
  starRating,
  formattedRating: formattedRating,
  displaysPremiumData,
}: Props) => {
  const isFiveStar = starRating === 5;
  const { isSmallAndDown } = useResponsive();

  if (!starRating) return null;

  const formattedRatingText = () => {
    if (formattedRating.includes("<em>(0)</em>")) {
      formattedRating = formattedRating.replace("<em>(0)</em>", "(0)");
      return (
        <RText weight="semiBold" color={isFiveStar ? "white" : "primary"}>
          <RText>TF</RText>
          <RText italic>{formattedRating}</RText>
        </RText>
      );
    }

    if (formattedRating === "0?") {
      formattedRating = "?";
    }

    if (formattedRating.includes("<")) {
      formattedRating = formattedRating.replace(/\D/g, "");
    }
    return (
      <RText weight="semiBold" color={isFiveStar ? "white" : "primary"}>
        {"TF " + formattedRating}
      </RText>
    );
  };

  return (
    <View style={[styles.container, isFiveStar && styles.filled]}>
      {displaysPremiumData ? (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <RText weight="semiBold" color={isFiveStar ? "white" : "primary"}>
            {formattedRatingText()}
          </RText>
        </View>
      ) : (
        <View style={styles.centerRow}>
          <RText weight="bold" color={isFiveStar ? "white" : "primary"}>
            {starRating}{" "}
          </RText>
          <RText color={isFiveStar ? "white" : "primary"}>★</RText>
        </View>
      )}

      <Gap size="xxxsmall" />
    </View>
  );
};

export default TimeformStarRating;

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors?.palette?.rtv?.primary,
    borderRadius: 5,
    flexDirection: "row",
    paddingHorizontal: spacing.xxxsmall,
  },
  filled: {
    backgroundColor: colors?.palette?.rtv?.primary,
  },
  centerRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});
