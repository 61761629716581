import React from "react";
import {
  LayoutRectangle,
  Platform,
  Pressable,
  StyleSheet,
  View,
} from "react-native";
import { useResponsive } from "../../hooks/useResponsive";
import variables from "../../styles/variables";
import RText from "../RText";
import { Channel } from "../Screens/Watch/Live/LiveStreamSection";
import { useAtom } from "jotai";
import { loadableLiveChannelsAtom, selectedChannelsAtom } from "../../atoms";
import Icon from "../Icon";

const isNative = Platform.OS === "android" || Platform.OS === "ios";

export type LiveChannelProps = {
  isVisible: boolean;
  parentSize: LayoutRectangle;
  onSelectChannel?: () => void;
};

const LiveChannel: React.FC<LiveChannelProps> = ({
  isVisible,
  parentSize,
  onSelectChannel,
}) => {
  const { isSmallAndDown } = useResponsive();
  const [liveChannels] = useAtom(loadableLiveChannelsAtom);
  const [selectedChannel, setSelectedChannel] =
    useAtom<Channel[]>(selectedChannelsAtom);

  const handleChannelPress = (channel: Channel) => {
    onSelectChannel?.();
    setSelectedChannel([channel]);
  };

  if (!isVisible || liveChannels.state !== "hasData") return null;
  return (
    <>
      <View
        style={[
          styles.container,
          {
            right: -parentSize.width / 2,
            bottom: parentSize.height,
          },
          isNative && {
            height: parentSize.height * (liveChannels.data.length + 3),
          },
        ]}
      >
        <View style={styles.titleTextContainer}>
          <RText
            size={isSmallAndDown ? "xs" : "md"}
            style={styles.channelTitle}
            weight="bold"
          >
            Select channel
          </RText>
        </View>

        {liveChannels.data.map((channel, index) => {
          const isSelected = selectedChannel.includes(channel);
          return (
            <View
              key={channel.name + index}
              style={[
                styles.channel,
                { width: isSmallAndDown ? 150 : 250 },
                !isNative && { padding: variables.spacing.xsmall },
              ]}
            >
              <Pressable
                onPress={() => handleChannelPress(channel)}
                key={index}
                style={{
                  flex: 4,
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <RText
                  size={isSmallAndDown ? "xs" : "md"}
                  uppercase
                  style={styles.channelTitle}
                  numberOfLines={1}
                >
                  {channel.title}
                </RText>
              </Pressable>
              <Pressable
                onPress={() =>
                  setSelectedChannel((prev) => {
                    if (prev.includes(channel)) {
                      return prev.filter((item) => item !== channel);
                    }
                    return [...prev, channel];
                  })
                }
                style={{
                  flex: 1,
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  name="channelOption"
                  size="small"
                  color={
                    isSelected
                      ? variables.colors.palette.rtv.secondary
                      : variables.colors.palette.neutral.x200
                  }
                />
              </Pressable>
            </View>
          );
        })}
      </View>
      {isVisible && <View style={styles.triangle} />}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: variables.colors.palette.rtv.white,
    padding: variables.spacing.xxsmall,
    borderRadius: variables.spacing.xxsmall,
    shadowRadius: variables.spacing.xxsmall,
    shadowColor: variables.colors.palette.rtv.black,
    shadowOpacity: 0.9,
    shadowOffset: {
      width: variables.spacing.xxsmall,
      height: variables.spacing.xxsmall,
    },
    elevation: variables.spacing.xxsmall,
  },
  titleTextContainer: {
    padding: variables.spacing.xsmall,
  },
  channel: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopColor: variables.colors.palette.neutral.x50,
    borderTopWidth: 1,
    flex: 1,
  },
  channelTitle: {
    color: variables.colors.palette.rtv.primary,
  },
  triangle: {
    position: "absolute",
    top: 0,
    marginRight: "50%",
    marginLeft: "50%",
    width: 0,
    height: 0,
    borderLeftWidth: 17,
    borderRightWidth: 17,
    borderBottomWidth: 12,
    borderStyle: "solid",
    backgroundColor: "transparent",
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderBottomColor: variables.colors.palette.rtv.white,
    transform: [{ rotate: "180deg" }],
  },
});

export default LiveChannel;
