// Manage FullStory library already loaded in the page through google tag manager

// Note: Fullstory initializes for all users just by importing the module.
// The only method in use here is identifying a user once we know who they are. It
// will still gather data for anon users.

import { initParams } from "../../types/fullstory";

// Declare the FS function that's defined in the FullStory library
declare function FS(command: string, params?: any): void;

const FullStory = {
  identifyUser: ({ uid, data }: initParams) => {
    if (document.readyState === "complete") {
      FullStory.setIdentity(uid, data);
    }
  },
  setIdentity: (uid: string, properties: any) => {
    FS("setIdentity", {
      uid,
      properties,
    });
  },
};

export default FullStory;
