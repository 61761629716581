import { StyleSheet, View } from "react-native";
import { useResponsive } from "../../../../../hooks/useResponsive";
import TextLoader from "../shared/TextLoader";
import variables from "../../../../../styles/variables";

const { spacing, colors } = variables;

const NonRunnersLoader = () => {
  const { isMediumAndDown } = useResponsive();

  return (
    <View>
      <TextLoader height={50} width={"100%"} />

      {[0, 1, 2, 3, 4].map((_, index: number) => {
        return (
          <View style={styles.raceDetailsContainer} key={index}>
            <TextLoader height={40} width={isMediumAndDown ? "85%" : "60%"} />
            <TextLoader width={40} height={40} />
          </View>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 12,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingHorizontal: spacing.small,
    paddingVertical: spacing.xsmall,
    backgroundColor: colors.palette.neutral.x100,
  },
  raceDetailsContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    paddingVertical: 15,
    borderBottomWidth: 0.5,
    borderBottomColor: colors.palette.neutral.x100,
  },
  raceDetailsLeftContainer: {
    flexDirection: "row",
  },
});
export default NonRunnersLoader;
