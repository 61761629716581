import React from "react";
import { View } from "react-native";
import { useResponsive } from "../../../../../hooks/useResponsive";
import variables from "../../../../../styles/variables";
import CircleLoader from "./CircleLoader";

const { spacing, sizes } = variables;

const SocialIconsLoader = () => {
  const { isLargeAndUp } = useResponsive();

  const diameter = isLargeAndUp ? sizes.xlarge.width : sizes.large.width;

  return (
    <View style={{ flexDirection: "row" }}>
      {[0, 1, 2, 3].map((index) => (
        <CircleLoader
          key={index}
          width={diameter}
          height={diameter}
          style={{ marginRight: spacing.xsmall }}
        />
      ))}
    </View>
  );
};

export default SocialIconsLoader;
