import React from "react";
import { View, StyleSheet } from "react-native";
import TextLoader from "../shared/TextLoader";
import variables from "../../../../../styles/variables";
import { useResponsive } from "../../../../../hooks/useResponsive";

const { colors, spacing } = variables;

const MemberPlansLoader: React.FC = () => {
  const { isMediumAndDown } = useResponsive();

  const PlanLoader = () => {
    return (
      <View style={styles.item}>
        <View style={styles.planContainer}>
          <TextLoader height="100%" />
        </View>
        <View style={[styles.description, { flex: isMediumAndDown ? 1 : 2 }]}>
          <TextLoader height="100%" />
        </View>
      </View>
    );
  };

  return (
    <View>
      {Array.from({ length: 4 }, (_, key) => (
        <PlanLoader key={key} />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    paddingVertical: spacing.xsmall,
    paddingHorizontal: spacing.xxsmall,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    borderTopColor: colors.palette.neutral.x100,
    borderTopWidth: 1,
    borderStyle: "solid",
    height: 95,
  },
  planContainer: {
    flex: 1,
    borderRadius: 5,
    marginRight: spacing.xsmall,
    backgroundColor: "pink",
    height: "90%",
  },
  description: {
    height: "90%",
  },
});

export default MemberPlansLoader;
