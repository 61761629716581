import React, { useState, useEffect } from "react";
import { fetchGet } from "../../../client";
import { APIResponse } from "../../../types/responses/APIResponse";
import RVideoPlayer from "../../RVideoPlayer";
import { View, StyleSheet } from "react-native";
import SquareLoader from "../../States/Loading/Loaders/shared/SquareLoader";
import { EmptyState } from "../../States/states";
import { Player } from "../../../types/replay";
import { selectOptimalCodec } from "../../VideoPlayer/selectOptimalCodec";

const VideoAsset = ({ metadata }) => {
  const [data, setData] = useState<Player>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  const fetchData = async () => {
    try {
      const res: APIResponse<any> = await fetchGet(
        `/member/watch/assets/${metadata.signed_id}`
      );
      if (!res.player) throw new Error("No player data returned");
      setData(res.player);
    } catch (err) {
      setError("Error loading demand video asset: " + err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (metadata.signed_id) setLoading(false);
    else setLoading(true);
  }, [metadata.signed_id]);

  if (loading)
    return (
      <View style={styles.loader}>
        <SquareLoader width="100%" />
      </View>
    );

  if (error) {
    return <EmptyState message={error || "unknown error"} />;
  }

  if (!metadata) return;

  return (
    <RVideoPlayer
      source={data?.sources && selectOptimalCodec(data.sources)}
      displayType="embedded"
      errorMessage={error}
      poster={metadata.placeholder_image_url}
      onPlayPress={() => {
        fetchData();
      }}
      title={metadata?.title}
      description={metadata?.description}
      uploadDate={metadata?.updated_at}
    />
  );
};

export default VideoAsset;

const styles = StyleSheet.create({
  loader: {
    width: "100%",
    aspectRatio: 16 / 9,
  },
});
