import React from "react";
import { View, StyleSheet } from "react-native";
import RText from "../../RText";
import variables from "../../../styles/variables";

// If the data request was successfully fulfilled but there is no content to display, display this component

type Props = {
  message?: string;
  style?: {};
};

const { spacing } = variables;

const EmptyState = ({
  message = "no content to display",
  style = { marginVertical: spacing.small },
}: Props) => {
  return (
    <View style={style}>
      <RText
        family="secondary"
        weight="bold"
        uppercase
        style={[styles.emptyState, style]}
      >
        {message}
      </RText>
    </View>
  );
};

export default EmptyState;

const styles = StyleSheet.create({
  emptyState: { textAlign: "center" },
});
