import { Platform, Pressable, StyleSheet, View } from "react-native";
import variables from "../../../styles/variables";
import { useEffect, useRef, useState } from "react";
import { useAtom } from "jotai";
import {
  cmsDataAtom,
  currentRouteAtom,
  menuAtom,
  menuItemWidthAtom,
  moreMenuAtom,
  popupDataAtom,
} from "../../../atoms";
import RScrollView from "../../RScrollView";
import RText from "../../RText/RText";
import Icon from "../../Icon/Icon";
import useScrollGap from "../../useScrollGap";
import { RLink } from "../../RLink/RLink";
import { MENU, MORE_MENU } from "../menu_types";
import useCmsMoreMenu from "../../../hooks/useCmsMoreMenu";
import { useClosePopup } from "../../../hooks/useClosePopup";

export const DesktopMenu = ({ navOverride = undefined }) => {
  const moreButtonRef = useRef(null);
  const [popupData, setPopupData] = useAtom(popupDataAtom);
  const [menu, setMenu] = useAtom(menuAtom);
  const [moreMenu, setMoreMenu] = useAtom(moreMenuAtom);
  const [menuItemWidth, setMenuItemWidth] = useAtom(menuItemWidthAtom);
  const [currentRoute] = useAtom(currentRouteAtom);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const initPopupPosition = useScrollGap();
  const [cmsData] = useAtom(cmsDataAtom);
  const prevMainMenuCountRef = useRef(0);
  const prevMoreMenuCountRef = useRef(0);
  useClosePopup(popupData[0]?.navDropdownRef, () => setIsDropdownOpen(false));

  const { moreMenuItems } = useCmsMoreMenu();

  // IMPORTANT: Unable to use useWebPopup hook here as it may lead to state not updating in time
  useEffect(() => {
    if (Platform.OS !== "web") return;

    setPopupData([
      {
        title: "moreDropDown",
        type: "dropdown",
        options: moreMenu.concat(moreMenuItems),
        parentRef: moreButtonRef.current,
        isOpen: isDropdownOpen,
        closePopup: () => {
          // Use a timeout before setting false.
          //
          // This is to because if the dropdown menu is open
          // and the More menu option is clicked again then the dropdown menu to collapse. Without
          // the time out, clicking the More menu option will first trigger this closePopup function
          // to run, setting isDropdownOpen to false, and then the More menu button onPress will run
          // setting isDropdownOpen to be the opposite (true), essentially undoing the action.
          setTimeout(() => setIsDropdownOpen(false), 150);
        },
        sticky: true,
        initPopupPosition,
      },
    ]);
  }, [moreButtonRef, isDropdownOpen, moreMenu, cmsData]);

  const handleMenuResize = (width: number) => {
    if (!width || !moreButtonRef?.current?.offsetWidth) return;

    const itemOffset = Math.floor(width / moreButtonRef?.current?.offsetWidth);
    const mainMenu = [...MENU.slice(0, itemOffset - 1)];
    const currentItemsInMenu = mainMenu.map((item) => item.title);
    const moreMenu = [
      ...MENU.filter(
        (menuItem) => !currentItemsInMenu.includes(menuItem.title)
      ),
      ...MORE_MENU,
    ];

    // Additional logic to handle dropdown state and previous counts remains the same
    const isMainMenuCountChanged =
      prevMainMenuCountRef.current !== mainMenu.length;
    const isMoreMenuCountChanged =
      prevMoreMenuCountRef.current !== moreMenu.length;

    if (isMainMenuCountChanged || isMoreMenuCountChanged) {
      setIsDropdownOpen(false); // Optionally close the moreMenu if counts have changed
    }

    // Update the previous counts for comparison in future renders
    prevMainMenuCountRef.current = mainMenu.length;
    prevMoreMenuCountRef.current = moreMenu.length;

    // Update the state with the new menus
    setMenu(mainMenu);
    setMoreMenu(moreMenu);
  };

  // TODO - tricky to stop dropdown re-opening on click. Needs rework.

  return (
    <View style={{ position: "relative", width: "100%", height: "100%" }}>
      <RScrollView
        nativeID="header-menu"
        onLayout={(event) => handleMenuResize(event.nativeEvent.layout.width)}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        horizontal
        elementDistance={menuItemWidth}
        childrenWidth={menuItemWidth * (menu.length + 1)}
        style={styles.menu}
        contentContainerStyle={styles.menuContent}
        hideArrows
      >
        {/* All menu items except more */}
        {menu.map((menuItem, idx) => {
          const isCurrentRoute = menuItem.route === currentRoute;

          return (
            <View
              key={menuItem.title}
              style={[styles.more]}
              onLayout={({ nativeEvent }) => {
                if (idx === 0) {
                  setMenuItemWidth(nativeEvent.layout.width);
                }
              }}
            >
              <RLink
                ref={menuItem.title === "More" ? moreButtonRef : null}
                to={{ screen: menuItem.route }}
                family="secondary"
                color="white"
                uppercase
                weight="bold"
                style={[styles.menuItem, { flex: 1 }]}
                size={"sm"}
                dynamicTextStyle={{
                  onHover: "changeTextColor",
                  onPressed: isCurrentRoute ? "changeTextColor" : "none",
                }}
              >
                {menuItem.title}
              </RLink>
            </View>
          );
        })}

        {/* More menu item */}
        <Pressable
          onPress={() => setIsDropdownOpen((prev) => !prev)}
          ref={moreButtonRef}
          style={[styles.more]}
        >
          <RText
            family="secondary"
            color="white"
            uppercase
            weight="bold"
            style={[styles.menuItem, { flex: 1 }]}
            size={"sm"}
            dynamicTextStyle={{
              onHover: "changeTextColor",
              onPressed: currentRoute === "More" ? "changeTextColor" : "none",
            }}
          >
            More
          </RText>
          <View style={{ top: 0, marginLeft: -5 }}>
            <Icon
              name="chevronLeft"
              size="small"
              fill="palette.rtv.mainBackground"
              rotate={270}
            />
          </View>
        </Pressable>
      </RScrollView>
    </View>
  );
};

const { colors, spacing } = variables;
const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  menuContainer: {
    backgroundColor: colors.palette.rtv.primary,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: spacing.xsmall,
  },
  optionsContainer: {
    display: "flex",
    justifyContent: "center",
    paddingHorizontal: 25,
    height: 50,
    borderBottomWidth: 0.5,
    borderBottomColor: variables.colors.palette.buttons.disabled.borderColor,
  },
  menu: {
    width: "100%",
    flex: 1,
  },
  menuContent: {
    flexGrow: 1,
  },
  menuItem: {
    width: "auto",
    paddingVertical: spacing.xsmall,
    paddingRight: spacing.xsmall,
  },
  more: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
});
