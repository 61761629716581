import { fetchPost } from "../..";

export const registerMemberDevice = async (id: string) => {
  const url = `member/devices/firebase/${id}`;
  return await fetchPost(url, {}, {}, "POST");
};

export const deregisterMemberDevice = async (id: string) => {
  const url = `member/devices/firebase/${id}`;
  return await fetchPost(url, {}, {}, "DELETE");
};
