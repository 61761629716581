export async function sendAnalyticsEventAsync(
  eventName: string,
  eventParams: Record<string, any> = {}
) {
  try {
    window?.dataLayer?.push({
      event: eventName,
      ...eventParams,
    });
  } catch (error) {
    console.log("Error sending analytics event", error);
  }
}
