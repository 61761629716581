import React from "react";
import TextLoader from "./TextLoader";

/* Adjust the width and numberOfLines to set the size of the loader */
/* Use the style prop for margin/padding between the individual lines */
/* Use the radius prop to set the border radius of the individual lines */

type Props = {
  width?: string | number;
  numberOfLines?: number;
  radius?: number;
  lineHeight?: number;
  style?: {};
};

const MultilineTextLoader: React.FC<Props> = ({
  width = "100%",
  numberOfLines = 3,
  radius = 3,
  lineHeight,
  style = { marginVertical: 4 },
}) => {
  const array = new Array(numberOfLines).fill("item");

  return (
    <>
      {array.map((_, index: number) => (
        <TextLoader
          width={width}
          height={lineHeight}
          radius={radius}
          style={style}
          key={index}
        />
      ))}
    </>
  );
};

export default MultilineTextLoader;
