import RText, { RTextColors } from "./RText";
import {
  StyleSheet,
  TouchableOpacity,
  Linking,
  Platform,
  View,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Screens } from "../Navigation/URLMapper";
import variables from "../../styles/variables";
import RTouchableOpacity from "../RLink/RTouchableOpacity";

type Props = {
  children: string;
  path?: Screens;
  externalWebPath?: string;
  navOverride?: any;
  color?: RTextColors;
  family?: "primary" | "secondary";
  weight?: "light" | "bold";
  params?: any;
};

const RFooterLink = ({
  children,
  path,
  externalWebPath,
  navOverride,
  color = "white",
  family = "primary",
  weight = "light",
  params = {},
}: Props) => {
  return Platform.OS == "web" && externalWebPath ? (
    <a
      href={externalWebPath}
      target="_blank"
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: variables.spacing.xsmall,
        textDecoration: "none",
      }}
    >
      <RText color={color} family={family} weight={weight} capitalize={true}>
        {children}
      </RText>
    </a>
  ) : (
    <RTouchableOpacity navScreen={path} navParams={params}>
      <RText
        color={color}
        family={family}
        weight={weight}
        capitalize={true}
        style={styles.paragraph}
      >
        {children}
      </RText>
    </RTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  paragraph: {
    textAlign: "center",
    paddingBottom: variables.spacing.xsmall,
  },
});
export default RFooterLink;
