import "expo/build/Expo.fx";
import { registerRootComponent } from "expo";
import { createElement } from "react";
import { createRoot } from "react-dom/client";
import { Platform } from "react-native";
import App from "./App";

/**
 * ReactDOM.render is deprecated in React 18. Have updated the code to use createRoot instead.
 * https://reactjs.org/blog/2021/06/08/the-plan-for-react-18.html#new-root-api
 **/
if (Platform.OS === "web") {
  const rootTag = createRoot(document.getElementById("root"));
  rootTag.render(createElement(App));
} else {
  registerRootComponent(App);
}
