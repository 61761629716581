export const DEFAULT_PREDICTOR_IMAGE = require("../../../assets/default-predictor-image.jpg");
export const DEFAULT_VIDEO_ANALYSIS_IMAGE = require("../../../assets/default-video-analysis-image.jpg");
export const DEFAULT_OFFER_IMAGE = require("../../../assets/default-offer-image.jpg");
export const DEFAULT_BOOKMAKER_IMAGE = require("../../../assets/default-bookmaker-image.jpg");
export const DEFAULT_BENEFITS_IMAGE = require("../../../assets/default-benefits-image.jpg");
export const DEFAULT_NEWS_IMAGE = require("../../../assets/default-news-image.jpg");
export const DEFAULT_MAGAZINE_IMAGE = require("../../../assets/default-magazine-image.jpg");
export const DEFAULT_REPLAY_IMAGE = require("../../../assets/default-replay-image.jpg");
export const DEFAULT_RTV_LIVE_IMAGE = require("../../../assets/default-rtv-live-image.png");
export const DEFAULT_RTV_JUMPS_IMAGE = require("../../../assets/default-rtv-jumps-image.png");
export const DEFAULT_RTV_FLAT_IMAGE = require("../../../assets/default-rtv-flat-image.png");
export const DEFAULT_PRESENTERS_IMAGE = require("../../../assets/default-presenters-image.jpg");
