import React, { useEffect, useState } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { useAtom } from "jotai";
import { useResponsive } from "../../../../hooks/useResponsive";
import variables from "../../../../styles/variables";
import { Tip as TipT, Tipster } from "../../../../types/tipster";
import StandardPriceButton from "../../../Buttons/StandardPriceButton";
import { userAtom } from "../../../../atoms";
import { convertUserPriceFormat } from "../../Account/components/Utils";
import { getOdds } from "../../../../client/odds";
import { Odd } from "../../../../types/races/runner";
import { LoadingState } from "../../../States/states";
import TipInfo from "./shared/TipInfo";
import TipComment from "./shared/TipComment";

const { colors, spacing } = variables;

type TipProps = {
  tip?: TipT;
  i?: number;
  tipCount?: number;
  skipLoader?: boolean;
  individualTipster?: boolean;
  tipster?: Tipster;
};

const Tip = ({
  tip,
  i,
  tipCount,
  skipLoader = false,
  individualTipster = false,
  tipster,
}: TipProps) => {
  const { isLargeAndUp } = useResponsive();
  const [user] = useAtom(userAtom);
  const [bestOdds, setBestOdds] = useState<Odd | undefined>();
  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const convertedUserPriceFormat = convertUserPriceFormat(user);
  const lastTip = tipCount == i + 1;

  useEffect(() => {
    setContentLoading(true);
    getOdds([tip.runner])
      .then(([[{ bestOdds }]]) => setBestOdds(bestOdds))
      .catch((e) => console.error("Error:", e.message))
      .finally(() => setContentLoading(false));
  }, [tip.runner]);

  if (contentLoading && !skipLoader)
    return <LoadingState name="TipsterLoader" />;

  if (contentLoading && skipLoader) return <LoadingState name="TipLoader" />;

  return (
    <View
      key={i}
      style={[
        (tip.result.state == "lost" || tip.result.state == "refunded") &&
        !tip.successful
          ? styles.opacity
          : null,
      ]}
    >
      {/* smaller screens */}
      {!isLargeAndUp && (
        <View style={[styles.details, lastTip ? {} : styles.separator]}>
          <View
            style={{
              flexDirection: "row",
              marginVertical: spacing.xsmall,
              justifyContent: "space-between",
            }}
          >
            <TipInfo tip={tip} individualTipster={individualTipster} />
            <View
              style={{
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <StandardPriceButton
                advisedPrice={tip?.advised_price}
                odd={bestOdds}
                race={tip?.runner?.race}
                runner={tip?.runner}
                priceFormat={convertedUserPriceFormat}
                startingPrice={tip?.starting_price}
              />
            </View>
          </View>
          <TipComment tip={tip} tipster={tipster} />
        </View>
      )}
      {/* bigger screens */}
      {isLargeAndUp && (
        <View
          style={[
            styles.details,
            { flexDirection: "row", marginTop: spacing.xsmall },
            lastTip ? {} : styles.separator,
          ]}
        >
          <View style={{ flex: 1 }}>
            <TipInfo tip={tip} individualTipster={individualTipster} />
          </View>
          <View style={{ flex: 2 }}>
            <TipComment tip={tip} tipster={tipster} />
          </View>
          <View
            style={{
              flex: 0.5,
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <View style={{ alignItems: "flex-end", justifyContent: "center" }}>
              <StandardPriceButton
                advisedPrice={tip?.advised_price}
                startingPrice={tip?.starting_price}
                odd={bestOdds}
                race={tip?.runner?.race}
                runner={tip?.runner}
                priceFormat={convertedUserPriceFormat}
              />
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  opacity: {
    opacity: 0.4,
  },
  details: {
    paddingBottom: spacing.xsmall,
    paddingHorizontal: spacing.xxsmall,
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderColor: colors.palette.neutral.x200,
  },
  textLink: {
    ...Platform.select({
      web: {},
      ios: {
        marginBottom: -3,
      },
      android: {
        marginBottom: -4,
      },
    }),
  },
  successfulTip: {
    padding: spacing.xxsmall,
    borderRadius: 4,
    borderWidth: 1.5,
    borderColor: colors.palette.rtv.secondary,
    marginBottom: spacing.xxsmall,
  },
  expertEyeIcon: {
    marginLeft: spacing.xxsmall,
  },
});

export default Tip;
