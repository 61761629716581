/**
 * Remove comma and any characters following the comma from the input string
 *
 * @example
 * removeCountry('Noal Meade Ireland') // 'Noel Meade'
 *
 * @param string The string to remove the country from.
 * @returns The string without the country.
 */

import { allCountries } from "../types/AllCountries";

export const removeCountry = (text: string) => {
  if (!text) return "";
  const includesCountry = allCountries.find((c) =>
    text.toLowerCase().includes(c.name.toLowerCase())
  );

  if (!includesCountry) return text;
  if (includesCountry) {
    text = text.toLowerCase().replace(includesCountry.name.toLowerCase(), "");
    if (text.endsWith("-")) {
      text = text.slice(0, -1);
    }
    return text.capitalize();
  }
};

// TESTS....
// console.log(removeCountry("Joseph O'Brien, Ireland")); // returns "Joseph O'Brien,"
