import React from "react";
import { StyleSheet, View } from "react-native";
import TextLoader from "./TextLoader";
import variables from "../../../../../styles/variables";

const { colors, spacing } = variables;

const RAccordionLoader = () => {
  return (
    <>
      {Array.from({ length: 3 }, (value, key) => (
        <View style={styles.header} key={key}>
          <TextLoader height="100%" />
        </View>
      ))}
    </>
  );
};

export default RAccordionLoader;

const styles = StyleSheet.create({
  header: {
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 30,
    marginVertical: spacing.xxsmall,
    backgroundColor: colors.palette.neutral.x100,
  },
});
