import React from "react";
import { Platform, StyleSheet } from "react-native";
import RText from "../RText/RText";
import { RLink } from "./RLink";
import variables from "../../styles/variables";

interface RLinkButtonProps {
  href: string;
  text: string;
}

// This component is a link in button form.
// For simple links see RLink.

// This could be extended to include internal navigation
// but there weren't any uses in the codebase at the time of writing.
// If that is the case, we should use the pressableProps to
// ensure the internal navigation link creates an <a> tag on web.
const RLinkButton = ({ href, text }: RLinkButtonProps) => (
  <RLink
    href={href}
    type="href"
    pressableStyle={[
      styles.button,
      Platform.OS != "web"
        ? {
            paddingHorizontal: 6,
            paddingVertical: 10,
          }
        : {},
    ]}
  >
    <RText
      color="white"
      weight="bold"
      style={styles.text}
      dynamicTextStyle={{}}
    >
      {text}
    </RText>
  </RLink>
);

export default RLinkButton;

const styles = StyleSheet.create({
  button: {
    backgroundColor: variables.colors.palette.rtv.secondary,
    borderRadius: 3,
    borderWidth: 1.5,
    borderColor: variables.colors.palette.rtv.secondary,
    userSelect: "none",
  },
  text: {
    textAlign: "center",
    justifyContent: "center",
    paddingVertical: 10,
    paddingHorizontal: 6,
  },
});
