import { StyleSheet, View } from "react-native";
import React from "react";
import TextLoader from "../shared/TextLoader";
import { useResponsive } from "../../../../../hooks/useResponsive";

const HorseTrackerLoader = () => {
  const { isSmallAndDown } = useResponsive();
  return (
    <View style={styles.container}>
      <View style={{ width: "40%", marginVertical: 10 }}>
        <View
          style={[
            styles.field,
            {
              width: "60%",
            },
          ]}
        >
          <TextLoader />
        </View>

        <View
          style={[
            styles.field,
            {
              width: "80%",
            },
          ]}
        >
          <TextLoader />
        </View>
      </View>

      <View style={{ width: isSmallAndDown ? "20%" : "10%" }}>
        <View
          style={[
            styles.field,
            {
              width: "100%",
            },
          ]}
        >
          <TextLoader />
        </View>
      </View>
    </View>
  );
};

export default HorseTrackerLoader;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  field: {
    height: 15,
    marginVertical: 2,
  },
});
