import { View } from "react-native";
import React from "react";
import SquareLoader from "../shared/SquareLoader";
import TextLoader from "../shared/TextLoader";
import Gap from "../../../../Gap";
import CircleLoader from "../shared/CircleLoader";

const NapOfTheDayLoader = () => {
  return [1, 2, 3].map((i) => (
    <View key={i}>
      <View>
        <TextLoader width={"100%"} height={40} />
      </View>
      <View style={{ flexDirection: "row" }}>
        <CircleLoader width={30} height={30} style={{ marginTop: 5 }} />
        <Gap size="xsmall" />
        <View style={{ flexDirection: "column" }}>
          <Gap vertical size="xxsmall" />
          <TextLoader width={150} height={20} />
          <Gap vertical size="xsmall" />
          <View style={{ flexDirection: "row" }}>
            <SquareLoader width={20} />
            <Gap size="xxsmall" />
            <SquareLoader width={20} />
            <Gap size="xxsmall" />
            <SquareLoader width={20} />
            <Gap size="xxsmall" />
            <SquareLoader width={20} />
            <Gap size="xxsmall" />
            <SquareLoader width={20} />
          </View>
        </View>
      </View>
      <Gap vertical size="xsmall" />
    </View>
  ));
};

export default NapOfTheDayLoader;
