import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { Helmet } from "react-helmet-async";

type Props = {
  type: string;
  data: any;
};

// https://developers.google.com/search/docs/appearance/structured-data/search-gallery

const StructuredData = ({ type, data }: Props) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": type,
    ...data,
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  );
};

export default StructuredData;

const styles = StyleSheet.create({});
