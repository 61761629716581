import { Platform, StyleSheet, Text, View } from "react-native";
import React from "react";
import ContentPreviewLoader from "../shared/ContentPreviewLoader";
import TextLoader from "../shared/TextLoader";
import variables from "../../../../../styles/variables";
import SquareLoader from "../shared/SquareLoader";
import { useResponsive } from "../../../../../hooks/useResponsive";

const SearchLoader = () => {
  const { isSmallAndDown } = useResponsive();

  return (
    <View style={styles.container}>
      <View style={{ padding: 10 }}>
        <TextLoader />
      </View>
      <View style={{ flexDirection: "row" }}>
        {new Array(6).fill(0).map((_, i) => (
          <SquareLoader
            width={isSmallAndDown ? 150 : 250}
            key={`searchCategoryLoader${i}`}
            style={
              isSmallAndDown ? styles.smallScreenCategory : styles.category
            }
          />
        ))}
      </View>

      <View style={{ flexDirection: "row" }}>
        {new Array(6).fill(0).map((_, i) => (
          <ContentPreviewLoader
            key={`searchContentPreviewLoader${i}`}
            style={[
              styles.newsItem,
              isSmallAndDown && styles.smallScreenNewsItem,
            ]}
          />
        ))}
      </View>
    </View>
  );
};

export default SearchLoader;

const { spacing } = variables;

const styles = StyleSheet.create({
  container: {
    position: "relative",
    backgroundColor: "white",
    flexGrow: 1,
    // minHeight: 300,
    // ...Platform.select({
    //   native: {
    //     minHeight: 550,
    //     paddingTop: "10%",
    //   },
    //   web: { minHeight: 300 },
    // }),
    zIndex: 10,
  },
  newsSection: {
    marginTop: spacing.xxsmall,
  },
  newsItem: {
    marginRight: spacing.xxsmall,
    marginTop: spacing.xxsmall,
    maxWidth: 250,
    backgroundColor: "white",
  },
  smallScreenNewsItem: {
    ...Platform.select({
      native: {
        width: 200,
        maxHeight: 180,
      },
      web: {
        minWidth: 150,
        maxWidth: 150,
        maxHeight: 150,
        minHeight: 150,
        marginBottom: spacing.xxsmall,
      },
    }),
  },
  smallScreenCategory: {
    height: 150,
    padding: spacing.xxsmall,
    margin: spacing.xxxsmall,
  },
  category: {
    height: 250,
    padding: spacing.xxsmall,
    margin: spacing.xxxsmall,
  },
});
