import React, { useEffect, useState } from "react";
import { View, StyleSheet, Platform } from "react-native";
import { useResponsive } from "../../hooks/useResponsive";
import StructuredData from "../SEO/StructuredData";
import { breadcrumbsAtom, pageTitleAtom } from "../../atoms";
import { useAtom } from "jotai";
import { Breadcrumb as BreadcrumbT } from "../../types/breadcrumb";
import { Breadcrumb } from "./Breadcrumb";
import { breadcrumbStructuredData, format } from "./utils";

// breadcrumbs doc: https://docs.google.com/spreadsheets/d/1PYGoGMhG93Pxce0_WC9J5h8hmTmBv0GuXW9W5-Tm-V0/edit#gid=0

// For simple example, see watch.tsx, where there's no params
// For complex example, see FestivalHub.tsx, where there's params that may or may not be there.

const Breadcrumbs = () => {
  if (Platform.OS !== "web") return;

  const [breadcrumbsForPage] = useAtom(breadcrumbsAtom);
  const { isMediumAndDown } = useResponsive();

  if (window.location.pathname.includes("my-account")) return;
  if (!breadcrumbsForPage?.length) return;

  const homeBreadcrumb = {
    label: "Home",
    component: "Home",
    params: {},
  };

  const breadcrumbs = [homeBreadcrumb, ...breadcrumbsForPage];

  return (
    <View style={[styles.container, isMediumAndDown && { display: "none" }]}>
      {breadcrumbs.map((crumb: BreadcrumbT, i: number) => {
        const isLastCrumb = i === breadcrumbs.length - 1;

        return (
          <React.Fragment key={i}>
            <Breadcrumb
              title={format(crumb?.label, crumb?.removeFormat)}
              crumb={crumb}
              isLastCrumb={isLastCrumb}
            />
            {isLastCrumb && (
              <StructuredData
                type="BreadcrumbList"
                data={breadcrumbStructuredData(breadcrumbs)}
              />
            )}
          </React.Fragment>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flexDirection: "row",
    maxHeight: 30,
  },
});

export default Breadcrumbs;
