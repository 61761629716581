import React, { useState, useEffect } from "react";
import { fetchGet } from "../../../client";
import { APIResponse } from "../../../types/responses/APIResponse";
import TipsterHeader from "../../Screens/Tips/components/TipsterHeader";
import Tip from "../../Screens/Tips/components/Tip";
import { Tip as TipT } from "../../../types/tipster";
import { View } from "react-native";
import { TipLoader } from "../../States/Loading/Loaders";
import { cleanSlug } from "../../../utils/cleanSlug";
import { YYYYMMDD } from "../../../utils/TimeCalculator";

const RunnerTip = ({ id }) => {
  const [data, setData] = useState<TipT>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res: APIResponse<TipT> = await fetchGet(
          `/racing/tips/${id}/attachment`
        );
        setData(res.tip);
      } catch (err) {
        setError("Error loading tip: " + err?.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) return <TipLoader />;

  if (!data || error) return;

  return (
    <View style={{ minWidth: "100%" }}>
      {data.tipster && data.tipster.name.formatted && (
        <TipsterHeader
          type="tipster"
          heading={data.tipster.name.formatted}
          screen="IndividualTipster"
          params={{
            slug: cleanSlug(data.tipster.slug),
          }}
        />
      )}
      {data && <Tip tip={data} skipLoader />}
    </View>
  );
};

export default RunnerTip;
