import { View } from "react-native";
import React from "react";
import { useResponsive } from "../../../../../hooks/useResponsive";
import variables from "../../../../../styles/variables";
import SquareLoader from "../shared/SquareLoader";
import TextLoader from "../shared/TextLoader";
import MultilineTextLoader from "../shared/MultilineTextLoader";
import PinnedPostLoader from "./PinnedPostLoader";
import GridLayoutLoader from "../shared/GridLayout/GridLayoutLoader";

const IndividualColumnistLoader = () => {
  const { isMediumAndDown } = useResponsive();

  const { colors, spacing } = variables;

  const ColumnistBio = () => {
    const ColumnistImage = () => {
      return (
        <View style={{ marginRight: spacing.xsmall }}>
          <SquareLoader width={50} />
        </View>
      );
    };

    const ColumnistName = () => {
      return (
        <View style={{ marginBottom: spacing.xxsmall }}>
          <TextLoader width={"70%"} />
        </View>
      );
    };

    const ColumnistDescription = () => {
      return (
        <View>
          <MultilineTextLoader numberOfLines={4} />
        </View>
      );
    };

    if (isMediumAndDown)
      return (
        <View style={{ flexDirection: "row" }}>
          <ColumnistImage />
          <View style={{ flex: 1 }}>
            <ColumnistName />
            <ColumnistDescription />
          </View>
        </View>
      );

    if (!isMediumAndDown)
      return (
        <>
          <View
            style={{
              flexDirection: "row",
              borderBottomColor: colors.separatorLight,
              borderBottomWidth: 1,
              borderStyle: "solid",
              paddingBottom: spacing.small,
              alignItems: "center",
            }}
          >
            <ColumnistImage />
            <ColumnistName />
          </View>
          <View style={{ width: "100%" }}>
            <ColumnistDescription />
          </View>
        </>
      );
  };

  return (
    <>
      <ColumnistBio />
      <PinnedPostLoader />
      <GridLayoutLoader />
    </>
  );
};

export default IndividualColumnistLoader;
