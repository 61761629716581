import { atomWithReducer, loadable } from "jotai/utils";
import { PrimitiveAtom, atom } from "jotai";
import { PopupType } from "../components/Popup/Popup";
import { CmsData } from "../types/cms";
import { User, UserPreferences } from "../types/user";
import { Breadcrumb } from "../types/breadcrumb";
import { AdvertDetails, Placements } from "../types/advert";
import { Channel } from "../components/Screens/Watch/Live/LiveStreamSection";
import { getLiveChannelsInfo } from "../client/watch/live";
import { Preroll } from "../types/replay";
import { ActiveFestivalsData, Festival } from "../types/festival";
import { getActiveFestivals } from "../client/cms";
import { ArticleSlugId } from "../types/news/article";
import { NotificationsSection } from "../hooks/useNotifications";
import { Meetings } from "../types/resultedRaces";
import { Plan } from "../types/memberPlans";
import {
  MENU,
  MORE_MENU,
  MenuItem,
  MoreMenuItem,
} from "../components/Header/menu_types";
import { Cable } from "@rails/actioncable";
import { PageRes } from "../types/page";
import { ScrollView } from "react-native";

export type AuthModalType =
  | "login"
  | "join"
  | "upgrade"
  | "fdp"
  | "activate"
  | "ios_paid"
  | Plan
  | false;

export type GlobalPlayerSourceType = {
  source: string;
  preroll?: Preroll;
  getNewSourceAfterAd?: () => Promise<string>;
};

export const openAuthModalAtom = atom<AuthModalType>(
  false
) as PrimitiveAtom<AuthModalType>;

export const userAtom = atom<User & UserPreferences>(
  {} as User & UserPreferences
);

export const pageTitleAtom = atom<string>("");
export const scrollEnabledAtom = atom<boolean>(true);
export const openDrawerAtom = atom<boolean>(false);
export const globalFullscreenAtom = atom<boolean>(false);
export const pageDataAtom = atom<PageRes | undefined>(undefined);
export const autoMetaDescriptionAtom = atom<string>("");
export const breadcrumbsAtom = atom<Breadcrumb[]>([]);
export const headerEndYAtom = atom<number>(0);
export const openCalendarAtom = atom<boolean>(false);
export const maxDateAtom = atom<boolean>(false);
export const minDateAtom = atom<boolean>(false);
export const popupDataAtom = atom<PopupType[]>([] as PopupType[]);
export const cmsDataAtom = atom<CmsData>({} as CmsData);
export const menuAtom = atom<MenuItem[]>(MENU);
export const moreMenuAtom = atom<MoreMenuItem[]>(MORE_MENU);
export const menuItemWidthAtom = atom<number>(0);
export const currentRouteAtom = atom<string>("");
export const changeDetailsModalAtom = atom<boolean>(false);
export const updatedHorseTrackerAtom = atom<boolean>(false);
export const selectedPlanAtom = atom<number | undefined>(undefined);
export const advertisingAtom = atom<Placements>({} as Placements);
export const advertOverlayModalAtom = atom<AdvertDetails>({} as AdvertDetails);
export const seenOverlayAdvertsAtom = atom<number[]>([]);
export const seenTakeoverAdvertsAtom = atom<number[]>([]);
export const showSkyScraperAdAtom = atom<boolean>(true);
export const showTrackerOnRacecardAtom = atom<boolean>(true);
export const showOddsCheckerOnRacecardAtom = atom<boolean>(true);
export const showFormOnRacecardAtom = atom<boolean>(true);
export const showTimeformOnRacecardAtom = atom<boolean>(true);
const asyncLiveChannelsAtom = atom<Promise<Channel[]>>(
  async () => await getLiveChannelsInfo()
);
export const loadableLiveChannelsAtom = loadable(asyncLiveChannelsAtom);
export const selectedChannelsAtom = atom<Channel[]>([]);
export const liveStreamFullScreenAtom = atom<boolean>(false);
export const globalPlayerSourceAtom = atom<GlobalPlayerSourceType>({
  source: "",
});
export const playerPIDFocusedAtom = atom<string>("");
export const articlesForNavigationAtom = atom<ArticleSlugId[]>([]);
export const currentPathAtom = atom<string>("");
export const unreadNotificationCountAtom = atom<number>(0);
export const notificationsAtom = atom<NotificationsSection[]>([]);
export const meetingsToFilterAtom = atom<Meetings>([]);
export const selectedMeetingAtom = atom<string | undefined>(undefined);
export const festivalsDataAtom = atom<ActiveFestivalsData | undefined>(
  undefined
);
export const showPreferenceHeaderAtom = atom<boolean>(false);
export const updateTrackerPreferencesAtom = atom<boolean>(false);
export const updateAccountPreferencesAtom = atom<boolean>(false);

type PreferencesButton = {
  lastAction?: "SAVE" | "RESET" | "CANCEL" | undefined;
};

const initialPreferencesButton: PreferencesButton = {
  lastAction: undefined,
};

const preferencesButtonReducer = (
  state: PreferencesButton,
  action: { type: "SAVE" | "RESET" | "CANCEL" }
) => {
  return { ...state, lastAction: action.type };
};

export const preferencesButtonReducerAtom = atomWithReducer(
  initialPreferencesButton,
  preferencesButtonReducer
);

export const displayCancelBtnAtom = atom<boolean>(false);
// festival hubs

export const asyncFestivalsAtom = atom<Promise<Festival[]>>(
  async () => await getActiveFestivals()
);
export const appScrollRefAtom = atom<React.RefObject<ScrollView> | undefined>(
  undefined
);
export const appScrollRefAtom2 = atom<React.RefObject<ScrollView> | undefined>(
  undefined
);
export const dotDigitalSdkAtom = atom<any>(null);
export const freeVideoPeriodsAtom = atom<string | undefined>(
  undefined
) as PrimitiveAtom<string | undefined>;

export const openRaceMatrixAtom = atom<boolean>(false);
export const scriptsAtom = atom<string[]>([]);
export const headerHeightAtom = atom<number>(0);
export const tabBarHeightAtom = atom<number>(0);

export const OnspaceCableAtom = atom<Cable>({} as Cable);
export const isLoggedInAtom = atom<boolean>(false);
