import { StyleSheet, View } from "react-native";
import React from "react";
import Gap from "../../../../Gap";
import CircleLoader from "../shared/CircleLoader";
import TextLoader from "../shared/TextLoader";
import { useResponsive } from "../../../../../hooks/useResponsive";
import variables from "../../../../../styles/variables";

const WrappedRowLoader = () => {
  const { isSmallAndDown } = useResponsive();

  return (
    <View
      style={[styles.container, !isSmallAndDown && styles.containerBigScreen]}
    >
      <View style={[styles.block, !isSmallAndDown && styles.blockBigScreen]}>
        <CircleLoader />
        <View
          style={[
            styles.notification,
            !isSmallAndDown && styles.notificationBigScreen,
          ]}
        >
          <TextLoader />
        </View>
      </View>
      <View style={[styles.block, !isSmallAndDown && styles.blockBigScreen]}>
        <View style={styles.button}>
          <TextLoader />
        </View>
        <Gap />
        <View style={styles.button}>
          <TextLoader />
        </View>
      </View>
    </View>
  );
};

export default WrappedRowLoader;

const { spacing } = variables;

const styles = StyleSheet.create({
  container: {
    flexWrap: "wrap",
    marginVertical: spacing.small,
    paddingHorizontal: spacing.xsmall,
  },
  containerBigScreen: {
    flexDirection: "row",
    alignItems: "center",
  },
  block: {
    flexDirection: "row",
  },
  blockBigScreen: {
    flex: 0.5,
    justifyContent: "flex-end",
  },
  notification: {
    width: "80%",
  },
  notificationBigScreen: {
    alignSelf: "center",
  },
  button: {
    width: "30%",
    marginLeft: "12%",
  },
});
