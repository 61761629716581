import { fetchGet } from "../../../client";
import { CountryCode } from "../../../types/races/horse";
import { Meta, Pagination } from "../../../types/responses/APIResponse";

// These don't match up exactly with the other responses for the same
// models in the codebase( i.e. SearchTrack != Track from racecards.ts)
// TODO: Discuss with debono whether they should be or not..
interface SearchTrack {
  slug: string;
  name: string;
  country_code: CountryCode;
  logo_image: string | null;
}

interface SearchArticle {
  news_article: {
    slug: string;
    headline: string;
    hero: null;
    columnist: null;
    published: {
      state: string;
      datetime: Date;
    };
  };
}

interface SearchResult<T> {
  score: number;
  type: string;
  id: number;
  [key: string]: number | string | T;
}

export type Autocomplete = {
  search: {
    took: number;
    max_score: number;
  };
  hits: AutocompleteHit[];
} & Meta &
  Pagination;

export type AutocompleteHit = {
  score: number;
  name: string;
  type: string;
};

export type Search = {
  took: number;
  max_score: number;
  did_you_mean: string;
};

export type FullSearch = {
  search: Search;
  hits: FullSearchHit[];
  meta: Meta;
  pagination: Pagination;
  autocomplete: Autocomplete;
};

export type FullSearchHit = SearchResult<SearchArticle | SearchTrack>;

const setUpSearchUrl = (category: string) => {
  category = category.toLowerCase();
  const categoryMap = {
    "All Categories": null,
    horses: "horse",
    jockeys: "jockey",
    trainers: "trainer",
    racecourses: "track",
    news: "news_article",
    // Replays: "replay_videos", not yet implemented
    // "Programmes": 'programme', not yet implemented
  };
  const mappedCategory = categoryMap[category];
  return mappedCategory ? `search/${mappedCategory}` : "search";
};

export const getSearchAutoComplete = async (
  query: string,
  category: string
): Promise<Autocomplete> => {
  const url = setUpSearchUrl(category);
  return await fetchGet(`${url}/autocomplete`, { query });
  // return await await (await fetch("http://localhost:3000/autocomplete")).json();
};

export const getSearchResults = async (
  query: string,
  category: string
): Promise<FullSearch> => {
  const url = setUpSearchUrl(category);
  return await fetchGet(`${url}`, { query });
  // return await await (await fetch("http://localhost:3000/search")).json();
};
