import React, { useEffect } from "react";
import { View, StyleSheet, Platform } from "react-native";
import RText from "../../RText";
import variables from "../../../styles/variables";
import { useAtom } from "jotai";
import { pageDataAtom, openAuthModalAtom } from "../../../atoms";
import Layout from "../../Layout/Layout";

// If there is no content to display and the data request responds with an error, display this component

type Props = {
  message?: Error | string;
  style?: {};
  inLayout?: boolean;
};

const { spacing } = variables;

const ErrorState = ({
  message = "Sorry, something went wrong",
  style = { marginVertical: spacing.small },
  inLayout = false,
}: Props) => {
  const [pageData] = useAtom(pageDataAtom);
  const [_, setAuthModalOpen] = useAtom(openAuthModalAtom);

  useEffect(() => {
    if (!pageData) return;
    if (pageData?.error && pageData?.meta?.status === 401) {
      setAuthModalOpen("login");
    }
    if (pageData?.error && pageData?.meta?.status === 403) {
      if (Platform.OS !== "ios") setAuthModalOpen("upgrade");
    }
  }, [pageData]);

  let messageStr: string =
    typeof message === "object" ? message.message : message;

  if (pageData?.meta?.status === 401)
    messageStr = "You must be signed in to access this page";
  if (pageData?.meta?.status === 403)
    messageStr = "You must be a member to access this page";

  if (inLayout)
    return (
      <Layout
        content={
          <View style={style}>
            <RText
              family="secondary"
              weight="bold"
              uppercase
              style={[styles.errorState, style]}
            >
              {messageStr}
            </RText>
          </View>
        }
        headingLoader={false}
      />
    );

  return (
    <View style={style}>
      <RText
        family="secondary"
        weight="bold"
        uppercase
        style={[styles.errorState, style]}
      >
        {pageData?.meta?.status === 401
          ? "You must be signed in to access this page"
          : messageStr}
      </RText>
    </View>
  );
};

export default ErrorState;

const styles = StyleSheet.create({
  errorState: { textAlign: "center" },
});
