import React from "react";
import { View, StyleSheet, Platform, ViewStyle } from "react-native";
import RText, { RTextWeight, RTextColors, RTextSize } from "../RText/RText";
import { useResponsive } from "../../hooks/useResponsive";
import lazyImport from "../../utils/lazyImport/lazyImport";
import RSuspense from "../../utils/RSuspense/RSuspense";

type FilterType = "dropdown" | "calendarPicker" | "search" | "button";

type Props = {
  filter: FilterProps;
};

export type FilterProps = {
  type: FilterType;
  data?: any;
  style?: ViewStyle;
  size?: "small" | "medium" | "large" | "xlarge" | "xxlarge";
  text?: string;
  onSelect?: (selectedItem) => void;
  onButtonPress?: () => void;
  displayFutureDates?: boolean;
  limitToLastSevenDays?: boolean;
  dropdownStyles?: DropdownProps;
  dropdownSelectedOption?: string;
};

export type DropdownProps = {
  borderStyle?: {
    color: RTextColors;
    width: number;
  };
  containerStyle?: ViewStyle | ViewStyle[];
  fontStyle?: {
    color?: RTextColors;
    size?: RTextSize;
    weight?: RTextWeight;
    uppercase?: boolean;
    family?: "primary" | "secondary";
  };
  displayOnlyChevron?: boolean;
  placeholderAlwaysVisible?: boolean;
};

const Filter = ({ filter }: Props) => {
  const { isMediumAndDown } = useResponsive();

  const components = {
    dropdown: lazyImport(() => import("./Dropdown/")),
    calendarPicker: lazyImport(
      () => import("../CalendarPicker/CalendarPicker")
    ),
    search: lazyImport(() => import("./Search")),
    button: lazyImport(() => import("../Buttons/RButton")),
  };
  const {
    type,
    data,
    style,
    size,
    text,
    onSelect,
    onButtonPress,
    displayFutureDates,
    limitToLastSevenDays,
    dropdownStyles: {
      borderStyle,
      containerStyle,
      fontStyle,
      displayOnlyChevron,
      placeholderAlwaysVisible,
    } = {},
    dropdownSelectedOption,
  } = filter;

  const Component = components[type];

  if (type == "calendarPicker")
    return (
      <View
        style={[
          styles.filter,
          Platform.OS == "web" && { height: "100%" },
          style,
          // isMediumAndDown && { alignItems: "center" },
        ]}
      >
        {text ? (
          <RText color="primary" size={"md"}>
            {text}
          </RText>
        ) : null}
        <RSuspense>
          <Component
            size={size}
            displayFutureDates={displayFutureDates}
            limitToLastSevenDays={limitToLastSevenDays}
          />
        </RSuspense>
      </View>
    );

  if (type == "dropdown")
    return (
      <View style={[styles.filter, { maxWidth: "50%" }, style]}>
        <RSuspense>
          <Component
            data={data}
            onSelect={onSelect}
            defaultText={text}
            borderStyle={{
              color: borderStyle?.color,
              width: borderStyle?.width,
            }}
            containerStyle={containerStyle}
            displayOnlyChevron={displayOnlyChevron}
            placeholderAlwaysVisible={placeholderAlwaysVisible}
            fontStyle={{
              color: fontStyle?.color,
              size: fontStyle?.size,
              weight: fontStyle?.weight,
              uppercase: fontStyle?.uppercase,
              family: fontStyle?.family,
            }}
            selectedOption={dropdownSelectedOption || null}
          />
        </RSuspense>
      </View>
    );

  if (type == "button")
    return (
      <View style={styles.filter}>
        <RSuspense>
          <Component title={text} onPress={onButtonPress} />
        </RSuspense>
      </View>
    );

  return (
    <Component
      data={data}
      onSelect={onSelect}
      size={size}
      style={styles.filter}
    />
  );
};

const styles = StyleSheet.create({
  filter: {
    position: "absolute",
    right: 0,
    top: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "auto",
    ...Platform.select({
      android: {
        marginTop: -10,
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      },
      web: {
        marginTop: -10,
      },
    }),
    marginBottom: "auto",
  },
});
export default Filter;
