import React from "react";
import Loader from "./Utils/";
import { Platform } from "react-native";

/* Adjust the width and height to set the size of the loader */
/* Use the style prop for margin/padding */

type Props = {
  width?: string | number;
  height?: string | number;
  style?: {};
};

const CircleLoader: React.FC<Props> = ({ width = 40, height = 30, style }) => {
  return (
    <Loader.ContentLoader
      speed={2}
      width={width}
      height={height}
      backgroundColor="#e8e8eb"
      foregroundColor="#aeaeb2"
      style={style}
    >
      {Platform.OS === "web" ? (
        <circle cx="15" cy="15" r="15" />
      ) : (
        <Loader.Circle cx="15" cy="15" r="15" />
      )}
    </Loader.ContentLoader>
  );
};

export default CircleLoader;
