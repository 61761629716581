import { Linking, Platform } from "react-native";
import { FRONTEND_URL } from "../client";
import * as Application from "expo-application";
import Bugsnag from "@bugsnag/expo";

const APP_STORES = {
  android:
    "https://play.google.com/store/apps/details?id=com.racinguk.app&hl=en&gl=GB",
  ios: "https://apps.apple.com/gb/app/racing-tv-live-horse-racing/id352776768",
};

export const openStore = (origin: string) => {
  const storeUrl = APP_STORES[Platform.OS];

  Linking.canOpenURL(storeUrl)
    .then((supported) => supported && Linking.openURL(storeUrl))
    .then(() =>
      Bugsnag.notify(new Error(`Store opened from ${origin}`), (event) => {
        event.severity = "info";
      })
    );
};

export const isStoreVersionNewer = async () => {
  if (Platform.OS == "web" || __DEV__) return false;

  const res = await fetch(`${FRONTEND_URL}/api/native/version`);
  const latestVersions = await res.json();

  if (!latestVersions) return false; // escape early if no versions returned

  const storeVersion = latestVersions[Platform.OS];

  return (
    storeVersion.localeCompare(
      Application.nativeApplicationVersion,
      undefined,
      {
        numeric: true,
      }
    ) === 1
  );
};
