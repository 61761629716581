import React from "react";
import { View, StyleSheet } from "react-native";
import TextLoader from "../shared/TextLoader";
import ContentImagePreviewLoader from "../shared/ContentImagePreviewLoader";
import MultilineTextLoader from "../shared/MultilineTextLoader";
import { useResponsive } from "../../../../../hooks/useResponsive";
import variables from "../../../../../styles/variables";

const { spacing, colors } = variables;

const PinnedPostLoader = () => {
  const { isLargeAndUp } = useResponsive();

  return (
    <View
      style={[
        styles.container,
        isLargeAndUp && styles.containerWeb,
        !isLargeAndUp && styles.containerMobile,
      ]}
    >
      <View style={[styles.image, isLargeAndUp && styles.imageWeb]}>
        <ContentImagePreviewLoader />
      </View>

      <View style={isLargeAndUp && styles.articleWeb}>
        <TextLoader
          width={"90%"}
          height={35}
          style={{ marginVertical: spacing.xxsmall }}
        />
        {isLargeAndUp && (
          <TextLoader
            width={"80%"}
            height={35}
            style={{ marginVertical: spacing.xxsmall }}
          />
        )}
        <TextLoader width={"40%"} style={{ marginVertical: spacing.xxsmall }} />
        <MultilineTextLoader />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: spacing.xsmall,
    borderRadius: 5,
    marginTop: spacing.xsmall,
  },
  containerWeb: {
    flexDirection: "row",
    borderColor: colors.palette.neutral.x100,
    borderWidth: 1,
    borderStyle: "solid",
  },
  containerMobile: {
    backgroundColor: colors.palette.neutral.x100,
  },
  image: {
    aspectRatio: 16 / 9,
    borderRadius: 5,
  },
  imageWeb: {
    backgroundColor: colors.palette.neutral.x200,
    flex: 0.5,
  },
  articleWeb: {
    flex: 0.5,
    paddingHorizontal: spacing.medium,
    paddingVertical: spacing.xsmall,
  },
});

export default PinnedPostLoader;
