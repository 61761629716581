import { View } from "react-native";
import TextLoader from "../shared/TextLoader";
import React from "react";
import TipLoader from "./TipLoader";

const TipsterLoader = () => {
  return (
    <View>
      <TextLoader height={20} />
      <TipLoader />
      <TextLoader height={20} />
      <TipLoader />
      <TipLoader />
    </View>
  );
};
export default TipsterLoader;
