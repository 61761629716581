import { StyleSheet, View } from "react-native";
import React from "react";
import RRadioButtonLoader from "./RRadioButtonLoader";

type Props = {
  label?: boolean;
  icon?: boolean;
  items?: number[];
};

const RadioButtonsLoader = ({ label, icon, items }: Props) => {
  return (
    <View style={styles.container}>
      {items.map((idx) => {
        return <RRadioButtonLoader label={label} icon={icon} key={idx} />;
      })}
    </View>
  );
};

export default RadioButtonsLoader;

const styles = StyleSheet.create({
  container: {
    flexWrap: "wrap",
    flexDirection: "row",
  },
});
