import React, { useState, useEffect } from "react";
import RVideoPlayer from "../../RVideoPlayer";
import { View, StyleSheet } from "react-native";
import SquareLoader from "../../States/Loading/Loaders/shared/SquareLoader";
import { getReplay } from "../../../client/watch/replays";
import { formatTitleShort } from "../../RaceSubHeading/RaceSubHeading";
import { useAtom } from "jotai";
import { openAuthModalAtom, userAtom } from "../../../atoms";
import { prepareParams } from "../../Screens/Results/utils";

// Most of this component is ripped from individualReplay.
// Given the amount of state objects in play, the complexit/benefit
// ratio of DRYING up this and IndidivualReplay is not worth it.
const ReplayAsset = ({ race }) => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedReplay, setSelectedReplay] = useState<any>();
  const [autoPlay, setAutoPlay] = useState<boolean>(false);
  const [titleShort, setTitleShort] = useState<string>("");
  const [, setAuthModalOpen] = useAtom(openAuthModalAtom);
  const [, setUser] = useAtom(userAtom);

  const fetchCurrentReplay = async (
    withSource?: boolean,
    prerollToken?: string
  ) => {
    if (!prerollToken) setAutoPlay(false);
    setLoading(true);

    try {
      const { date, time, track } = prepareParams({
        time: race.start_time_scheduled,
        track: race.track.slug,
      });

      const replay = await getReplay({
        date,
        time,
        track,
        prerollToken,
        withSource,
      });
      setSelectedReplay(replay);
      setTitleShort(formatTitleShort(replay.startTime, track));
    } catch (error) {
      if (error?.message === "UNAUTHORISED") {
        setUser({});
        setAuthModalOpen("login");
      }
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrentReplay(false);
  }, [race]);

  if (loading)
    return (
      <View style={styles.loader}>
        <SquareLoader width="100%" />
      </View>
    );

  return (
    <RVideoPlayer
      source={selectedReplay && selectedReplay.sourcePlayer}
      displayType="embedded"
      errorMessage={error && error.message}
      autoPlay={autoPlay}
      onPlayPress={() => {
        if (!selectedReplay?.sourcePlayer) {
          fetchCurrentReplay(true);
          setAutoPlay(true);
        }
      }}
      advertisement={
        selectedReplay?.preroll && {
          actionDomain: selectedReplay?.preroll?.action_domain,
          actionText: selectedReplay?.preroll?.action_text,
          source: selectedReplay?.sourcePlayer,
          logo: selectedReplay?.preroll?.icon?.placeholder_image_url,
          prerollsId: selectedReplay?.preroll?.id,
          onEnd: () => {
            setSelectedReplay(null);
            setAutoPlay(true);
            fetchCurrentReplay(true, selectedReplay.prerollToken);
          },
        }
      }
      title={titleShort}
      description={selectedReplay?.description}
      poster={selectedReplay?.thumbnailUrl}
      contentType={selectedReplay?.contentType}
      uploadDate={
        new Date(
          selectedReplay?.createdAt || selectedReplay?.startTime.split("T")[0]
        )
      }
    />
  );
};

export default ReplayAsset;

const styles = StyleSheet.create({
  loader: {
    width: "100%",
    aspectRatio: 16 / 9,
  },
});
