export type MenuItem = (typeof MENU)[number];
export type MoreMenuItem = (typeof MORE_MENU)[number];

export const MENU = [
  { title: "Home", route: "Home" },
  { title: "Watch", route: "Watch" },
  { title: "Racecards", route: "Racecards" },
  { title: "Results", route: "Results" },
  { title: "Tips", route: "RTVTipsters" },
  { title: "News", route: "AllNews" },
  { title: "Free Bets", route: "FreeBets" },
  { title: "Members", route: "Benefits" },
];

export let MORE_MENU = [
  { title: "Racecourses", route: "Racecourses" },
  { title: "Podcasts", route: "Podcasts" },
  { title: "Competitions", route: "Competitions" },
  { title: "Tracker", route: "Tracker" },
];
