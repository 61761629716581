import React, { Suspense } from "react";

interface RSuspenseProps {
  children: React.ReactNode;
}

const RSuspense: React.FC<RSuspenseProps> = ({ children }) => {
  return <Suspense fallback={<></>}>{children}</Suspense>;
};

export default React.memo(RSuspense);
