// lazyImport.web.ts
import React from "react";

const lazyImport = (
  importFunction: () => Promise<any>, // Change the type to Promise<any>
  exportName?: string // Add an optional parameter for named exports
) => {
  return React.lazy(() =>
    importFunction().then((module) => {
      // Check if a named export is specified
      if (exportName) {
        return { default: module[exportName] };
      } else {
        return module;
      }
    })
  );
};

export default lazyImport;
