import React, { forwardRef } from "react";
import {
  Platform,
  TouchableOpacity,
  ViewStyle,
  StyleSheet,
  TouchableOpacityProps,
} from "react-native";
import usePressableProps from "../../hooks/usePressableProps";

interface RTouchableOpacityProps extends TouchableOpacityProps {
  navScreen: string;
  navParams: any;
  children: React.ReactNode | React.ReactNode[];
  style?: ViewStyle | ViewStyle[];
  shouldOpenInNewTab?: boolean; // New prop to decide if the link should open in a new ta
  preNavAction?: () => void;
  rest?: any;
}

const RTouchableOpacity = (
  {
    navScreen,
    navParams,
    children,
    style,
    shouldOpenInNewTab = false, // New prop to decide if the link should open in a new tab
    preNavAction = () => {},
    ...rest
  }: RTouchableOpacityProps,
  ref
) => {
  style = Array.isArray(style) ? StyleSheet.flatten(style) : style;

  const pressableProps = usePressableProps({
    screen: navScreen,
    params: navParams,
    openInNewTab: shouldOpenInNewTab,
    preNavAction,
  });

  if (Platform.OS === "web") {
    return (
      <TouchableOpacity
        ref={ref}
        onMouseEnter={() => rest?.onHoverIn && rest?.onHoverIn()}
        onMouseLeave={() => rest?.onHoverOut && rest?.onHoverOut()}
        style={style}
        {...pressableProps}
        {...rest}
      >
        {children}
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity ref={ref} style={style} {...pressableProps} {...rest}>
      {children}
    </TouchableOpacity>
  );
};

export default forwardRef(RTouchableOpacity);
