import React, { useState } from "react";
import {
  StyleSheet,
  View,
  Pressable,
  Platform,
  ViewStyle,
  TextStyle,
} from "react-native";
import variables from "../../styles/variables";
import RText, { RTextSize } from "../RText/RText";
import Icon from "../Icon/Icon";
import { useResponsive } from "../../hooks/useResponsive";
import usePressableProps from "../../hooks/usePressableProps";

type Props = {
  navigateTo?: any;
  text: string;
  queryParams?: any;
  dark?: boolean;
  navOverride?: () => void;
  size?: RTextSize;
  style?: ViewStyle;
  textStyle?: TextStyle;
};

const { spacing } = variables;

const ShowMore: React.FC<Props> = ({
  navigateTo,
  text,
  queryParams,
  dark = false,
  navOverride,
  style,
  textStyle,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { isMediumAndDown, isSmallAndDown } = useResponsive();

  const pressableProps = navOverride
    ? {}
    : usePressableProps({
        screen: navigateTo,
        params: queryParams,
      });

  return (
    <Pressable
      onPress={navOverride ? navOverride : undefined}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={[
        {
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: Platform.OS === "android" ? 8 : 0,
        },
        isHovered && styles.hover,
        style,
      ]}
      {...pressableProps}
    >
      <RText
        size={Platform.OS === "android" ? "xs" : isMediumAndDown ? "sm" : "md"}
        lineHeight={isMediumAndDown ? 18 : 20}
        color={dark ? "white" : "primary"}
        style={[
          {
            alignItems: "center",
            justifyContent: "flex-end",
            textAlign: "right",
            minHeight: 20,
          },
          textStyle,
        ]}
      >
        {text}
      </RText>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginLeft: Platform.OS === "android" ? 0 : spacing.xxsmall,
        }}
      >
        <Icon
          name={dark ? "chevronBoldRightWhite" : "chevronBoldRight"}
          size={Platform.OS === "android" ? "xsmall" : "small"}
          // fill="palette.rtv.white" - Fill doesn't work for the chevrons. no idea why.
        />
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  hover: {
    transform: [{ scale: 1.1 }],
  },
});

export default ShowMore;
