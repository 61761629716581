import { fetchGet } from "..";
import { Odd, Runner } from "../../types/races/runner";
import { Bookmaker, OddBookmakers } from "../../types/bookmaker";
import { APIResponse } from "../../types/responses/APIResponse";
import { PriceFormat, TipRunner } from "../../types/tipster";
import { chunk } from "../../utils/array";

export const findBestOdds = (odds: Odd[]) => {
  return odds.sort(
    (a, b) => Number(b.price.decimal) - Number(a.price.decimal)
  )[0];
};

export const getOdds = async (runners: Runner[] | TipRunner[]) => {
  try {
    const runnerIds = runners.map((runner) => `runner_ids[]=${runner.id}`);
    if (!runnerIds) return;

    // if runner ids is over 200, then split into multiple requests
    const CHUNK_SIZE = 200;
    let odds;
    if (runnerIds.length > CHUNK_SIZE) {
      // split into chunks of CHUNK_SIZE
      const splitRunnerIds = chunk(runnerIds, CHUNK_SIZE);

      const splitOdds = await Promise.all(
        splitRunnerIds.map(async (runnerIds) =>
          fetchGet(`racing/runner/odds?${runnerIds.join("&")}`)
        )
      );

      odds = splitOdds.reduce(
        (acc, odds) => {
          return {
            runners: [...acc.runners, ...odds.runners],
            bookmakers: odds.bookmakers,
          };
        },
        { runners: [], bookmakers: [] }
      );
    } else {
      odds = await fetchGet(`racing/runner/odds?${runnerIds.join("&")}`);
    }

    const runnersWithOdds = runners.map((runner) => {
      const runnerOdds = odds.runners.find((odd) => odd.id === runner.id);

      if (runnerOdds) {
        const bestOdds = findBestOdds(runnerOdds.odds);
        return {
          ...runner,
          odds: runnerOdds.odds,
          bestOdds,
        };
      }

      return runner;
    });

    return [runnersWithOdds, odds.bookmakers];
  } catch (e) {
    console.log("Error getting odds:", e); // error in fetch return runners without odds
    return [runners, undefined];
  }
};

export const bestOddsForBookmaker = (odds: Odd[], bookmakerId: number) => {
  const bookmakerOdds = odds.filter((odd) => odd.bookmaker_id === bookmakerId);
  return findBestOdds(bookmakerOdds);
};

export const getBookmaker = async (
  bookmakerId: number
): Promise<APIResponse<Bookmaker>> => {
  return await fetchGet(`bookmakers/${bookmakerId}`);
};

export const priceFormats = {
  fractions: "fractional" as PriceFormat,
  decimals: "decimal" as PriceFormat,
  moneyline: "moneyline" as PriceFormat,
};

export const sortOddsByPriority = (
  oddsArray: [] | Odd[],
  bookmakers: OddBookmakers
): Odd[] => {
  if (!oddsArray || !bookmakers) return [];

  oddsArray.forEach((odd: Odd) => {
    odd.odds_priority = bookmakers[odd.bookmaker_id].odds_priority;
  });

  return oddsArray.sort((a: Odd, b: Odd) => {
    // Create priority variables and assign odds_priority. Treat null values as lowest priority by assiging an Infinity value that's larger than any odds_priority value
    const priorityA = a.odds_priority ?? Infinity;
    const priorityB = b.odds_priority ?? Infinity;

    // Then sort the priority variables
    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    // For null values, sort by bookmaker_id to ensure odds aren't randomly ordered and therefore inconsistent across different oddsArrays
    return a.bookmaker_id - b.bookmaker_id;
  });
};
