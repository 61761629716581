// save jwt token in storage

import { STORAGE_KEYS, storage } from "../../utils/storage";

export const saveJwtToken = async (token: string) => {
  await storage.save({
    key: STORAGE_KEYS.JWT_TOKEN,
    data: token,
  });
};

export const getJwtToken = async () => {
  try {
    const token = await storage.load({
      key: STORAGE_KEYS.JWT_TOKEN,
    });
    return token;
  } catch (e) {
    // console.log("***** COULD NOT GET JWT TOKEN *****", e);
    return undefined;
  }
};

export const removeJwtToken = async () => {
  await storage.remove({
    key: STORAGE_KEYS.JWT_TOKEN,
  });
};

// this method lets us get the current route name without a route object
// which seems to be always null inside this RWebView component
export const getCurrentRouteName = (navigation) => {
  const rootState = navigation.getRootState();
  let route = rootState.routes[rootState.index];

  // Traverse through nested navigators
  while (route.state && route.state.index !== undefined) {
    route = route.state.routes[route.state.index];
  }

  console.log("Current Route Name:", route.name);
  return route.name;
};
