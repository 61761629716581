const dayjs = require("dayjs");

export const findRaceByTitleInEntries = (entries: any, title: string) =>
  entries.find((r) => r.race.title.toLowerCase() === title.toLowerCase());

export const findCourseByThreeLetterName = (
  entries: any,
  threeLetterName: string,
  recordType: string = "futureEntries"
) =>
  entries.find((c) => {
    if (recordType === "futureEntries")
      return c.race.meeting.track.name_3_char === threeLetterName;

    if (recordType === "form") return c.track.name_3_char === threeLetterName;
  });

export const findJockeySlugByName = (entries: any, name: string) => {
  const entry = entries.find(
    (j) => j.jockey?.name === name || j.jockey_name === name
  );
  return entry.jockey.slug;
};

export const findHorseSlugByName = (entries: any, name: string) => {
  const entry = entries.find(
    (h) => h.horse?.name === name || h.horse_name === name
  );
  return entry.horse.slug;
};

export const findTrainerSlugByName = (entries: any, name: string) => {
  const entry = entries.find(
    (t) => t.trainer?.name === name || t.trainer_name === name
  );
  return entry.trainer.slug;
};

export const isSameDate = (date1: Date, date2: Date) =>
  date1 === dayjs(date2).format("YYYY-MM-DD");

export const allDaysBetween = (start: Date, end: Date) => {
  //  get array of dates between start and end
  const arr = [];
  const dt = new Date(start);
  while (dt <= end) {
    arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
};
