import { useEffect } from "react";
import { userAgentIsSSR } from "../utils/userAgent";

export const useCookieBanner = () => {
  useEffect(() => {

    if(userAgentIsSSR()) return

    const src =
      window.location.hostname === "www.racingtv.com"
        ? "79dabfad-4e45-4c80-89cf-f70b98273b7f"
        : "79dabfad-4e45-4c80-89cf-f70b98273b7f-test";

    const script = document.createElement("script");
    script.src =
      "https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js";
    script.type = "text/javascript";
    script.charset = "UTF-8";
    script.setAttribute("data-domain-script", src);
    script.async = true;
    script.defer = true;

    document.head.appendChild(script);
  }, []);
};
