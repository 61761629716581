import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import RText from "../../../RText";
import variables from "../../../../styles/variables";
import RHeading from "../../../RHeading/RHeading";

type HeaderProps = {
  headerLeft: string;
  headerLeftNonBold?: string;
  headerRight?: string;
  link?: string;
};

const TableTitle = ({
  headerLeft,
  headerLeftNonBold,
  headerRight,
  link,
}: HeaderProps) => {
  const navigation = useNavigation(); //uncomment to set up navigation

  return (
    <View style={styles.headerContainer}>
      <View style={{ flexDirection: "row" }}>
        <RHeading level="3" boldText uppercase>
          {headerLeft}
        </RHeading>
        {headerLeftNonBold && (
          <View style={{ flexDirection: "row" }}>
            <RText> </RText>
            <RHeading level="3" uppercase>
              {headerLeftNonBold}
            </RHeading>
          </View>
        )}
      </View>
      {link && (
        <TouchableOpacity onPress={() => navigation?.navigate(link)}>
          <RText weight="bold" color="secondary">
            {headerRight}
          </RText>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default TableTitle;

const { colors } = variables;

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.palette.neutral.x100,
    paddingHorizontal: 10,
    height: 30,
    marginTop: 10,
  },
});
