import { Meeting } from "../../../../types/resultedRaces";
import { YYYYMMDD, months } from "../../../../utils/TimeCalculator";
import { cleanSlug } from "../../../../utils/cleanSlug";
import {
  daysOfTheWeek,
  lastSevenDays,
  nextSevenDays,
} from "../../../Tabs/helpers";

// 2023-01-18T13:15:00+00:00 => 2023-apr-18
// const DDMMMYYYY = (date: Date) => {
//   const [year, month, day] = new Date(date)
//     .toISOString()
//     .split("T")[0]
//     .split("-");
//   return `${year}-${months[parseInt(month) - 1].toLowerCase()}-${day}`;
// };

// shared function to prepare params for the navigation on Individual race screen
export const prepareParams = (params: any) => {
  let date, time, formattedTime;
  // time can be either in the format of 2021-01-18T13:15:00+00:00 or 1315
  if (params.time?.includes("T") || params.startTime?.includes("T")) {
    [date, time] = (params.time || params.startTime)?.split("T"); //2021-01-18T13:15:00+00:00 => 2021-01-18 13:15:00
    formattedTime = time.split(":").slice(0, 2).join(""); // 13:15:00 => 1315
  } else {
    date = params.date;
    formattedTime = params.time;
  }

  let slugHolder, idHolder;
  if (params?.slug) {
    let [slug, id] = params?.slug && cleanSlug(params.slug).split("/");
    slugHolder = slug;
    idHolder = id;
  }

  return {
    date: (params.date && params.date) || (date && YYYYMMDD(date)), // `${day}-${months[parseInt(month) - 1]}-${year}`),
    track:
      params.track && cleanSlug(params.track.replace(/\s+/g, "").toLowerCase()), // Del Mar => delmar
    time: formattedTime && formattedTime,
    ...(slugHolder ? { slug: slugHolder } : {}),
    ...(idHolder ? { id: idHolder } : {}),
  };
};

export const getLabelFromDate = (date: Date) => {
  const fortnight = [...lastSevenDays, ...nextSevenDays];

  const index = fortnight.findIndex(
    (d) => d.toDateString() === date.toDateString()
  );
  return index < 7 ? prevLabels[index] : nextLabels[index - 7];
};

export const nextLabels = {
  0: "today",
  1: "tomorrow",
  2: daysOfTheWeek[nextSevenDays[2].getDay()],
  3: daysOfTheWeek[nextSevenDays[3].getDay()],
  4: daysOfTheWeek[nextSevenDays[4].getDay()],
  5: daysOfTheWeek[nextSevenDays[5].getDay()],
  6: daysOfTheWeek[nextSevenDays[6].getDay()],
};

export const prevLabels = {
  0: "today",
  1: "yesterday",
  2: daysOfTheWeek[lastSevenDays[2].getDay()],
  3: daysOfTheWeek[lastSevenDays[3].getDay()],
  4: daysOfTheWeek[lastSevenDays[4].getDay()],
  5: daysOfTheWeek[lastSevenDays[5].getDay()],
  6: daysOfTheWeek[lastSevenDays[6].getDay()],
};

export const nextLabelsNotRelative = {
  0: daysOfTheWeek[nextSevenDays[0].getDay()],
  1: daysOfTheWeek[nextSevenDays[1].getDay()],
  2: daysOfTheWeek[nextSevenDays[2].getDay()],
  3: daysOfTheWeek[nextSevenDays[3].getDay()],
  4: daysOfTheWeek[nextSevenDays[4].getDay()],
  5: daysOfTheWeek[nextSevenDays[5].getDay()],
  6: daysOfTheWeek[nextSevenDays[6].getDay()],
};

export const prevLabelsNotRelative = {
  0: daysOfTheWeek[lastSevenDays[0].getDay()],
  1: daysOfTheWeek[lastSevenDays[1].getDay()],
  2: daysOfTheWeek[lastSevenDays[2].getDay()],
  3: daysOfTheWeek[lastSevenDays[3].getDay()],
  4: daysOfTheWeek[lastSevenDays[4].getDay()],
  5: daysOfTheWeek[lastSevenDays[5].getDay()],
  6: daysOfTheWeek[lastSevenDays[6].getDay()],
};

export const swapKeysAndValues = (obj: Object): Object => {
  return Object.fromEntries(Object.keys(obj).map((key) => [obj[key], key]));
};

export const favoritesMapper = {
  1: "f",
  2: "jf",
  3: "cf",
};

export const orderByFirstRace = (meetings) =>
  meetings.sort(
    (a: Meeting, b: Meeting) =>
      new Date(a.completed_races[0].start_time_scheduled).getTime() -
      new Date(b.completed_races[0].start_time_scheduled).getTime()
  );

export const orderMeetings = (meetings: Meeting[]) => {
  const [ukAndIreMeetings, internationalMeetings] = meetings.reduce(
    (acc, meeting) => {
      if (
        meeting.track.country_code.iso === "gb" ||
        meeting.track.country_code.iso === "ie"
      ) {
        acc[0].push(meeting);
      } else {
        acc[1].push(meeting);
      }
      return acc;
    },
    [[], []]
  );

  return [
    ...orderByFirstRace(ukAndIreMeetings),
    ...orderByFirstRace(internationalMeetings),
  ];
};
