import { fetchGet } from "..";
import { AntepostComponent } from "../../components/Screens/Races/Antepost";
import { IndividualAntepostComponent } from "../../components/Screens/Races/Antepost/IndividualAntepostRacecard";
import { Antepost } from "../../types/races/antepost";
import { Race } from "../../types/races/race";
import { Meetings } from "../../types/resultedRaces";
import { APIResponse, Meta } from "../../types/responses/APIResponse";
import { Meeting } from "../../types/races/meeting";
import { Runner } from "../../types/races/runner";
import { formatDateLong } from "../../utils/TimeCalculator";
import { prepareParams } from "../../components/Screens/Results/utils";
import {
  isParamFriendlyDateFormat,
  isParamFriendlyTimeFormat,
} from "../../utils/validParams";

type MeetingsByDateResponse = {
  meta: Meta;
  meetings: Meetings;
};

type VideoRacePreview = {
  sources: {
    url: string;
    content_type: string;
    video_codec: string;
    audio_codec: string;
  }[];
};

export type RaceData = {
  race: Race;
  runners: Runner[];
};

export const getRace = async (
  date: string,
  track: string,
  time: string
): Promise<APIResponse<RaceData>> => {
  const { date: preparedDate, time: preparedTime } = prepareParams({
    date: date,
    time: time,
  });

  if (
    isParamFriendlyDateFormat(preparedDate) &&
    isParamFriendlyTimeFormat(preparedTime)
  )
    return await fetchGet(
      `racing/racecards/${preparedDate}/${track}/${preparedTime}`
    );
  else throw new Error("Invalid date or time format");
};

// Racecards API
export type RacecardResponse = {
  meta: Meta;
  meetings: Meeting[];
};

export const getRacecardsByDate = async (
  date?: string
): Promise<RacecardResponse> => {
  if (isParamFriendlyDateFormat(date))
    return await fetchGet(`racing/racecards/list/${date}`); // yyyy-mm-dd
  else throw new Error("Invalid date format");
};

export const getAntepost = async (
  month?: string
): Promise<AntepostComponent> => {
  if (!isParamFriendlyDateFormat(month)) {
    throw new Error("Invalid date format");
  }
  const monthDate = month ? `/${month}` : "";
  const result: Antepost = await fetchGet(
    `racing/racecards/ante-post/list${monthDate}`
  );

  const thisWeekResult = await fetchGet("racing/racecards/ante-post/list");

  const weeklyRacelist = thisWeekResult.races_grouped_by_day.filter((day) => {
    const today = new Date();
    const fiveDaysFromNow = new Date();
    fiveDaysFromNow.setDate(today.getDate() + 5);
    const givenDate = new Date(day.antepost_race_date);
    if (givenDate >= today && givenDate <= fiveDaysFromNow) return day;
  });

  const { all_months_of_antepost_races, races_grouped_by_day } = result;
  return {
    allMonths: all_months_of_antepost_races.map((month) =>
      new Date(month[1]).toLocaleDateString("en-GB", {
        month: "long",
        year: "numeric",
      })
    ),
    racelist: races_grouped_by_day,
    weeklyRacelist,
  };
};

export const getIndividualAntepost = async (
  date: string
): Promise<IndividualAntepostComponent> => {
  const monthDate = date ? `/${date}` : "";
  const { races_grouped_by_day }: Antepost = await fetchGet(
    `racing/racecards/ante-post/list${monthDate}`
  );
  if (races_grouped_by_day.length > 0) {
    const [meeting] = races_grouped_by_day.filter(
      (day) => day.antepost_race_date === date
    );

    const { antepost_races } = meeting;
    const races = antepost_races.map((r) => {
      return {
        id: `${r.id}`,
        time: r.start_time_scheduled
          .split("T")[1]
          .split(":")
          .slice(0, 2)
          .join(":"),
        runners: r.antepost_runners.map((runner) => {
          return {
            horseName: runner.horse_name,
            propositions: runner.propositions,
          };
        }),
      };
    });

    return {
      date: formatDateLong(meeting.antepost_race_date),
      races,
    };
  } else return null;
};

// NonRunners API
export const getNonRunners = async (): Promise<APIResponse<Meeting[]>> => {
  return await fetchGet(`racing/racecards/non-runners`);
};
// export const cleanSlug = (slug: string) => {
//   if (slug.startsWith("/")) slug = slug.substring(1); // remove first /
//   return slug;
// };

// QLS Race Previews API

export const getRacePreview = async (
  date: string,
  track: string,
  time: string
): Promise<APIResponse<Race>> => {
  if (
    // (isToday(date) || isTomorrow(date)) && // this breaks for americas because date
    // is taken from start of day in UTC, e.g. 00:00
    // then converted to local time, making it day before.
    isParamFriendlyDateFormat(date) &&
    isParamFriendlyTimeFormat(time)
  )
    return await fetchGet(`racing/racecards/${date}/${track}/${time}/preview`);
  else throw new Error("Invalid date or time format");
};

export const getVideoRacePreview = async ({
  date,
  track,
  race,
  type,
  prerollToken,
}: {
  date: string;
  track: string;
  race: number;
  type: "form-analysis" | "predictor";
  prerollToken?: string;
}): Promise<APIResponse<VideoRacePreview>> => {
  return await fetchGet(
    `member/watch/previews/${date}/${track}/${race}/${type}`,
    prerollToken ? { preroll_token: prerollToken } : {}
  );
};

export const getRaceTips = async (
  date: string,
  track: string,
  time: string
) => {
  if (isParamFriendlyDateFormat(date) && isParamFriendlyTimeFormat(time))
    return await fetchGet(`racing/racecards/${date}/${track}/${time}/tips`);
  else throw new Error("Invalid date or time format");
};
