import LoadingSpinner from "../components/LoadingSpinner";
import { finishStates, FinishStateChar } from "../types/finishState";
import { getOrdinal } from "./TimeCalculator";

// returns 1st/12
export const getRunnerFinishText = (
  position: number,
  text: FinishStateChar,
  deadHeat?: boolean
) => {
  if (text == null && position == null) {
    return <LoadingSpinner />;
  }
  if (position) {
    return getOrdinal(position);
  } else {
    return `${finishStates[text]?.text ?? text} ${deadHeat ? "DH " : ""}`;
  }
};

// returns  1/12 or PU/12 if 'pulled up'
export const getRunnerPosition = ({
  position, // 1-20
  text, // 1st, 2nd, etc
}: {
  position: number;
  text: string;
}) => {
  // debugger;
  if (position) {
    return position;
  } else if (text) {
    const res = finishStates[text]?.text || text;

    // remove 'st', 'nd', 'rd' or 'th' from res
    if (
      ["st", "nd", "rd", "th"].map((pos) => res.endsWith(pos)).includes(true)
    ) {
      return res.slice(0, res.length - 2);
    } else {
      return res.toUpperCase(); // PU, F, etc
    }
  } else return "-";
};
