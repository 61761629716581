import React, { useState, useEffect } from "react";
import RVideoPlayer from "../../RVideoPlayer";
import { OnDemandVideo, Video } from "../../../types/onDemand";
import { View, StyleSheet } from "react-native";
import SquareLoader from "../../States/Loading/Loaders/shared/SquareLoader";
import { EmptyState } from "../../States/states";
import { getOnDemandVideo } from "../../../client/watch/onDemand";
import { useAtom } from "jotai";
import { globalPlayerSourceAtom } from "../../../atoms";
import { VideoPreviewIcon } from "../../ContentPreview";
import { DEFAULT_RTV_LIVE_IMAGE } from "../../Image/images/defaultImages";

const DemandVideoAsset = ({ id, metadata }) => {
  const [, setGlobalPlayerSource] = useAtom(globalPlayerSourceAtom);

  const [data, setData] = useState<{
    info: Video;
    player: OnDemandVideo;
  }>({ info: metadata, player: null });
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (id, preroll_token = undefined) => {
    try {
      const player: OnDemandVideo = await getOnDemandVideo(id, preroll_token);
      if (!player) throw new Error("No player data returned");
      if (!preroll_token) setData({ ...data, player });
      else return player.sources;
    } catch (err) {
      setError("Error loading demand video asset: " + err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!data.player?.sources) return;
    const { player } = data;
    setGlobalPlayerSource({
      source: player.sources,
      preroll: player.preroll,
      getNewSourceAfterAd: async () => {
        return await fetchData(id, player?.prerollToken);
      },
    });
  }, [data.player?.sources]);

  const renderVideoPlayer = () => {
    const { info } = data;
    if (loading)
      return (
        <View style={styles.ratio}>
          <SquareLoader width="100%" />
        </View>
      );
    else if (error) {
      return <EmptyState message={error || "unknown error"} />;
    } else if (!info) return <EmptyState message="no video available" />;
    else
      return (
        <VideoPreviewIcon
          thumbnailUrl={info?.placeholder_image_url || DEFAULT_RTV_LIVE_IMAGE}
          onPress={() => {
            setData({ ...data, player: null });
            fetchData(id);
          }}
          size="large"
        />
      );
  };

  return <View style={styles.ratio}>{renderVideoPlayer()}</View>;
};

export default DemandVideoAsset;

const styles = StyleSheet.create({
  ratio: {
    width: "100%",
    aspectRatio: 16 / 9,
  },
});
