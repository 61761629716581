/**
 * check if the date is in the correct format: YYYY-MM-DD
 *
 * @example isParamFriendlyDateFormat('2024-07-01') => true
 * @example isParamFriendlyDateFormat('2024-07-1') => false
 *
 * @param date the date to check
 * @returns true if the date is in the correct format, false otherwise
 */

export const isParamFriendlyDateFormat = (date: string): boolean => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  return dateRegex.test(date);
};

/**
 * check if the time is in the correct format: HHMM
 *
 * @example isParamFriendlyTimeFormat('2359') => true
 * @example isParamFriendlyTimeFormat('24:00') => false
 *
 * @param time the time to check
 * @returns true if the time is in the correct format, false otherwise
 */

export const isParamFriendlyTimeFormat = (time: string): boolean => {
  const timeRegex = /^(?:[01]\d|2[0-3])[0-5]\d$/;
  return timeRegex.test(time);
};

/**
 *
 * check if a string only has numeric characters
 *
 * @example isNumeric('123') => true
 * @example isNumeric('abc') => false
 * @example isNumeric('123abc') => false
 *
 * @param str the string to check
 * @returns true if the string only has numeric characters, false otherwise
 */

export const isNumeric = (str: string): boolean => {
  return /^\d+$/.test(str);
};
