import { useEffect, useRef, useState } from "react";

const useScrollGap = (initPosition = 0) => {
  const [newMarginTop, setNewMarginTop] = useState(initPosition);
  const appScroller = useRef(null);

  useEffect(() => {
    const handleScroll = (e) => {
      setNewMarginTop(e.nativeEvent.target.scrollTop);
    };

    try {
      appScroller.current = document.getElementById("appScrollView");
      appScroller.current.addEventListener("scroll", handleScroll);
    } catch (e) {
      return; // no document, just return.
    }

    return () => {
      if (appScroller.current) {
        appScroller.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return newMarginTop;
};

export default useScrollGap;
