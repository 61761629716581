import React from "react";
import {
  View,
  StyleSheet,
  Platform,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import variables from "../../styles/variables";
import { useResponsive } from "../../hooks/useResponsive";
import { useNavigation } from "@react-navigation/native";
import RText from "../RText";
import { MenuProps, useShowSubmenu } from "../../hooks/useShowSubmenu";
import { useAtom } from "jotai";
import { currentRouteAtom } from "../../atoms";
import RTouchableOpacity from "../RLink/RTouchableOpacity";

const SubMenu = ({ navOverride = undefined }) => {
  const navigation = useNavigation();
  const menu = useShowSubmenu();
  let [currentRoute] = useAtom(currentRouteAtom);
  const { isMediumAndUp, isXXLargeAndUp } = useResponsive();
  const [selected, setSelected] = React.useState<number>();

  const getLargeDeviceStyle = () => {
    if (isMediumAndUp) {
      return { justifyContent: "center" };
    }
  };

  const getLargeDeviceTextStyle = () => {
    if (isMediumAndUp) {
      return { fontSize: 22 };
    }

    if (isXXLargeAndUp) {
      return { fontSize: 22, marginHorizontal: 40 };
    }
  };

  const renderMenuItems = (menu: MenuProps[]) => {
    return menu.map((item: MenuProps, i: number) => {
      const isSelected = selected
        ? selected === i
        : item.route === currentRoute;

      return (
        <View style={styles.optionsContainer} key={`option${item.route}${i}`}>
          <TouchableOpacity
            onPress={() => {
              try {
                console.log("attempting navigation.");
                setSelected(i);
                navigation.navigate(item.route, item.params || {});
              } catch (error) {
                console.log("errror navigating::: ::", error);
              }
            }}
            accessibilityLabel={`${item.title}`}
          >
            <RText
              family="secondary"
              color={isSelected ? "primary" : "secondary"}
              weight="regular"
              style={[getLargeDeviceTextStyle()]}
              dynamicTextStyle={{
                onHover: "underline",
              }}
              uppercase
              // onPress={
              // () => navigation.navigate(item.route)
              // navOverride
              //   ? navOverride(item.route)
              //   : navigation?.navigate(item.route)
              // }
            >
              {item.title}
            </RText>
          </TouchableOpacity>
        </View>
      );
    });
  };

  const renderMenuItemsWeb = (menu: MenuProps[]) => {
    return menu.map((item: MenuProps, i: number) => {
      const isSelected = selected
        ? selected === i
        : item.route === currentRoute;

      return (
        <RTouchableOpacity
          navScreen={item.route}
          navParams={item.params || {}}
          style={styles.optionsContainer}
          key={`option${item.route}`}
        >
          <RText
            family="secondary"
            color={isSelected ? "primary" : "secondary"}
            weight="regular"
            style={[getLargeDeviceTextStyle()]}
            dynamicTextStyle={{
              onHover: "underline",
            }}
            uppercase
          >
            {item.title}
          </RText>
        </RTouchableOpacity>
      );
    });
  };

  return (
    <>
      {menu ? (
        <View style={{ width: "100%", backgroundColor: "#f2f2f2" }}>
          <ScrollView
            style={{ width: "100%" }}
            showsHorizontalScrollIndicator={true}
            horizontal
            contentContainerStyle={[
              styles.subMenuContainer as any,
              getLargeDeviceStyle(),
            ]}
          >
            {Platform.OS == "web"
              ? renderMenuItemsWeb(menu)
              : renderMenuItems(menu)}
          </ScrollView>
        </View>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  subMenuContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 5,
    height: 50,
    flexGrow: 1,
    backgroundColor: variables.colors.palette.neutral.x25,
    justifyContent: "center",
  },
  optionsContainer: {
    marginHorizontal: 10,
  },
});

export default React.memo(SubMenu);
